import {useState, useEffect, useContext} from 'react';
import { useUserContext } from '../context/UserContext';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import {Background} from '../components/CustomProps';
import Box from '@mui/material/Box';
import DataGrid from '../components/DataGrids/DataGrid';
import DataGridOrderAllOrders from '../components/DataGrids/DataGridAllOrders';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import OrdersSidebar from '../components/OrdersSidebar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker} from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


function Orders(props, theme) {

    theme=useTheme(theme)
    const {auth} = useUserContext();

    const [loadingOrder, setLoadingOrder] = useState(true);

    //for all order data
    const [dataGridAll, setDataGridAll] = useState([]);
    //for completed order
    const [dataGridComplete, setDataGridComplete] = useState([]);
    //pending payment order
    const [dataGridPending, setDataGridPending] = useState([]);
    //for cancelled order
    const [dataGridCancelled, setDataGridCancelled] = useState([]);
    //for current order
    const[dataGridcurrentOrder, setDataGridCurrentOrder] = useState([]);

    const [displayItem, setDisplayItem] = useState('');
    const [title, setTitle] = useState('all');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchValue, setSearchValue] = useState([
                                            {
                                            columnField: "invoice",
                                            operatorValue: "contains",
                                            value: null
                                            }
                                        
                                        ]);

    //to retrieve info for the order
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOSTNAME}/quotation/`,
        {
            headers:{
                'Authorization': "Token " + auth.logginToken
            }
        }).then((res) => {
            console.log(res)
            console.log(res.data)

            let pending = []
            let complete = []
            let cancelled = []
            let currentOrder = []

            for(let i of res.data){
                if(i.status === 'Payment Pending' || i.status === 'PP'){
                    pending.push(i)
                }else if(i.status === 'Completed'){
                    complete.push(i)
                }else if(i.status === 'Payment Received'){
                    currentOrder.push(i)
                }else if(i.status === 'Ready for Collection'){
                    currentOrder.push(i)
                }else if(i.status === 'On Delivery'){
                    currentOrder.push(i)
                }else if(i.status === 'Cancelled'){
                    cancelled.push(i)
                }else if(i.status === 'Pending Quotation'){
                    pending.push(i)
                }
            }
            setDataGridAll(res.data)
            setDataGridComplete(complete)
            setDataGridPending(pending)
            setDataGridCancelled(cancelled)
            setDataGridCurrentOrder(currentOrder)
            setLoadingOrder(false)
        }).catch((err) => {
            console.log(err)
            setLoadingOrder(false)
        })
    }, [])


    //to make changes to the displayItem when:
    // 1. the user clicked on the sidebar
    // 2. the user used the date filter
    useEffect(() => {
        if(title === 'all'){
            setDisplayItem(
                <DataGridOrderAllOrders rowData={dataGridAll} setRowData={setDataGridAll} searchValue={searchValue} fromDate={fromDate} toDate={toDate}></DataGridOrderAllOrders>
            )
        }else if(title === 'quotation'){
            setDisplayItem(
                <DataGrid rowData={dataGridPending} setRowData={setDataGridPending} searchValue={searchValue} fromDate={fromDate} toDate={toDate}></DataGrid>
            )
        }
        else if(title === 'currentOrder'){
            setDisplayItem(
                <DataGrid rowData={dataGridcurrentOrder} setRowData={setDataGridCurrentOrder} searchValue={searchValue} fromDate={fromDate} toDate={toDate}></DataGrid>
            )
        }else if(title === 'pastOrder'){
            setDisplayItem(
                <DataGrid rowData={dataGridComplete} setRowData={setDataGridComplete} searchValue={searchValue} fromDate={fromDate} toDate={toDate}></DataGrid>
            )
        }
    }, [title, dataGridAll, dataGridPending, dataGridcurrentOrder, dataGridComplete, searchValue, fromDate, toDate])

    //when user used the invoice filtering textfield
    //https://stackoverflow.com/questions/73200492/how-to-filter-datagrid-columns-on-click-of-an-external-button-in-mui
    const searchInvoice = (event) =>{
        setSearchValue([
                {
                  columnField: "invoice",
                  operatorValue: "contains",
                  value: event.target.value
                }
              
            ])
        console.log(searchValue)
    }

    //when user used the filter by status
    const searchStatus = (event) =>{
        setSearchValue([
                {
                  columnField: "status",
                  operatorValue: "contains",
                  value: event.target.value
                }
              
            ])
        console.log(searchValue)
    }

    //to reset the date filter
    const resetDateFilter = () =>{
        setFromDate(null)
        setToDate(null)
    }



    return (
        <Background sx={{pb:3, flexDirection:'column', backgroundColor:'white'}}>

            <Helmet>
              <title>CutAnything | My Orders</title>
            </Helmet>

            <Typography variant="h4" sx={{ml:10, mb:3}}>
                        Orders History
            </Typography>

            {/* to display the side bar */}
            <Box sx={{display:'flex', flexDirection:'row', flexGrow:1}}>
                <Box sx={
                        {
                            bgcolor:'white', 
                            borderRadius:1, 
                            // boxShadow:3, 
                            minHeight:'450px',
                            ml:-1,
                            border: '1px solid',
                            borderColor:'#D9D9D9'
                        }
                    }>

                        <OrdersSidebar setTitle={setTitle}/>
                    
                </Box>
                

                <Box sx={{flexGrow:1, ml:2, mr:2}}>

                    <Box sx={{display:'flex', border:'1px solid', borderColor:'#D9D9D9', mb:2}}> 

                        {/* for the search bar */}
                        <Box>
                            <TextField
                                id="search"
                                variant='outlined'
                                label='Search by Invoice No.'
                                onChange={searchInvoice}
                                sx={{ m: 2, width: '400px', backgroundColor:'white', borderRadius:1}}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start"><SearchOutlinedIcon/></InputAdornment>,
                                }}
                            />
                        </Box>

                        {/* for the status filter field */}
                        <Box sx={{flexGrow:1, m:2}}>
                            <FormControl fullWidth>
                                <InputLabel id="filterByStatus">Filter by Status</InputLabel>
                                <Select
                                    id='filterByStatus'
                                    label="Filter by Status"
                                    defaultValue=""
                                    onChange={searchStatus}
                                >
                                    <MenuItem value={'Payment Pending'}>Payment Pending</MenuItem>
                                    <MenuItem value={'Pending Quotation'}>Pending Quotation</MenuItem>
                                    <MenuItem value={'Quotation Ready'}>Quotation Ready</MenuItem>
                                    <MenuItem value={'Payment Received'}>Payment Received</MenuItem>
                                    <MenuItem value={'Ready for Collection'}>Ready for Collection</MenuItem>
                                    <MenuItem value={'On Delivery'}>On Delivery</MenuItem>
                                    <MenuItem value={'Completed'}>Completed</MenuItem>
                                    <MenuItem value={null}>Reset</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        
                        {/* for the from date picker */}
                        <Box sx={{m:2, maxWidth:150}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        newValue === null? setFromDate(null):setFromDate(newValue.$d)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>                   
                        
                        {/* for the To date picker */}
                        <Box sx={{m:2, maxWidth:150}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={toDate}
                                    onChange={(newValue) => {
                                        newValue === null? setToDate(null):setToDate(newValue.$d)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>

                        {/* for the reset date filter button */}
                        <Box sx={{m:2}}>
                            <Button 
                                variant="contained"
                                sx={{
                                    p:2,
                                    display: fromDate != null || toDate != null?'':'none'
                                }}
                                onClick={resetDateFilter}
                                >
                                    Reset Date Filter
                            </Button>
                        </Box>
                    </Box>

                    {/* for the datagrid */}
                    {loadingOrder
                    ?
                        <Box sx={
                                {
                                    display:'flex', 
                                    flexDirection:'column', 
                                    alignItems: 'center', 
                                    justifyContent:'center', 
                                    minHeight:'400px',
                                    spacing:2, 
                                    pl:4, 
                                    mb:3
                                }
                            }>
                            <CircularProgress />
                            <Typography 
                                variant="body2" 
                                component="div"  
                                align='left' 
                                sx={{color: theme.palette.neutral.darkgrey1.main}}
                                >
                                    Retrieving your orders
                            </Typography>
                        </Box>
                    :
                        <Box 
                        sx={
                                {    
                                    justifyContent:'center', 
                                    bgcolor:'white', 
                                    pb:5, 
                                    pt:3,
                                    border: '1px solid',
                                    borderColor:'#D9D9D9'
                                }
                            }
                        >
                            {displayItem}
                        </Box>
                    }

                </Box>
                    
            </Box>

        </Background>
    );
}

export default Orders;