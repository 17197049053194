import { useState } from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import EditorSide from '../builder/EditorSide';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

function CreateShape2(props) {
    
    const style = {
        position: "fixed",
        left: "5%",
        top: "5%",
        right: "12.5%",
        height: "75%",
        //borderRadius:"8px",
        display: "contain",
        minWidth:'350px',
        //maxWidth: "1300px",
        maxHeight: "900px",
        float: "none",
        clear: "none",
        backgroundColor: "#F2F4F6",
        overflow:'scroll',
      
        

    };

    const {auth} = useUserContext();
    const handleOpen = () => props.setShow(true);
    const handleClose = () => props.setShow(false);

    const checkComplete = (file_id,i,repeat_count) =>{
        console.log("check for file being initiated,",file_id)
        if (repeat_count<100)
        {setTimeout(
           function() {
              axios.get(`${process.env.REACT_APP_HOSTNAME}/asyncFileUpload/results/`+file_id,
              {
                headers: {
                    'Authorization': 'Token ' + auth.logginToken
                },
                timeout: 35000
            }).then((res) => {
              if (res.data.status){
                console.log("response received")
                console.log(res)
                const details = JSON.parse(res.data.results.details)
                props.addFile(res.data.results,i) //CHANGE TO A LOOKUP ON THE FILE_ID, then UPDATE the corresponding item from there
                if (details.FATAL_ERRORS.length<1)
                {props.updateItemState(i,'success')}
                else
                {props.updateItemState(i,'error')}
    
              }
              else{
                checkComplete(file_id,i,repeat_count+1)
                
              }
    
            }) },5000)}
        else
          { 
            props.addFile({"details":"hohoho"},i)
            props.updateItemState(i,'error')
          }
      }

    return (

            <Modal
                open={props.show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{justifyContent:'center'}}
                
                >
                <Box sx={style}>
                    <Grid container padding={2} direction='column'>
                        {/* <Grid item container direction="row" sx={{display:'flex'}}>
                            <Grid item sx={{flexGrow:1, m:2}}>
                                
                            </Grid>
                            <Grid item sx={{flexGrow:0.1, mt:2}}>
                                <IconButton onClick={()=>{props.setShow(false)}}>
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid> */}

                        <Box sx={{display:'flex', mt:2}}>
                            <Box sx={{flexGrow:1}}>
                                <Typography variant='h6'> Create Simple Shapes</Typography>
                            </Box>

                            <Box>
                                <IconButton onClick={()=>{props.setShow(false)}}>
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    

                        <Divider sx={{backgroundColor:'#555555', mb:3}} />
    
                        <Grid item sx={{display:'flex',justifyContent:'center'}}>
                            <EditorSide sx={{display: 'flex', 
                            bgcolor: `#F2F4F6`,
                            width: "90%",
                            mb: 5
                            }}
                                {...props}
                                checkComplete={checkComplete}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
  


    );
}

export default CreateShape2;