import {useState, useEffect, useContext} from 'react';
import { useUserContext } from '../context/UserContext';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {Background} from '../components/CustomProps';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';
import EditMyAccount from './EditMyAccount';
import AccountDetails from './AccountDetails';


function MyAccount(props, theme) {

    theme=useTheme(theme)
    const {auth} = useUserContext();
    const [account, setAccount] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const TypographyAccountTitle = styled(Typography)(({ theme }) => ({
        color:'#778CA2'
    }));

    //for the account details
    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/self_details/`,
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res)=>{
            console.log(res.data)
            setAccount(res.data)
            setLoading(false)
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
    },[])


    return (
        
        <Background sx={{pb:3, flexDirection:'column', backgroundColor:'white'}}>

            <Helmet>
                <title>CutAnything | My Profile</title>
            </Helmet>

            <Box sx={{display:'flex', flexDirection:'row'}}>
                <Box>
                    <Typography variant="h4" sx={{ml:10, mb:3}}>
                        My Profile
                    </Typography>
                </Box>

                <Box sx={{ml:4, mt:1}}>
                    {!isEditing?<Button 
                    variant='outlined' 
                    onClick={()=>{setIsEditing(!isEditing)}}
                    sx={{
                            borderRadius:50, pl:4, pr:4
                        }}
                    >
                        Edit
                    </Button>:
                    
                    <Button 
                    variant='outlined' 
                    onClick={()=>{setIsEditing(!isEditing)}}
                    sx={{
                        borderRadius:50, pl:4, pr:4, 
                        backgroundColor:'red', 
                        '&:hover': {
                            backgroundColor: 'light-red',
                        },
                    }} 
                    >
                        Cancel Editing
                    </Button>}
                </Box>
            </Box>
            
            {loading
            ?
                <Box sx={
                    {
                        display:'flex', 
                        flexDirection:'column', 
                        alignItems: 'center', 
                        justifyContent:'center', 
                        minHeight:'400px',
                        spacing:2, 
                        pl:4, 
                        mb:3
                    }
                }>
                <CircularProgress />
                <Typography 
                    variant="body2" 
                    component="div"  
                    align='left' 
                    sx={{color: theme.palette.neutral.darkgrey1.main}}
                    >
                        Retrieving your information
                </Typography>
                </Box>
            :
                <Box sx={{display:'flex', flexGrow: 1, justifyContent: 'center', ml:5}}>
                    {isEditing?
                    <EditMyAccount account={account} setIsEditing={setIsEditing} />
                    :
                    <AccountDetails account={account}/>}
                </Box>
            }


        </Background>
    );
}

export default MyAccount;