import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Logout from '@mui/icons-material/Logout';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useUserContext } from '../context/UserContext';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function AccountMenu(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const {auth, setAuth} = useUserContext();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        props.closeNavbar(null)
    };

    const logout = (() => {
        localStorage.clear();
        sessionStorage.clear()
        setAuth({...auth, logginToken:'token removed'})
        navigate('/login')
    })

  return (
    <div>

      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{pl:2, color:'black'}}
      >
        Account
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        {/* <MenuItem onClick={() => {navigate("/my-orders");handleClose()}}>
            <ListItemIcon >
                <FileUploadOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Order
        </MenuItem> }

        <MenuItem onClick={() => {navigate("/checkout");handleClose()}}>
            <ListItemIcon >
                <ShoppingCartOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Checkout
      </MenuItem>*/}

        <Divider />

        <MenuItem onClick={() => {navigate("/account");handleClose()}}>
            <ListItemIcon>
                <PermIdentityOutlinedIcon fontSize="small" />
            </ListItemIcon> 
            My account
        </MenuItem>

        <MenuItem onClick={() => {navigate("/orders");handleClose()}}>
            <ListItemIcon>
                <ShoppingCartOutlinedIcon fontSize="small" />
            </ListItemIcon> 
            My Orders
        </MenuItem>

        <MenuItem  onClick={logout}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Logout
        </MenuItem>

      </StyledMenu>
    </div>
  );
}
