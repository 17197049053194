import {useState} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useWindowDimensions from '../GetWindowSize';
import ColorPicker from '../ColorPicker';

function DeleteMultipleDiscountBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const {height, width} = useWindowDimensions();
    const [color, setColor] = useState('');

    //this is to customise the <Accordion/> component
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
        ))(({ theme }) => ({
        border: `1px solid white`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    //this is to customise the <AccordionSummary/> component
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          {...props}
        />
      ))(({ theme }) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
      }));
    
    //this is to customise the <AccordionDetails/> component
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    //this is to handle the change when user click to expand/collapse the items
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    //this is to delete the items in the backdrop
    const Delete = () =>{
        // console.log(props.dataCostTable)
        // console.log(props.rowCategorySelectionModel)
        console.log('deleting')
        setLoading(true)

        axios.delete(`${process.env.REACT_APP_HOSTNAME}/discount_settings/`, {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            },
            data:{id_list:props.rowDiscountSelectedModel}
        }).then((res)=>{
            console.log(res)
            let data = props.discountTable;
            for(let i=0; i<=props.rowDiscountSelectedModel.length; i++){
                let filteredData = data.filter(function(obj){
                    return obj.id !== props.rowDiscountSelectedModel[i]
                })
                data = filteredData
            }
            props.setDiscountTable(data)
            props.setDeleteMultipleDiscountBackdrop(false)
            setLoading(false)
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
    }

    console.log(props.discountTable)

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.deleteMultipleDiscountBackdrop}
            style={{overflow:'scroll'}}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Delete Discount Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setDeleteMultipleDiscountBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />

                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom
                    sx={{ml:7, color:'red'}}
                >
                    You are about to delete the following discount(s):
                </Typography>

                {
                    props.rowDiscountSelectedModel.map(function(id, i){

                        const output = props.discountTable.filter(function(obj){
                            return obj.id === id
                        })

                        console.log(output)

                        return(
                            // https://mui.com/material-ui/react-accordion/
                            <Accordion 
                                expanded={expanded === `panel${i}`} 
                                onChange={handleChange(`panel${i}`)}
                                sx={{m:5}}
                                key={id}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id={output[0].id}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {output[0].sizing}
                                    </Typography>

                                </AccordionSummary>

                                <AccordionDetails
                                    sx={{m:5}}
                                >
                                <>
                                {/* first row of inputs */}
                                    <Box sx={{display:'flex', ml:5}}>
                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Size</Typography>
                                            <TextField 
                                                id="areaMarkup" 
                                                variant="outlined"
                                                value={output[0].sizing}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>

                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Hard Stop</Typography>
                                            <TextField 
                                                id="hardStop" 
                                                variant="outlined"
                                                value={output[0].hard_stop}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>

                                    {/* second row of inputs */}
                                    <Box sx={{display:'flex', ml:5}}>
                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Soft Stop</Typography>
                                            <TextField 
                                                id="areaMarkup" 
                                                variant="outlined"
                                                value={output[0].soft_stop}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>

                                    {/* third row of inputs */}
                                    <Box sx={{display:'flex', ml:5}}>
                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Max Discount</Typography>
                                            <TextField 
                                                id="areaMarkup" 
                                                variant="outlined"
                                                value={output[0].max_discount}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>

                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Max Discount Quantity</Typography>
                                            <TextField 
                                                id="interiorLineMarkup" 
                                                variant="outlined"
                                                value={output[0].max_discount_quantity}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>

                                    {/* fourth row of inputs */}
                                    <Box sx={{display:'flex', ml:5}}>
                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Discount Breakpoints</Typography>
                                            <TextField 
                                                id="areaMarkup" 
                                                variant="outlined"
                                                value={output[0].discount_breakpoints}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>

                                        <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                                            <Typography variant='body2' gutterBottom>Multiplier</Typography>
                                            <TextField 
                                                id="interiorLineMarkup" 
                                                variant="outlined"
                                                value={output[0].multiplier}
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>
                                    </>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }

                    <Box sx={{display:'flex', flexDirection:'row-reverse', mr:5, mb:5, mt:5}}>
                        <LoadingButton
                            loading = {loading}
                            loadingIndicator = 'Deleting...'
                            variant='contained' 
                            sx={{
                                minWidth:'300px', 
                                minHeight:'50px', 
                                backgroundColor:'red', 
                                '&:hover': {
                                    backgroundColor: 'red',
                                },
                            }} 
                            onClick={Delete}
                        >
                            Delete
                        </LoadingButton>
                    </Box>
            </Paper>
        </Backdrop>
    );
}

export default DeleteMultipleDiscountBackdrop;