import { useEffect,useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import MuiField from '../../components/MuiField';
import { Helmet } from 'react-helmet-async';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Item } from '../../components/CustomProps';
import { useIsFocusVisible, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SecurityUpdateSharp, SportsKabaddiSharp } from '@mui/icons-material';

function SignUp(theme) {
    theme=useTheme(theme)

    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, reset, control } = useForm();

    const watchChecked = watch("sameAsMailingAddress", false);
    const [phoneNum, setPhoneNum] = useState();
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState();
    const [disabled, setDisabled] = useState(false);
    const [buttonMsg, setButtonMsg] = useState("Sign up");
    const [passwordError,setPasswordError] = useState(null)


    const navigateToLogin = () => {
        navigate('/login');
      };
      
      const loginButton = (
        <Button onClick={navigateToLogin}>Go to Login</Button>
      );

    useEffect(() => {
        let weblink = window.location.href;
        if (weblink.includes("?")) {
            const url = new URL(weblink);
            const params = new URLSearchParams(url.search);
            const reference = params.get('reference');
    
            axios.get(`${process.env.REACT_APP_HOSTNAME}/quotation/anon_payment_request_id/${reference}`)
                .then((res) => {
                    reset({
                        email: res.data.email,
                        name: res.data.billing_name,
                        delivery_address_line_1: res.data.billing_address_line_1,
                        delivery_address_line_2: res.data.billing_address_line_2,
                        delivery_postal_code: res.data.billing_postal_code,
                        billing_address_line_1: res.data.billing_address_line_1,
                        billing_address_line_2: res.data.billing_address_line_2,
                        billing_postal_code: res.data.billing_postal_code,
                        phone_number: res.data.billing_number
                    });
                    ['email', 'name', 'delivery_address_line_1', 'delivery_address_line_2', 'delivery_postal_code', 'billing_address_line_1', 'billing_address_line_2', 'billing_postal_code', 'phone_num'].forEach(name => {
                        const input = document.querySelector(`input[name=${name}]`);
                        const label = document.querySelector(`label[for=${name}]`);
                        const legend = document.querySelector(`label[for=${name}] ~ div fieldset legend`);
                        if (input && label && legend) {
                            // Add/remove classes from the label and set data-shrink to true.
                            label.classList.remove('css-klj2xu-MuiFormLabel-root-MuiInputLabel-root');
                            label.classList.add('MuiFormLabel-filled', 'MuiInputLabel-shrink', 'css-501kqo-MuiFormLabel-root-MuiInputLabel-root');
                            label.dataset.shrink = 'true';
                    
                            // Modify the legend's class
                            legend.className = 'css-14lo706';
                        }
                    });
                    
                    
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        console.log(error.response);
                    } else {
                        console.log('Error occurred:', error);
                    }
                });
        }
    }, []);

                   
    const validatePassword = () => {
    if(watch('password') !== watch('confirmPassword')) {
    setPasswordError('Passwords do not match')
    return false
    }
    setPasswordError(null)
    return true
    }

    const onSubmit = data => {
        setDisabled(true);
        setError(false);
        setButtonMsg("authenticating...");
      
        const {
          email,
          password,
          name,
          company_name,
          delivery_address_line_1,
          delivery_address_line_2,
          delivery_postal_code,
          billing_address_line_1,
          billing_address_line_2,
          billing_postal_code,
          phone_number,
        } = data;
        console.log(data)
        console.log(phone_number)

        if (phone_number !== undefined) {
                if (validatePassword()) {
              axios
                .post(`${process.env.REACT_APP_HOSTNAME}/signup/`, {
                  email: email.toLowerCase(),
                  password,
                  name,
                  company_name,
                  delivery_address_line_1,
                  delivery_address_line_2,
                  delivery_postal_code,
                  billing_address_line_1,
                  billing_address_line_2,
                  billing_postal_code,
                  phone_number,
                  domain_url: window.location.host,
                })
                .then((res) => {
                  // handle success
                    console.log(res);
                    console.log('link:', res.data.link);

                    if (res.data.errors.length === 0) {
                    localStorage.setItem('user', res.data.user);
                    localStorage.setItem('token', res.data.token);
                    navigate('/account-verification', { state: res.data.user });
                    } else {
                    console.log('here');
                    let error_list = [];
                    for (let error_key in res.data.errors) {
                        console.log(error_key);
                        console.log(res.data.errors[error_key][0]);

                        let error_field = error_key;
                        if (error_field === 'non_field_errors'){
                            const uniqueSetError = res.data.errors.non_field_errors[0];
                            const match = uniqueSetError.match(/The fields (.+?) must make a unique set\./);
                            console.log(match)
                            if (match) {
                              const field = match[1].split(', ')[1]
                              console.log(field)
                              const existingUserError = `A user with the existing ${field} already exists.`;
                              error_list.push({ field_name: 'Existing user', error: existingUserError });
                            }
                            else {
                                error_list.push({ field_name: error_field, error: res.data.errors[error_key][0] });
                            }
                          }
                        else {
                            if (error_field === 'address_line_1') {
                        error_field = 'street name';
                        } else if (error_field === 'address_line_2') {
                        error_field = 'unit no.';
                        } else if (error_field === 'postal_code') {
                        error_field = 'postal code';
                        } else if (error_field === 'company_name') {
                        error_field = 'company name';
                        } else if (error_field === 'phone_number') {
                        error_field = 'phone number';
                        }
                        
                        error_list.push({ field_name: error_field, error: res.data.errors[error_key][0] });}
                    }

                    console.log(error_list);
                    setErrorMsg(
                        
                        <div>
                            <ul>
                                {error_list.map(function (item, index) {
                                    return <li key={index}>{`${item.field_name}: ${item.error}`}</li>;
                                })}
                            </ul>
                            {error_list.find(item => item.field_name === 'existing user') && loginButton}
                        </div>
                    )
                    setButtonMsg('Sign Up')
                    setDisabled(false)
                    setError(true)
                }})
                .catch((err) => {

                  // handle error {errors: {non_field_errors: ["The fields tenant, phone_number must make a unique set."]}}
                });
            } else {
              setButtonMsg('Sign Up');
              setDisabled(false);
              setError(true);
              setErrorMsg('Passwords do not match');
            }
                  } else {
          setButtonMsg('Sign Up');
          setDisabled(false);
          setError(true);
          setErrorMsg('Phone number is empty');
        }
      };
      

    
    return (
        <div className="App">
            <Helmet>
                <title>Sign Up</title>
            </Helmet>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Box paddingY={{xs:4,md:10}} sx={{ display: 'flex', 
                    bgcolor: `${theme.palette.neutral.lightgrey2.main}`,
                        alignContent:'flex-end',
                        justifyContent:'center',
                        flexDirection:'column',
                        m:-1,
                        mt:1
                    }}>
                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={10} md={6}>
                        <Box padding={{xs:4,md:8}} sx={{flexGrow: 1 , bgcolor: 'white',  borderRadius: 5}}>
                            <Typography variant="h3" fontSize={{xs:28,md:36,lg:48}}  component="div" gutterBottom  align='left' sx={{fontWeight: 'bold', color:'#3898ec',mb:6}}>Sign Up now!</Typography>
                            <Stack spacing={2}>
                {/* Name input field */}
                <TextField
                    {...register("name", { required: true })}
                    id="name"
                    label="Name"
                    error={errors.name ? true : false}
                    helperText={errors.name && "Name is required"}
                />

                {/* Company name input field */}
                <TextField
                    {...register("company_name", { required: true })}
                    id="company_name"
                    label="Company Name"
                    error={errors.company_name ? true : false}
                    helperText={errors.company_name && "Company name is required"}
                />

                {/* Email input field */}
                <TextField
                    {...register("email", { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ })}
                    id="email"
                    label="Email Address"
                    error={errors.email ? true : false}
                    helperText={errors.email && "Email is required"}
                />

                {/* Password input field */}
                <TextField
                    {...register("password", { required: true })}
                    id="password"
                    type="password"
                    label="Password"
                    error={errors.password ? true : false}
                    helperText={errors.password && "Password is required"}
                />

                {/* Confirm password input field */}
                <TextField
                    {...register("confirmPassword", { required: true })}
                    id="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    error={errors.confirmPassword ? true : false}
                    helperText={errors.confirmPassword && "Confirm password is required"}
                />
                </Stack>

                {/* Mailing Address Fields */}
                
                <Box sx={{flexGrow:1, my:2}}>
                    <Typography variant="h6">Mailing Address</Typography>
                </Box>
                    <Stack spacing={2}>
                <TextField
                    {...register("delivery_address_line_1", { required: true })}
                    id="delivery_address_line_1"
                    label="Mail Street Name"
                    error={errors.delivery_address_line_1 ? true : false}
                    helperText={errors.delivery_address_line_1 && "Mail Street Name is required"}
                />
                <TextField
                    {...register("delivery_address_line_2", { required: true })}
                    id="delivery_address_line_2"
                    label="Mail Apartment/Unit No."
                    error={errors.delivery_address_line_2 ? true : false}
                    helperText={errors.delivery_address_line_2 && "Mail Apartment/Unit No. is required"}
                />
                <TextField
                    {...register("delivery_postal_code", { required: true })}
                    id="delivery_postal_code"
                    label="Mail Postal Code"
                    error={errors.delivery_postal_code ? true : false}
                    helperText={errors.delivery_postal_code && "Mail Postal Code is required"}
                    
                />
                </Stack>


                {/* Billing Address Fields */}
                
                <Box sx={{flexGrow:1, my:2}}>
                <Typography variant="h6">Billing Address</Typography>
                    </Box>
                <Stack spacing={2}>
                
                
                <TextField
                    {...register("billing_address_line_1", { required: true })}
                    id="billing_address_line_1"
                    label="Billing Street Name"
                    error={errors.billing_address_line_1 ? true : false}
                    helperText={errors.billing_address_line_1 && "Billing Street Name is required"}
                />
                <TextField
                    {...register("billing_address_line_2", { required: true })}
                    id="billing_address_line_2"
                    label="Billing Apartment/Unit No."
                    error={errors.billing_address_line_2 ? true : false}
                    helperText={errors.billing_address_line_2 && "Billing Apartment/Unit No. is required"}
                />
                <TextField
                    {...register("billing_postal_code", { required: true })}
                    id="billing_postal_code"
                    label="Billing Postal Code"
                    error={errors.billing_postal_code ? true : false}
                    helperText={errors.billing_postal_code && "Billing Postal Code is required"}
                />
                
                <Controller
                    control={control}
                    name="phone_number"
                    defaultValue=""
                    rules={{ required: true, validate: value => isValidPhoneNumber(value) || 'Invalid phone number' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                        <PhoneInput
                        defaultCountry ='SG'
                        placeholder="Enter phone number"
                        value={value}
                        onChange={onChange}
                        inputComponent={MuiField}
                        />
                        {error && <span>{error.message}</span>}
                    </div>
                    )}
                />

            <Button 
                    style={{color:'white', backgroundColor: disabled?'#98A9BC':'#009AF9', padding:20}} 
                    onClick={handleSubmit(onSubmit)}
                    disabled={disabled}
                >
                    {buttonMsg}
                </Button>
                {error?  <Item elevation={0}>{errorMsg}</Item> : <></>}

                            </Stack>
                        </Box>
                    </Grid>
                </Grid>

            </Box>
            </form>
        </div>
    );
    }
    
    export default SignUp;
