import React from 'react';
import {Background} from '../../../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';

function TermsofUse(props, theme) {

    theme=useTheme(theme)

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
              <title>CutAnything | Terms of Use</title>
              <meta name='description' content="Find out more about our term of use policies when you plan to do cutting services with us."/>
              <link rel='canonical' href='/policy/termsofuse'/>
            </Helmet>

            <Typography variant="title">Terms of use</Typography>
            
        </Background>
    );
}

export default TermsofUse;