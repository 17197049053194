import React from "react";
import NumberedSlider from "../../../components/NumberedSlider";

function Oval(props) {
    return (
        <div className="container">

            <div>
                <h3>Radius X (mm)</h3>
                    <NumberedSlider
                    setValue={props.setRadiusX}
                    value={props.radiusX}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1500}
                    />

                <h3>Radius Y (mm)</h3>
                <NumberedSlider
                    setValue={props.setRadiusY}
                    value={props.radiusY}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1500}
                    />
            </div>
      </div>
    );
}

export default Oval;