import React from "react";
import NumberedSlider from "../../../components/NumberedSlider";

function Polygon(props) {
  const { setPolyNumSides, setPolyRadius, setOffsetAngle, polyNumSides, polyRadius, offsetAngle } = props;

  return (
    <div className="container">
      <div>
        <h3>Number of Sides</h3>
        <NumberedSlider
          setValue={setPolyNumSides}
          value={polyNumSides}
          valueLabelDisplay="auto"
          min={0}
          max={10}
        />
        <h3>Radius (mm)</h3>
        <NumberedSlider
          setValue={setPolyRadius}
          value={polyRadius}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />
        <h3>Offset Angle (degrees)</h3>
        <NumberedSlider
          setValue={setOffsetAngle}
          value={offsetAngle}
          valueLabelDisplay="auto"
          min={0}
          max={180}
        />
      </div>
    </div>
  );
}

export default Polygon;
