import React, { useState, useEffect } from 'react';
import { Stack, Box, Button, FormControl, InputLabel, MenuItem, Select, Typography,TextField,Grid} from '@mui/material';
import EmailEditor from "../../components/email-editor/EmailEditor";
import apiClientInstance from '../../components/api-client/apiclient';
import { Background } from '../../components/CustomProps';
import HTMLViewer from '../../components/HTMLViewer';

function EmailTemplateBuilder() {
  const [emailTemplates, setEmailTemplates]= useState([]);
  const [emailComponents, setEmailComponents] = useState([]);
  const [emailTemplateName, setEmailTemplateName] = useState('');
  const [emailTemplateSubject, setEmailTemplateSubject] = useState('');
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');
  const [footer, setFooter] = useState('');
  const [signature, setSignature] = useState('');
  const [loadID, setLoadID] = useState('');
  const [assembledHtml, setAssembledHtml] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isAssembled, setIsAssembled] = useState(true);
  const [mode, setMode] = useState('create');
  
  useEffect(() => {
    fetchEmailComponents();
    fetchEmailTemplates();
  }, []);

  const fetchEmailComponents = async () => {
    try {
      setIsLoading(true);
      const response = await apiClientInstance.get('/emailComponent');
      setEmailComponents(response.data);
    } catch (error) {
      console.error('Error fetching email components:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchEmailTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await apiClientInstance.get('/email-templates');
      setEmailTemplates(response.data);
    } catch (error) {
      console.error('Error fetching email templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, setType) => {
    setType(event.target.value);
  };


  const handleAssemble = async () => {
    let headerHtml = '';
    let bodyHtml = '';
    let signatureHtml = ''
    let footerHtml = '';
  
    if (header) {
      try {
        const response = await apiClientInstance.get(`/emailComponent/${header}`);
        headerHtml = response.data.content;
      } catch (error) {
        console.error('Error fetching header component:', error);
      }
    }
  
    if (body) {
      try {
        const response = await apiClientInstance.get(`/emailComponent/${body}`);
        bodyHtml = response.data.content;
      } catch (error) {
        console.error('Error fetching body component:', error);
      }
    }
  
    if (footer) {
      try {
        const response = await apiClientInstance.get(`/emailComponent/${footer}`);
        footerHtml = response.data.content;
      } catch (error) {
        console.error('Error fetching footer component:', error);
      }
    }

    if (signature) {
      try {
        const response = await apiClientInstance.get(`/emailComponent/${signature}`);
        signatureHtml = response.data.content;
      } catch (error) {
        console.error('Error fetching footer component:', error);
      }
    }
    console.log(headerHtml + bodyHtml + signatureHtml + footerHtml)
    setAssembledHtml(headerHtml + bodyHtml + signatureHtml + footerHtml);
    setIsAssembled(false)
  };

  const handleLoad = async () => {
    const item = emailTemplates.find(obj => obj.id === loadID);
    setHeader(item.header)
    setFooter(item.footer)
    setBody(item.body)
    setSignature(item.signature)
    setEmailTemplateName(item.name)
    setEmailTemplateSubject(item.subject)
    handleAssemble()
    

  };

  const handleSubmit = async () => {
  if(loadID)
  {
    try {
    // Prepare data to send in the POST request
    const data = {
      name: emailTemplateName,
      subject: emailTemplateSubject,
      header: header,
      body: body,
      footer: footer,
      signature: signature
    };

    // Send POST request to backend API endpoint
    const response = await apiClientInstance.patch(`/email-templates/${loadID}/`, data);

    // Handle successful response
    console.log('Response:', response.data);

  } catch (error) {
    // Handle error
    console.error('Error:', error.response);
  }}
  else  {try {
    // Prepare data to send in the POST request
    const data = {
      name: emailTemplateName,
      subject: emailTemplateSubject,
      header: header,
      body: body,
      footer: footer,
      signature: signature
    };

    // Send POST request to backend API endpoint
    const response = await apiClientInstance.post('/email-templates/', data);

    // Handle successful response
    console.log('Response:', response.data);

  } catch (error) {
    // Handle error
    console.error('Error:', error.response);
  }}
};

  const Dropdown = ({components, label, type, setType, isLoading }) => {
    return (
      <FormControl  margin="normal">
        <InputLabel>{label}</InputLabel>
        <Select sx={{width:"100%"}} value={type} onChange={(event) => handleChange(event, setType)} disabled={isLoading}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          { label!=="Load Content"? 
          components.filter((component) => component.component_type === label).map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            )):
            components.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))
             }
        </Select>
      </FormControl>
    );
  };
  

  return (
    <Box sx={{background: "#F2F4F6"}}>
      <Grid container direction='row' spacing={1} justifyContent="space-evenly" sx={{width:"100%",alignItems: "stretch",boxSizing: "border-box"}} >
        <Grid xs={2} item >

          <Box sx={{ display: 'flex', 
              flexDirection: 'column',
              backgroundColor: "#F2F4F6",
              width: '100%',
              height: 'auto'
              }}>

      <Box sx={{ maxWidth: 300, ml: 3 }}>
            <Select
              fullWidth
              value={mode}
              onChange={(event) =>handleChange(event,setMode)}
              style={{ marginBottom: '10px' }}
            >
        <MenuItem value="create">Create Mode</MenuItem>
        <MenuItem value="edit">Edit Mode</MenuItem>
      </Select>
      </Box>
      {mode === 'create' ? (

<></>
) : (
  <>
    <Dropdown
      components={emailTemplates}
      label="Load Content"
      type={loadID}
      setType={setLoadID}
      isLoading={isLoading}
    />
    <Button
      sx={{
        width: '100%',
        height: '48px',
        border: '1px solid #ECEBED',
        borderRadius: '4px',
        color: '#98A9BC',
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
        textTransform: 'none',
        padding: '12px',
        fontColor: '#98A9BC',
      }}
      variant="contained"
      color="primary"
      onClick={handleLoad}
    >
      LOAD
    </Button>
  </>
)}
          <Stack sx={{ maxWidth: 300, m: 3 }} spacing={2}>
            <TextField
          
              label="Template Name"
              value={emailTemplateName}
              onChange={(event) => setEmailTemplateName(event.target.value)}
            />
            <TextField
           
              label="Template Subject"
              value={emailTemplateSubject}
              onChange={(event) => setEmailTemplateSubject(event.target.value)}
            />
            <Dropdown components={emailComponents} label="Header" type={header} setType={setHeader} isLoading={isLoading} />
            <Dropdown components={emailComponents} label="Body" type={body} setType={setBody} isLoading={isLoading} />
            <Dropdown components={emailComponents} label="Footer" type={footer} setType={setFooter} isLoading={isLoading} />
            <Dropdown components={emailComponents} label="Signature" type={signature} setType={setSignature} isLoading={isLoading} />
 

          
          <Button sx={{width:"100%", height:"48px",border: "1px solid #ECEBED",borderRadius: "4px",
          color:"#fff",fontFamily:"Open Sans",fontSize:16,fontWeight:400,lineHeight:"22px",
          textTransform:'none', padding:"12px",fontColor:"#fff", mt:3}} variant="contained" color="primary" onClick={handleAssemble}>
              Preview
            </Button>
            <Button sx={{width:"100%", height:"48px",border: "1px solid #ECEBED",borderRadius: "4px",
          color:"#fff",fontFamily:"Open Sans",fontSize:16,fontWeight:400,lineHeight:"22px",
          textTransform:'none', padding:"12px",fontColor:"#fff"}} variant="contained" color="primary" onClick={handleSubmit} disabled={isAssembled}>
              Create Template
            </Button>
            </Stack>
          </Box>

        </Grid>
        <Grid xs={9} item>
        <Box sx={{ display: 'flex', 
              flexDirection: 'column',
              backgroundColor: "#F2F4F6",
              width: '100%',
              p:5,
              height: 'auto'
              }}>
            <EmailEditor htmlContent={assembledHtml} contentEditable={false} isExportable={false} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EmailTemplateBuilder;
