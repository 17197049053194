import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ConfirmDeleteIcon = (props) => {
    const [isDialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = (e) => {
        e.stopPropagation();
        setDialogOpen(true);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleDelete = () => {
        props.onDelete();
        handleCloseDialog();
    }

    return (
        <>
            <DeleteOutlineIcon 
                background="transparent" 
                onClick={handleOpenDialog} 
                disabled={props.disabled}
                sx={{fill:`black`,  height:"24px", marginX:"12px",marginY:"11px"}}
            />
            

            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
            >
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmDeleteIcon;
