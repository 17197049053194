import {useState} from 'react'
import { Stack,TextField,MenuItem, Box} from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import SelectComponent from './SelectComponent';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

const ManualCard = (props,theme) => {
  theme=useTheme(theme)
  const [costingTable,setCostingTable]=useState(props.costingTable);
  const handleChange = (event) => {

    var costingTable_temp=props.costingTable
    props.setItemCosting(false)
    const orderFilter=[['material',material,setMaterial,setMaterialOptions],
    ['surfacing',surfacing,setSurfacing,setSurfacingOptions],
    ['thickness',thickness,setThickness,setThicknessOptions],
    ['processing_method',method,setMethod,setMethodOptions]]

    try{
      var index=10
    orderFilter.forEach(
      (e,i)=>{

        if(e[0]==event.target.name)
          {e[2](event.target.value)
            index=i
            costingTable_temp=filterTable(e[0],event.target.value,costingTable_temp)
          
            }
        else
        { if(i<index){costingTable_temp=filterTable(e[0],e[1],costingTable_temp)     
}
          else{
            e[2]('')
            e[3](generateOptions(e[0],costingTable_temp))
          }
        }
      })}catch(err){
        console.log('loop exited')
        
      };
    console.log(material,surfacing,thickness,method)
    setCostingTable(costingTable_temp)


}
  const generateOptions = (column,costingTable) => {

    let columnData =
    costingTable.map((item,i) =>
    {
      return item[column]
    })
    let out = [... new Set(columnData)]
 
    let optionsList =
    out.map((item,i) =>
    {
      return {name: item,value:item,text: item}
    })
    
    return optionsList

  };

  const filterTable = (column,value,costingTablein) => {
    console.log('filtering: ',costingTablein.length,'on: ',column,'with:',value)
    const costingTableout=costingTablein.filter((e)=>{return e[column]==value})

    return costingTableout
  };

  const handleChange2= (event) => {
    handleChange(event)
    if (costingTable.length==1){
    props.setItemCosting(costingTable[0].id)}}
  
  


  var [materialOptions,setMaterialOptions] = useState(generateOptions('material',props.costingTable))
  var [surfacingOptions,setSurfacingOptions] = useState([])
  var [thicknessOptions,setThicknessOptions] = useState([])
  var [methodOptions,setMethodOptions] = useState([])


  const [material,setMaterial] = useState('');
  const [surfacing,setSurfacing] = useState('');
  const [thickness,setThickness] = useState(0);
  const [method,setMethod] = useState('');
  const [quantity,setQuantity] = useState(1);




      const fileName = props.fileName


    
 



      const FileHeader = () =>
    {return (<Stack height="60px" flexDirection="row" alignItems="center" bgcolor={theme.palette.error.light}>
            <Stack direction="row" alignItems="center"><DoNotDisturbAltOutlinedIcon background="transparent"   sx={{fill:`${theme.palette.error.main}`,  height:"24px", marginX:"12px",marginY:"11px"}}/>
            <Typography variant="filename">{fileName}</Typography></Stack>
            
            <Typography flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1">{fileName}</Typography>
            <DeleteOutlineIcon background="transparent" 
              sx={{fill:`black`,  height:"24px", marginX:"12px",marginY:"11px"}}/>
        </Stack>)}


      return (
        <>
          <Stack   sx={{ borderRadius: "8px 8px 0px 0px", width:'100%', height:'contain', left:56, top:160, background:"#FFFFFF",boxSizing: "border-box"}}>
              <FileHeader/>
            
                <Stack sx={{m:2}}  flexDirection="row">

                  <Stack flex={2} >
                    <Stack flex={2} flexDirection="row">
                      <Box flex={1} sx={{alignItems:'center', display:'flex',m:3,justifyContent:'center',maxHeight:"267px",maxWidth:"272px" }}  >
                      {props.imageUrl==null
                            ?
                            <>
                                <BrokenImageIcon sx={{border: "1px solid #ECEBED",borderRadius:1,Height:"267px",Width:"272px"}}/>
                            </>
                            :
                            <>
                                <img style={{border: "1px solid #ECEBED",borderRadius:1,maxHeight:"267px",maxWidth:"272px"}} src={props.imageUrl}/>
                            </>
                            }
                      </Box>

                      <Stack flex={1} sx={{m:3,mt:0}}>
                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Material</Typography>
                        <SelectComponent name="material" items={materialOptions} selected={material} handleSelect={handleChange}/>

                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}}  variant="caption1">Color/Finishing</Typography>
                        <SelectComponent name="surfacing" items={surfacingOptions} selected={surfacing} handleSelect={handleChange}/>

                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Thickness</Typography>
                        <SelectComponent name="thickness" items={thicknessOptions} selected={thickness} handleSelect={handleChange}/>
                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Processing Method</Typography>
                        <SelectComponent name="processing_method" items={methodOptions} selected={method}  handleSelect={handleChange2}/>
                      </Stack>
                    </Stack>

                  </Stack>
                    
                  <Stack flex={1} sx={{m:3,mt:0,mr:0}}>
                    <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Quantity</Typography>
                    <input type="number" placeholder="1" style={{width:"auto", height:"auto",border: "1px solid #ECEBED",borderRadius: "4px",
                      color:"#1B1E24",fontFamily:"Open Sans",fontSize:16,fontWeight:600,lineHeight:"22px",
                      textTransform:'none', padding:"12px", fontColor:"#98A9BC"}} onChange={(e) => {setQuantity(e.target.value);props.setQuantity(e.target.value)}} value={quantity}></input>
                    <Stack  sx={{mt:3,mb:1,}} justifyContent="space-between">
                    <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">{"Remarks (if any)"}</Typography>
                    <TextField sx={{m:2}} multiline={true} rows={4} onChange={(e)=>props.setComments(e.target.value)}/>
                    </Stack>
                  </Stack>

                </Stack>
          </Stack>

          <Typography variant="subtitle1" align='center' sx={{color:theme.palette.neutral.darkgrey2.main, pt:5}}>You may close this window if you choose not to request for further quotation enquiry.</Typography>
        </>
    )}


export default ManualCard;