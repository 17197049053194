import {useState, useRef} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from '../GetWindowSize';

function AddMaterialBackprop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Submit');
    const [uploadResult, setUploadResult] = useState('none');
    const {height, width} = useWindowDimensions();

    //for the useRef for the fields
    const [category, setCategory] = useState('');
    const [processingMethod, setProcessingMethod] = useState('');
    const inputMaterial = useRef(null);
    const inputSurfacing = useRef(null);
    const inputMaxLength = useRef(null);
    const inputMaxWidth = useRef(null);
    const inputThickness = useRef(null);
    const inputRouterSpeed = useRef(null);
    const inputRunCostHour = useRef(null);
    const inputBaseUnitCostArea = useRef(null);
    // const inputUnitCostArea = useRef(null);
    // const inputUnitCostLength = useRef(null);


    const handleCategoryChange = (event) => {
        console.log(event)
        if(event.target.name === 'category'){
            setCategory(event.target.value);
        }else if(event.target.name === 'processingMethod'){
            setProcessingMethod(event.target.value)
        }

    };

    const submit = (props) => {
        setLoading(true)
        setSubmitText('Processing')

        // console.log(category)
        // console.log(inputMaterial.current.value)
        // console.log(processingMethod)
        // console.log(inputSurfacing.current.value)
        // console.log(inputMaxLength.current.value)
        // console.log(inputMaxWidth.current.value)
        // console.log(inputThickness.current.value)
        // console.log(inputRouterSpeed.current.value)
        // console.log(inputRunCostHour.current.value)
        // console.log(inputBaseUnitCostArea.current.value)
        // console.log(inputUnitCostArea.current.value)
        // console.log(inputUnitCostLength.current.value)


        axios.post(`${process.env.REACT_APP_HOSTNAME}/cost/`, 
            {
                category_name: category,
                material: inputMaterial.current.value,
                processing_method: processingMethod,
                surfacing: inputSurfacing.current.value,
                max_length: inputMaxLength.current.value,
                max_width: inputMaxWidth.current.value,
                thickness: inputThickness.current.value,
                router_speed: inputRouterSpeed.current.value,
                run_cost_hour: inputRunCostHour.current.value,
                base_unit_cost_area: inputBaseUnitCostArea.current.value,
            },
            {
                headers:{
                    'Authorization': 'Token ' + auth.logginToken
                }
            }
        ).then((res)=>{
            // console.log(res)

            axios.get(`${process.env.REACT_APP_HOSTNAME}/recalc-costs/`,
                {
                    headers: {
                        'Authorization': 'Token ' + auth.logginToken
                    }
                }
            ).then((res)=>{
                // console.log(res)
                setLoading(false)
                setSubmitText('submit')
                setUploadResult('success')

            }).catch((err)=>{
                console.log(err)
                setLoading(false)
                setSubmitText('submit')
                setUploadResult('fail')

            })
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
            setSubmitText('submit')
            setUploadResult('fail')

        })
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.addBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Add New Material</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setAddBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />
                
                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Choose Category</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="category"
                                id="category"
                                name="category"
                                value={category}
                                onChange={handleCategoryChange}
                            >
                                <MenuItem value={'Metal'}>Metal</MenuItem>
                                <MenuItem value={'Wood'}>Wood</MenuItem>
                                <MenuItem value={'Plastic'}>Plastic</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Material</Typography>
                        <TextField id="material" variant="outlined" inputRef={inputMaterial} fullWidth/>
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Processing Method</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="processingMethod"
                                id="processingMethod"
                                name='processingMethod'
                                value={processingMethod}
                                onChange={handleCategoryChange}
                            >
                            <MenuItem value={'CNC Router'}>CNC Router</MenuItem>
                            <MenuItem value={'Fiber Laser'}>Fiber Laser</MenuItem>
                            <MenuItem value={'CO2 Laser'}>CO2 Laser</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <TextField id="processingMethod" variant="outlined" inputRef={inputProcessingMethod} fullWidth/> */}
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Surfacing</Typography>
                        <TextField id="surfacing" variant="outlined" inputRef={inputSurfacing} fullWidth/>
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Length (mm)</Typography>
                        <TextField id="maxLength" variant="outlined" inputRef={inputMaxLength} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Width (mm)</Typography>
                        <TextField id="maxWidth" variant="outlined" inputRef={inputMaxWidth} fullWidth/>
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Thickness</Typography>
                        <TextField id="thickness" variant="outlined" inputRef={inputThickness} fullWidth/>
                    </Box>
                </Box>

                {/* fifth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Router Speed</Typography>
                        <TextField id="routerSpeed" variant="outlined" inputRef={inputRouterSpeed} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Run Cost Per Hour</Typography>
                        <TextField id="runCostHour" variant="outlined" inputRef={inputRunCostHour} fullWidth/>
                    </Box>
                </Box>

                {/* sixth row of inputs */}
                {/* <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Unit Cost Area</Typography>
                        <TextField id="unitCostArea" variant="outlined" inputRef={inputUnitCostArea} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Unit Cost Length</Typography>
                        <TextField id="unitCostLength" variant="outlined" inputRef={inputUnitCostLength} fullWidth/>
                    </Box>
                </Box> */}

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.7, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Base Unit Cost Area</Typography>
                        <TextField id="baseUnitCostAread"  variant="outlined" inputRef={inputBaseUnitCostArea} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maxWidth}}>
                        <Button 
                                variant='contained'
                                onClick={()=>{submit(props)}}
                                sx={{
                                    minWidth:'300px', 
                                    minHeight:'50px',
                                    backgroundColor:loading?'#98A9BC':'#009AF9',
                                    mb:2
                                }} 
                            >
                            {submitText}
                        </Button>

                        {uploadResult === 'none'
                        ?
                            <></>
                        :
                        uploadResult === 'success'
                            ?
                                <Typography sx={{color:'green'}}>
                                    The new material has been successfully added. Please wait for a while for the data to be updated in the database.
                                </Typography>
                            :
                                <Typography sx={{color:'red'}}>
                                    Please check the above information again
                                </Typography>
                        }

                    </Box>
                </Box>

            </Paper>
        </Backdrop>
    );
}

export default AddMaterialBackprop;