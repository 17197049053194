// ProductPage.jsx file
import { useState, useEffect } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import apiClientInstance from '../../components/api-client/apiclient'; 
import ProductDescription from './ProductDescription';

function ProductPage() {
  const [product, setProduct] = useState(null);
  const { productname } = useParams();
  const location = useLocation();
  const fetchData = ({ value, setData, endpoint, processData }) => {
    if (value) {
        setData(value);
      } else {
        return apiClientInstance.fetchData(endpoint, setData, processData);
      }
    };

  useEffect(() => {
    const { state } = location;
    const dataFetchOperations = [
      { value: state?.product, setData: setProduct, endpoint: `/products?name=${productname}`, processData: response => response[0]},
    ];

        Promise.all(dataFetchOperations.map(fetchData)).catch(error => console.error(error));
    
    }, []);

  console.log(product)
  if (!product ) {
    return <div>Loading...</div>;
  }

  return (
    <ProductDescription 
      product={product} 
      discountTable={product.discount_table} 
      categoryTable={product.category_table} 
      costTable={product.costings} 
      details = {JSON.parse(product.file.details)}
    />
  );
}

export default ProductPage;
