
import { useState, useEffect } from 'react';
import {  MenuItem, Select } from '@mui/material';
var ReactSafeHtml = require('react-safe-html');
function HTMLViewer(props) {
  const { htmlContent, baseStyle } = props;
  const [additionalStyle, setAdditionalStyle] = useState({});

  const handleAddStyle = () => {
    setAdditionalStyle({
      backgroundColor: 'yellow',
      padding: '10px',
    });
  };

  const getMergedStyle = () => {
    return { ...baseStyle, ...additionalStyle };
  };
  const [viewportSize, setViewportSize] = useState('desktop');
  
  const handleViewportSizeChange = (event) => {
    setViewportSize(event.target.value);
    const viewportStyles = {
      desktop: { width: '1024px', height: 'auto'},
      tablet: { width: '768px', height: 'auto' },
      mobile: { width: '375px', height: 'auto'},
    };

    const styles = viewportStyles[event.target.value];
    setAdditionalStyle(styles);
  };

  return (
    <div>
      <Select
            value={viewportSize}
            onChange={handleViewportSizeChange}
            style={{ marginBottom: '10px' }}
            >
            <MenuItem value="desktop">Desktop</MenuItem>
            <MenuItem value="tablet">Tablet</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
        </Select>
    
      <ReactSafeHtml html={htmlContent} style={getMergedStyle()} />
    </div>
  );
}

export default HTMLViewer;