import {useState, useEffect} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import useWindowDimensions from '../GetWindowSize';
import ColorPicker from '../ColorPicker';

function DeleteCategoryBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const categoryInfo = props.deleteCategoryInfo;
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Delete');
    const [uploadResult, setUploadResult] = useState('none');
    const {height, width} = useWindowDimensions();
    const [color, setColor] = useState('');

    const Delete = () =>{
        console.log('delete')
        setSubmitText('Deleting...')
        setLoading(true)
        setUploadResult('none')

        axios.delete(`${process.env.REACT_APP_HOSTNAME}/get-category-table/`,
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            },
            data:{id_list:[categoryInfo.id]}
        }).then((res)=>{
            console.log(res)

            const output = props.categoryTable.filter(function(obj){
                return obj.id !== categoryInfo.id
            })
            setLoading(false)
            props.setCategoryTable(output)
            props.setDeleteCategoryBackdrop(false)
        }).catch((err)=>{
            console.log(err)
            setLoading(true)
            setUploadResult('fail')
        })

    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.deleteCategoryBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Delete Category Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setDeleteCategoryBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />

                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom
                    sx={{ml:7, color:'red'}}
                >
                    You are about to delete the following material:
                </Typography>

                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Category</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={categoryInfo.category_name}
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Area Markup</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={categoryInfo.area_markup}
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Interior Line Markup</Typography>
                        <TextField 
                            id="interiorLineMarkup" 
                            variant="outlined"
                            value={categoryInfo.interior_line_markup}
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Line Markup</Typography>
                        <TextField 
                            id="lineMarkup" 
                            variant="outlined"
                            value={categoryInfo.line_markup}
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Minimum Cost</Typography>
                        <TextField 
                            id="minCost" 
                            variant="outlined"
                            value={categoryInfo.minimum_cost}
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Color (hex value)</Typography>
                        <Box sx={{mt:3}}>
                            <ColorPicker
                                color={categoryInfo.color}
                                setColor={setColor}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', flexDirection:'row-reverse', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maxWidth}}>
                        <Button 
                                variant='contained'
                                onClick={()=>{Delete(props)}}
                                disabled={loading}
                                sx={{
                                    minWidth:'300px', 
                                    minHeight:'50px', 
                                    backgroundColor:'red', 
                                    '&:hover': {
                                        backgroundColor: 'red',
                                    },
                                }} 
                            >
                            {submitText}
                        </Button>

                        {uploadResult === 'none'
                        ?
                            <></>
                        :
                        uploadResult === 'success'
                            ?
                                <Typography sx={{color:'green'}}>
                                    The new category has been successfully deleted.
                                </Typography>
                            :
                                <Typography sx={{color:'red'}}>
                                    Seems like there is a problem in with the server. Please try again later. Sorry for the inconvenience.
                                </Typography>
                        }

                    </Box>
                </Box>
            
            </Paper>
        </Backdrop>
    );
}

export default DeleteCategoryBackdrop;