import React, { useState,useRef,useEffect } from 'react';
import EmailEditor from "../../components/email-editor/EmailEditor";
import { Button,Stack, TextField, MenuItem, Box, Grid, Typography, FormControl,InputLabel, Select} from '@mui/material';
import { Background } from "../../components/CustomProps";
import apiClientInstance from '../../components/api-client/apiclient';
import SelectComponent from '../../components/SelectComponent';

function EmailComponentPage() {
  const [componentName, setComponentName] = useState('');
  const [componentType, setComponentType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [loadID, setLoadID] = useState('');
  const [emailComponents, setEmailComponents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState('create');
  const componentNameRef = useRef(componentName);
  const componentTypeRef = useRef('');
  const items = [
    {
      name: "Header",
      value: "Header",
      text: "Header",
    },
    {
      name: "Footer",
      value: "Footer",
      text: "Footer",
    },
    {
      name: "Body",
      value: "Body",
      text: "Body",
    },
    {
      name: "Signature",
      value: "Signature",
      text: "Signature",
    },
  ];
  const style = () => {
      return {width:"100%", height:"48px",border: "1px solid #ECEBED",borderRadius: "4px",
      background:"#fff", fontWeight:600,fontFamily:"Open Sans",fontSize:16,lineHeight:"22px",
      textTransform:'none', padding:"12px",fontColor:"#98A9BC"}
    
  }

  const handleLoad = async () => {
    setSuccessMessage('')
    const item = emailComponents.find(obj => obj.id === loadID);
    console.log(item)
    componentTypeRef.current=item.component_type
    componentNameRef.current=item.name
    setComponentName(item.name)
    try {
      const response = await apiClientInstance.get(`/emailComponent/${loadID}`);
      setHtmlContent(response.data.content);
    } catch (error) {
      console.error('Error fetching email component:', error);
      setErrorMessage('Error: Failed to load the component.');
    }
  };
  
  const handleModeChange = (event) => {
    setMode(event.target.value);
  };
  const handleChange = (event, setType) => {
    setType(event.target.value);
  };

  const handleSubmit = async (html, componentName, componentType) => {
    console.log(componentName, componentType)
    if (loadID)
    {try {
      // Prepare data to send in the POST request
      const data = {
        content: html,
        name: componentName,
        component_type: componentType,
      };

      // Send POST request to backend API endpoint
      const response = await apiClientInstance.patch(`/emailComponent/${loadID}/`, data);

      // Handle successful response
      console.log('Response:', response.data);
      setSuccessMessage('Component created successfully.');
      setErrorMessage('');

    // Reset the form fields
    componentNameRef.current='';
    componentTypeRef.current='';

    } catch (error) {
      // Handle error
      console.error('Error:', error.response);
    setErrorMessage('Error: Failed to create the component.');

    }}
    else
    {try {
      // Prepare data to send in the POST request
      const data = {
        content: html,
        name: componentName,
        component_type: componentType,
      };

      // Send POST request to backend API endpoint
      const response = await apiClientInstance.post('/emailComponent/', data);

      // Handle successful response
      console.log('Response:', response.data);
      setSuccessMessage('Component created successfully.');
      setErrorMessage('');

    // Reset the form fields
    componentNameRef.current='';
    componentTypeRef.current='';

    } catch (error) {
      // Handle error
      console.error('Error:', error.response);
    setErrorMessage('Error: Failed to create the component.');

    }}
  };

  
  const fetchEmailComponents = async () => {
    try {
      setIsLoading(true);
      const response = await apiClientInstance.get('/emailComponent');
      setEmailComponents(response.data);
    } catch (error) {
      console.error('Error fetching email components:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const Dropdown = ({ label, type, setType, isLoading }) => {
    return (
      <FormControl fullWidth margin="normal">
        <InputLabel>{label}</InputLabel>

        <Select style={style()}
      value={type} onChange={(event) => handleChange(event, setType)} disabled={isLoading}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {emailComponents
            .map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    fetchEmailComponents();
  }, []);

  const handleComponentNameChange = (event) => {
    setComponentName(event.target.value);
    componentNameRef.current = event.target.value;
    console.log('Component Name:', event.target.value);
  };
  
  const handleComponentTypeChange = (event) => {
    setComponentType(event.target.value);
    componentTypeRef.current = event.target.value;
    console.log('Component Type:', event.target.value);
  };

  return (
    <Background sx={{ dislay: 'flex', flexDirection: 'column' }}>
    <Typography variant="h4" sx={{ m: 3 }}>
      Email Component Editor
    </Typography>
    <Box sx={{ maxWidth: 300, ml: 3 }}>
      <Select
        fullWidth
        value={mode}
        onChange={handleModeChange}
        style={{ marginBottom: '10px' }}
      >
        <MenuItem value="create">Create Mode</MenuItem>
        <MenuItem value="edit">Edit Mode</MenuItem>
      </Select>
      </Box>
      <Box sx={{ maxWidth: 300, ml: 3 }}>
      <>
          <TextField
            label="Component Name"
            value={componentName}
            onChange={handleComponentNameChange}
            fullWidth
            margin="normal"
          />
          <SelectComponent
            label="Component Type"
            fullWidth
            margin="normal"
            name="category"
            items={items}
            handleSelect={handleComponentTypeChange}
            selected={componentTypeRef.current}
          />
        </>
      {mode === 'create' ? (

      <></>
      ) : (
        <>
          <Dropdown
            label="Load Content"
            type={loadID}
            setType={setLoadID}
            isLoading={isLoading}
          />
          <Button
            sx={{
              width: '100%',
              height: '48px',
              border: '1px solid #ECEBED',
              borderRadius: '4px',
              color: '#98A9BC',
              fontFamily: 'Open Sans',
              fontSize: 16,
              fontWeight: 400,
              lineHeight: '22px',
              textTransform: 'none',
              padding: '12px',
              fontColor: '#98A9BC',
            }}
            variant="contained"
            color="primary"
            onClick={handleLoad}
          >
            LOAD
          </Button>
        </>
      )}
    </Box>
        {successMessage && (
          <Typography variant="subtitle1" color="success" sx={{ ml: 3 }}>
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography variant="subtitle1" color="error" sx={{ ml: 3 }}>
            {errorMessage}
          </Typography>
        )}
      <Box sx={{ display: 'flex', minHeight: '650px', m: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <EmailEditor
            onSubmit={handleSubmit}
            componentNameRef={componentNameRef}
            componentTypeRef={componentTypeRef}
            htmlContent={htmlContent}
            isExportable={true}
          />
        </Box>
      </Box>
    </Background>
  );
}

export default EmailComponentPage;
