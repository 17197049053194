import { useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import {   Grid,   Card,   Box,   Typography,   TextField,   Stack,   useTheme,   styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PhoneInput from 'react-phone-number-input';
import MuiField from '../components/MuiField';
import { useUserContext } from '../context/UserContext';

function EditMyAccount(props,theme) {

    theme=useTheme(theme);
    const navigate = useNavigate();
    const {auth} = useUserContext();
    const [account, setAccount] = useState(props.account);
    const [updating, setUpdating] = useState(false);
    const [billingContact, setBillingContact] = useState(props.account.phone_number);

    //for the inputRef
    const inputName = useRef(null);
    const inputCompany = useRef(null);
    const inputDeliveryAddress = useRef(null);
    const inputDeliveryUnitNumber = useRef(null);
    const inputDeliveryPostalCode = useRef(null);
    const inputBillingAddress = useRef(null);
    const inputBillingUnitNumber = useRef(null);
    const inputBillingPostalCode = useRef(null);

    const edit = () => {
        setUpdating(true)
        console.log(account.email)
        console.log(inputName.current.value)
        console.log(inputCompany.current.value)
        // console.log(inputPhone.current.value)
        console.log(inputDeliveryAddress.current.value)
        console.log(inputDeliveryUnitNumber.current.value)
        console.log(inputDeliveryPostalCode.current.value)
        console.log(inputBillingAddress.current.value)
        console.log(inputBillingUnitNumber.current.value)
        console.log(inputBillingPostalCode.current.value)
        console.log(billingContact)

        const updatedUserInfo = {
            name: inputName.current.value,
            phone_number: billingContact,
            company_name: inputCompany.current.value,
            delivery_address_line_1: inputDeliveryAddress.current.value,
            delivery_address_line_2: inputDeliveryUnitNumber.current.value,
            delivery_postal_code: inputDeliveryPostalCode.current.value,
            billing_address_line_1: inputBillingAddress.current.value,
            billing_address_line_2: inputBillingUnitNumber.current.value,
            billing_postal_code: inputBillingPostalCode.current.value
        }

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/users/update/`, updatedUserInfo, 
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }
        ).then((res) => {
            setUpdating(false)
            console.log(res)
            props.setIsEditing(false)
        }).catch((err) => {
            setUpdating(false)
            console.log(err)
        })
    }

    const StyledCard = styled(Card)(({ theme }) => ({
        cornerRadius:"5px"
    }));


    return (
        <Box sx={{ justifyContent: 'center', ml:5,minWidth:'50%',maxWidth:'60%'}}>
        <Grid container spacing={2} direction='column'>
  
          {/* Basic Info */}
          <Grid item xs={12} md={4}>
            <StyledCard variant="outlined">
              <Grid container direction="row" spacing={2} p={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Basic Info</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField id='name' label='Full Name' defaultValue={account.name} inputRef={inputName} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='company' label='Company' defaultValue={account.company_name} inputRef={inputCompany} fullWidth />
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
  
          {/* Contact Info */}
          <Grid item xs={12} md={4}>
            <StyledCard variant="outlined">
              <Grid container direction="row" spacing={2} p={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Contact Info</Typography>
                </Grid>
                <Grid item xs={6}>
                <PhoneInput 
                        placeholder="Enter phone number"
                        value={billingContact}
                        onChange={setBillingContact}
                        defaultCountry='SG'
                        inputComponent={MuiField}
                    />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='email' label='Email' defaultValue={account.email} disabled fullWidth />
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
  
          {/* Addresses */}
          <Grid item xs={12} md={4}>
            <StyledCard variant="outlined">
              <Grid container direction="row" spacing={2} p={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Address</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField id='deliveryAddress' label='Delivery Address' defaultValue={account.delivery_address_line_1} inputRef={inputDeliveryAddress} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='deliveryUnit' label='Delivery Unit no.' defaultValue={account.delivery_address_line_2} inputRef={inputDeliveryUnitNumber} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='deliveryPostal' label='Delivery Postal Code' defaultValue={account.delivery_postal_code} inputRef={inputDeliveryPostalCode} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='billingAddress' label='Billing Address' defaultValue={account.billing_address_line_1} inputRef={inputBillingAddress} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='billingUnit' label='Billing Unit no.' defaultValue={account.billing_address_line_2} inputRef={inputBillingUnitNumber} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id='billingPostal' label='Billing Postal Code' defaultValue={account.billing_postal_code} inputRef={inputBillingPostalCode} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton 
                        loading={updating} 
                        loadingIndicator="Updating..." 
                        variant='contained' 
                        onClick={edit}
                        >
                            Update
                    </LoadingButton>
                    </Grid>
              </Grid>
            </StyledCard>
          </Grid>

          
          
        </Grid>
      </Box>
    );
}

export default EditMyAccount;