import {useRef, useState} from 'react';
import {Background, ResponsiveGridContainer} from '../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet-async';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LogoPhrase from '../components/LogoPhrase';
import Paper from '@mui/material/Paper';

function ContactUs(theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();

    const inputName = useRef(null);
    const inputEmail = useRef(null);
    const inputMessage = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonMsg, setButtonMsg] = useState('Submit');
    const [error, setError] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const widthsize = 310;

    //to validate email by checking if there is a "@" and ".<string>" and return error message if empty
    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        setEmailError(!re.test(email))
    }

    //to validate if the name field is not empty and return error message if empty
    const validateName = (name) => {
        if(name.length > 0){
            setNameError(false)
        }else{
            setNameError(true)
        }
    }

    //to validate if the message field is not empty and return error message if empty
    const validateMessage = (message) => {
        if(message.length > 0){
            setMessageError(false)
        }else{
            setMessageError(true)
        }
    }

    //to check if all the validation is valid
    const validateInputs = () => {
        if(!emailError && 
            inputName.current.value.length > 0 &&
            inputEmail.current.value.length > 0 &&
            inputMessage.current.value.length > 0){
            return true
        }else{
            return false
        }
    }

    //https://formsubmit.co/documentation
    const submit = () => {
        setLoading(true)
        setError(false)
        setValidationError(false)
        setButtonMsg('Submitting...')
        
        //if all the validation is valid, proceed to submit API request
        if(validateInputs()){
            //change to the email that you want to send the customer's message to!!!!!!!!!!!!!!!!!!!!!
            axios.post('https://formsubmit.co/ajax/admin@cutanything.co', 
            {
                name: inputName.current.value,
                email: inputEmail.current.value,
                message: inputMessage.current.value
            },
            {
                headers:{
                    'Content-Type': 'application/json'
                }
            }
            ).then((res) =>{
                navigate('/contact-us-success')
            }).catch((err)=>{
                console.log(err)
                setError(true)
                setLoading(false)
                setButtonMsg("Submit")
            })
        }else{
            setValidationError(true)
            setError(false)
            setButtonMsg('Submit')
            setLoading(false)
        }

    }

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
                <title>CutAnything | Contact Us</title>
                <meta name='description' content="Got any question for us? Enter your question here and we will get back to you soon!"/>
                <link rel='canonical' href='/contact-us'/>
            </Helmet>

            
            <ResponsiveGridContainer container spacing={{
                        xs: 0,
                        sm: 0,
                        md: 2
                }} sx={{alignItems:'center',justifyContent:'space-evenly'}}
            >

                <LogoPhrase/>

                <Grid item xs='auto'>
                    <Box 
                        padding={{xs:3, sm: 3}} 
                        paddingLeft={{xs: 2}} 
                        sx={{bgcolor: 'white',  borderRadius: 5}}
                    >
                        <Stack>
                            <Typography textAlign='center' variant='h5' sx={{mb:3}}>Get in Touch</Typography>
                            <Typography textAlign='center' variant='subtitle2' sx={{color:theme.palette.neutral.darkgrey1.main, mb:3}}>Need something done? Talk to us.</Typography>
                        </Stack>

                        <Stack direction='column' spacing={5} >
                            <TextField  
                                id="name" 
                                label="Name" 
                                inputRef={inputName} 
                                onChange={(e)=>validateName(e.target.value)}
                                error = {nameError}
                                helperText={nameError?"Please enter a name":null}
                                style = {{width: widthsize}} 
                                required
                            />

                            <TextField  
                                id="email" 
                                label="Email" 
                                inputRef={inputEmail}
                                onChange={(e)=>validateEmail(e.target.value)}
                                error = {emailError}
                                helperText={emailError?"Please enter a valid email":null}
                                style = {{width: widthsize}}  
                                required
                            />

                            <TextField
                                id="message"
                                label="Message"
                                multiline
                                rows={4}
                                sx={{mb:3}}
                                style = {{width: widthsize}}
                                inputRef={inputMessage}
                                onChange={(e)=>validateMessage(e.target.value)}
                                error = {messageError}
                                helperText={messageError?"Please enter message":null}
                            />

                            <Button variant='contained' style={{width: widthsize}} onClick={submit} disabled={loading}>{buttonMsg}</Button>
                        </Stack>

                        {/* if there is any empty field */}
                        {validationError
                        ?
                            <Paper sx={{bgcolor:theme.palette.error.main, color:'white', mt:2, p:2}}>
                                <Box sx={{display:'flex', justifyContent:'center'}}>
                                    Please fill in all the blanks!
                                </Box>
                            </Paper>                        
                        :
                            <></>

                        }
                        
                        {/* if there is any error with the API call */}
                        {error
                        ?
                            <Paper elevation={3} sx={{bgcolor:theme.palette.error.main, color:'white'}}>
                                <Box sx={{display:'flex', justifyContent:'center'}}>
                                    Submitting was unsuccessful. Please try again!
                                </Box>
                            </Paper>
                        :
                        <></>
                        }
                    </Box>

                </Grid>
                
                

            </ResponsiveGridContainer>

            
            
        </Background>
    );
}

export default ContactUs;


{/* <Box 
                sx={
                        {
                            display:'flex', 
                            flexGrow:0.8, 
                            flexDirection:'column', 
                            bgcolor:'white', 
                            alignItems: 'center',
                            borderRadius:5,
                            pt:5, 
                            pb:5
                        }
                    }
            >
                <Typography variant='h3' sx={{mb:3}}>Get in Touch</Typography>
                <Typography variant='body1' sx={{color:theme.palette.neutral.darkgrey1.main, mb:3}}>Need something done? Talk to us.</Typography>

                <Stack direction='row' spacing={5} sx={{mb:3}}>
                    <TextField  id="name" label="Name" inputRef={inputName} required/>
                    <TextField  id="email" label="Email" inputRef={inputEmail} required/>
                </Stack>

                <TextField
                    id="message"
                    label="Message"
                    multiline
                    rows={4}
                    sx={{mb:3}}
                    style = {{width: 450}}
                    inputRef={inputMessage}
                />

                
                
                {error
                ?
                    <Paper elevation={3} sx={{bgcolor:theme.palette.error.main, color:'white', p:3, pl:9, pr:9, mt:5}}>
                        Submitting was unsuccessful. Please try again!
                    </Paper>
                :
                <></>
                }

            </Box> */}



// --------------------------------------------------------

{/* <Paper elevation={0} sx={{ borderRadius: 1, p:4, mb:3 }} variant="outlined">

<Grid 
    container 
    direction='row' 
    justifyContent="center" 
    alignItems="center" 
    textAlign='center'
    columnSpacing={2} 
    rowSpacing={2}
>

    <Grid item xs='auto' md={12}>
        <Stack>
            <Typography variant='h3' sx={{mb:3}}>Get in Touch</Typography>
            <Typography variant='body1' sx={{color:theme.palette.neutral.darkgrey1.main, mb:3}}>Need something done? Talk to us.</Typography>
        </Stack>
    </Grid>

    <Grid item xs='auto' md={12}>
        <TextField  id="name" label="Name" inputRef={inputName} style = {{width: 450}} required/>
    </Grid>

    <Grid item xs='auto' md={12}>
        <TextField  id="email" label="Email" inputRef={inputEmail} style = {{width: 450}} required/>
    </Grid>

    <Grid item xs='auto' md={12}>
        <TextField
            id="message"
            label="Message"
            multiline
            rows={4}
            sx={{mb:3}}
            style = {{width: 450}}
            inputRef={inputMessage}
        />
    </Grid>

    <Grid item xs='auto' md={12}>
        <Button variant='contained' style={{width: 450}} onClick={submit} disabled={loading}>{buttonMsg}</Button>
    </Grid>

    <Grid item xs='auto' md={12}>
        {error
        ?
            <Paper elevation={3} sx={{bgcolor:theme.palette.error.main, color:'white', p:3, pl:9, pr:9, mt:5}}>
                Submitting was unsuccessful. Please try again!
            </Paper>
        :
        <></>
        }
    </Grid>
    


</Grid>

</Paper> */}