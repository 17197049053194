import {useContext, useState} from 'react';
import { useUserContext } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

function OrdersSidebar(props, theme) {

    theme=useTheme(theme)
    const {auth, setAuth} = useUserContext();
    const navigate = useNavigate();

    const [selected, setSelected] = useState('all');

    const SidebarButton = styled(Button)((props) => ({
        //the first 4 props is based on the "selected" useState
        //for border, need to use this format "6px solid"
        borderLeft: selected === props.name?"6px solid":"0px solid",
        borderColor: selected === props.name?theme.palette.primary.main:"white",
        backgroundColor: "white",
        color: selected === props.name? theme.palette.primary.main:theme.palette.neutral.darkgrey2.main,
        width: '210px',
        justifyContent: 'left',
        padding:20,
        // color: theme.palette.neutral.darkgrey2.main,
        ':hover': {
        // borderLeft:'4px solid',
        // borderColor:theme.palette.primary.main,
        backgroundColor: "#D9D9D9",
        color: theme.palette.primary.main
        },
    }));

    const buttonClick = (title) => {
        console.log(title)
        props.setTitle(title)
        setSelected(title)
    }


    return (
        <Box sx={
            {
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-start',
                bgcolor:'white',
                pl:1,
            }
        }>
        
        <Stack direction='column' alignItems='flex-start'>
            <SidebarButton name={'all'}  onClick={()=>buttonClick('all')}>All Orders</SidebarButton>
            <SidebarButton name={'quotation'} onClick={()=>buttonClick('quotation')}>Quotation</SidebarButton>
            <SidebarButton name={'currentOrder'} onClick={()=>buttonClick('currentOrder')}>Current Orders</SidebarButton>
            <SidebarButton name={'pastOrder'} onClick={()=>buttonClick('pastOrder')}>Past Orders</SidebarButton>

        </Stack>
        
        
    </Box>
    );
}

export default OrdersSidebar;