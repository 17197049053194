import { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Navbar from './components/Navbar';
import { Helmet } from "react-helmet";
import {ThemeProvider} from '@mui/material';

//import context
import { useUserContext } from './context/UserContext';

//import pages
import Home from "./pages/Home";
import Login from './pages/Login';
import Quotation from './pages/quotation/Quotation';
import Orders from './pages/Orders';
import SignUp from './pages/SignUp';
import ForgetPassword from './pages/ForgetPassword';
import PasswordRetrieval from './pages/PasswordRetrieval';
import ResetPassword from './pages/ResetPassword';
import Unauthorised from './pages/Unauthorised';
import AccountVerification from './pages/AccountVerification';
import ManualQuotation from './pages/ManualQuotation';
import PaymentComplete from './pages/PaymentComplete';
import MyAccount from './pages/MyAccount';
import EditMyAccount from './pages/EditMyAccount';
import ContactUs from './pages/ContactUs';
import ContactUsSuccess from './pages/ContactUsSuccess';
import About from './pages/statics/About'
import Capabilities from './pages/statics/Capabilities';
import Guides from './pages/statics/Guides'
import TermsofUse from './pages/statics/policies/TermsofUse'
import ReturnPolicy from './pages/statics/policies/ReturnPolicy';
import DeliveryPolicy from './pages/statics/policies/DeliveryPolicy';
import Policy from './pages/statics/Policy';
import Wood from './pages/statics/guides/Wood';
import Metal from './pages/statics/guides/Metal';
import Plastics from './pages/statics/guides/Plastics';
import Others from './pages/statics/guides/Others';
import FiberLaser from './pages/statics/capabilities/FiberLaser';
import Co2Laser from './pages/statics/capabilities/Co2Laser';
import MetalTubing from './pages/statics/capabilities/MetalTubing';
import SixAxis from './pages/statics/capabilities/SixAxis';
import SpindleRouting from './pages/statics/capabilities/SpindleRouting';
import WaterJet from './pages/statics/capabilities/WaterJet';
import Manufacturability from './pages/statics/guides/Manufacturability';
import AdminControl from './pages/AdminControl';
import Delivery from './pages/quotation/Delivery';
import ProductListing from './pages/products/ProductListing';
import ProductPage from './pages/products/ProductPage';
import ProductCheckout from './pages/products/ProductCheckout';
import ProductSignUp from './pages/products/ProductSignUp';


//import components
import GeneralUserNavbar from './components/GeneralUserNavbar';
import AdminNavbar from './components/AdminNavbar';
import theme from './components/styling/Theme';
import PersistLogin from './components/PersistLogin';
import PublicPageCheck from './components/PublicPageCheck';
import Loader from './components/Loader';
import Footer from './components/Footer';
import ProtectedRoutes from './components/ProtectedRoutes';


//builder
import EditorPage from './pages/builder/EditorPage';
import EmailComponentPage from './pages/email-template-builder/EmailComponentPage';
import EmailTemplateBuilder from './pages/email-template-builder/EmailTemplateBuilder';
import ProductController from './pages/products/ProductController';
import ProductPaymentComplete from './pages/products/ProductPaymentComplete';


function MainSite() {

  const [navBar, setNavBar] = useState()
  const {auth} = useUserContext();
  const roles = {
    'Admin': 1,
    'Customer': 2
  }

  //everytime the token value changes
  useEffect(()=> {
    console.log("token value: ", auth.logginToken)
    //token == 'no token yet' is the page is first rendered. While 'token removed' is when the user loggedout
    if(auth.logginToken === 'no token yet' || auth.logginToken === 'token removed'){
      setNavBar(<Navbar />)
    }else{
      if(auth.userRole === 1){
        setNavBar(<AdminNavbar />)  
      }else{
        setNavBar(<GeneralUserNavbar />)
      }
      
    }
  }, [auth.logginToken, auth.userRole])


  return (
    <ThemeProvider theme={theme}>
      
      {/* the UserContext.Provide allow us to change state values from the parents to child, vice versa.
      in this case, the token state is being changed when user logged into their account. And it will change the navbar accordingly */}

        <div className="App">
          <Helmet>
            <style>{`body { background-color: ${theme.palette.primary.main}; }`}</style>
          </Helmet>
          
          <Router>
            {navBar}
            <Routes>
              <Route element={<PersistLogin/>}>
                {/* Protected Routes for admin and customer */}
                <Route element={<ProtectedRoutes allowedRoles={[roles.Admin, roles.Customer]}/> }>
                  <Route path='/quotation' element={<Quotation auth={auth}/>}/>
                  <Route path='/manual-quotation' element={<ManualQuotation auth={auth}/>}/>
                  <Route path='/payment-complete' element={<PaymentComplete />} />
                  <Route path='/account' element={<MyAccount />} />
                  <Route path='/edit-account' element={<EditMyAccount />} />
                  <Route path='/orders' element={<Orders />}></Route>
                  <Route path='/editor' element ={<EditorPage />}> </Route>
                  <Route path='/delivery' element={<Delivery/>}></Route>
                </Route>


                {/* Protected Routes for the admin only */}
                <Route element={<ProtectedRoutes allowedRoles={[roles.Admin]}/> }>
                  <Route path='/admin' element={<AdminControl />}></Route>
                  <Route path='/email-editor' element={<EmailComponentPage/>}></Route>
                  <Route path='/email-template-builder' element={<EmailTemplateBuilder/>}></Route>
                </Route>

                
              </Route>

              {/* Public Routes */}
              <Route element={<PublicPageCheck/>}>
                <Route path='/' element={<Home />}></Route> 
                <Route path='/login' element={<Login />}></Route>
                <Route path='/sign-up' element={<SignUp />}></Route>
                <Route path='/forget-password' element={<ForgetPassword />}></Route>
                <Route path='/password-retrieval' element={<PasswordRetrieval />}></Route>
                <Route path='/reset-password' element={<ResetPassword />}></Route>
                <Route path='/account-verification' element={<AccountVerification />}></Route>
                <Route path='/contact-us' element={<ContactUs />}></Route>
                <Route path='/contact-us-success' element={<ContactUsSuccess />}></Route>
                <Route path='/about' element={<About />} />

                 <Route path='/capabilities' element={<Capabilities />} >
                  
                  <Route path='co2laser' element={<Co2Laser />} />
                  <Route path='fiberlaser' element={<FiberLaser />} />
                  <Route path='metaltubing' element={<MetalTubing />} />
                  <Route path='sixaxis' element={<SixAxis />} />
                  <Route path='spindlerouting' element={<SpindleRouting />} />
                  <Route path='waterjet' element={<WaterJet />} />

                </Route>

                <Route path='/guides' element={<Guides />} >

                  <Route path='metal' element={<Metal />} />
                  <Route path='wood' element={<Wood />} />
                  <Route path='plastic' element={<Plastics />} />
                  <Route path='others' element={<Others />} />
                  <Route path='mfg' element={<Manufacturability />} />

                </Route> 

                <Route path='/policy' element={<Policy />} >

                  <Route path='delivery' element={<DeliveryPolicy />} />
                  <Route path='termsofuse' element={<TermsofUse/>} />
                  <Route path='returns' element={<ReturnPolicy />} />
                  
                </Route>

                <Route path='product' element={<ProductController/>}>
                  <Route path='listings' element={<ProductListing/>} />
                  <Route path=':productname' element={<ProductPage/>} />
                  <Route path='checkout' element={<ProductCheckout/>} />
                  <Route path='payment-complete' element={<ProductPaymentComplete/>} />
                  <Route path='signup' element={<ProductSignUp/>} />
                </Route>

              </Route>

              <Route path='/unauthorised' element={<Unauthorised />}></Route>
              <Route path='/loader' element={<Loader />}></Route>
              <Route path='*' element={<Navigate to="/" replace />}></Route>
            
            </Routes>
            <Footer/>
          </Router>
        </div>
  </ThemeProvider>
  );
}

export default MainSite;

// static core pages: /home, /about, /contact, /design guide (Darren to provide static pages in figma, will convert)
// Standard Webapp elements: /login, /signup, /password-mgmt (Rivaldi)
// Core Webapp flow: /quotation, /quotation/checkout, /my-orders (Tyn)