import { useState, useContext, useEffect} from 'react';
import { ProductContext } from '../../context/ProductContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import titleImg from "../../img/productListing.jpeg";
import titleImgMobile from "../../img/productListingMobile.jpeg";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { CardActionArea } from '@mui/material';
import useWindowDimensions from '../../components/GetWindowSize';
import ProductListingFilterBar from '../../components/ProductListingFilterBar';
import ProductListingFilterMobile from '../../components/ProductListingFilterMobile';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CircularProgress from '@mui/material/CircularProgress';

function ProductListing(props) {

    const navigate = useNavigate();
    const productData = useContext(ProductContext);
    const [loading, setLoading] = useState(true);
    const dimension = useWindowDimensions();
    const image = dimension.width < 600? titleImgMobile : titleImg;
    const titleColor = dimension.width < 600? "black": "white";
    const titleSize = dimension.width < 600? "h4": "h1";
    const productList = productData.productList;
    const productGroups = productData.productGroups;
    const [filteredProductList, setFilteredProductList] = useState([]);
    const [costTable, setCostTable] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All Products');
    const [categoryTitle, setSelectedTitle] = useState("All Products");

    useEffect(()=>{
        if(productList.length > 0){
            setLoading(false)
        }
        setFilteredProductList(productList)
    }, [productList])

    //to move on to the next page
    const moveOnToNextPage = (productName, i) => {
        var idList = productList[i].costing_ids;

        var output = []
        for(let i=0; i<idList.length; i++){
            let filteredData = costTable.filter(function(obj){
            return obj.id === idList[i]
        })
            output.push(filteredData[0])
        }

        navigate(`/product/${productName}`, 
       )
    }

    //to set the category everytime users search item name or filter according to category
    useEffect(()=>{
        setSelectedTitle(selectedCategory)
    }, [selectedCategory])

    //when user use the search bar to find product name
    const search = (e) => {

        if(e.target.value.length === 0){
            setSelectedCategory("All Products")
            setFilteredProductList(productList)
        }else{
            setSelectedCategory("Searched Products")
            // console.log(e.target.value)
            //to filter product items based on the name of the searched item using .includes()
            const output = productList.filter(x=>x.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setFilteredProductList(output)
        }
    }



    return (
        <Box
            sx={{
                backgroundColor: "#F2F4F6",
                display:'flex',
                flexDirection:'column',
                width: '100%',
                mt:-2
            }}
        >

            {/* title section */}
            <Box 
                sx={{width:'100%', minHeight:'800px'}} 
                style={{
                    backgroundImage:`url(${image})`, 
                    backgroundRepeat:"no-repeat", 
                    backgroundSize:"100% 100%"
                    }}>
                <Grid container wrap="nowrap">
                    <Grid 
                        item
                        lg={7}
                        sx={{
                            pt:dimension.width < 600? 65 : 40,
                            pl:4,
                            pr: dimension.width < 600? 2 : 0
                        }}
                    >
                        <Typography variant={titleSize} color={titleColor} gutterBottom >
                            Products, 
                        </Typography>

                        <Typography variant='manualQuotation' color={titleColor}>
                           Here are some highly demanded products that customers request from us.
                        </Typography>
                    </Grid>
                    <Grid item lg={5}/>

                </Grid>
            </Box>
            
            {/* this is for the Filter bar */}
            <Box 
                sx={{
                    display:'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-evenly',
                    backgroundColor:"white",
                    height:dimension.width < 600? '100px' : '50px',
                    mb:5,
                    pb:2
                }}
                >
                    {/* if dimension is less than 600, then it will show filer button instead of categoires selection */}
                    {dimension.width < 600? 
                        <Box>
                            <ProductListingFilterMobile 
                                productList={productList}
                                setFilteredProductList={setFilteredProductList}
                                productGroups={productGroups}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                            />
                            
                        </Box>
                    :
                        <>
                            <ProductListingFilterBar
                                productList={productList}
                                setFilteredProductList={setFilteredProductList}
                                productGroups={productGroups}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                            />
                        </>
                    }


            </Box>

            <Box
                sx={{
                    display: dimension.width < 600? 'none':'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="productSearch">Search Product</InputLabel>
                    <Input
                        id="productSearch"
                        endAdornment={
                        <InputAdornment position="end">
                            <SearchOutlinedIcon/>
                        </InputAdornment>
                        }
                        onChange={(e)=>{search(e)}}
                    />
                </FormControl>
            </Box>

            {/* this is for the product list */}
            <Typography 
                variant='h4'
                sx={{
                    ml:dimension.width < 600? 0:10,
                    mb:5,
                    textAlign:'center'
                }}
            >
                {categoryTitle}
            </Typography>

            <Grid 
                container
                justifyContent= {dimension.width < 600? "flex-start":"center"}
                alignItems={dimension.width < 600? "flex-start":"center"}
                sx={{
                    ml:filteredProductList.length === 0?0:10,
                    mr:10,
                    mb:10
                }}
            >   
                {/* when there is no items on the filteredList */}
                {loading?
                   <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        m:5
                    }}>
                        <CircularProgress />
                        <Typography>Loading</Typography>
                    </Box>
                
                :
                filteredProductList.map(function(item, i){
                    return(
                        <Grid
                            item
                            key={i}
                            xs={8}
                            sm={8}
                            md={5.5}
                            lg={4}
                            xl={4}
                            sx={{
                                // border:1,
                                mb:3
                            }}
                        >
                            <Card 
                                raised 
                                sx={{ 
                                    maxWidth: {
                                        xs:350,
                                        sm:400,
                                        md:400,
                                        lg:350,
                                        xl:500
                                    } 
                                }}
                            >
                                <CardActionArea 
                                    onClick={()=> {moveOnToNextPage(item.name, i)}}
                                >
                                    <CardMedia
                                        component="img"
                                        image={item.image}
                                        alt={item.name}
                                        sx={{
                                            maxHeight:250,
                                        }}
                                    />

                                    <CardContent
                                        sx={{
                                            maxHeight:100,
                                            backgroundColor:'#F2F4F6',
                                            overflow:'hidden',
                                        }}
                                    >

                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.name}
                                        </Typography>

                                        <Typography 
                                            variant="body2" 
                                            color="text.secondary"
                                            noWrap
                                        >
                                            {item.description}
                                        </Typography>

                                    </CardContent>
                                </CardActionArea>
                                <CardActions 
                                    sx={{
                                        justifyContent: 'flex-end',
                                        backgroundColor:'#F2F4F6'
                                    }}
                                >
                                    <Button size="small" onClick={()=>{moveOnToNextPage(item.name, i)}}>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
}

export default ProductListing;