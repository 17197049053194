import React from 'react';
import { useNavigate } from "react-router-dom";

//components from material ui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { TypographyChanged} from '../CustomProps';
import { useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function BackdropPasswordRetrieval(props, theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();

    return (
        <Backdrop open={props.backdropPasswordRetrieval} sx={{color:"#fff"}}>
            <Paper 
                elevation={0}
                sx={{
                    width: 500,
                    height: 500,
                    borderRadius:5
                }}
            >
                <Box sx={{display:'flex', flexDirection:'row-reverse', mr:1}}>
                    <IconButton aria-label='close' size='large' onClick={() => {props.setBackdropPasswordRetrieval(false)}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                
                <Box 
                    padding={{xs:1, sm:8}}
                    sx={{bgcolor: 'white',  borderRadius: 5, mt:12}}
                >
                    <Stack direction="column" alignItems="center" spacing={2}>
                        <TypographyChanged variant="subtitle2">Please check your email for your password reset link</TypographyChanged>
                        <Link onClick={() => {props.setBackdropPasswordRetrieval(false);props.setBackdropSignUp(true)}}>Don't have an account?</Link>
                    </Stack>
                </Box>
            </Paper>
        </Backdrop>
    );
}

export default BackdropPasswordRetrieval;