import {useEffect, useState, useRef, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserContext } from '../context/UserContext';
import {Background} from '../components/CustomProps';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

function PaymentComplete(theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();
    const userEmail = localStorage.getItem('user');
    const [status, setStatus] = useState('waiting');

    const {auth} = useUserContext();
    const statuses = ['Payment Received','Complete', 'On Delivery', 'Ready for Collection', 'Completed']
    const unpaid_statuses = ['Payment Pending']

    useEffect(()=>{
        let weblink = window.location.href;
        console.log(weblink.split("?").length)
        
        // let weblink = 'http://localhost:3000/payment-complete?reference=98dd6961-cfc8-424b-9b0e-d583570ab9ac&status=completed';
        if(weblink.split("?").length > 1){
            let anchor = weblink.toString().split("?")[1];
            let parsed = JSON.parse(`{"${decodeURI(anchor).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"')}"}`)
            console.log(parsed)

            axios.get(`${process.env.REACT_APP_HOSTNAME}/quotation/payment_request_id/${parsed.reference}`, 
                {
                    headers: {
                        'Authorization': 'Token ' + auth.logginToken
                    },
                }).then((res) => 
                    {
                        
                        console.log(res.data)
                        

                        setStatus(res.data.status)
                    
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 404) {
                          console.log(error.response);
                          setStatus(error.response.data.status)
                          // Handle 404 error here
                        } else {
                          console.log('Error occurred:', error);
                          setStatus(null)
                          // Handle other errors here
                        }
                      });
                  }
                }, []);

    return (
        <Background sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
            {/* completed the payment */}
            {status === 'waiting' ? (
  <>
    <Stack sx={{pt: 5, alignItems: 'center', justifyContent: 'center'}} spacing={3}>
      <Typography variant='h4' sx={{color: theme.palette.primary.main}}>Waiting for Payment Confirmation...</Typography>
      <HourglassEmptyRoundedIcon
        sx={{
          width: '3em',
          height: '3em',
          color: theme.palette.primary.main
        }}
      />
    </Stack>
    <Stack sx={{pt: 2, mb: 4, alignItems: 'center', justifyContent: 'center', color: theme.palette.neutral.darkgrey1.main}}>
      <Typography variant='subtitle1'>Please wait while we confirm your payment.</Typography>
      <Typography variant='subtitle1'>Do not close or refresh the page.</Typography>
    </Stack>
  </>
)
 : statuses.includes(status)?
                <>
                <Stack sx={{pt:5, alignItems:'center', justifyContent:'center'}} spacing={3}>
                    <Typography variant='h4' sx={{color:theme.palette.success.main}}>Payment Status: {status}</Typography>
                    <CheckCircleRoundedIcon 
                        sx={{
                            width:'3em', 
                            height:'3em',
                            color:theme.palette.success.main
                        }}
                    />
                </Stack>

                <Stack sx={{pt:2, mb:4, alignItems:'center', justifyContent:'center', color:theme.palette.neutral.darkgrey1.main}}>
                    <Typography variant='subtitle1'>We have received your order.</Typography>
                    <Typography variant='subtitle1'>Please check your email for the invoice of your order sent to:</Typography>
                    <Typography variant='subtitle1' sx={{color:'black'}}>{userEmail}</Typography>
                    <Button
                        variant="contained"
                        onClick={() => {navigate("/orders", {state:'complete'})}}
                        sx={{
                            mt:3
                        }}
                    >
                        Proceed to My Orders
                    </Button>
                </Stack>
                </>
            :
            unpaid_statuses.includes(status)?
            
                // canceled the payment
                <>
                <Stack sx={{pt:5, alignItems:'center', justifyContent:'center'}} spacing={3}>
                    <Typography variant='h4' sx={{color:theme.palette.error.main}}>Payment Cancelled!</Typography>
                    <CancelRoundedIcon 
                        sx={{
                            width:'3em', 
                            height:'3em',
                            color:theme.palette.error.main
                        }}
                    />
                </Stack>

                <Stack sx={{pt:2, mb:4, alignItems:'center', justifyContent:'center', color:theme.palette.neutral.darkgrey1.main}}>
                    <Typography variant='subtitle1'>You have canceled your payment</Typography>
                    <Typography variant='subtitle1'>Please proceed to the Order page to continue with your payment process:</Typography>
                    <Button
                        variant="contained"
                        onClick={() => {navigate("/orders", {state:'cancelled'})}}
                        sx={{
                            mt:3
                        }}
                    >
                        Proceed to My Orders
                    </Button>
                </Stack>
                </>:
                //bad link
                    <>
                    <Stack sx={{pt:5, alignItems:'center', justifyContent:'center'}} spacing={3}>
                        <Typography variant='h4' sx={{color:theme.palette.error.main}}>{status}</Typography>
                        <CancelRoundedIcon 
                            sx={{
                                width:'3em', 
                                height:'3em',
                                color:theme.palette.error.main
                            }}
                        />
                    </Stack>

                    <Stack sx={{pt:2, mb:4, alignItems:'center', justifyContent:'center', color:theme.palette.neutral.darkgrey1.main}}>
                        <Typography variant='subtitle1'>Quotation Not Found</Typography>
                        <Typography variant='subtitle1'>Click to return to the Order page:</Typography>
                        <Button
                            variant="contained"
                            onClick={() => {navigate("/orders", {state:'cancelled'})}}
                            sx={{
                                mt:3
                            }}
                        >
                            Proceed to My Orders
                        </Button>
                    </Stack>
                    </>
        
            }
            

        </Background>
    );
}

export default PaymentComplete;