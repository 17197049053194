import {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import { useUserContext } from '../../context/UserContext';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import SelectComponent from '../../components/SelectComponent';
import ItemSmall from '../../components/ItemSmall';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, 
        Typography, 
        Stack,
        Grid,
        Button,
        useTheme
    } from '@mui/material';

function ProductDescription(props, theme) {

    theme=useTheme(theme)
    const {auth} = useUserContext();
    const navigate = useNavigate();
    const {details, product, discountTable, categoryTable} = props;
    const costingTable = useRef(props.costTable);
    const discountSetting = discountTable.find(discount => discount.sizing === details.SIZE);
    //console.log(discountSetting)
    const [originalCostTable, setOriginalCostTable] = useState(props.costTable);
    

    const imageUrl = product.image;
    const [materialOptions,setMaterialOptions] = useState([]);
    const [surfacingOptions,setSurfacingOptions] = useState([]);
    const [thicknessOptions,setThicknessOptions] = useState([]);
    const [methodOptions,setMethodOptions] = useState([]);
    const [activeCheckout, setActiveCheckout] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nextSelect,setNextSelect]=useState('Material');
    

    const category = useRef('');
    const material = useRef('');
    const surfacing = useRef('');
    const thickness = useRef(0);
    const method = useRef('');
    const [quantity, setQuantity] = useState(1);
    const discount =useRef(0)
    const [ppCost,setPpCost]=useState('--')
    const [itemCost,setItemCost]=useState('--')
    const [subtotal,setSubtotal]=useState(0)
    const [minCost,setminCost] = useState(0)
    const [isExpanded, setIsExpanded] = useState(true);
    const [maxCost,setMaxCost] = useState(false);
    const [minCostReached, setminCostReached] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [itemCosting, setItemCosting] = useState(0);

    const findBreakpoint = (quantity,discountBreakpoints) =>
    {
      
      for (let xid = 0; xid < discountBreakpoints.length; xid++) {
        if (discountBreakpoints[xid] > quantity) {
          if (xid !== 0) {
            return discountBreakpoints[xid - 1];
          } else {
            console.log(xid);
            return 0;
          }
        }
      }
      return discountBreakpoints[discountBreakpoints.length - 1];

    }
    

    const filterTable = (column,value,costingTablein) => {
        //console.log('filtering: ',costingTablein.length,'on: ',column,'with:',value)
        const costingTableout=costingTablein.filter((e)=>{return e[column]==value})
        return costingTableout
    };

    const generateOptions = (column,costingTable) => {

        let columnData = costingTable.map((item,i) =>
        {
          return item[column]
        })


        let out = [...new Set(columnData)]

     
        let optionsList = out.map((item,i) =>
        {
          return {name: item,value:item,text: item}
        })

        optionsList.sort((a, b) => {
          return a.name - b.name;
        });

        return optionsList
    };

    //update cost whenever user changed product settings
    const updateCosts = (qty) => {   
        qty=(parseInt(qty).toFixed(0))

        if (qty>100){
            qty=100
        }



        
        if (costingTable.current.length === 1){
            var areaCost=details.bbox_area*costingTable.current[0].unit_cost_area/1000000
            var lineCost=details.perimeter_length*costingTable.current[0].unit_cost_length/1000
            var interiorCost = (details.line_length-details.perimeter_length)*costingTable.current[0].unit_cost_length/1000
            let current_category = categoryTable.find(category => category.category_name === costingTable.current[0].category);

            var line_markup = current_category.interior_line_markup
            var area_markup = current_category.area_markup
            //console.log("current_category.minimum_cost", current_category.minimum_cost)
            setminCost(current_category.minimum_cost)
            
            var ppCosttemp = '--'


            setMaxCost(false)
            ppCosttemp=((areaCost*(1+area_markup)+lineCost+interiorCost*line_markup)*(discountSetting.multiplier)).toFixed(2)

            if(ppCosttemp>0.5){
                ppCosttemp=ppCosttemp
            }else{
                ppCosttemp=0.5
            }

            if (ppCosttemp>1000){
                setMaxCost(true)
            }

            setPpCost(ppCosttemp)
            var ro_value=discountSetting.ro_value
            var max_discount=discountSetting.max_discount
            var discountBreakpoints=discountSetting.discount_breakpoints

            var breakpoint = findBreakpoint(qty,discountBreakpoints)

            discount.current=((max_discount - (breakpoint**ro_value * max_discount)).toFixed(2))

            var totalCost=ppCosttemp*qty*(1-discount.current)


            if (totalCost>minCost){
                setminCostReached(true)
                setTotalCost(totalCost)
            }else{
                setminCostReached(false)
                setTotalCost(minCost)
            }

            setItemCost(totalCost.toFixed(2))
            setSubtotal((ppCosttemp*qty).toFixed(2))
            setItemCosting(costingTable.current[0].id)
          }else{
            setPpCost('--')
            setItemCost('--')
        }
    }

    const handleChange = (event) => {
        var costingTable_temp = originalCostTable
        const orderFilter=[
            ['category',category,category,setCategoryOptions,'Category'],
            ['material',material,material,setMaterialOptions,'Material'],
            ['surfacing',surfacing,surfacing,setSurfacingOptions,'Color/Finishing'],
            ['thickness',thickness,thickness,setThicknessOptions,'Thickness'],
            ['processing_method',method,method,setMethodOptions, 'Processing Method']
        ]
        try{
            var index=10
            orderFilter.forEach((e,i)=>{
                if(e[0] === event.target.name){
                    e[2].current=event.target.value
                    index=i
                    costingTable_temp=filterTable(e[0],event.target.value,costingTable_temp)
                    if(event.target.value !== ''){
                        if(index<4){
                            setNextSelect(orderFilter[index+1][4])}
                        else{
                            setNextSelect('')
                        }
                    }
                    else{
                        setNextSelect(orderFilter[index][4])
                    }
                }else{ 
                    if(i<index){
                        costingTable_temp = filterTable(e[0],e[1].current,costingTable_temp)     
                    }else if(i == (index+1)){
                        e[2].current=''
                        const options = generateOptions(e[0],costingTable_temp)
                        e[3](options)
                        if(options.length==1){
                            console.log('OPTIONS:',options[0].value)
                            e[2].current=(options[0].value)
                            index+=1
                            if(index<4){
                                setNextSelect(orderFilter[index+1][4])}
                            else{
                                setNextSelect('')
                                console.log('cost updated thru backdoor')
                                updateCosts(quantity)
                            }
                        }
                    }else{
                        e[2].current=''
                        const options = []
                        e[3](options)
                    }
                }
            })
        }catch(err){
            console.log('loop exited')
        };
        console.log(category,material,surfacing,thickness,method)
        costingTable.current=costingTable_temp
    }

    const handleChange2 = (event) =>{
        setPpCost('--')
        setItemCost('--')
        const promiseA = new Promise((resolve,reject)=>handleChange(event));
        promiseA.then(updateCosts(quantity))
    }

    const [categoryOptions,setCategoryOptions] = useState(generateOptions('category', originalCostTable));

    //to check if user has filled in all the fields before being able to checkout
    useEffect(()=>{
        if(category.current !== "" && material.current !== "" && surfacing.current !== "" && thickness.current !== "" && method.current !== ""){
            setActiveCheckout(true)
        }else{
            setActiveCheckout(false)
        }
    })

    //for checking out
    const checkout = () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_HOSTNAME}/quotation/anonymous_quotation/`, 
        {

        }).then((res)=>{
            console.log(res.data)
            const quotationId = res.data.id;
            const orderData = new FormData();
            orderData.append('file', product.file.id);
            orderData.append('costing', costingTable.current[0].id);
            orderData.append('quantity', quantity);
            orderData.append('quotation', quotationId);

            axios.post(`${process.env.REACT_APP_HOSTNAME}/order/anonymous_order/`, orderData,
            {

            }).then((res)=>{
                console.log(res)
                localStorage.setItem('currentQuotationAnonymous', quotationId)
                navigate('/product/checkout')
            }).catch((err)=>{
                setLoading(false)
                console.log(err)
            })
        }).catch((err)=>{
            setLoading(false)
            console.log(err)
        })
    }


    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            backgroundColor:"#F2F4F6",
            p:10
        }}>

            <Typography variant='h1' sx={{mb:5}}>{product.name}</Typography>
            <Typography variant='body1' sx={{mb:5}}>{product.description}</Typography>

            <Grid container>
                <Grid item xs={12} md={12} lg={8} sx={{mb:{sm:5, md:5, lg:0}}}>
                    <Stack 
                        sx={{ borderRadius: "8px 8px 8px 8px",  
                            background:"#FFFFFF",
                            p:5
                        }}
                    >
                        {isExpanded && (
                            <>
                                <Grid container direction='row'>
                                    {/* picture field */}
                                    <Grid item xs={12} md={12} lg={4}>
                                    <Box flex={1} sx={{alignItems:'center', display:'flex', mt:3, ml:{lg:3},justifyContent:'center'}}  >
                                        {imageUrl==null?
                                        <>
                                            <BrokenImageIcon sx={{border: "1px solid #ECEBED",borderRadius:1,Height:"267px",Width:"272px"}}/>
                                        </>
                                        :
                                        <>
                                            <img 
                                                style={{
                                                border: "1px solid #ECEBED",
                                                borderRadius:1,
                                                maxHeight:"267px",
                                                maxWidth:"272px"
                                                }} 
                                                src={imageUrl}
                                                alt={product.image.alt_tag}
                                            />
                                        </>
                                        }
                                        </Box>
                                    </Grid>
                                    
                                    {/* category field */}
                                    <Grid item xs={6} md={6} lg={4}>
                                    <Stack flex={1} sx={{m:3,mt:0}}>
                                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Category</Typography>
                                        <SelectComponent name="category" items={categoryOptions} selected={category.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

                                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Material</Typography>
                                        <SelectComponent name="material" items={materialOptions} selected={material.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

                                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}}  variant="caption1">Color/Finishing</Typography>
                                        <SelectComponent name="surfacing" items={surfacingOptions} selected={surfacing.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

                                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Thickness</Typography>
                                        <SelectComponent name="thickness" items={thicknessOptions} selected={thickness.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

                                        <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Processing Method</Typography>
                                        <SelectComponent name="processing_method" items={methodOptions} selected={method.current}  handleSelect={handleChange2} nextSelect={nextSelect}/>
                                    </Stack>

                                    </Grid>
                                    
                                    {/* quantity field */}
                                    <Grid item xs={6} md={6} lg={4} sx={{pr:3}}>
                                        <Stack flex={1} width sx={{mt:0,mr:0}}>
                                            <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Quantity</Typography>
                                            <input 
                                                type="number" 
                                                min={1} 
                                                max={100} 
                                                placeholder="1" 
                                                style={{
                                                    width:"auto", 
                                                    height:"auto",
                                                    border: "1px solid #ECEBED",
                                                    borderRadius: "4px",
                                                    color:"#1B1E24",
                                                    fontFamily:"Open Sans",
                                                    fontSize:16,
                                                    fontWeight:600,
                                                    lineHeight:"22px",
                                                    textTransform:'none', 
                                                    padding:"12px", 
                                                    fontColor:"#98A9BC"
                                                }} 
                                                onChange={(e) => {setQuantity(e.target.value);updateCosts(e.target.value)}} 
                                                value={quantity}
                                                >
                                            </input>

                                            <Stack direction="row" sx={{mt:3,mb:0}} justifyContent="space-between">
                                                <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Cost per Piece</Typography>
                                                <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${ppCost}</Typography>
                                            </Stack>

                                            <Stack direction="row" sx={{mt:0,mb:1}} justifyContent="space-between">
                                                <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Discounted Cost</Typography>
                                                <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${ppCost==='--'?'--':(ppCost*(1-discount.current)).toFixed(2)}</Typography>
                                            </Stack>
                                            
                                            <hr 
                                            style={{
                                                color: "#ECEBED",
                                                backgroundColor: "#ECEBED",
                                                height: '1px',
                                                width: "100%",
                                                border: 0
                                            }}
                                            />
                                            
                                            {maxCost?
                                                <>
                                                    <Stack direction="row" sx={{my:1}} justifyContent="space-between">
                                                        <Typography  textAlign="left" sx={{ml:"8px",my:0}} variant="caption1">Subtotal</Typography>
                                                        <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${itemCost}</Typography>
                                                    </Stack>
                                                    
                                                    <Typography width="100%" textAlign="left" textcolor={theme.palette.warning.main} sx={{ml:"8px",mt:0,my:1}} variant="caption1">
                                                        For orders above $1000, please contact our service representative for a manual quote.
                                                    </Typography>

                                                    <Button sx={{ width: "70%", m:3, mt:3, p:2, pt:1, pb:1}} variant="outlined" component="span">
                                                        <Link 
                                                        to={{pathname:"/manual-quotation",
                                                            // search:`?${createSearchParams({fileId:props.file.file_id,fileName:props.itemName,imageUrl:props.file.image_name})}` 
                                                        }}
                                                        target="_blank" 
                                                        style={{textDecoration:'none'}}
                                                        >
                                                        <Typography variant="filename" sx={{color:theme.palette.primary.main}}>Request quote</Typography> 
                                                        </Link>
                                                    </Button>
                                                </>
                                            : 
                                                <>
                                                    <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                                                        <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Subtotal</Typography>
                                                        <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${subtotal}</Typography>
                                                    </Stack>

                                                    <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                                                        <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Bulk Discount </Typography>
                                                        <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">{(discount.current*100).toFixed(0)}%</Typography>
                                                    </Stack>

                                                    <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                                                        <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Total Savings ($)</Typography>
                                                        <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">-${(discount.current*subtotal).toFixed(2)}</Typography>
                                                    </Stack>

                                                    <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                                                        <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Total Cost</Typography>
                                                        {minCostReached?
                                                        <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${itemCost}</Typography>
                                                        :
                                                        <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">Minimum Order ${minCost} ({itemCost})</Typography>
                                                        }
                                                    </Stack>
                                                </>
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Stack>
                </Grid>

                {/* this grid is for the order summary */}
                <Grid item xs={12} md={12} lg={4}>
                    <Box sx={{display:'flex', flexDirection:'column', height:'100%', mr:0, ml:{xs:0, sm:0, md:0, lg:2}}}>
                        <Box 
                            sx={{
                                flexGrow:1,
                                borderRadius: "8px 8px 8px 8px",
                                backgroundColor:'#FFFFFF',
                                pt:3,
                            }}
                        >
                            <Typography variant="orderSummary" sx={{fontWeight: 'bold', p:4, textAlign:'left'}}>Order Summary</Typography>
                            <Box sx={{ flexDirection: 'column', pl:4, pt:4}}>
                                <ItemSmall quantity={quantity} cost={totalCost} itemName={product.name}/>
                            </Box>

                            <Box sx={{display:'flex', pl:4, mt:4}}>
                                <Grid container direction='row' sx={{mb:2}} justifyContent='flex-start'>
                                    <Grid item xs={9} md={9} lg={9}>
                                        <Typography sx={{textAlign:'left', pl:1}} variant="enterDetailsItem" >Subtotal</Typography>
                                    </Grid>
                
                                    <Grid item xs={3} md={3} lg={3} pl={2}>
                                        <Typography variant="enterDetailsItem"> {totalCost === '--' || totalCost === '0.00'? "--": "$"+totalCost.toFixed(2)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <LoadingButton 
                                    loading={loading}
                                    loadingPosition='start'
                                    startIcon={<></>}
                                    variant='contained'
                                    disabled={!activeCheckout} 
                                    onClick={checkout}
                                    sx={{
                                        flexGrow:1,
                                        m:4,
                                        bgcolor: activeCheckout?'#009AF9':"#CFD8DD", 
                                        "&:hover": {
                                            backgroundColor: theme.palette.action.main,
                                        }
                                    }}
                                >  
                                    Proceed to Checkout
                                </LoadingButton>

                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>


        </Box>
    );
}

export default ProductDescription;