import { useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DragAndDrop from '../../components/DragAndDrop';
import FileCard from '../../components/FileCard';
import ItemSmall from '../../components/ItemSmall';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

function EnterDetails(props,theme) {
    theme=useTheme(theme)
    console.log(props)
    const navigate = useNavigate();
    const [activeCheckout, setActiveCheckout] = useState(false);
    const [totalCost,setTotalCost]=useState(0);
    const [loading, setLoading] = useState(false);
    const [checkoutItems, setCheckoutItems] = useState();
    const [counter, setCounter] = useState(0);

    //console.log(height, width)

    // console.log(props.totalCost)
    // console.log(typeof(props.totalCost))

    const checkout = (e) => {
        if(props.checkValidity()){
            setLoading(true);
            setActiveCheckout(false);
            props.checkout()

        }else{
            setActiveCheckout(false)
        }
    }
    

    const removeItem = (e) => {
        props.removeItem(e)

        props.costCalc()
        if(props.checkValidity()){setActiveCheckout(true)}else{setActiveCheckout(false)}
    }

    const counterAdd = () =>{
        setCounter(counter + 1)
    }

    useEffect(()=>{
        setCheckoutItems(
            props.files.map(function(item,i){
                return <ItemSmall key={i} quantity={props.itemQuantity[i]} cost={props.itemCosts[i]} itemName={props.itemNames[i]} />;
            })
        )
    }, [counter])

    return (

        <Grid container directon='row'>
            {/* this is for the upload and create shape button and orders */}
            <Grid item xs={12} md={8}>
                <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, ml:{xs:2, sm:7}}}>
                    {/* upload file button */}
                    <Grid item xs={12}>
                        <Box sx={{flexGrow:0.5, mr:2}}>
                                <DragAndDrop variant='button'{...props}/>
                        </Box>
                    </Grid>

                    {/* create shape button */}
                    <Grid item xs={12}>
                        <Box sx={{flexGrow:0.5, mr:{xs:2, md:0}}}>
                            <Button 
                                disabled={props.uploadDisabled} 
                                onClick={()=>{props.setShow(true);}}  
                                variant='outlined' 
                                size="large" 
                                sx={{mt:1, mb:1, color:'#009AF9', backgroundColor: 'white', borderColor:'#009AF9', width:"100%",py:2,borderRadius:"6px" }}
                                startIcon={<AddCircleOutlineOutlinedIcon sx={{color:'inherit'}} />}
                            > 
                                <Typography variant="uploadBtn">Create Shape</Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Box>

                <Box sx={{display:'flex', flexDirection:'row', ml:{xs:2, sm:7}, mr:{xs:2, md:0}, flexGrow:1}}>
                    {/* This is for the list of orders */}
                    <Grid item xs={12} sx={{p:0}}>
                        <Stack 
                            spacing={1} 
                            sx={{
                                mt:0, 
                                mb:1,
                                color:'#009AF9', 
                                backgroundColor: `${theme.palette.neutral.lightgrey2.main}`, 
                                borderColor:'#009AF9',
                                verticalAlign:"top"
                            }} 
                        >
                        {props.costTableLoaded? 
                            <>
                                {props.itemNames.map((item,i) =>
                                    <FileCard 
                                        key={props.itemNames[i]+'_'+i} 
                                        propkey={i} 
                                        state={props.itemStates[i]}
                                        removeItem={(e)=> removeItem(e)}
                                        file={props.files[i]} 
                                        costingTable={props.costingTable} 
                                        itemName={props.itemNames[i]}
                                        setQuantity={(e,i) => {props.setItemQuantity(e,i)}} 
                                        setCost={(e,i) => {props.setItemCosts(e,i);props.costCalc();}}
                                        checkValidity={()=>{if(props.checkValidity()){setActiveCheckout(true)}else{setActiveCheckout(false)}}}
                                        setItemCosting={(e,i) => {props.setItemCosting(e,i)}} 
                                        setStep={(e)=>{props.setStep(e)}}
                                        uploadDisabled = {props.uploadDisabled} 
                                        disableCheckout={(e)=>{setActiveCheckout(false)}} 
                                        categoryTable={props.categoryTable} 
                                        discountTable={props.discountTable} 
                                        updateItemName = {(index, filename)=>props.updateItemName(index, filename)}
                                        setCounter={setCounter}
                                        counter={counter}
                                    />
                                )}
                            </>
                            :
                            <Stack height="60px" flexDirection="row" bgcolor="#FFFFFF" color={theme.palette.neutral.darkgrey1.main} >
                                <Stack direction="row" alignItems="center">
                                    <CircularProgress color="inherit" background="transparent" size="24px" sx={{marginX:"12px",marginY:"11px"}}/>
                                    <Typography color={theme.palette.neutral.darkgrey1.main} variant="filename">Uploading file...</Typography>
                                </Stack>
                                
                                <Typography  flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1"></Typography>
                                <ClearRoundedIcon 
                                    background="transparent"
                                    sx={{fill:`${theme.palette.error.main}`, height:"24px", marginX:"12px",marginY:"11px"}}
                                />

                            </Stack>
                        }
                        </Stack>
                    </Grid>
                </Box>
            </Grid>
            
            {/* this grid is for the order summary */}
            <Grid item xs={12} md={4}>
                <Box sx={{display:'flex', flexDirection:'column', height:'100%', mr:2, ml:{xs:2, sm:7, md:2}}}>
                    <Box 
                        sx={{
                            flexGrow:1, 
                            backgroundColor:'#FFFFFF',
                            pt:3,
                            }}
                    >
                            <Typography variant="orderSummary" sx={{fontWeight: 'bold', p:4, textAlign:'left'}}>Order Summary</Typography>
                            <Box sx={{ flexDirection: 'column', pl:4, pt:4}}>
                                {checkoutItems}
                            </Box>

                            <Box sx={{display:'flex', pl:4, mt:4}}>
                                <Grid container direction='row' sx={{mb:2}} justifyContent='flex-start'>
                                    <Grid item xs={9} md={9} lg={9}>
                                        <Typography sx={{textAlign:'left', pl:1}} variant="enterDetailsItem" >Subtotal</Typography>
                                    </Grid>
                
                                    <Grid item xs={3} md={3} lg={3} pl={2}>
                                        <Typography variant="enterDetailsItem"> {props.totalCost === '--' || props.totalCost === '0.00'? "--": "$"+props.totalCost}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Button 
                                    sx={
                                        {
                                            color:'white', 
                                            bgcolor: activeCheckout?'#009AF9':"#CFD8DD", 
                                            flexGrow:1,
                                            m:4, 
                                            textTransform: "none",
                                            "&:hover": {
                                                backgroundColor: theme.palette.action.main,
                                                cursor: "default"
                                            }
                                        }
                                    }
                                    disabled={!activeCheckout} 
                                    onClick={(e)=>{checkout(e)}}
                                >   
                                    {loading
                                    ?
                                    <>
                                        <CircularProgress size={15} sx={{color:'white', mr:1}}/>
                                        <Typography fontSize={15} color="white">Checking Out...</Typography>
                                    </>
                                    :
                                    <>
                                        <Typography fontSize={15} color="white">Proceed to Checkout</Typography>
                                    </>
                                    }
                                </Button>

                            </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default EnterDetails;


