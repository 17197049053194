import {useState, useEffect} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from '../GetWindowSize';
import ColorPicker from '../ColorPicker';

function UpdateCategoryBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Update');
    const [uploadResult, setUploadResult] = useState('none');
    const {height, width} = useWindowDimensions();

    const [id, setID] = useState('');
    const [category, setCategory] = useState('');
    const [areaMarkup, setAreaMarkup] = useState('')
    const [interiorLineMarkup, setInteriorLineMarkup] = useState('');
    const [lineMarkup, setLineMarkup] = useState('');
    const [minCost, setMinCost] = useState('');
    const [color, setColor] = useState(props.updateCategoryInfo.color);

    //for the useRef for the fields
    // const inputCategory = useRef(null);
    // const inputAreaMarkup = useRef(null);
    // const inputInteriorLineMarkup = useRef(null);
    // const inputLineMarkup = useRef(null);
    // const inputMinCost = useRef(null);
    // const inputColor = useRef(null);

    useEffect(()=>{
        // console.log(props.categoryTable)
        // console.log(props.updateCategoryInfo)
        if(props.updateCategoryInfo != null){
            const categoryInfo = props.updateCategoryInfo
            setID(categoryInfo.id)
            setCategory(categoryInfo.category_name)
            setAreaMarkup(categoryInfo.area_markup)
            setInteriorLineMarkup(categoryInfo.interior_line_markup)
            setLineMarkup(categoryInfo.line_markup)
            setMinCost(categoryInfo.minimum_cost)
            setColor(categoryInfo.color)
        }
    }, [props.updateCategoryInfo])

    const update = (props) =>{
        // console.log('update')
        setLoading(true)
        setSubmitText('Updating...')
        setUploadResult('none')

        const updatedCategoryInfo = [{
            id: id,
            category_name: category,
            area_markup: areaMarkup,
            interior_line_markup: interiorLineMarkup,
            line_markup: lineMarkup,
            minimum_cost: minCost,
            color: color
        }]
        console.log(updatedCategoryInfo[0])

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/get-category-table/`, updatedCategoryInfo[0],
            {
                headers:{
                    'Authorization': 'Token ' + auth.logginToken
                }
            }
        ).then((res)=>{
            // console.log(res)

            const result  = props.categoryTable.map(obj => updatedCategoryInfo.find( o => o.id === obj.id) || obj)
            //to change color of the category in the cost table 
            props.categoryColor[updatedCategoryInfo[0].category_name] = updatedCategoryInfo[0].color
            props.setCategoryTable(result)
            setLoading(false)
            setSubmitText('Update')
            setUploadResult('success')

        }).catch((err)=>{
            console.log(err)
            setLoading(false)
            setSubmitText('Update')
            setUploadResult('fail')
        })
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.updateCategoryBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Update Category Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setUpdateCategoryBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />
                
                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Category</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={category}
                            onChange={(e)=>{setCategory(e.target.value)}}
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Area Markup</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={areaMarkup}
                            onChange={(e)=>{setAreaMarkup(e.target.value)}}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Interior Line Markup</Typography>
                        <TextField 
                            id="interiorLineMarkup" 
                            variant="outlined"
                            value={interiorLineMarkup}
                            onChange={(e)=>{setInteriorLineMarkup(e.target.value)}} 
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Line Markup</Typography>
                        <TextField 
                            id="lineMarkup" 
                            variant="outlined"
                            value={lineMarkup}
                            onChange={(e)=>{setLineMarkup(e.target.value)}}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Minimum Cost</Typography>
                        <TextField 
                            id="minCost" 
                            variant="outlined"
                            value={minCost}
                            onChange={(e)=>{setMinCost(e.target.value)}}
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Color (hex value)</Typography>
                        <Box sx={{mt:3}}>
                            <ColorPicker
                                color={color}
                                setColor={setColor}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', flexDirection:'row-reverse', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maxWidth}}>
                        <Button 
                                variant='contained'
                                onClick={()=>{update(props)}}
                                disabled={loading}
                                sx={{
                                    minWidth:'300px', 
                                    minHeight:'50px',
                                    backgroundColor:loading?'#98A9BC':'#009AF9',
                                    mb:2
                                }} 
                            >
                            {submitText}
                        </Button>

                        {uploadResult === 'none'
                        ?
                            <></>
                        :
                        uploadResult === 'success'
                            ?
                                <Typography sx={{color:'green'}}>
                                    The new category has been successfully added.
                                </Typography>
                            :
                                <Typography sx={{color:'red'}}>
                                    Please check the above information again
                                </Typography>
                        }

                    </Box>
                </Box>

            </Paper>
        </Backdrop>
    );
}

export default UpdateCategoryBackdrop;