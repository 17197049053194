import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // Azure Blue
      main: "#009AF9",
      
    },
    secondary: {
        // Steel Grey
        main: "#727272",
      },
    
    success: {
        // Emphasize different semantic states, like green for highlighting something positive
        main: "#00B283",
        light: "#D0F1E8"
      },
    warning: {
        // Emphasize different semantic states, like yellow for confirming a warning message:
        main: "#F2CB66",
        light: "#FFF2D8"
    },
    error: {
        // Emphasize different semantic states, like red for informing error or confirming a destructive action:
        main: "#DC3233",
        light: "#F4E7E7"
    },
    action: {
        // Grabs attention to positive interactions, such as call-to-action buttons and highlighting a feature:
        main: "#0078C2",
        light: "#EDF8FF"
    },
    neutral: {
        black: {
            // 
            main: "#0078C2",
        },
        darkgrey1: {
            // 
            main: "#778CA2",
        },
        darkgrey2: {
            // 
            main: "#98A9BC",
        },
        lightgrey1: {
            // 
            main: "#CFD8DD",
        },
        lightgrey2: {
            // 
            main: "#F2F4F6",
        },},

    background:{
        offwhite: {
            main: "#F8FAFB",

        },
        white: {
            main: "#FFFFFF",

        },
    }
    

    

  },
  typography: {
    "fontFamily": `"Open Sans"`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold":700,
    "h6": {fontWeight: 700,letterSpacing:1},
    "label":{fontFamily:"Open Sans",fontSize:14,fontWeight:400,color:"#778CA2",marginBottom:20,paddingBottom:20},
    "uploadBtn":{fontFamily:"Open Sans",fontSize:18,fontWeight:600,lineHeight:"25px", textTransform:'none',},
    "caption1":{fontFamily:"Open Sans",fontSize:14,fontWeight:400,lineHeight:"19px", textTransform:'none',color: '#727272',marginTop:8},
    "caption2":{fontFamily:"Open Sans",fontSize:14,fontWeight:600,lineHeight:"19px", textTransform:'none',color: '#727272',marginTop:8},
    "filename":{fontFamily:"Open Sans",fontSize:16,fontWeight:600,lineHeight:"22px", textTransform:'none',color: '#1B1E24',marginRight:16},
    "orderSummary":{fontFamily:"Open Sans",fontSize:24,fontWeight:600,lineHeight:"33px", textTransform:'none',color: '#1B1E24',marginRight:16},
    "manualQuotation":{fontFamily:"Open Sans",fontSize:20,fontWeight:600,lineHeight:"27px", textTransform:'none',color: '#1B1E24'},
    "finishManual":{fontFamily:"Open Sans",fontSize:18,fontWeight:400,lineHeight:"25px", textTransform:'none',color: '#778CA2'},
    "bold":{fontFamily:"Open Sans", fontWeight:700},
    "enterDetailsItem": {fontFamily:"Open Sans",fontSize:14, fontWeight:700},
    "h2Home": {fontFamily:"Oxygen",fontSize:28,fontSize: 'calc( 14px + (32 - 14) * (100vw - 400px) / (1600 - 400) )', color:"#009af9",fontWeight:400, lineHeight: '40px', height:'80px'},
    "pHome":{fontFamily:"Nunito",fontSize:16, fontWeight:400,lineHeight:"25px", textTransform:'none',color: '#1B1E24'},
    "title": {fontFamily:"MontSerrat", fontSize:34, fontSize:'36px', color:"#1B1E24",fontWeight:300, lineHeight: '60px', },
    "pHome2":{fontFamily:"Open Sans",fontSize:'calc( 10px + (20 - 10) * (100vw - 400px) / (1600 - 400) )',fontWeight:400,lineHeight:"30px", textTransform:'none',color: '#1B1E24'},



   },


   
});

export default theme