import React from 'react';
import {Background} from '../../components/CustomProps';
import { Stack, Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, Outlet, useParams } from "react-router-dom";
import Box from '@mui/material/Box';

function Policy(props, theme) {

    theme=useTheme(theme)
    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
              <title>CutAnything | Policies</title>
              <meta name='description' content="Please look through this pages for the policies that we have. It contain our return and exhange policy, delivery policies and term of use."/>
              <link rel='canonical' href='/policy'/>
            </Helmet>
            <Stack><Typography variant="title">Policies Coming Soon!</Typography>
            <Outlet/></Stack>
            
           
            
        </Background>
    );
}

export default Policy;