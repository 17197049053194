import {useState,useRef} from 'react'
import { Stack,TextField,MenuItem, Box, Grid} from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme} from '@mui/material';
import { Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectComponent from './SelectComponent';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate,Link,createSearchParams } from 'react-router-dom';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { CircleNotifications } from '@mui/icons-material';
import { animated, useSpring } from '@react-spring/web'
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ConfirmDeleteIcon from './ConfirmDeleteIcon';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

const FileCard = (props,theme) => {
  theme=useTheme(theme)
  const costingTable=useRef(props.costingTable);
  const [nextSelect,setNextSelect]=useState('Material');
  const [editName, setEditName] = useState(false);
  const edittedFileName = useRef(null);
  

  const generateOptions = (column,costingTable) => {

    let columnData =
    costingTable.map((item,i) =>
    {
      return item[column]
    })
    let out = [... new Set(columnData)]
 
    let optionsList =
    out.map((item,i) =>
    {
      return {name: item,value:item,text: item}
    })
    optionsList.sort((a, b) => {
      return a.name - b.name;
  });
    return optionsList
    

  };

  const findBreakpoint = (quantity,discountBreakpoints) =>
    {
      
      for (let xid = 0; xid < discountBreakpoints.length; xid++) {
        if (discountBreakpoints[xid] > quantity) {
          if (xid !== 0) {
            return discountBreakpoints[xid - 1];
          } else {
            console.log(xid);
            return 0;
          }
        }
      }
      return discountBreakpoints[discountBreakpoints.length - 1];

    }

  const filterTable = (column,value,costingTablein) => {
    //console.log('filtering: ',costingTablein.length,'on: ',column,'with:',value)
    const costingTableout=costingTablein.filter((e)=>{return e[column]==value})

    return costingTableout
  };

  
  

  var [categoryOptions,setCategoryOptions] = useState(generateOptions('category',props.costingTable))
  var [materialOptions,setMaterialOptions] = useState([])
  var [surfacingOptions,setSurfacingOptions] = useState([])
  var [thicknessOptions,setThicknessOptions] = useState([])
  var [methodOptions,setMethodOptions] = useState([])
  var [maxCost,setMaxCost] = useState(false)
  const [minCostReached, setminCostReached] = useState(false)
  const navigate = useNavigate()

  const category = useRef('');
  const material = useRef('');
  const surfacing = useRef('');
  const thickness = useRef(0);
  const method = useRef('');
  const quantity = useRef(1);
  const discount =useRef(0)
  const [ppCost,setPpCost]=useState('--')
  const [itemCost,setItemCost]=useState('--')
  const [subtotal,setSubtotal]=useState(0)
  const [minCost,setminCost] = useState(0)
  const [isExpanded, setIsExpanded] = useState(true);

  const animationProps = useSpring({
    height: isExpanded ? 'auto' : '60px'
});

const handleToggle = (event) => {
  event.stopPropagation();  // Prevents propagation to parent stack
  setIsExpanded(!isExpanded);
};

  console.log(props.propkey)

  

  if (props.state==='success')
    

    {
      

      const fileName = props.itemName
      console.log(props.file)
      const imageUrl = `${process.env.REACT_APP_HOSTNAME}/`+props.file.image_name

      
      const details = JSON.parse(props.file.details)
      console.log(details)
      const length = details.bbox_length
      const width = details.bbox_width 
      const sizing = details.SIZE
      const perimeterLength = details.perimeter_length
      const userWarnings = details.USER_WARNINGS
      const fatalErrors = details.FATAL_ERRORS
      
      const discountSetting = props.discountTable[sizing]
      console.log(discountSetting)
      
      const handleChange = (event) => {

        var costingTable_temp=props.costingTable
        console.log(costingTable_temp)
        const orderFilter=[['category',category,category,setCategoryOptions,'Category'],
          ['material',material,material,setMaterialOptions,'Material'],
        ['surfacing',surfacing,surfacing,setSurfacingOptions,'Color/Finishing'],
        ['thickness',thickness,thickness,setThicknessOptions,'Thickness'],
        ['processing_method',method,method,setMethodOptions, 'Processing Method']]
    
        
    
        try{
          var index=10
        orderFilter.forEach(
          (e,i)=>{
    
            if(e[0]==event.target.name)
              {e[2].current=event.target.value
                index=i
                costingTable_temp=filterTable(e[0],event.target.value,costingTable_temp)
                if(event.target.value!='')
                  {
                    if(index<4)
                      {setNextSelect(orderFilter[index+1][4])}
                    else
                      {setNextSelect('')}
                  }
                else{
                  setNextSelect(orderFilter[index][4])
                }
              }
                
            else
            { 
              if(i<index){costingTable_temp=filterTable(e[0],e[1].current,costingTable_temp)     
    }
              else if(i==(index+1)){
                e[2].current=''
                var options=generateOptions(e[0],costingTable_temp)
                e[3](options)
                if (options.length==1)
                  {console.log('OPTIONS:',options[0].value)
                    e[2].current=(options[0].value)
                  index+=1
                  if(index<4)
                {setNextSelect(orderFilter[index+1][4])}
                else
                {setNextSelect('')
                console.log('cost updated thru backdoor')
                updateCosts(quantity.current)
                
              
              }
                }
              }
              else {
                e[2].current=''
                var options=[]
                e[3](options)
              }
    
            }
          })}catch(err){
            console.log('loop exited')
            
          };
        console.log(category,material,surfacing,thickness,method)
        costingTable.current=costingTable_temp
    
    
    }
      const updateCosts = (qty) => {   
        console.log('START OF UPDATE COSTS')
        console.log(quantity.current)
        qty=(parseInt(qty).toFixed(0))
        if (qty>100)
        {qty=100}
        quantity.current=(qty)

        props.setQuantity(props.propkey,qty) 
        console.log('costingtable length!:',costingTable.current.length)
        console.log(costingTable)
        
        if (costingTable.current.length==1){
          var areaCost=details.bbox_area*costingTable.current[0].unit_cost_area/1000000
          var lineCost=perimeterLength*costingTable.current[0].unit_cost_length/1000
          var interiorCost = (details.line_length-details.perimeter_length)*costingTable.current[0].unit_cost_length/1000
          console.log(props.categoryTable)
          let current_category = props.categoryTable.find(category => category.category_name === costingTable.current[0].category);
          console.log(current_category)
          var line_markup = current_category.interior_line_markup
          var area_markup = current_category.area_markup
          setminCost(current_category.minimum_cost)
          
          var ppCosttemp = '--'




          console.log(line_markup)
          console.log('areacost:/linecost/nomarkupinteriorcost',areaCost,lineCost,interiorCost)
          console.log('with line_markup at *',line_markup)  
          console.log('with area_markup at *',area_markup)  
          console.log('old_cost:',areaCost+lineCost+interiorCost)
          console.log('new_cost:',areaCost*(1+area_markup)+lineCost+interiorCost*line_markup)
          console.log('multiplier:',discountSetting.multiplier)
          setMaxCost(false)
          ppCosttemp=((areaCost*(1+area_markup)+lineCost+interiorCost*line_markup)*(discountSetting.multiplier)).toFixed(2)

          if(ppCosttemp>0.5)
            {ppCosttemp=ppCosttemp
              }
          else{
            ppCosttemp=0.5
          }

          if (ppCosttemp>1000)
            {setMaxCost(true)}
          setPpCost(ppCosttemp)
          var ro_value=discountSetting.ro_value
          var max_discount=discountSetting.max_discount
          var discountBreakpoints=discountSetting.discount_breakpoints
          var breakpoint = findBreakpoint(qty,discountBreakpoints)
          console.log("the breakpoint is: ", breakpoint)

          discount.current=((max_discount - (breakpoint**ro_value * max_discount)).toFixed(2))
          console.log(discount.current)

          var totalCost=ppCosttemp*qty*(1-discount.current)
          console.log('TOTAL COST',totalCost)
          console.log(minCost)
          if (totalCost>minCost)
            {setminCostReached(true)
              
              props.setCost(props.propkey,(totalCost))}
          else
            {setminCostReached(false)
            props.setCost(props.propkey,(minCost))}
          setItemCost(totalCost.toFixed(2))
          setSubtotal((ppCosttemp*qty).toFixed(2))
          
          props.setItemCosting(props.propkey,costingTable.current[0].id)
          props.setCounter(props.counter+1)
          
          }
        
        else{
          setPpCost('--')
          setItemCost('--')
          props.setItemCosting(props.propkey,false)
          props.setCost(props.propkey,false)
        
        }
        props.checkValidity(quantity)
  
  ;}
      const handleChange2 = (event) => {
        setPpCost('--')
        setItemCost('--')
        props.setCost(props.propkey,false)
        const promiseA = new Promise((resolve,reject)=>handleChange(event));
        promiseA.then(updateCosts(quantity.current))
        props.setCounter(props.counter+1)
      }

  const updateItemName = (e) => {
    props.updateItemName(props.propkey, edittedFileName.current.value)
    props.setCounter(props.counter + 1)
    setEditName(false);
  }
      
      
            
  return (
    <Stack 
    sx={{ borderRadius: "8px 8px 0px 0px",  height:'contain', left:56, top:160, background:"#FFFFFF",boxSizing: "border-box"}}

    >
        {userWarnings.length === 0 && !maxCost?
          <Stack height="60px" flexDirection="row" alignItems="center" bgcolor={theme.palette.success.light}>
            <Stack direction="row" alignItems="center">
              <DoneIcon background="transparent" sx={{fill:`${theme.palette.success.main}`, height:"24px", marginX:"12px",marginY:"11px"}}/>
              {editName?
                <>
                  <Input 
                    defaultValue={fileName.split(".")[0]} 
                    inputRef={edittedFileName}
                    endAdornment={<InputAdornment position="end">.dxf</InputAdornment>}
                  />
                  <Button variant='outlined' onClick={(e)=>{updateItemName(e)}} sx={{m:1}}>Submit</Button>
                  <Button variant='outlined' onClick={()=>setEditName(false)} sx={{m:1}}>Cancel</Button>
                </>
              :
                <>
                  <Typography variant="filename">{fileName}</Typography>
                  <Button variant='outlined' onClick={()=>setEditName(true)}>Edit</Button>
                </>
              }

            </Stack>
            
            <Typography flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1">{width.toFixed(0)}mm x {length.toFixed(0)}mm</Typography>
            <IconButton onClick={handleToggle}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <ConfirmDeleteIcon 
                    onDelete={() => {props.removeItem(props.propkey)}}
                    disabled={props.uploadDisabled}
                />
          </Stack>
        :
          <Stack height="60px" flexDirection="row" alignItems="center" bgcolor={theme.palette.warning.light}>
            <Stack direction="row" alignItems="center">
              <ErrorOutlineIcon background="transparent" sx={{fill:`${theme.palette.warning.main}`,  height:"24px", marginX:"12px",marginY:"11px"}}/>
              <Typography variant="filename">{fileName}</Typography>
            </Stack>
            
            <Typography flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1">{width.toFixed(0)}mm x {length.toFixed(0)}mm</Typography>
            <ConfirmDeleteIcon 
                    onDelete={() => {props.removeItem(props.propkey)}}
                    disabled={props.uploadDisabled}
                />
          </Stack>
        }
      {isExpanded && (
                <>
      <Grid container direction='row'>
        {/* picture field */}
        <Grid item xs={12} md={12} lg={4}>
          <Box flex={1} sx={{alignItems:'center', display:'flex', mt:3, ml:{lg:3},justifyContent:'center'}}  >
            {imageUrl==null?
              <>
                  <BrokenImageIcon sx={{border: "1px solid #ECEBED",borderRadius:1,Height:"267px",Width:"272px"}}/>
              </>
              :
              <>
                  <img 
                    style={{
                      border: "1px solid #ECEBED",
                      borderRadius:1,
                      maxHeight:"267px",
                      maxWidth:"272px"
                      }} 
                    src={imageUrl}
                    alt={imageUrl}
                  />
              </>
            }
            </Box>
        </Grid>
        
        {/* category field */}
        <Grid item xs={6} md={6} lg={4}>
          <Stack flex={1} sx={{m:3,mt:0}}>
              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Category</Typography>
              <SelectComponent name="category" items={categoryOptions} selected={category.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Material</Typography>
              <SelectComponent name="material" items={materialOptions} selected={material.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}}  variant="caption1">Color/Finishing</Typography>
              <SelectComponent name="surfacing" items={surfacingOptions} selected={surfacing.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Thickness</Typography>
              <SelectComponent name="thickness" items={thicknessOptions} selected={thickness.current} handleSelect={handleChange2} nextSelect={nextSelect}/>

              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Processing Method</Typography>
              <SelectComponent name="processing_method" items={methodOptions} selected={method.current}  handleSelect={handleChange2} nextSelect={nextSelect}/>
            </Stack>

            {userWarnings.map((item,i) =>
            <Typography key={i} textAlign="left"  color={theme.palette.error.main}>{item}</Typography>)
            }

        </Grid>
        
        {/* quantity field */}
        <Grid item xs={6} md={6} lg={4} sx={{pr:3}}>
          <Stack flex={1} width sx={{mt:0,mr:0}}>
            <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Quantity</Typography>
            <input 
              type="number" 
              min={1} 
              max={100} 
              placeholder="1" 
              style={{
                width:"auto", 
                height:"auto",
                border: "1px solid #ECEBED",
                borderRadius: "4px",
                color:"#1B1E24",
                fontFamily:"Open Sans",
                fontSize:16,
                fontWeight:600,
                lineHeight:"22px",
                textTransform:'none', 
                padding:"12px", 
                fontColor:"#98A9BC"
              }} 
              onChange={(e) => {updateCosts(e.target.value);}} 
              value={quantity.current}>
            </input>

            <Stack direction="row" sx={{mt:3,mb:0}} justifyContent="space-between">
              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Cost per Piece</Typography>
              <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${ppCost}</Typography>
            </Stack>

            <Stack direction="row" sx={{mt:0,mb:1}} justifyContent="space-between">
              <Typography  textAlign="left" sx={{ml:"8px",mt:0,my:1}} variant="caption1">Discounted Cost</Typography>
              <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${ppCost==='--'?'--':(ppCost*(1-discount.current)).toFixed(2)}</Typography>
            </Stack>
            <hr 
              style={{
                color: "#ECEBED",
                backgroundColor: "#ECEBED",
                height: '1px',
                width: "100%",
                border: 0
              }}
            />
              
            {maxCost?
            <>
              <Stack direction="row" sx={{my:1}} justifyContent="space-between">
                <Typography  textAlign="left" sx={{ml:"8px",my:0}} variant="caption1">Subtotal</Typography>
                <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${itemCost}</Typography>
              </Stack>
            
              <Typography width="100%" textAlign="left" textcolor={theme.palette.warning.main} sx={{ml:"8px",mt:0,my:1}} variant="caption1">
                For orders above $1000, please contact our service representative for a manual quote.
              </Typography>

              <Button sx={{ width: "70%", m:3, mt:3, p:2, pt:1, pb:1}} variant="outlined" component="span">
                <Link 
                  to={{pathname:"/manual-quotation",
                  search:`?${createSearchParams({fileId:props.file.file_id,fileName:props.itemName,imageUrl:props.file.image_name})}` }}
                  target="_blank" 
                  style={{textDecoration:'none'}}
                >
                <Typography variant="filename" sx={{color:theme.palette.primary.main}}>Request quote</Typography> 
                </Link>
              </Button>
            </>
            : 
            <>
              <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Subtotal</Typography>
                <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${subtotal}</Typography>
              </Stack>

              <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Bulk Discount </Typography>
                <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">{(discount.current*100).toFixed(0)}%</Typography>
              </Stack>

              <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Total Savings ($)</Typography>
                <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">-${(discount.current*subtotal).toFixed(2)}</Typography>
              </Stack>

              <Stack direction="row" sx={{my:0}} justifyContent="space-between">
                <Typography  textAlign="left" sx={{ml:"8px",my:1}} variant="caption1">Total Cost</Typography>
                {minCostReached?
                  <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">${itemCost}</Typography>
                :
                  <Typography  textAlign="right" alignSelf={"right"} sx={{ml:"8px",mt:0,my:1,mr:2}} variant="caption1">Minimum Order ${minCost} ({itemCost})</Typography>
                }
              </Stack>
            </>
            }
          </Stack>
        </Grid>

      </Grid>
      </>
      )}
    </Stack>
    )
  
  }else if (props.state==='error'){
    const details = JSON.parse(props.file.details)
    const fatalErrors = details.FATAL_ERRORS
    return (
        <Stack>
          <Stack height="60px" flexDirection="row"  bgcolor={theme.palette.error.light}>
            <Stack direction="row" alignItems="center">
              <ClearRoundedIcon background="transparent" sx={{fill:`${theme.palette.error.light}`,  height:"24px", marginX:"12px",marginY:"11px"}}/>
              <Typography color={theme.palette.error.main} variant="filename">Upload Failed: </Typography>
              <Typography color={theme.palette.error.main} variant="filename">{props.itemName}</Typography>
            </Stack>

            <Typography flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1"></Typography>
            <ConfirmDeleteIcon 
                    onDelete={() => {props.removeItem(props.propkey)}}
                    disabled={props.uploadDisabled}
                />
          </Stack>

          <Stack sx={{ borderRadius: "8px 8px 0px 0px", width:'100%', height:'contain', left:56, top:160, background:"#FFFFFF",boxSizing: "border-box",flexDirection:"row"}}>
            <Stack sx={{m:2}}>
              {fatalErrors.map((item,i) =>
                <Typography key={i} textAlign="left"  color={theme.palette.error.main}>{item}</Typography>
              )}
            </Stack>
            
            <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Button sx={{bgcolor:'#009AF9'}} variant="contained">
                  <Link to={{pathname:"/manual-quotation",
                    search:`?${createSearchParams({fileId:props.file.file_id,fileName:props.itemName,imageUrl:props.file.image_name})}` }}
                    target="_blank" 
                    style={{textDecoration:'none'}}
                  >
                  <Typography variant="filename" sx={{color:"white"}}>Request Manual Quote</Typography> 
                </Link>
              </Button>
            </Box>
        </Stack>
      </Stack>
    )
  }else if (props.state==='uploading'){
    return (
      <Stack height="60px" flexDirection="row" bgcolor="#FFFFFF" color={theme.palette.neutral.darkgrey1.main} >
        <Stack direction="row" alignItems="center">
          <CircularProgress color="inherit" background="transparent" size="24px" sx={{marginX:"12px",marginY:"11px"}}/>
          <Typography color={theme.palette.neutral.darkgrey1.main} variant="filename">Uploading file...</Typography>
        </Stack>
        
        <Typography  flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1"></Typography>

      </Stack>
    )
  }else if (props.state==='loading'){
    return (
      <Stack height="60px" flexDirection="row" bgcolor="#FFFFFF" color={theme.palette.neutral.darkgrey1.main} >
        <Stack direction="row" alignItems="center">
          <CircularProgress color="inherit" background="transparent" size="24px" sx={{marginX:"12px",marginY:"11px"}}/>
          <Typography color={theme.palette.neutral.darkgrey1.main} variant="filename">Processing file... {props.itemName}</Typography>
        </Stack>
        
        <Typography  flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1"></Typography>
        <ConfirmDeleteIcon 
                    onDelete={() => {props.removeItem(props.propkey)}}
                    disabled={props.uploadDisabled}
                />
      </Stack>
    )
  }else{
    console.log(props)
    return (
      <Stack>
        <Stack height="60px" flexDirection="row"  bgcolor={theme.palette.error.light}>
          <Stack direction="row" alignItems="center" sx={{pl:3}}>
          <Typography color={theme.palette.error.main} variant="filename">Upload Failed: </Typography>
          <Typography color={theme.palette.error.main} variant="filename">{props.itemName}</Typography>
          </Stack>

          <Typography flex={3} textAlign="right" sx={{ml:"16px",mt:0}} variant="caption1"></Typography>
          <ConfirmDeleteIcon 
                    onDelete={() => {props.removeItem(props.propkey)}}
                    disabled={props.uploadDisabled}
                />
        </Stack>

        <Stack 
          direction='row'
          justifyContent="center"
          alignItems="center"
          sx={{ borderRadius: "8px 8px 0px 0px", width:'100%', height:'contain', left:56, top:160, background:"#FFFFFF",boxSizing: "border-box", pt:3, pb:3}}
        >
          <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Button sx={{bgcolor:'#009AF9'}} variant="contained">
                  <Link to={{pathname:"/manual-quotation",
                    search:`?${createSearchParams({fileId:props.file.file_id,fileName:props.itemName,imageUrl:props.file.image_name})}` }}
                    target="_blank" 
                    style={{textDecoration:'none'}}
                  >
                  <Typography variant="filename" sx={{color:"white"}}>Request Manual Quote</Typography> 
                </Link>
              </Button>
            </Box>
        </Stack>
      </Stack>
  )}
}

export default FileCard;