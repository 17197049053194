import React from 'react';
import Slider from '@mui/material/Slider';
import { TextField, Stack } from '@mui/material';

function NumberedSlider(props) {
  const { setValue, value, min, max, valueLabelDisplay } = props;

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
  };

  return (
    <Stack spacing={2} direction='row' alignItems='center'>
      <Slider
        value={typeof value === 'number' ? value : 0}
        onChange={handleSliderChange}
        min={min}
        max={max}
        valueLabelDisplay={valueLabelDisplay}
      />
      <TextField
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
          min: min,
          max: max,
          type: 'number',
        }}
      />
    </Stack>
  );
}

export default NumberedSlider;
