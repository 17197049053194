import React, {useMemo, useCallback, useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import Typography from '@mui/material/Typography';
import {useUserContext} from '../context/UserContext';
import axios from 'axios';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 40,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#D9E6EE',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  transition: 'border .24s ease-in-out',
  height:125,
  width:380
};

function DragAndDrop(props, theme) {

  theme=useTheme(theme)
  const {auth} = useUserContext();
    

  const checkComplete = (file_id,i,repeat_count) =>{
    console.log("check for file being initiated,",file_id)
    console.log("repeat_count: ", repeat_count)
    if (repeat_count<5)
    {setTimeout(
       function() {
          axios.get(`${process.env.REACT_APP_HOSTNAME}/asyncFileUpload/results/`+file_id,
          {
            headers: {
                'Authorization': 'Token ' + auth.logginToken
            },
            timeout: 35000
        }).then((res) => {
          if (res.data.status === "error") {
            props.addFile({errors:['File was too complex to handle, please use the Manual Quotation']},i)
            props.updateItemState(i,'filetoolarge')} // Raise an error
          else if (res.data.status) {
            console.log("response received")
            console.log(res)
            const details = JSON.parse(res.data.results.details)
            props.addFile(res.data.results,i) //CHANGE TO A LOOKUP ON THE FILE_ID, then UPDATE the corresponding item from there
            if (details.FATAL_ERRORS.length<1)
            {props.updateItemState(i,'success')}
            else
            {props.updateItemState(i,'error')}
            

          }
          else{
            checkComplete(file_id,i,repeat_count+1)
            
          }

        }) },5000)}
    else
      { 
        props.addFile({errors:['File was too complex to handle, please use the Manual Quotation']},i)
        props.updateItemState(i,'filetoolarge')
      }
  }

  const loadNext= (selectedFiles,i) =>{
      const tempItemNum=props.itemNum
      props.setUploadDisabled(true)
      if (i < selectedFiles.length){
        console.log(selectedFiles[i])
          var file_name_full = selectedFiles[i].name;
          var file_name = file_name_full.split('/')[file_name_full.split('/').length - 1]
          console.log(file_name)
          
          console.log(selectedFiles[i].size)
          if (selectedFiles[i].size<(1024*1024*50))
          {
          props.updateItemState(tempItemNum+i,'uploading')
          props.addItemName(file_name,'itemNames')
          
          var formData = new FormData();
          formData.append('file', selectedFiles[i]);
          formData.append('file_name',file_name);
          formData.append('imgRes', 50)
          formData.append('is_simple', 'False')


          axios.post(`${process.env.REACT_APP_HOSTNAME}/asyncFileUpload/`, 
         formData,
      {
          headers: {
              'Authorization': 'Token ' + auth.logginToken
          },
          timeout: 35000
      }).then(
        (res) => {
          console.log("filesubmitted:")
          console.log(res.data)
          console.log(tempItemNum+i)
          props.updateItemId(tempItemNum+i,res.data.id)
          props.updateItemState(tempItemNum+i,'loading')
          checkComplete(res.data.id,tempItemNum+i,0)
          i++;
          
          loadNext(selectedFiles,i);}
          ).catch((error)=>{
              console.log(error)
              console.log(error.code)
              if(error.code!='ECONNABORTED'&&error.code!='ERR_BAD_RESPONSE'&&error.code!='ERR_NETWORK')
              {props.addFile(error.data,i)
              props.updateItemState(tempItemNum+i,'error');}
              else{
              console.log('Error:',error)
              console.log(tempItemNum+i)
              props.addFile({errors:['File was too complex to handle, please use the Manual Quotation']},i)
              props.updateItemState(tempItemNum+i,'ECONNABORTED');
              }
             
              
              i++;loadNext(selectedFiles,i)})
          
      }
      else{
          console.log('file too big la')
          console.log(tempItemNum+i)
          props.addFile({errors:['File was larger than the filesize limit of 10MB, please use the Manual Quotation']},i)
          props.updateItemState(tempItemNum+i,'ECONNABORTED')
          i++;loadNext(selectedFiles,i)}}
      else{props.setUploadDisabled(false)
          }}
  

  const handleSubmission = (acceptedFiles) => {
        var i = 0;
        console.log(acceptedFiles)
        props.setStep(2)
        loadNext(acceptedFiles,i)

        }
  

  const onDrop = useCallback(acceptedFiles => {
      console.log(acceptedFiles)
      handleSubmission(acceptedFiles)
  }, [])

  const {
      getRootProps,
      getInputProps,
      acceptedFiles,
  } = useDropzone({onDrop, noClick:true, accept:{'image/x-dxf': ['.dxf']}});
  
  const style = useMemo(() => ({
    ...baseStyle,
  }));



  // https://react-dropzone.js.org/#section-basic-example

  return (
<>{props.variant ==='full'?
      <div className="container">
        <div {...getRootProps({style})}>
        <label htmlFor="contained-button-file">
            <input disabled={props.uploadDisabled} onChange={(e)=>handleSubmission(e.target.files)} accept=".dxf , .DXF"multiple type="file"  {...getInputProps()} id="contained-button-file"/>
            
            <Button 
              disabled={props.uploadDisabled} 
              sx={
                {
                  mt:4, 
                  mb:1, 
                  color:"white", 
                  bgcolor:'#009AF9',
                  ':hover': {
                    bgcolor: theme.palette.action.main,
                    color: 'white',
                  },
                }
              } 
              variant="outlined" 
              component="span"  
              startIcon={<FileUploadOutlinedIcon />}  
              size="large"
            >
                <Typography variant="uploadBtn">Upload file(s)</Typography>
            </Button>
          </label>        

            <Typography variant="caption1" display="block"  gutterBottom> or drag and drop here </Typography>
            <Typography variant="caption2" display="block"  gutterBottom> .dxf files are supported </Typography>
        </div>

    </div>
    :
    <label htmlFor="contained-button-file">
            <input disabled={props.uploadDisabled} onChange={(e)=>handleSubmission(e.target.files)} accept=".dxf , .DXF"multiple type="file"  {...getInputProps()} id="contained-button-file"/>
            
            <Button 
              disabled={props.uploadDisabled} 
              sx={{mt:1, mb:1, color:'#009AF9', backgroundColor: 'white', borderColor:'#009AF9',width:"100%", height:"60px",borderRadius:"6px" }}
              variant="outlined" 
              component="span"  
              startIcon={<FileUploadOutlinedIcon />}  
              size="large"
            >
                <Typography variant="uploadBtn">Upload file(s)</Typography>
            </Button>
          </label>
  }
     </>
       
    );
}

export default DragAndDrop;