import { useState, useEffect} from 'react';
import { Fab } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';

function BackToTopButton(props) {

    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        backgroundColor:'#009AF9'
    };

    const [showButton, setShowButton] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll", ()=>{
            if(window.scrollY > 100){
                setShowButton(true)
            }else{
                setShowButton(false)
            }
        })
    }, [])

    const scrollUp = () =>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }


    return (
        <>
            {showButton
            ?
                <Fab variant="extended" style={style} onClick={scrollUp}>
                    <NavigationIcon sx={{color:'white'}} />
                </Fab>
            :
                <></>
            }
        </>
    );
}

export default BackToTopButton;