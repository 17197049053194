import React, { useRef, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from 'buffer';
import { useUserContext } from '../context/UserContext';

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import {Item,ResponsiveGridContainer,StackChanged,Background,HomeH2,HomeP2,HomeP3} from '../components/CustomProps'
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { HomeMiniRounded } from '@mui/icons-material';


function Home(theme) {
    theme=useTheme(theme)




    return (
        <div>
            <Background sx={{pb:10,display:'block'}}>

                <Helmet>
                    <title>CutAnything | Home</title>
                    <meta name='description' content="Do you need help with cutting your materials? We are here to help you cut materials from metal, plastic, wood and many more."/>
                    <link rel='canonical' href='/'/>
                </Helmet>

                <ResponsiveGridContainer container 
                spacing={{
                        xs: 0,
                        sm: 2,
                        md: 5
                    }}
                sx={{alignItems:'center', justifyContent:'centre'}}>
                    <Grid container item xs={12} md={6} justifyContent="center" >
                    <Box
                        component="img"
                        sx={{ display: {display:'flex', maxWidth:"350px",justifyContent:'center' } }}
                        alt="cutanything_word log"
                        src='https://global-uploads.webflow.com/6100f59c138a680259458e8b/624bea19cb3480e0fc077246_Artboard%20112-p-500.png' 
                        />

                    </Grid>

                    <Grid item xs={12} md={6} sx={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                        <Stack spacing={2} sx={{width:"90%", maxWidth:"500px"}}>
                            <HomeH2>Instant quotes for custom Parts from Sketch to Reality Quick</HomeH2>
                            <HomeP2 pl={1}>Get custom parts manufactured to your specifications on demand with our Instant quote solution with instant Dfm feedback.
                            </HomeP2>
                            <HomeP2 pl={1}>Getting parts cut is now easier than ever.
                            </HomeP2>
                            <HomeH2>One-Stop Shop for all your CNC cutting solutions</HomeH2>
                            <HomeP2 pl={1}>                            With our large array of cutting capabilities, CutAnything is your one stop platform to process all your cutting needs ! 
                            </HomeP2>                            
                        </Stack>
                    </Grid>


    
                </ResponsiveGridContainer>
            </Background>
            <Background sx={{pb:10,mt:2,display:'block'}}>

            <ResponsiveGridContainer container sx={{display:'flex',alignItems:'center',justifyContent:'space-evenly'}} >

                <Grid item xs={12} container justifyContent='center'> 
                <Typography variant="title" mb={10}>
                    Go from design to product in three quick steps!
                </Typography>
                </Grid>


                <Grid item xs={12} lg={3} sx={{minHeight:'350px'}} >
                    <Grid container >
                        <Grid item xs={6} lg={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}} >

                        <Box
                        component="img"
                        sx={{ display: {display:'flex',width:"100%", maxWidth:"280px",justifyContent:'center' } }}
                        alt="cutanything_word log"
                        src='https://global-uploads.webflow.com/6100f59c138a680259458e8b/6234443c9da35f0a57e385ac_cloud-computing.png' 
                        />

                        </Grid>
                    
                        <Grid item xs={6} lg={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <Stack sx={{display:{display:'flex', width:"100%", maxWidth:"320px"}}}>
                            <HomeH2  mt={6} align='center' sx={{color:'black',fontFamily:"MontSerrat",lineHeight:"40px"}}>
                                Upload Your File
                            </HomeH2>
                            
                            <HomeP3 align='center'>
                            Upload your design files to our platform to check for manufacturability issues.

                            </HomeP3>
                            </Stack>
                            
                        </Grid>

                    </Grid>
                
                </Grid>
                <Grid item xs={12} lg={3} sx={{minHeight:'350px'}} >
                <Grid container >
                    <Grid item xs={6} lg={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Box
                        component="img"
                        sx={{ display: {display:'flex', width:"100%",  maxWidth:"280px",justifyContent:'center' } }}
                        alt="generate quotes"
                        src='https://global-uploads.webflow.com/6100f59c138a680259458e8b/6234470f8a341789feeebedb_quotation-p-500.png' 
                        />
                        </Grid>
                    <Grid item xs={6} lg={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Stack sx={{display:{display:'flex', width:"100%", maxWidth:"320px"}}}>
                        <HomeH2  mt={6} align='center' sx={{color:'black',fontFamily:"MontSerrat",lineHeight:"40px"}}>
                                Generate Quotes
                            </HomeH2>
                            <HomeP3 align='center'>
                            Choose your material and manufacturing process get an instant quotation and estimated lead time.

                            </HomeP3>
                            </Stack>
                            </Grid>
                </Grid>
                
                </Grid>
                <Grid item xs={12} lg={3} sx={{minHeight:'350px'}}>
                    <Grid container  >
                    <Grid item xs={6} lg={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Box
                        component="img"
                        sx={{ display: {display:'flex', width:"100%", maxWidth:"280px",justifyContent:'center' } }}
                        alt="cutanything_word log"
                        src='https://global-uploads.webflow.com/6100f59c138a680259458e8b/62344789684f32bca76ae769_delivery-box-p-500.png' 
                        />
                        </Grid>
                        <Grid item xs={6} lg={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <Stack sx={{display:{display:'flex', width:"100%", maxWidth:"320px"}}}>
                            <HomeH2  mt={6} align='center' sx={{color:'black',fontFamily:"MontSerrat",lineHeight:"40px"}}>
                                Receive Your Parts
                            </HomeH2>
                            <HomeP3  align='center' >
                            Receive your parts with our same-day delivery or pickup options. Get high quality parts with short lead-time!
                            </HomeP3>
                            </Stack>
                        </Grid>

                    </Grid>
                
                </Grid>

                



            </ResponsiveGridContainer>



    
              
            </Background>
        </div>
    );
}

export default Home;



{/* <Link onClick={() => {navigate("/sign-up")}}>Don't have an account?</Link>
<Link onClick={() => {navigate("/forget-password")}}>Forget your password?</Link> */}
