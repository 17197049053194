import {useState, useRef} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from '../GetWindowSize';
import ColorPicker from '../ColorPicker';

function AddCategoryBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Submit');
    const [uploadResult, setUploadResult] = useState('none');
    const {height, width} = useWindowDimensions();
    const [color, setColor] = useState('#000000');

    //for the useRef for the fields
    const inputCategory = useRef(null);
    const inputAreaMarkup = useRef(null);
    const inputInteriorLineMarkup = useRef(null);
    const inputLineMarkup = useRef(null);
    const inputMinCost = useRef(null);


    const submit = (props) =>{
        console.log('submit')
        setLoading(true)
        setSubmitText('Submitting...')
        setUploadResult('none')

        axios.post(`${process.env.REACT_APP_HOSTNAME}/get-category-table/`,
            {
                category_name: inputCategory.current.value,
                area_markup: inputAreaMarkup.current.value,
                interior_line_markup: inputInteriorLineMarkup.current.value,
                line_markup: inputLineMarkup.current.value,
                color: color
            },
            {
                headers:{
                    'Authorization': 'Token ' + auth.logginToken
                }
            }
        ).then((res)=>{
            console.log(res)

            props.setCategoryTable([...props.categoryTable, res.data])

            setLoading(false)
            setSubmitText('Submit')
            setUploadResult('success')
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
            setSubmitText('Submit')
            setUploadResult('fail')
        })
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.addCategoryBackdrop}
        >

            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Add New Category Material</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setAddCategoryBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />
                
                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Category</Typography>
                        <TextField id="areaMarkup" variant="outlined" inputRef={inputCategory} fullWidth/>
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Area Markup</Typography>
                        <TextField id="areaMarkup" variant="outlined" inputRef={inputAreaMarkup} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Interior Line Markup</Typography>
                        <TextField id="interiorLineMarkup" variant="outlined" inputRef={inputInteriorLineMarkup} fullWidth/>
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Line Markup</Typography>
                        <TextField id="lineMarkup" variant="outlined" inputRef={inputLineMarkup} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Minimum Cost</Typography>
                        <TextField id="minCost" variant="outlined" inputRef={inputMinCost} fullWidth/>
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Color (hex value)</Typography>
                        <Box sx={{mt:3}}>
                            <ColorPicker
                                color={color}
                                setColor={setColor}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', flexDirection:'row-reverse', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maxWidth}}>
                        <Button 
                                variant='contained'
                                onClick={()=>{submit(props)}}
                                disabled={loading}
                                sx={{
                                    minWidth:'300px', 
                                    minHeight:'50px',
                                    backgroundColor:loading?'#98A9BC':'#009AF9',
                                    mb:2
                                }} 
                            >
                            {submitText}
                        </Button>

                        {uploadResult === 'none'
                        ?
                            <></>
                        :
                        uploadResult === 'success'
                            ?
                                <Typography sx={{color:'green'}}>
                                    The new category has been successfully added.
                                </Typography>
                            :
                                <Typography sx={{color:'red'}}>
                                    Please check the above information again
                                </Typography>
                        }

                    </Box>
                </Box>

            </Paper>
            
        </Backdrop>
    );
}

export default AddCategoryBackdrop;