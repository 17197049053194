import React from 'react';
import { Grid, 
        Typography,
        Box,
        Stack,
        Divider,
    } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import cncimage from "../../img/cnc6.jpeg";
import cncimagephone from "../../img/cnc2.jpeg";
import Co2Laser from './capabilities/Co2Laser';
import FiberLaser from './capabilities/FiberLaser';
import SixAxis from './capabilities/SixAxis';
import SpindleRouting from './capabilities/SpindleRouting';
import useWindowDimensions from '../../components/GetWindowSize';
import HashLink from '../../components/HashLink';
import BackToTopButton from '../../components/BackToTopButton';

function Capabilities(props) {

    const dimension = useWindowDimensions();
    const image = dimension.width < 600? cncimagephone: cncimage;
    const titleColor = dimension.width < 600? "black": "white";
    const titleSize = dimension.width < 600? "h4": "h1";
    const stackDirection = dimension.width < 600? "column": "row";
    const dividerOrientation = dimension.width < 600? "horizontal": "vertical";

    const productInfo = {
        "co2": {
            "title": "CO2 Laser Cutting",
            "description": "Ideal for creating precise components, detailed engravings, and intricate designs in both industrial and artisan contexts.",
            "suitable": "A wide range of materials, including metals, plastics, textiles, and wood.",
            "tolerance": "Exceptional level of accuracy, up to 0.1mm.",
            "price": 3,
            "speed": 3,
            "best": "Rapidly producing highly detailed and precise components in a wide array of materials.",
            "notFor": "Very thick materials or highly heat-sensitive materials."
        },
        "fiber":{
            "title": "Fiber Laser Cutting",
            "description": "Suitable use cases: Perfect for high-speed precision cutting in automotive, aerospace, and electronics industries.",
            "suitable": "Particularly efficient for reflective materials like aluminum, copper, and brass.",
            "tolerance": "Offers precision comparable to CO2 Laser, with tolerances around 0.1mm.",
            "price": 4,
            "speed": 4,
            "best": "Providing high-speed, precise cuts in reflective materials for high-volume manufacturing.",
            "notFor": "Non-metallic materials or low volume projects where cost-efficiency may be lower."
        },
        "cnc":{
            "title": "Six Axis CNC Machine",
            "description": "Especially suitable for creating complex and intricate parts in the aerospace, automotive, and medical industries.",
            "suitable": "Most metals and hard plastics.",
            "tolerance": "Capable of achieving tolerances as tight as 0.005mm.",
            "price": 5,
            "speed": 2,
            "best": "Manufacturing complex parts with irregular geometries that traditional machining can't handle.",
            "notFor": "Simple geometries or projects where speed and cost are primary considerations."
        },
        "spindle":{
            "title": "Spindle Routing",
            "description": "Popular for furniture manufacturing, signage, and mold creation.",
            "suitable": "Ideal for woodworking, plastics, and foam.",
            "tolerance": "Offers an excellent balance of speed and precision, with typical tolerances around 0.05mm.",
            "price": 2,
            "speed": 4,
            "best": "Rapid production of large and intricately designed wooden and plastic items.",
            "notFor": "Metals or extremely hard materials."
        }
    
    }

    return (
        <Box sx={{
                justifyContent: 'center', 
                flexDirection: 'column', 
                alignItems: 'center', 
                backgroundColor:'white',
                mt:-2,
            }}>

            <Helmet>
              <title>CutAnything | Capabilities</title>
              <meta name='description' content="In CutAnythingwe have several different methods of cutting for you material 
              which ranges from CO2 Laser, Fiber Laser, Water Jet and many more."/>
              <link rel='canonical' href='/capabilities'/>
            </Helmet>

            {/* title section */}
            <Box 
                sx={{width:'100%', minHeight:'800px'}} 
                style={{
                    backgroundImage:`url(${image})`, 
                    backgroundRepeat:"no-repeat", 
                    backgroundSize:"100% 100%"
                    }}>
                <Grid container wrap="nowrap">
                    <Grid 
                        item
                        lg={7}
                        sx={{
                            pt:dimension.width < 600? 65 : 30,
                            pl:4
                        }}
                    >
                        <Typography variant={titleSize} color={titleColor} gutterBottom >
                            In CutAnything, 
                        </Typography>

                        <Typography variant='manualQuotation' color={titleColor}>
                            We provides multiple customised cutting services for you to choose from. This will allow you to get the services that best fit for your requirements.
                        </Typography>
                    </Grid>
                    <Grid item lg={5}/>

                </Grid>
            </Box>
            
            {/* service navbar */}
            <Box 
                sx={{
                    mt:4,
                    mb:2,
                    minHeight:'50px'
                }}
            >

                <Stack
                    direction={stackDirection}
                    justifyContent="space-evenly"
                    alignItems="center"
                    divider={<Divider orientation={dividerOrientation} sx={{backgroundColor:'#778CA2', borderRightWidth: 1}}  flexItem />}
                    spacing={2}
                    
                >
                    <HashLink name="CO2 Laser Cutting" section="co2" />
                    <HashLink name="Fiber Laser Cutting" section="fiber" />
                    <HashLink name="Six Axis CNC Machining" section="cnc" />
                    <HashLink name="Spindle Routing" section="spindle" />
                </Stack>
            </Box>
            
            {/* CO2 laser cutting */}
            <Box id="co2">
                <Co2Laser productInfo={productInfo.co2}/>
            </Box>

            
            <Box id="fiber">
                <Box sx={{width:'100%', minHeight:'400px'}}>
                    <FiberLaser productInfo={productInfo.fiber} />
                </Box>
            </Box>

            <section id="cnc">
                <Box sx={{width:'100%', minHeight:'400px'}}>
                    <SixAxis productInfo={productInfo.cnc} />
                </Box>
            </section>

            <section id="spindle">
                <Box sx={{width:'100%', minHeight:'400px'}}>
                    <SpindleRouting productInfo={productInfo.spindle} />
                </Box>
            </section>


            <BackToTopButton/>
        </Box>
    );
}

export default Capabilities;