import {useState} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useWindowDimensions from '../GetWindowSize';

function DeleteMultipleMaterialBackdrop(props) {

    const {auth} = useUserContext();
    const maximumWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const {height, width} = useWindowDimensions();

    //this is to customise the <Accordion/> component
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
        ))(({ theme }) => ({
        border: `1px solid white`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    //this is to customise the <AccordionSummary/> component
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          {...props}
        />
      ))(({ theme }) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
      }));
    
    //this is to customise the <AccordionDetails/> component
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    //this is to handle the change when user click to expand/collapse the items
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    //this is to delete the items in the backdrop
    const Delete = () =>{
        // console.log(props.dataCostTable)
        // console.log(props.rowSelectionModel)
        setLoading(true)

        axios.delete(`${process.env.REACT_APP_HOSTNAME}/cost`, {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            },
            data:{id_list:props.rowSelectionModel}
        }).then((res)=>{
            let data = props.dataCostTable;
            for(let i=0; i<=props.rowSelectionModel.length; i++){
                let filteredData = data.filter(function(obj){
                    return obj.id !== props.rowSelectionModel[i]
                })
                data = filteredData
            }
            props.setDataCostTable(data)
            props.setDeleteMultipleBackdrop(false)
            setLoading(false)
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.deleteMultipleBackdrop}
            style={{overflow:'scroll'}}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Delete Material Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setDeleteMultipleBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />

                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom
                    sx={{ml:7, color:'red'}}
                >
                    You are about to delete the following material(s):
                </Typography>

                {
                    props.rowSelectionModel.map(function(id, i){

                        const output = props.dataCostTable.filter(function(obj){
                            return obj.id === id
                        })

                        return(
                            // https://mui.com/material-ui/react-accordion/
                            <Accordion 
                                expanded={expanded === `panel${i}`} 
                                onChange={handleChange(`panel${i}`)}
                                sx={{m:5}}
                                key={id}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id={output[0].id}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {output[0].category}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{output[0].material}</Typography>
                                </AccordionSummary>

                                <AccordionDetails
                                    sx={{m:5}}
                                >
                                <>
                                    {/* first row of inputs */}
                                        <Box sx={{display:'flex', ml:5}}>
                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Category</Typography>
                                                <TextField 
                                                    id="category" 
                                                    variant="outlined" 
                                                    value={output[0].category} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>

                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Material</Typography>
                                                <TextField 
                                                    id="material" 
                                                    variant="outlined" 
                                                    value={output[0].material} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>

                                        {/* second row of inputs */}
                                        <Box sx={{display:'flex', ml:5}}>
                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Processing Method</Typography>
                                                <TextField 
                                                    id="processingMethod" 
                                                    variant="outlined" 
                                                    value={output[0].processing_method} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>

                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Surfacing</Typography>
                                                <TextField 
                                                    id="surfacing" 
                                                    variant="outlined" 
                                                    value={output[0].surfacing} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>

                                        {/* third row of inputs */}
                                        <Box sx={{display:'flex', ml:5}}>
                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Max Length (mm)</Typography>
                                                <TextField 
                                                    id="maxLength" 
                                                    variant="outlined" 
                                                    value={output[0].max_length} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>

                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Max Width (mm)</Typography>
                                                <TextField 
                                                    id="maxWidth" 
                                                    variant="outlined" 
                                                    value={output[0].max_width} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>

                                        {/* fourth row of inputs */}
                                        <Box sx={{display:'flex', ml:5}}>
                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Thickness</Typography>
                                                <TextField 
                                                    id="thickness" 
                                                    variant="outlined" 
                                                    value={"materialInfo.thickness"} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>

                                        {/* fifth row of inputs */}
                                        <Box sx={{display:'flex', ml:5}}>
                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Router Speed</Typography>
                                                <TextField 
                                                    id="routerSpeed" 
                                                    variant="outlined" 
                                                    value={output[0].router_speed} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>

                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Run Cost Per Hour</Typography>
                                                <TextField 
                                                    id="runCostHour" 
                                                    variant="outlined" 
                                                    value={output[0].run_cost_hour} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>

                                        {/* sixth row of inputs */}
                                        <Box sx={{display:'flex', ml:5}}>
                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Unit Cost Area</Typography>
                                                <TextField 
                                                    id="unitCostArea" 
                                                    variant="outlined" 
                                                    value={output[0].unit_cost_area} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>

                                            <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Unit Cost Length</Typography>
                                                <TextField 
                                                    id="unitCostLength" 
                                                    variant="outlined" 
                                                    value={output[0].unit_cost_length} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>

                                        {/* seventh row of inputs */}
                                        <Box sx={{display:'flex', ml:5, mb:5}}>
                                            <Box sx={{flexGrow:0.7, m:2, maxWidth:maximumWidth}}>
                                                <Typography variant='body2' gutterBottom>Base Unit Cost Area</Typography>
                                                <TextField 
                                                    id="baseUnitCostAread"  
                                                    variant="outlined" 
                                                    value={output[0].base_unit_cost_area} 
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>
                                    </>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
                

                    <Box sx={{display:'flex', flexDirection:'row-reverse', mr:5, mb:5, mt:5}}>
                        <LoadingButton
                            loading = {loading}
                            loadingIndicator = 'Deleting...'
                            variant='contained' 
                            sx={{
                                minWidth:'300px', 
                                minHeight:'50px', 
                                backgroundColor:'red', 
                                '&:hover': {
                                    backgroundColor: 'red',
                                },
                            }} 
                            onClick={Delete}
                        >
                            Delete
                        </LoadingButton>
                    </Box>
            
            </Paper>
        </Backdrop>
    );
}

export default DeleteMultipleMaterialBackdrop;