import React, { Component, useEffect } from 'react'
import Delivery from './Delivery'
import EnterDetails from './EnterDetails'
import Upload from './Upload'

import axios from "axios";
import {Background} from '../../components/CustomProps'
import { Helmet } from 'react-helmet'
import Loader from '../../components/Loader'
import CreateShape2 from './CreateShape2'

class Quotation extends React.Component{

  
  
  tableConstructor = () => {
    var table =[]
    axios.get(`${process.env.REACT_APP_HOSTNAME}/get-cost-table/`,
  {
      headers: {
          'Authorization': 'Token ' + this.state.auth.logginToken
      }
  }).then((cs) => {

    for (var i = 0; i < cs.data.data.length; i++) 
  
    {table.push(cs['data']['data'][i])};
    this.costingTable = table
    this.setState({costTableLoaded:true})
    localStorage.setItem('costingTable',JSON.stringify(table))


  })
  
  
  }

  categoryTableConstructor = () => {
    var table =[]
    axios.get(`${process.env.REACT_APP_HOSTNAME}/get-category-table/`,
    {
        headers: {
            'Authorization': 'Token ' + this.state.auth.logginToken
        }
    }).then((cs) => {

      console.log(cs.data)
      this.categoryTable = cs.data
      localStorage.setItem('categoryTable',JSON.stringify(table))

    })
  }
  discountTableConstructor= () => {
    axios.get(`${process.env.REACT_APP_HOSTNAME}/discount_settings/`,
  {
      headers: {
          'Authorization': 'Token ' + this.state.auth.logginToken
      }
  }).then((cs) => {
    console.log(cs.data.data)
    this.discountTable=cs.data.data

  })

  }


  updateArr = (key,new_val,val_arr_name) => {
    try{
        var val_arr_temp=this.state[val_arr_name]

        val_arr_temp[key]=new_val
        console.log(new_val+" added to "+val_arr_name)
        this.setState({[val_arr_name]:val_arr_temp})

    }catch(err){
        console.log(err)
        this.setState({[val_arr_name]:[...this.state[val_arr_name], new_val]})
    
  }
  }

  removeItem = (key) => {
    var tempFiles=this.state.files
    var tempNames=this.state.itemNames
    var tempStates=this.state.itemStates
    var tempitemId=this.state.itemId
    var tempCosts=this.state.itemCosts
    var tempitemCostings =this.state.itemCostings
    var tempQuantity=this.state.itemQuantity
    console.log(key+" "+String(tempitemId[key])+String(this.state.itemNames[key])+" item removed")

    tempFiles.splice(key,1)
    tempNames.splice(key,1)
    tempStates.splice(key,1)
    tempitemId.splice(key,1)
    tempCosts.splice(key,1)
    tempitemCostings.splice(key,1)
    tempQuantity.splice(key,1)

    this.setState({
      files:tempFiles,
      itemNames:tempNames,
      itemStates:tempStates,
      itemId:tempitemId,
      itemCosts:tempCosts,
      itemCostings:tempitemCostings,
      itemQuantity:tempQuantity})
  }
  checkValidity = () => {
    //console.log("CHECKING VALIDITY OF CHECKOUT")
    var numFile=this.state.itemStates.filter(x => x==='success').length
    var numCosts=this.state.itemCosts.filter(x => x).length
    var maxCost = this.state.itemCosts.map((item,i) => item/this.state.itemQuantity[i]).filter(x => x>1000).length
    var minCost = 10
    console.log("MAX COST COUNT")
    console.log(maxCost)
    var totalCost=this.costCalc()
    //console.log(totalCost,numFile,numCosts)
    if (numFile===numCosts&&totalCost!=0&&maxCost==0&&totalCost>=minCost)
        {return true}
    else{
      return false
    }
}

  addItemName = (itemName) =>{
    var dupes=this.state.itemNames.filter(x => x===itemName).length
    console.log(this.state.itemNames)
    if (dupes)
      {
        itemName=itemName.split('.')[0]+'_'+dupes+'.dxf'
       }


    this.updateArr(this.state.itemNames.length,itemName,'itemNames')
    

  }
  costCalc = () => {
    //console.log('COST BEING CALCULATED')
    var cost=0
    this.state.itemCosts.forEach((item,i)=>{
        if (item)
        {cost+=item
        }
    })
    this.setState({totalCost:cost.toFixed(2)})
    return cost
}

  checkout = () => {
    this.checkValidity()
    this.costCalc()
    console.log(this.state.auth.logginToken)
    axios.post(`${process.env.REACT_APP_HOSTNAME}/quotation/`, 
    {  
      status: 'DR'
    },
  {
      headers: {
          'Authorization': 'Token ' + this.state.auth.logginToken
      }
  }).then((res) => {
    console.log(res.data)

    this.setState({quotationId:res.data.id})
    var i = 0;
    this.loadNext(res.data.id,i)})

  }

  loadNext= (quotationId,i) =>{
    
    if (i < this.state.itemCosts.length){
        var orderData = new FormData();

        console.log(this.state.files[i].file_id)
        console.log(this.state.itemCostings[i])
        console.log(this.state.itemQuantity[i])
        console.log(quotationId)

        orderData.append('file', this.state.files[i].file_id); 
        orderData.append('costing', this.state.itemCostings[i]);
        orderData.append('quantity', this.state.itemQuantity[i]);
        orderData.append('quotation', quotationId);

        axios.post(`${process.env.REACT_APP_HOSTNAME}/order/`, 
       orderData,
    {
        headers: {
            'Authorization': 'Token ' + this.state.auth.logginToken
        }
    }).then((res) => {
      console.log('Order created')
      console.log(res)
        i++;
        
        this.loadNext(quotationId,i);}).catch((error)=>{
            console.log(this.state.files[i])
            
            i++;this.loadNext(quotationId,i)})
        
    }
    else{
      console.log('the orders have all been uploaded!')
      localStorage.setItem('currentQuotation',this.state.quotationId)
      this.setState({step:3})
    }
  }




    constructor(props)
    
    {
      super(props)
      this.state = {step:1,
      prevStep:1,
      show:false,
      auth:props.auth,
      files:[],
      itemNames:[],
      itemStates:[],
      itemId:[],
      itemCosts:[],
      itemQuantity:[],
      itemCostings:[],
      totalCost:'--',
      uploadDisabled:false,
      costTableLoaded:false
     }

     
      const costingTable = {id:1}
      this.tableConstructor()

      const categoryTable = {id:1}
      this.categoryTableConstructor()
      this.discountTableConstructor()

      console.log(costingTable)
      console.log(categoryTable)
      
      
  }

  updateItemName = (index, filename) => {
    console.log('im here')
    console.log(index, filename)
    this.state.itemNames[index] = filename + ".dxf"
    console.log(this.state.itemNames)



  }

  

    // go back to previous step
    
    // handle field change
    
render()
    {switch (this.state.step) {
        case 1: 
          return (
            <Background sx={{pb:10, pt:10}}>
            <Helmet>
              <title>CutAnything | Quotation</title>
            </Helmet>
            <Upload  setStep={(e) => this.setState({step:e})} 
            setShow={(e) => {this.setState({show:e})}} 
            addFile={(e,i) => {this.updateArr(i,e,'files')}} 
            addItemName={(e) => {this.addItemName(e);this.updateArr(this.state.itemNames.length,'loading','itemStates')}}
            updateItemState={(e,i) => {this.updateArr(e,i,'itemStates')}}
            updateItemId={(e,i) => {this.updateArr(e,i,'itemId')}}
            itemNum={this.state.itemNames.length} 
            uploadDisabled={this.state.uploadDisabled} setUploadDisabled={(e) => {this.setState({uploadDisabled:e})}} />

            <CreateShape2
              show={this.state.show} 
              setShow={(e) => {this.setState({show:e})}} 
              setStep={(e) => this.setState({step:e})} 
              addFile={(e,i) => {this.updateArr(i,e,'files')}}  
              addItemName={(e) => {this.addItemName(e);this.updateArr(this.state.itemNames.length,'loading','itemStates')}}
              updateItemId={(e,i) => {this.updateArr(e,i,'itemId')}}
              updateItemState={(e,i) => {this.updateArr(e,i,'itemStates')}}
              itemNum={this.state.itemNames.length} setUploadDisabled={(e) => {this.setState({uploadDisabled:e})}}
            />            
            </Background>
          )
        case 2: 
          return (<Background >
            <Helmet>
              <title>CutAnything | Quotation</title>
            </Helmet>

            <CreateShape2
              show={this.state.show} 
              setShow={(e) => {this.setState({show:e})}} 
              setStep={(e) => this.setState({step:e})} 
              addFile={(e,i) => {this.updateArr(i,e,'files')}}  
              addItemName={(e) => {this.addItemName(e);this.updateArr(this.state.itemNames.length,'loading','itemStates')}}
              updateItemId={(e,i) => {this.updateArr(e,i,'itemId')}}
              updateItemState={(e,i) => {this.updateArr(e,i,'itemStates')}}
              itemNum={this.state.itemNames.length} setUploadDisabled={(e) => {this.setState({uploadDisabled:e})}}
            />  

            <EnterDetails setStep={(e) => this.setState({step:e})}
              setShow={(e) => {this.setState({show:e})}}
              costingTable={this.costingTable} 
              categoryTable={this.categoryTable} 
              files={this.state.files} 
              addFile={(e,i) => {this.updateArr(i,e,'files')}} 
              itemStates={this.state.itemStates} itemNames={this.state.itemNames}
              addItemName={(e) => {this.addItemName(e);this.updateArr(this.state.itemNames.length,'loading','itemStates')}}
              updateItemState={(e,i) => {this.updateArr(e,i,'itemStates')}}
              itemNum={this.state.itemNames.length}
              removeItem={(e) => this.removeItem(e)} 
              itemCosts={this.state.itemCosts} setItemCosts={(e,i) => this.updateArr(e,i,'itemCosts')}
              itemQuantity={this.state.itemQuantity} setItemQuantity={(e,i) => this.updateArr(e,i,'itemQuantity')}
              itemCosting={this.state.itemCostings} setItemCosting={(e,i) => this.updateArr(e,i,'itemCostings')}
              costCalc={()=>this.costCalc()} checkValidity={()=>this.checkValidity()} totalCost={this.state.totalCost}
              checkout={()=>this.checkout()}
              uploadDisabled={this.state.uploadDisabled} setUploadDisabled={(e) => {this.setState({uploadDisabled:e})}}
              updateItemId={(e,i) => {this.updateArr(e,i,'itemId')}}
              discountTable={this.discountTable} costTableLoaded={this.state.costTableLoaded}
              updateItemName = {(index, filename)=>this.updateItemName(index, filename)}

             />
            </Background>
            
          )
        case 3: 
          return (
            <>
            <Delivery  files={this.state.files} itemStates={this.state.itemStates} itemCosts={this.state.itemCosts} 
            itemNames={this.state.itemNames} itemQuantity={this.state.itemQuantity} totalCost={this.state.totalCost} />
            </>
          )

        // never forget the default case, otherwise VS code would be mad!
        default: 
          return <Loader/>

      }}
  }

  export default Quotation;

