import {useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';


function Verifying(props) {

    const [open, setOpen] = useState(true)

    return (
        <div>
            <Backdrop open={open} sx={{bgcolor:"#009AF9"}}>
                <Stack spacing={2} direction='column' alignItems='center' justifyContent='center'>
                    <CircularProgress color="secondary" />
                    <Typography variant="body2" component="div"  align='left' sx={{fontWeight: 'bold'}}>{props.name}</Typography>
                </Stack>
            </Backdrop>
        </div>
    );
}

export default Verifying;