import React from "react";
import NumberedSlider from "../../../components/NumberedSlider";


function RoundRect(props) {
  const { setLength, setWidth, setRadius, length, width, radius } = props;

  return (
    <div className="container">
      <div>
        <h3>Length (mm)</h3>
        <NumberedSlider
          setValue={setLength}
          value={length}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />
        <h3>Width (mm)</h3>
        <NumberedSlider
          setValue={setWidth}
          value={width}
          valueLabelDisplay="auto"
          min={0}
          max={3000}
        />
        <h3>Corner Radius (mm)</h3>
        <NumberedSlider
          setValue={setRadius}
          value={radius}
          valueLabelDisplay="auto"
          min={0}
          max={100}
        />
      </div>
    </div>
  );
}

export default RoundRect;