export const getDeploymentConfig = () => {
  const domain = window.location.hostname;
  console.log(domain)
  if (domain === 'www.cutanything.co') {
    return { siteType: 'main-site' };
  } else if (domain.match(/(www\.)?[^.]+\.cutanything\.co/)) {
    const clientSiteName = domain.split('.')[1];
    return { siteType: 'client-site', clientSiteName: clientSiteName };
  }
  else{
    return {siteType: 'main-site', clientSiteName: 'localHost'};
  }
};