import React from 'react';
import { Typography, 
        useTheme, 
        Grid, 
        Box, 
        Divider, 
        Chip, 
        Rating
    } from '@mui/material';
import spindleimage from "../../../img/spindle.jpeg";
import useWindowDimensions from '../../../components/GetWindowSize';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function SpindleRouting(props, theme) {

    theme=useTheme(theme)
    const dimension = useWindowDimensions();
    const productInfo = props.productInfo;

    return (
        <Box sx={{width:'100%', minHeight:'400px', backgroundColor:'white', pb:10, pt:15}}>
            <Grid 
                container
                direction="row-reverse"
                justifyContent="center"
                alignItems="center"
            >
                <Grid 
                    item
                    sx={{pl:15, pr:15}}
                    xs={12} 
                    sm={12}
                    md={12}
                    lg={6}
                >
                    <Typography variant='h2' gutterBottom>
                        {productInfo.title}
                    </Typography>
                    
                    <Divider sx={{mt:2, mb:2}}>
                        <Chip label="Product Information" />
                    </Divider>

                    <Typography variant='body1' paragraph gutterBottom>
                        {productInfo.description} {productInfo.suitable} {productInfo.tolerance}
                    </Typography>

                    <Box sx={{display:'flex', flexDirection:dimension.width < 500?"column":"row", justifyContent: 'space-evenly'}}>
                        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', flexGrow:1}}>
                            <Box>
                                <Divider sx={{mt:2, mb:2}}>
                                    <Chip label="Price" />
                                </Divider>
                            </Box>

                            <Box>
                                <Rating 
                                    name="price" 
                                    readOnly
                                    value={productInfo.price} 
                                    icon={<AttachMoneyIcon fontSize="inherit"/>}
                                    emptyIcon={<AttachMoneyIcon fontSize="inherit" />}
                                />
                            </Box>
                        </Box>

                        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', flexGrow:1}}>
                            <Box>
                                <Divider sx={{mt:2, mb:2}}>
                                    <Chip label="Speed" />
                                </Divider>
                            </Box>

                            <Box>
                                <Rating 
                                    name="speed" 
                                    readOnly
                                    value={productInfo.speed} 
                                    icon={<AccessTimeIcon fontSize="inherit"/>}
                                    emptyIcon={<AccessTimeIcon fontSize="inherit" />}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{width:'100%', minHeight:'200px', mb:3}}>
                        <Divider sx={{mt:2, mb:2, width:"100%"}} textAlign='left'>
                            <Chip label="Best For" />
                        </Divider>

                        <Typography>
                            {productInfo.best}
                        </Typography>

                        <Divider sx={{mt:2, mb:2, width:"100%"}} textAlign='right'>
                            <Chip label="Not For" />
                        </Divider>

                        <Typography>
                            {productInfo.notFor}
                        </Typography>
                    </Box>
                </Grid>

                <Grid 
                    item
                    sx={{pl:15, pr:15}}
                    xs={12} 
                    sm={12}
                    md={12}
                    lg={6}
                >
                    {/* adjust the image size with the maxWidth */}
                    <img 
                        src={spindleimage} 
                        alt="spindle" 
                        style={{width:'100%', 
                                maxWidth:'100%', 
                                height:'auto',
                                borderRadius:"10%",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default SpindleRouting;