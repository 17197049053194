import {useState} from 'react'
import { Stack,TextField,MenuItem, Box} from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectComponent from './SelectComponent';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';

const ItemSmall = (props,theme) => {
  theme=useTheme(theme)

    const quantity = () => {
        if (props.quantity)
        {return props.quantity}
        else{return 1}
    }

    const cost = () => {
    if (props.cost)
        {return "$"+ props.cost.toFixed(2)}
        else{return '--'}
    }



      return (
        <Grid  container direction='row' alignItems='center' sx={{mb:2}}>
                    <Grid item xs={1} md={1} lg={1}>
                        <Typography variant="body2">{quantity()}x</Typography>
                    </Grid>
        
                    <Grid item xs={8} md={8} lg={8}>
                        <Typography variant="body2">{props.itemName}</Typography>
                    </Grid>
        
                    <Grid item xs={3} md={3} lg={3} pl={2}>
                        <Typography variant="body2">{cost()}</Typography>
                    </Grid>
                </Grid>

      )
}

export default ItemSmall;