// apiClient.js
import axios from 'axios';

const apiClientInstance = axios.create({
  baseURL: process.env.REACT_APP_HOSTNAME,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Add an interceptor to add the auth token to requests
apiClientInstance.interceptors.request.use(
  (config) => {
    const auth = localStorage.getItem('token');
    if (auth) {
      config.headers['Authorization'] = `Token ${auth}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle failed API requests
apiClientInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const { detail } = error.response.data;
      if (detail === 'Invalid token') {
        // Clear tokens and redirect to re-login page
        localStorage.removeItem('token');
        // Replace '/login' with the URL of your re-login page
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

apiClientInstance.fetchData = async function (endpoint, setData, processData) {
  try {
    const res = await this.get(endpoint);
    setData(processData(res.data));
  } catch (err) {
    console.log(err);
  }
}

export default apiClientInstance;
