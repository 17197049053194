import {useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {Background, TypographyChanged, StackChanged, ResponsiveGridContainer} from '../CustomProps';
import { useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Backdrop from '@mui/material/Backdrop';
import LogoPhrase from '../LogoPhrase';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function BackdropForgetPassword(props, theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false)
    const [msg, setMsg] = useState('Reset Password')
    const [error, setError] = useState(false)
    const inputEmail = useRef();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#ffcccc',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: "#FF0000",
    }));

    const reset = () => {
        setMsg('Processing...')
        setDisabled(true)
        setError(false)
        axios.post(`${process.env.REACT_APP_HOSTNAME}/reset_password_link/`, {email: inputEmail.current.value,domain_url:window.location.host}, 
        {
            headers: {
                "Content-Type": "multipart/form-data" 
            }
        }).then((res) => {
            props.setBackdropForgetPassword(false)
            props.setBackdropPasswordRetrieval(true)
            setMsg('Reset Password')
            setDisabled(false)

        }).catch((err) => {
            console.log(err)
            setError(true)
            setMsg('Reset Password')
            setDisabled(false)
        })
    }

    return (
        <Backdrop open={props.backdropForgetPassword} sx={{color:"#fff"}}>
            <Paper 
                elevation={0}
                sx={{
                    width: 500,
                    height: 500,
                    borderRadius:5
                }}
            >
                <Box sx={{display:'flex', flexDirection:'row-reverse', mr:1}}>
                    <IconButton aria-label='close' size='large' onClick={() => {props.setBackdropForgetPassword(false)}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                
                <Box                            
                    padding={{xs: 4,sm: 8}} 
                    paddingTop={{xs:4}}
                    paddingBottom={{xs:4}}
                    margin={{xs:1, md:0}}
                    sx={{bgcolor: 'white',  borderRadius: 5}}
                >
                    <Stack spacing={2}>
                        <TextField  id="email_forgetPassword" label="Email Address" sx={{width:'auto'}} inputRef={inputEmail} required/>
                        <Button style={{color:'white', backgroundColor: disabled?'#98A9BC':'#009AF9'}} onClick={reset}  disabled={disabled}>{msg}</Button>
                        {error?  <Item elevation={0}>Please check your email again</Item> : <></>}
                    </Stack>
                </Box>

                <Box sx={{display:'flex', flexDirection:'row', bgcolor: 'white',  borderRadius: 5, justifyContent:'center'}} padding={{xs:2}}>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'right', mr: 4, ml:2}}>
                        <Typography variant='subtitle1' sx={{textAlign:'left'}}>Don't have an account?</Typography>
                        <Typography variant='bold' sx={{textAlign:'left', fontWeight: 'bold'}}>Create free account here</Typography>
                    </Box>

                    <Box sx={{display:'flex',  alignItems:'flex-end', justifyContent:'left'}}>
                        <Button sx={{color:'white', backgroundColor:'#009AF9', pl:3, pr:3}} onClick={() => {props.setBackdropForgetPassword(false);props.setBackdropSignUp(true)}}>Sign Up</Button>
                    </Box>
                </Box>
            </Paper>
        </Backdrop>
    );
}

export default BackdropForgetPassword;


