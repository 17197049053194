import {useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {Background, TypographyChanged, StackChanged, ResponsiveGridContainer} from '../components/CustomProps';
import { useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LogoPhrase from '../components/LogoPhrase';

function ForgetPassword(theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false)
    const [msg, setMsg] = useState('Reset Password')
    const [error, setError] = useState(false)
    const inputEmail = useRef();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#ffcccc',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: "#FF0000",
    }));

    const reset = () => {
        setMsg('Processing...')
        setDisabled(true)
        setError(false)
        axios.post(`${process.env.REACT_APP_HOSTNAME}/reset_password_link/`, 
        {
            email: inputEmail.current.value,
            domain_url:window.location.host
        }, 
        {
            headers: {
                "Content-Type": "multipart/form-data" 
            }
        }).then((res) => {
            console.log(res)
            navigate("/password-retrieval")

        }).catch((err) => {
            console.log(err)
            setError(true)
            setMsg('Reset Password')
            setDisabled(false)
        })
    }


    return (
            <Background sx={{pb:10, pt:10}}>

                <Helmet>
                    <title>CutAnything | Forget Password</title>
                    <meta name='description' content="Forget your password? Don't worry, enter your email to reset your password."/>
                    <link rel='canonical' href='/forget-password'/>
                </Helmet>

                <ResponsiveGridContainer container spacing={{
                        xs: 0,
                        sm: 0,
                        md: 2
                    }} sx={{alignItems:'center',justifyContent:'space-evenly'}}>
                        
                <LogoPhrase/>

                    <Grid item xs='auto' md='auto'>
                        <Box                            
                            padding={{xs: 4,sm: 8}} 
                            paddingTop={{xs:4}}
                            paddingBottom={{xs:4}}
                            margin={{xs:1, md:0}}
                            sx={{bgcolor: 'white',  borderRadius: 5}}
                        >
                            <Stack spacing={2}>
                                <TextField  id="email" label="Email Address" sx={{width:'auto'}} inputRef={inputEmail} required/>
                                <Button style={{color:'white', backgroundColor: disabled?'#98A9BC':'#009AF9'}} onClick={reset}  disabled={disabled}>{msg}</Button>
                                {error?  <Item elevation={0}>Please check your email again</Item> : <></>}
                            </Stack>
                        </Box>

                        <Box sx={{display:'flex', flexDirection:'row', bgcolor: 'white',  borderRadius: 5, mt:2, justifyContent:'center'}} padding={{xs:2, md:6}}>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'right', mr: 4, ml:2}}>
                                <Typography variant='subtitle1' sx={{textAlign:'left'}}>Don't have an account?</Typography>
                                <Typography variant='bold' sx={{textAlign:'left', fontWeight: 'bold'}}>Create free account here</Typography>
                            </Box>

                            <Box sx={{display:'flex',  alignItems:'flex-end', justifyContent:'left'}}>
                                <Button sx={{color:'white', backgroundColor:'#009AF9', pl:3, pr:3}} onClick={() => {navigate("/sign-up")}}>Sign Up</Button>
                            </Box>
                        </Box>
                    </Grid>
                    
                
                </ResponsiveGridContainer>
            </Background>
    );
}

export default ForgetPassword;

{/* <Link onClick={() => {navigate("/sign-up")}}>Don't have an account?</Link> */}