import { useState, useEffect } from "react";
import { ProductContext } from "../../context/ProductContext";
import apiClientInstance from '../../components/api-client/apiclient';
import { Outlet } from "react-router-dom";

function ProductController(props) {

    const [productList, setProductList] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        apiClientInstance.get('/products/')
          .then((res) => {
            console.log("products:", res.data);
            setProductList(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
    }, []);

    useEffect(()=>{
        apiClientInstance.get('/productgroups/')
        .then((res) => {
          console.log("groups:", res.data);
          setProductGroups(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [])
    

    return (
        <ProductContext.Provider value={{productList, productGroups}}>
            <Outlet  />
        </ProductContext.Provider>
    );
}

export default ProductController;