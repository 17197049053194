import React from 'react';
import { useNavigate } from "react-router-dom";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import {Background, TypographyChanged, ResponsiveGridContainer} from '../components/CustomProps';
import { useTheme } from '@mui/material';
import LogoPhrase from '../components/LogoPhrase';
import { Helmet } from 'react-helmet-async';

function PasswordRetrieval(theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();

    return (
        <Background sx={{pb:10, pt:10}}>

            <Helmet>
                <title>CutAnything | Password Retrieval</title>
                <meta name='description' content="Check your email for the link to reset your password"/>
                <link rel='canonical' href='/password-retrieval'/>
            </Helmet>

            <ResponsiveGridContainer container spacing={{
                        xs: 0,
                        sm: 0,
                        md: 2
                    }} sx={{alignItems:'center',justifyContent:'space-evenly'}}>

               <LogoPhrase/>

                <Grid item xs='auto' md='auto' textAlign="center">
                    <Box 
                        padding={{xs:1, sm:8}}
                        sx={{bgcolor: 'white',  borderRadius: 5}}
                    >
                        <Stack spacing={2}>
                            <TypographyChanged variant="subtitle2">Please check your email for your password reset link</TypographyChanged>
                            <Link onClick={() => {navigate("/sign-up")}}>Don't have an account?</Link>
                        </Stack>
                    </Box>
                </Grid>
                
            </ResponsiveGridContainer>
        </Background>
    );
}

export default PasswordRetrieval;