import {useState} from 'react';
import { useUserContext } from '../../context/UserContext';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbar
    } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

//to customise the bottom right footer
function labelDisplayRows({from, to, count}){
    return(<span>Showing {from} to {to} of {count} entries</span>)
}

//this function is for the footer customisation
function CustomPagination(props) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(2);

    const handleChangePage = (event, newPage) => {
        setSelectedPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        // setPage(0);
    };

    return (
        <Box sx={{display:'flex'}} pb={2} >
            {/* bottom left footer  */}
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                sx={{pt:1, flexGrow:3 }}
                renderItem={(item) => (
                <PaginationItem
                    {...item}
                    sx={{
                    color: '#778CA2',
                    "&.Mui-selected": {
                        backgroundColor: "transparent",
                        color: "#009AF9",
                    },
                    "& .MuiSvgIcon-root":{
                        color:'#009AF9'
                    }
                    }}
                />
                )}
            />

            {/* bottom right footer */}
            <TablePagination
                component="div"
                count={props.rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={(page) => labelDisplayRows(page)}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                rowsPerPageOptions={[]}
                sx={{color:'#778CA2'}}
            />
        </Box>
    );
}

//when there is no order (row) data
function NoRowsOverlay() {
    return (
    <Stack height="100%" alignItems="center" justifyContent="center" sx={{color:'#778CA2'}}>
        No data yet!
    </Stack>
    );
}

function roundUpCost(params){

    return(`$${params.value.toFixed(2)}`)
    
}

function roundUpPerimeter(params){

    return(`${params.value.toFixed(0)} mm`)
    
}

//this function helps with the status colours
function categoryStyle(values){
    let status_style = { paddingLeft:20, 
                            paddingRight:20, 
                            paddingTop:5, 
                            paddingBottom:5, 
                            color:'white',
                            borderRadius:4,
                            backgroundColor:values.row.color
                        }

    // if(values.row.category_name === 'Metal'){
    //     return ({...status_style, backgroundColor:'#182869'})
    // }else if(values.row.category_name === 'Wood'){
    //     return({...status_style, backgroundColor: '#FFE192'})
    // }else if(values.row.category_name === 'Plastics'){
    //     return({...status_style, backgroundColor: '#28D7A9'})
    // }else{
    //     return({color: "#00B283"})
    // }

    return({...status_style})
}

function Actions(params){

    const OpenNewTab = () => {
        console.log(params)
        console.log(params.params.row)
        // window.location.replace("http://localhost:3000/adminControl/CostTableDetails", "CostTable")
        params.props.setUpdateCategoryBackdrop(true)
        params.props.setUpdateCategoryInfo(params.params.row)
    }

    const Delete = () => {
        console.log('delete')
        console.log(params)
        console.log(params.params.row)
        params.props.setDeleteCategoryBackdrop(true)
        params.props.setDeleteCategoryInfo(params.params.row)
    
    }

    return(

        <Stack direction='row'>
            <Button 
                startIcon={<RemoveRedEyeOutlinedIcon />}
                sx={{borderRadius:5}}
                onClick={OpenNewTab}
            >
            </Button>

            <LoadingButton 
                startIcon={<DeleteOutlineOutlinedIcon />}
                sx={{borderRadius:5, color:'red'}}
                onClick={Delete}
            >
            </LoadingButton>

        </Stack>

    )
}

function DataGridCategory(props, theme) {

    theme=useTheme(theme);
    const {auth} = useUserContext();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rows = !props.rowData?[]:props.rowData;
    const columns = [
        { 
            field: 'category_name', 
            headerName: 'Category', 
            minWidth: 200,
            flex:0.6,
            paddingLeft:2,
            editable:false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <div style={categoryStyle(cellValues)}>
                        {cellValues.row.category_name}
                    </div>
                )
            },
            valueGetter: (params) => params.row.category_name
        },
        {
            field: 'area_markup',
            headerName: 'Area Mark up',
            minWidth: 200,
            flex:1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'interior_line_markup',
            headerName: 'Interior Line Markup',
            minWidth: 200,
            flex:1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'line_markup',
            headerName: 'Line Markup',
            minWidth: 200,
            flex:0.7,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'minimum_cost',
            headerName: 'Min Cost',
            minWidth: 200,
            flex:0.7,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            // valueGetter: roundUpPerimeter
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200,
            flex:0.7,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            //to add the download button to every cell
            renderCell: (params)=><Actions params={params} props={props} auth={auth}/>
            // renderCell: (params)=>Actions({params, props, auth:auth})
        }
    ];

    return (
        <div style={{ height: 85*rowsPerPage , width: '100%' }}>
            <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            rowHeight={65}
            rowLength={rowsPerPage}
            sx={
                { 
                    bgcolor:'white',
                    border:0,
                    mr:2,
                    ml:2,
                    pl:2,
                    pr:2,
                    "& .MuiDataGrid-columnHeaders": {
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        paddingRight:3
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight":{
                        visibility:'hidden'
                    },
                    "& .MuiDataGrid-cell":{
                        borderBottom:'0px',
                        justifyContent:'center'
                    }
                }
            }
            disableColumnSelector
            disableDensitySelector
            checkboxSelection
            onSelectionModelChange = {(newSelectionModel) => {
                console.log(newSelectionModel)
                //this is the list of selected items by checkbox
                props.setRowCategorySelectionModel(newSelectionModel)
                props.setNumOfCategorySelected(newSelectionModel.length)
            }}
            components={{
                NoRowsOverlay,
                Footer: CustomPagination,
                Toolbar: GridToolbar,
            }}
            componentsProps={{
                footer: { rows }
            }}
            // filterModel={{
            //     items:props.searchValue
            // }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'category', sort: 'asc' }],
                }
            }}
            />

        </div>
    );
}

export default DataGridCategory;