// src/contexts/UserContext.js
import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [clientSiteName, setClientSiteName] = useState(null);
  const [auth, setAuth] = useState({
    logginToken: localStorage.getItem('token') || 'no token yet',
    verified: localStorage.getItem('verified'),
    userRole: localStorage.getItem('userRole'),
    expiry: localStorage.getItem('expiry')
  });

  return (
    <UserContext.Provider value={{ clientSiteName, setClientSiteName, auth, setAuth }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};