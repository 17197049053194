import React from 'react';
import {Background} from '../../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function Guides(props, theme) {

    theme=useTheme(theme)

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
              <title>CutAnything | Guides</title>
              <meta name='description' content="We do not only provide cutting services but also consultant on how you can cuting of materials and other projects that 
              requires you to process materials."/>
              <link rel='canonical' href='/guides'/>
            </Helmet>

            <Stack><Typography variant="title">Guides Coming Soon!</Typography>
            <Outlet/></Stack>
            
        </Background>
    );
}

export default Guides;