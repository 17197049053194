import { useState } from 'react';
import BackdropLogin from '../../components/client-components/BackdropLogin'
import BackdropForgetPassword from '../../components/client-components/BackdropForgetPassword';
import BackdropPasswordRetrieval from '../../components/client-components/BackdropPasswordRetrieval';
import BackdropSignUp from '../../components/client-components/BackdropSignUp';

//components from material ui
import {Background} from '../../components/CustomProps'
import { useTheme } from '@mui/material';
import ClientNavbar from '../../components/client-components/ClientNavBar';



function ClientHome(theme) {
    theme=useTheme(theme)

    const [backdropLogin, setBackdropLogin] = useState(true);
    const [backdropForgetPassword, setBackdropForgetPassword] = useState(false);
    const [backdropPasswordRetrieval, setBackdropPasswordRetrieval] = useState(false);
    const [backdropSignUp, setBackdropSignUp] = useState(false);

    return (
        <div>
            <ClientNavbar setBackdropLogin={setBackdropLogin}/>
            <Background sx={{pb:10,display:'block'}}>

                {/* these backdrops will only shnow if it is open */}
                <BackdropLogin 
                    backdropLogin={backdropLogin} 
                    setBackdropLogin={setBackdropLogin}
                    setBackdropForgetPassword={setBackdropForgetPassword}
                    setBackdropSignUp = {setBackdropSignUp}
                />

                <BackdropSignUp 
                    backdropSignUp = {backdropSignUp}
                    setBackdropSignUp = {setBackdropSignUp}
                />

                <BackdropForgetPassword 
                    backdropForgetPassword={backdropForgetPassword} 
                    setBackdropForgetPassword={setBackdropForgetPassword}
                    setBackdropPasswordRetrieval = {setBackdropPasswordRetrieval}
                    setBackdropSignUp = {setBackdropSignUp}
                />

                <BackdropPasswordRetrieval
                    backdropPasswordRetrieval= {backdropPasswordRetrieval}
                    setBackdropPasswordRetrieval = {setBackdropPasswordRetrieval}
                    setBackdropSignUp = {setBackdropSignUp}
                />
    
              
            </Background>
        </div>
    );
}

export default ClientHome;