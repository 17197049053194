import { useRef, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from 'buffer';
import { useUserContext } from '../../context/UserContext'

//components from material ui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import {Item} from '../CustomProps'
import { Typography, useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function BackdropLogin(props, theme) {
    
    theme=useTheme(theme)

    const navigate = useNavigate();
    const inputEmail = useRef(null);
    const inputPassword = useRef(null);
    const [msg, setMsg] = useState('Login')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [disabled, setDisabled] = useState(false)
    const {setAuth} = useUserContext();

    const login = () => {
        setDisabled(true)
        setMsg('Signing in...')
        setError(false)

        const token = Buffer.from(`${inputEmail.current.value.toLowerCase()}:${inputPassword.current.value}`, 'utf8').toString('base64')
        console.log("Basic "+ token)

        axios.post(`${process.env.REACT_APP_HOSTNAME}/api-token-auth/`, {},
        {
            headers: {
            'Authorization': 'Basic ' + token
            }
        })
        .then((res) => {
            console.log("login in:")
            console.log(res.data)
            // console.log(res.data.token)

            var logginToken = res.data.token
            var expiry = res.data.expires

            localStorage.setItem('login_state', res.data.status)
            localStorage.setItem('user',  res.data.user)
            localStorage.setItem('user_id', res.data.user_id)
            localStorage.setItem('token', logginToken)
            localStorage.setItem('expiry', expiry)



            axios.get(`${process.env.REACT_APP_HOSTNAME}/token_check/`, 
            {
                headers: {
                    'Authorization': 'Token ' + res.data.token
                }
            }).then((res) => {
                console.log("token_check")
                console.log(res.data)
                // console.log(res.data.is_verified)
                // console.log("setting of auth")

                if(res.data.is_admin){
                    // setErrorMsg("This is an admin account")
                    // setError(true)
                    // setMsg('Login')
                    // setDisabled(false)
                    localStorage.setItem('verified', res.data.is_verified)
                    setAuth({logginToken: logginToken, 
                                verified: res.data.is_verified, 
                                userRole: res.data.is_admin?1:2,
                                expiry: expiry})
                    navigate('/admin')
                }else{
                    localStorage.setItem('verified', res.data.is_verified)
                    setAuth({logginToken: logginToken, 
                                verified: res.data.is_verified, 
                                userRole: res.data.is_admin?1:2,
                                expiry: expiry})
                    navigate('/quotation')
                }
            }).catch((err) => {
                setDisabled(false)
                navigate('/login')
            })
        })
        .catch((err) => {
            setErrorMsg(err.response.data.detail)
            setError(true)
            setMsg('Login')
            setDisabled(false)
        })
    };

    const handleKeyDown = event => {
        console.log("entered key pressed")
        if (event.key === 'Enter') {
            login()
        }
    };


    return (
        <Backdrop open={props.backdropLogin} sx={{color:"#fff"}}>
            <Paper 
                elevation={0}
                sx={{
                    width: 500,
                    height: 500,
                    borderRadius:5
                }}
            >
                <Box sx={{display:'flex', flexDirection:'row-reverse', mr:1}}>
                    <IconButton aria-label='close' size='large' onClick={() => {props.setBackdropLogin(false)}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                
                <Box  
                    padding={{xs: 4,sm: 8}} 
                    paddingTop={{xs:4}}
                    paddingBottom={{xs:4}}
                    margin={{xs:1, md:0}}
                    sx={{bgcolor: 'white'}}
                >
                    <Stack spacing={2}>
                        <TextField id="email_login" label="Email Address" inputRef={inputEmail} onKeyDown={handleKeyDown} required/>
                        <TextField  id="password" label="Password" type="password" inputRef={inputPassword} onKeyDown={handleKeyDown} required/>
                        <Button style={{color:'white', backgroundColor: disabled?'#98A9BC':'#009AF9'}} onClick={login}  disabled={disabled}>{msg}</Button>
                        {error? (               
                                <Item elevation={0}>{errorMsg}</Item>
                        ):(
                            <></>
                        )}
                        <Link component="button" onClick={() => {props.setBackdropLogin(false); props.setBackdropForgetPassword(true)}}>Forget Password?</Link>
                    </Stack>
                </Box>

                <Box sx={{display:'flex', flexDirection:'row', bgcolor: 'white',  borderRadius: 5, justifyContent:'center'}}>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'right', mr: 4, ml:2}}>
                        <Typography variant='subtitle1' sx={{textAlign:'left'}}>Don't have an account?</Typography>
                        <Typography variant='bold' sx={{textAlign:'left', fontWeight: 'bold'}}>Create free account here</Typography>
                    </Box>

                    <Box sx={{display:'flex',  alignItems:'flex-end', justifyContent:'left'}}>
                        <Button sx={{color:'white', backgroundColor:'#009AF9', pl:3, pr:3}} onClick={() => {props.setBackdropLogin(false); props.setBackdropSignUp(true)}}>Sign Up</Button>
                    </Box>
                </Box>
            </Paper>
        </Backdrop>
    );
}

export default BackdropLogin;