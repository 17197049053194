import {useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';

function LoginPopOut(props) {

    const [open, setOpen] = useState(false)

    return (
        <Backdrop open={open} sx={{bgcolor:"#009AF9"}}>
            <Typography variant="body2" component="div"  align='left' sx={{fontWeight: 'bold'}}>lmao</Typography>
        </Backdrop>
    );
}

export default LoginPopOut;