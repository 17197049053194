import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import MuiField from '../MuiField';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Item} from '../CustomProps';
import { useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function BackdropSignUp(props, theme) {

    theme=useTheme(theme)

    const navigate = useNavigate();
    const inputEmail = useRef(null);
    const inputName = useRef(null);
    const inputCompany = useRef(null);
    const inputPassword = useRef(null);
    //for mailing address
    const inputMailingStreetName = useRef(null);
    const inputMailingUnitNumber = useRef(null);
    const inputMailingPostalCode = useRef(null);
    //for billing address
    const inputBillingStreetName = useRef(null);
    const inputBillingUnitNumber = useRef(null);
    const inputBillingPostalCode = useRef(null);

    //for the billing address
    const [billingStreetName, setBillingStreerName] = useState('');
    const [billingUnitNumber, setBillingUnitNumber] = useState('');
    const [billingPostalCode, setBillingPostalCode] = useState('');

    const [checked, setChecked] = useState(false);
    const [phoneNum, setPhoneNum] = useState();
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState();
    const [disabled, setDisabled] = useState(false);
    const [buttonMsg, setButtonMsg] = useState("Sign up");

    //so that user can type the billing information
    const changing = (event)=>{
        if(event.target.id === 'billing_street_name'){
            setBillingStreerName(event.target.value)
        }else if(event.target.id === 'billinb_unit_no'){
            setBillingUnitNumber(event.target.value)
        }else if(event.target.id === 'billing_postal_code'){
            setBillingPostalCode(event.target.value)
        }
    }

    const register = () => {

        setDisabled(true)
        setError(false)
        setButtonMsg("authenticating...")

        if(phoneNum !== undefined){
            if(isValidPhoneNumber(phoneNum)){
                console.log('phone number is valid')
                axios.post(`${process.env.REACT_APP_HOSTNAME}/signup/`, 
                {
                    email:inputEmail.current.value.toLowerCase(),
                    password: inputPassword.current.value,
                    name: inputName.current.value,
                    company_name: inputCompany.current.value,
                    delivery_address_line_1: inputMailingStreetName.current.value,
                    delivery_address_line_2: inputMailingUnitNumber.current.value,
                    delivery_postal_code: inputMailingPostalCode.current.value,
                    billing_address_line_1:inputBillingStreetName.current.value,
                    billing_address_line_2:inputBillingUnitNumber.current.value,
                    billing_postal_code: inputBillingPostalCode.current.value,
                    phone_number: phoneNum,
                    domain_url: window.location.host
                }
                ).then((res) => {
                    console.log(res)
                    console.log('link:', res.data.link)
                    if(res.data.errors.length === 0){
                        localStorage.setItem('user', res.data.user)
                        localStorage.setItem('token', res.data.token)
                        navigate('/account-verification', {state: res.data.user})
                    }else{
                        console.log('here')
                        let error_list = []
                        for(let error_key in res.data.errors){
                            console.log(error_key)
                            console.log(res.data.errors[error_key][0])
    
                            let error_field = error_key;
                            if(error_field === 'address_line_1'){
                                error_field = 'street name'
                            }else if(error_field === 'address_line_2'){
                                error_field = 'unit no.'
                            }else if(error_field === 'postal_code'){
                                error_field = 'postal code'
                            }else if(error_field === 'company_name'){
                                error_field = 'company name'
                            }else if(error_field === 'phone_number'){
                                error_field = 'phone number'
                            }
    
                            error_list.push({field_name:error_field, error:res.data.errors[error_key][0]})
                        }
                        console.log(error_list)
                        setErrorMsg( <ul>
                                        {error_list.map(function(item, index){
                                            return <li key={index}>{`${item.field_name}: ${item.error}`}</li>;
                                        })}
                                    </ul>
                        )
                        setButtonMsg('Sign Up')
                        setDisabled(false)
                        setError(true)
                    }
                }).catch((err) => {
                    console.log(err)
                    setButtonMsg('Sign Up')
                    setDisabled(false)
                    setError(true)
                    setErrorMsg("Please try again later")
                })
            }else{
                setButtonMsg('Sign Up')
                setDisabled(false)
                setError(true)
                setErrorMsg('phone number is invalid')
            }
        }else{
            setButtonMsg('Sign Up')
            setDisabled(false)
            setError(true)
            setErrorMsg('phone number is empty')
        }

    }

    //for the checkbox of billing address
    const billingAddressCheckbox = () => {

        if(checked === true){
            setChecked(false)
            setBillingStreerName('')
            setBillingUnitNumber('')
            setBillingPostalCode('')
        }else{
            setChecked(true)
            setBillingStreerName(inputMailingStreetName.current.value)
            setBillingUnitNumber(inputMailingUnitNumber.current.value)
            setBillingPostalCode(inputMailingPostalCode.current.value)

        }
    }

    return (
        <Backdrop open={props.backdropSignUp} sx={{color:"#fff"}}>
            <Paper 
                elevation={0}
                sx={{
                    width: 500,
                    height: 500,
                    borderRadius:5
                }}
                style={{maxHeight: '100%', overflow: 'auto'}}
            >
                <Box sx={{display:'flex', flexDirection:'row-reverse', mr:1}}>
                    <IconButton aria-label='close' size='large' onClick={() => {props.setBackdropSignUp(false)}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                
                <Box padding={{xs:4,md:8}} sx={{flexGrow: 1 , bgcolor: 'white',  borderRadius: 5}}>
                    <Typography variant="h3" fontSize={{xs:28,md:36,lg:48}}  component="div" gutterBottom  align='left' sx={{fontWeight: 'bold', color:'#3898ec',mb:6}}>Sign Up now!</Typography>
                    <Stack spacing={2}>
                        <TextField id="email" label="Email Address" inputRef={inputEmail} required/>
                        <TextField id="name" label="Name" inputRef={inputName} required/>
                        <TextField id="company_name" label="Company Name" inputRef={inputCompany} required/>
                        <TextField id="password" label="Password" type='password' inputRef={inputPassword} required/>
                        <PhoneInput placeholder="Enter phone number"
                            value={phoneNum}
                            onChange={setPhoneNum}
                            defaultCountry='SG'
                            inputComponent={MuiField}
                        />

                        {/* for the mailing address */}
                        <Typography variant="subtitle1">Mailing Address</Typography>
                        <Stack spacing={2}>
                            <TextField id="mail_street_name" label="Mail Street Name" inputRef={inputMailingStreetName} required/>
                            <TextField id="mail_unit_no" label="Mail Apartment/Unit No." inputRef={inputMailingUnitNumber} required/>
                            <TextField id="mail_postal_code" label="Mail Postal Code" inputRef={inputMailingPostalCode} required/>
                        </Stack>

                        {/* for the billing address */}
                        <Box sx={{display:'flex'}}>
                            <Box sx={{flexGrow:1, mt:1}}>
                                <Typography variant="subtitle1">Billing Address</Typography>
                            </Box>
                            
                            <Box>
                                <FormControlLabel 
                                    control={<Checkbox onChange={billingAddressCheckbox} checked={checked}/>} 
                                    label="Same as Mailing Address"
                                />
                            </Box>
                        </Box>
                        <Stack spacing={2}>
                            <TextField id="billing_street_name" label="Billing Street Name" inputRef={inputBillingStreetName} value={billingStreetName} onChange={changing} required/>
                            <TextField id="billinb_unit_no" label="Billing Apartment/Unit No." inputRef={inputBillingUnitNumber} value={billingUnitNumber} onChange={changing} required/>
                            <TextField id="billing_postal_code" label="Billing Postal Code" inputRef={inputBillingPostalCode} value={billingPostalCode} onChange={changing} required/>
                        </Stack>
                        <Button 
                            style={{color:'white', backgroundColor: disabled?'#98A9BC':'#009AF9', padding:20}} 
                            onClick={register} 
                            disabled={disabled}
                        >
                            {buttonMsg}
                        </Button>
                        {error?  <Item elevation={0}>{errorMsg}</Item> : <></>}
                    </Stack>
                </Box>

            </Paper>
        </Backdrop>
    );
}

export default BackdropSignUp;