import React from 'react';
import {Background} from '../../../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';

function WaterJet(props, theme) {

    theme=useTheme(theme)

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
              <title>CutAnything | Water Jet</title>
              <meta name='description' content="In this page, we will be explaning what you can do with our Water Jet machine."/>
              <link rel='canonical' href='/capabilities/waterjet'/>
            </Helmet>

            <Typography variant="title">Water Jet</Typography>
            
        </Background>
    );
}

export default WaterJet;