import {useState,useRef,useEffect} from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

function AdminControlSideBar(props, theme) {

    //console.log('admincontrolsidebar')
    theme=useTheme(theme)
    
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const [selected, setSelected] = useState('All Quotation');

    const SidebarButton = styled(Button)((props) => ({
        //the first 4 props is based on the "selected" useState
        borderLeft: selected === props.name?"6px solid":"0px solid",
        borderColor: selected === props.name?theme.palette.primary.main:"white",
        backgroundColor: "white",
        color: selected === props.name? theme.palette.primary.main:theme.palette.neutral.darkgrey2.main,
        width: '210px',
        justifyContent: 'left',
        padding:20,
        textTransform:'capitalize',
        // color: theme.palette.neutral.darkgrey2.main,
        ':hover': {
        // borderLeft:'4px solid',
        // borderColor:theme.palette.primary.main,
        backgroundColor: "#D9D9D9",
        color: theme.palette.primary.main,

        "&:hover": {
            textDecoration: "none",
            color: theme.palette.primary.main,
            backgroundColor: "#D9D9D9",
            borderLeft: "6px solid",
            borderColor:theme.palette.primary.main,

          },

        },
    }));

    const SidebarLink = styled(Button)((props) => ({
        display: "flex",
        alignItems: "center",
        color: activeSubMenu === props.name
          ? theme.palette.primary.main
          : theme.palette.neutral.darkgrey2.main,
        width: "100%",
        textDecoration: "none",
        padding: "10px 20px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "16px",
        textTransform: 'capitalize',
        borderLeft: activeSubMenu === props.name
        ? "6px solid":"0px solid",
        borderColor: activeSubMenu === props.name
        ? theme.palette.primary.main: "white",


        "&:hover": {
            borderLeft: "6px solid",
            borderColor:theme.palette.primary.main,
            textDecoration: "none",
            color: theme.palette.primary.main,
            backgroundColor: "#D9D9D9",
        },
      }));


    const SubMenu = (props) => {
        const [subnav, setSubnav] = useState(false);
        const subNavRef = useRef(null);
        
        const handleClick = () => {
            if (activeSubMenu === props.name) {
            setActiveSubMenu(null);
            } else {
            setActiveSubMenu(props.name);
            }
        };

        return (
            <div ref={subNavRef}>
                <div style={{ display: "flex", alignItems: "center", width: "234px",}}>
                    <SidebarLink
                    selected={activeSubMenu === props.name}
                    onClick={handleClick}
                    name={props.name}
                    >
                    {props.name}
                    <div style={{ marginLeft: "auto" }}>
                    {activeSubMenu === props.name ? (
                        <RiIcons.RiArrowUpSFill />
                    ) : (
                        <RiIcons.RiArrowDownSFill />
                    )}
                    </div>
                    </SidebarLink>
                    
                </div>
                {activeSubMenu === props.name && (
                    <Stack direction="column" sx={{ pl: 3 }}>
                    {props.children}
                    </Stack>
                )}
            </div>
        );
    };

    const buttonClick = (title) => {
        // console.log(title)
        props.setTitle(title)
        setSelected(title)
    }


    return (
        <Box sx={
            {
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-start',
                bgcolor:'white',
                m:1,
                ml:0,
                mt:0
            }
        }>
        
        <Stack direction='column' alignItems='flex-start'>
            
            <SubMenu name={'Order Management'}  >
                <SidebarButton name={'All Quotation'} onClick={()=>{buttonClick('All Quotation')}}>All Quotation</SidebarButton>
                <SidebarButton name={'Completed'}  onClick={()=>{buttonClick('Completed')}}>Completed Orders</SidebarButton>
                {/* <SidebarButton name={'Current Orders'} onClick={()=>buttonClick('Current Orders')}>Current Orders</SidebarButton> */}
                <SidebarButton name={'Payment Pending'} onClick={()=>{buttonClick('Payment Pending')}}>Pending Quotation</SidebarButton>
                <SidebarButton name={'Payment Received'} onClick={()=>{buttonClick('Payment Received')}}>Payment Receieved</SidebarButton>
                <SidebarButton name={'Ready for Collection'} onClick={()=>{buttonClick('Ready for Collection')}}>Ready for Collection</SidebarButton>
                <SidebarButton name={'On Delivery'} onClick={()=>{buttonClick('On Delivery')}}>On Delivery</SidebarButton>
                <SidebarButton name={'Cancelled'} onClick={()=>{buttonClick('Cancelled')}}>Canceled</SidebarButton>            
            </SubMenu>

            <SubMenu name={'User Management'}>
            <SidebarButton name={'Users'} onClick={()=>{buttonClick('Users')}}>Users</SidebarButton>
            </SubMenu>
            
            <SubMenu name={'Costing Management'}>
                <SidebarButton name={'Cost Table'} onClick={()=>{buttonClick('Cost Table')}}>Cost Table</SidebarButton> 
                <SidebarButton name={'Category Table'} onClick={()=>{buttonClick('Category Table')}}>Category Table</SidebarButton>
                <SidebarButton name={'Discount Table'} onClick={()=>{buttonClick('Discount Table')}}>Discount Table</SidebarButton> 
            </SubMenu>

            <SubMenu name={'Product Management'}>
                <SidebarButton name={'Current Products'} onClick={()=>{buttonClick('Current Products')}}>Current Products</SidebarButton> 
                <SidebarButton name={'Add Categories'} onClick={()=>{buttonClick('Add Categories')}}>Add Categories</SidebarButton>
                <SidebarButton name={'Add Products'} onClick={()=>{buttonClick('Add Products')}}>Add Products</SidebarButton>
            </SubMenu>
        </Stack>
        
        
    </Box>
    );
}

export default AdminControlSideBar;