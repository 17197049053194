import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";


const TestingNavbar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static" sx={{bgcolor:'white', marginBottom:2, mt:-1}} elevation={0} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
      
        <Box
            component="img"
            sx={{ display: { xs: 'none',  md: 'none', lg: 'flex', width:"20%", height:'20%' }, mr: 20, cursor: "pointer" }}
            alt="cutanything_word log"
            src="https://global-uploads.webflow.com/6100f59c138a680259458e8b/615bd984b674160508289339_Artboard%202%20copy%2021.png"
            onClick={() => {navigate("/")}}
          />
        <Container maxWidth='xl'>
          <Box sx={{  display: { xs: 'none',  md: 'none', lg: 'flex'},  justifyContent: 'flex-end', alignItems:'center', mr: 1}} >
            {/* <Button className="bwBtn navBtn"  onClick={() => {navigate("/about")}}>About</Button> */}
            <Button className="bwBtn navBtn"  onClick={() => {navigate("/capabilities")}}>Capabilities</Button>
            <Button className="bwBtn navBtn" onClick={() => {navigate("/contact-us")}}>Contact Us</Button>

            <Button className="caBtn navBtn"  onClick={() => {navigate("/login")}}>Login</Button>
          </Box>
        </Container>


          {/* this is the for side bar */}
          <Box
          component="img"
          sx={{ display: { xs: 'flex', md: 'flex', lg:'none', maxWidth:"20%", maxHeight:'20%'}, mr: 1,cursor: "pointer" }}
          alt="cutanything_word log"
          src="https://global-uploads.webflow.com/6100f59c138a680259458e8b/615bd984b674160508289339_Artboard%202%20copy%2021.png"
          onClick={() => {navigate("/")}}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg:'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block', lg:'none'  }
              }}
            >
            {/* <MenuItem onClick={() => {handleCloseNavMenu();navigate("/about")}}>
                About
            </MenuItem> */}

            <MenuItem onClick={() => {handleCloseNavMenu();navigate("/capabilities")}}>
              Capabilities
            </MenuItem>

            {/* <MenuItem onClick={() => {handleCloseNavMenu();navigate("/guides")}}>
              Guides
            </MenuItem> */}

            <MenuItem  onClick={() => {handleCloseNavMenu();navigate("/login")}}>
                Login
            </MenuItem>

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TestingNavbar;
