import React from 'react';
import {Background} from '../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';

function ContactUsSuccess(props, theme) {

    theme=useTheme(theme)

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
                <title>CutAnything | Contact Us</title>
                <meta name='description' content="Thank you for submitting your question. We will get back to you as soon as possible"/>
                <link rel='canonical' href='/contact-us-success'/>
            </Helmet>

            <Box 
                sx={
                        {
                            display:'flex', 
                            flexGrow:0.5, 
                            flexDirection:'column', 
                            bgcolor:'white', 
                            alignItems: 'center',
                            borderRadius:5,
                            pt:5, 
                            pb:5
                        }
                    }
            >
                <Box
                    component="img"
                    sx={{ display: { xs: 'none',  md: 'none', lg: 'flex', width:"40%", height:'40%' }, mb:5}}
                    alt="cutanything_word log"
                    src="https://global-uploads.webflow.com/6100f59c138a680259458e8b/615bd984b674160508289339_Artboard%202%20copy%2021.png"
                />

                <Typography variant='h5' sx={{mb:3}}>Thank you for contacting us!</Typography>
                <Typography variant='body1' sx={{color:theme.palette.neutral.darkgrey1.main, mb:3}}>You will hear from us soon.</Typography>


            </Box>
            
        </Background>
    );
}

export default ContactUsSuccess;