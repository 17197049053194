import React from 'react';
import NumberedSlider from '../../../components/NumberedSlider';

function Star(props) {
  const {
    setNumOfSides,
    setStarOuterRadius,
    setStarInnerRadius,
    numOfSides,
    starOuterRadius,
    starInnerRadius,
  } = props;


  return (
    <div className="container">
      <div>
        <h3>Number of Sides</h3>
        <NumberedSlider
          setValue={setNumOfSides}
          value={numOfSides}
          valueLabelDisplay="auto"
          min={0}
          max={10}
        />

        <h3>Outer Radius (mm)</h3>
        <NumberedSlider
          setValue={setStarOuterRadius}
          value={starOuterRadius}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />

        <h3>Inner Radius (mm)</h3>
        <NumberedSlider
          setValue={setStarInnerRadius}
          value={starInnerRadius}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />
      </div>
    </div>
  );
}

export default Star;
