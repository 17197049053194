import React, { useState, useEffect } from 'react';
import SourceEditor from './custom-ckeditor5-build-editor/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyImageUploadAdapterFactory from './MyImageUploadAdapter';
import apiClientInstance from "../api-client/apiclient";
import { Button,  MenuItem, Select, Typography, FormControl, InputLabel } from '@mui/material';

const EmailTemplateEditor = (props) => {

  const [exporting, setExporting] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);
  const [viewportSize, setViewportSize] = useState('desktop');

  const handleViewportSizeChange = (event) => {
    setViewportSize(event.target.value);
  };

  const setInitialStyles = (mainElement) => {
    mainElement.style.marginTop = '1em';
    mainElement.style.justifyContent = 'center';
    mainElement.style.display = 'flex'
  };

  const handleExport = async () => {
    setExporting(true);
    try {
      const html = editorInstance.getData();
      console.log('HTML:', html);

      console.log(props.componentNameRef.current, props.componentTypeRef.current);

      // Replace this with the API call to send the HTML content to your Django backend
      // await api.sendTemplate(html);

      await props.onSubmit(html, props.componentNameRef.current, props.componentTypeRef.current);
    } catch (error) {
      console.error(error);
    } finally {
      setExporting(false);
    }
  };
  const style = () => {
    return {width:"100%", height:"48px",border: "1px solid #ECEBED",borderRadius: "4px",
    background:"#fff", fontWeight:600,fontFamily:"Open Sans",fontSize:16,lineHeight:"22px",
    textTransform:'none', padding:"12px",fontColor:"#98A9BC",marginBottom:8}
  
}

  useEffect(() => {
    if (editorInstance) {
      const setViewportSizeStyle = () => {
        editorInstance.editing.view.change((writer) => {
          const rootElement = editorInstance.editing.view.document.getRoot();
          const viewportStyles = {
            desktop: { width: '100%', height: 'auto'},
            tablet: { width: '768px', height: 'auto' },
            mobile: { width: '375px', height: 'auto'},
          };
  
          const styles = viewportStyles[viewportSize];
  
          writer.setStyle('width', styles.width, rootElement);
          writer.setStyle('height', styles.height, rootElement);
        });
      };
  
      setViewportSizeStyle();
    }
  }, [viewportSize, editorInstance]);



  return (
    <div>
        
        <FormControl margin="normal">
        <InputLabel>Change Viewport Type</InputLabel>
        <Select
            sx= {{width:'80px'}}
            value={viewportSize}
            onChange={handleViewportSizeChange}
            style={style()}
            >
            <MenuItem value="desktop">Desktop</MenuItem>
            <MenuItem value="tablet">Tablet</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
        </Select></FormControl>

      <CKEditor
        editor={SourceEditor}
        data={props.htmlContent || ''}
        config={{
          myApi: {
            client: apiClientInstance,
          },
          baseURL: `${process.env.REACT_APP_HOSTNAME}`,
          contentEditable: props.contentEditable ? true : false,
        }}
        onReady={(editor) => {
            setEditorInstance(editor);
            MyImageUploadAdapterFactory(editor);
            // Set initial styles
            const mainElement = document.querySelector('.ck-editor__main');
            setInitialStyles(mainElement);

          }}
      />

      {props.isExportable && <Button
        variant="contained"
        color="primary"
        className="export-button bwBtn navBtn"
        disabled={exporting}
        onClick={handleExport}
      
      >
        {exporting ? 'Exporting...' : 'Export Email Template'}
      </Button>}
    </div>
  );
};

export default EmailTemplateEditor;
