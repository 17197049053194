import { useRef } from 'react';
import { Box, 
    Button, 
    Typography,
    TextField,
} from '@mui/material';
import { useTheme } from '@mui/material';

function AddNewCategories(props, theme) {

    theme=useTheme(theme)

    //useRef
    const inputCategoryName = useRef(null);
    const inputCategoryDescription = useRef(null);


    const submit = () => {
        console.log("submit")
        console.log(inputCategoryName.current.value)
        console.log(inputCategoryDescription.current.value)
    }

    return (
        <Box
            sx={{
                p:10,
                pt:5

            }}
        >
            <Typography variant='h3' sx={{mb:3}}>Add New Category</Typography>

            <Box
                sx={{
                    display:"flex",
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}
            > 

                <Box
                    sx={{
                        flexGrow:1
                    }}
                >  
                    {/* product name */}
                    <TextField 
                        required
                        fullWidth
                        label="Category Name"
                        inputRef={inputCategoryName}
                        sx={{
                            mb:3
                        }}
                    />

                    {/* product description */}
                    <TextField 
                        required
                        fullWidth
                        multiline
                        label="Category Description"
                        rows={3}
                        inputRef={inputCategoryDescription}
                        sx={{
                            mb:3
                        }}
                    />

                    <Button
                        variant='contained'
                        fullWidth
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Box>

                
            </Box>

        </Box>
    );
}

export default AddNewCategories;