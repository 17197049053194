import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import AccountMenu from '../../components/AccountMenu';
import Divider from '@mui/material/Divider';

const ClientGeneralUserNavbar = () => {
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [button, setButton] = useState(<>
                                        <Button style={{color:'white', margin:25, backgroundColor: '#3898ec'}} onClick={() => {handleCloseNavMenu(); navigate("/quotation")}}>Quote</Button>
                                        <AccountMenu/>
                                      </>)



  return (
    <AppBar position="static" sx={{bgcolor:'white', marginBottom:2, mt:-1}} elevation={0} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>

        <Container maxWidth='xl'>
            <Box sx={{  display: { xs: 'none',  md: 'none', lg: 'flex'},  justifyContent: 'flex-end', alignItems:'center', mr: 1}} >
                {button}
            </Box>
        </Container>


          {/* this is the for side bar */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg:'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block', lg:'none' }
              }}
            >

            <MenuItem onClick={() => {handleCloseNavMenu();navigate("/quotation")}}>
              Quote
            </MenuItem>

            <Divider />

            <AccountMenu closeNavbar={setAnchorElNav}/>

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ClientGeneralUserNavbar;
