import {useState,} from 'react';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbarQuickFilter,
    GridToolbar
    } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';


//to customise the bottom right footer
function labelDisplayRows({from, to, count}){
    return(<span>Showing {from} to {to} of {count} entries</span>)
}

//this function is for the footer customisation
function CustomPagination(props) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(2);

    const handleChangePage = (event, newPage) => {
        setSelectedPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        // setPage(0);
    };

    return (
        <Box sx={{display:'flex'}} pb={2} >
            {/* bottom left footer  */}
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                sx={{pt:1, flexGrow:3 }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      color: '#778CA2',
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        color: "#009AF9",
                      },
                      "& .MuiSvgIcon-root":{
                        color:'#009AF9'
                      }
                    }}
                  />
                )}
            />

            {/* bottom right footer */}
            <TablePagination
                component="div"
                count={props.rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={(page) => labelDisplayRows(page)}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                rowsPerPageOptions={[]}
                sx={{color:'#778CA2'}}
            />
        </Box>
    );
}

//when there is no order (row) data
function NoRowsOverlay() {
    return (
    <Stack height="100%" alignItems="center" justifyContent="center" sx={{color:'#778CA2'}}>
        No user yet!
    </Stack>
    );
}

function getPostalCode(params){

    return(`S${params.row.delivery_postal_code}`)
    
}

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          display:'flex',
          justifyContent: 'flex-start',
          p:2
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
}

 
export default function DataGridUser(props, theme) {
    console.log(props.rowData)
    theme=useTheme(theme)
    //if u change the number of rows per page, pls changed it in the CustomPagination() too
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rows = !props.rowData?[]:props.rowData;
    const columns = [
    { 
        field: 'name', 
        headerName: 'Name', 
        minWidth: 200,
        flex:1,
        paddingLeft:2,
        editable:false,
        headerAlign: 'center',
        align: 'center',
        cellClassName: () =>{
            return("Name")
        }
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 200,
        flex:1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'company_name',
        headerName: 'Company',
        minWidth: 200,
        flex:1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'phone_number',
        headerName: 'Phone Number',
        minWidth: 150,
        flex:0.6,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'delivery_address_line_1',
        headerName: 'Address 1',
        minWidth: 200,
        flex:0.7,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'delivery_address_line_2',
        headerName: 'Address 2',
        minWidth: 200,
        flex:0.7,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'delivery_postal_code',
        headerName: 'Postal Code',
        minWidth: 200,
        flex:0.7,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        valueGetter: getPostalCode
    }
    ];


    return (
        
        <div style={{ height: 80*rowsPerPage , width: '100%' }}>
            <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            rowHeight={65}
            rowLength={rowsPerPage}
            sx={
                { 
                    bgcolor:'white',
                    border:0,
                    mr:2,
                    ml:2,
                    pl:2,
                    pr:2,
                    "& .MuiDataGrid-columnHeaders": {
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        paddingRight:3
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight":{
                        visibility:'hidden'
                    },
                    "& .MuiDataGrid-cell":{
                        borderBottom:'0px',
                        justifyContent:'center',
                    },
                    "& .Name":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#009AF9"
                    },
                }
            }
            disableColumnSelector
            disableDensitySelector
            components={{
                NoRowsOverlay,
                Footer: CustomPagination,
                Toolbar: GridToolbar,
            }}
            // filterModel={{
            //     items:props.searchValue
            // }}
            componentsProps={{
                footer: { rows }
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                }
            }}
            />

        </div>
    );
}
