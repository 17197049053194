import { Box, Card, Grid, Typography,TextField } from "@mui/material";
import MuiField from '../components/MuiField';
import PhoneInput from 'react-phone-number-input';
import { styled } from '@mui/system';

function AccountDetails({ account }) {

    const StyledCard = styled(Card)(({ theme }) => ({
        cornerRadius:"5px"
    }));

  return (
    <Box sx={{ justifyContent: 'center', ml:5,minWidth:'50%',maxWidth:'60%'}}>
      <Grid container spacing={2} direction='column'>

        {/* Basic Info */}
        <Grid item xs={12} md={4}>
          <StyledCard variant="outlined">
            <Grid container direction="row" spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Info</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField id='name' label='Full Name' defaultValue={account.name} disabled fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id='company' label='Company' defaultValue={account.company_name} disabled fullWidth />
              </Grid>
            </Grid>
          </StyledCard>
        </Grid>

        {/* Contact Info */}
        <Grid item xs={12} md={4}>
          <StyledCard variant="outlined">
            <Grid container direction="row" spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Info</Typography>
              </Grid>
              <Grid item xs={6}>
                <PhoneInput 
                  placeholder="Enter phone number"
                  value={account.phone_number}
                  defaultCountry='SG'
                  inputComponent={MuiField}
                  fullWidth disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField id='email' label='Email' defaultValue={account.email} disabled fullWidth />
              </Grid>
            </Grid>
          </StyledCard>
        </Grid>

        {/* Addresses */}
        <Grid item xs={12} md={4}>
          <StyledCard variant="outlined">
            <Grid container direction="row" spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Address</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField id='deliveryAddress' label='Delivery Address' defaultValue={account.delivery_address_line_1} disabled fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id='deliveryUnit' label='Delivery Unit no.' defaultValue={account.delivery_address_line_2} disabled fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id='deliveryPostal' label='Delivery Postal Code' defaultValue={account.delivery_postal_code} disabled fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id='billingAddress' label='Billing Address' defaultValue={account.billing_address_line_1} disabled fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id='billingUnit' label='Billing Unit no.' defaultValue={account.billing_address_line_2} disabled fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id='billingPostal' label='Billing Postal Code' defaultValue={account.billing_postal_code} disabled fullWidth />
              </Grid>
            </Grid>
          </StyledCard>
        </Grid>
        
      </Grid>
    </Box>
  );
}

export default AccountDetails;
