import {useState, useContext} from 'react';
import { useUserContext } from '../../context/UserContext';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbarQuickFilter,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarContainer, 
    GridToolbarExport,
    GridCsvExportOptions
    } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

//to customise the bottom right footer
function labelDisplayRows({from, to, count}){
    return(<span>Showing {from} to {to} of {count} entries</span>)
}

//this function is for the footer customisation
function CustomPagination(props) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(2);

    const handleChangePage = (event, newPage) => {
        setSelectedPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        // setPage(0);
    };

    return (
        <Box sx={{display:'flex'}} pb={2} >
            {/* bottom left footer  */}
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                sx={{pt:1, flexGrow:3 }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      color: '#778CA2',
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        color: "#009AF9",
                      },
                      "& .MuiSvgIcon-root":{
                        color:'#009AF9'
                      }
                    }}
                  />
                )}
            />

            {/* bottom right footer */}
            <TablePagination
                component="div"
                count={props.rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={(page) => labelDisplayRows(page)}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                rowsPerPageOptions={[]}
                sx={{color:'#778CA2'}}
            />
        </Box>
    );
}

//custom toolbar
function CustomToolBar(){
    return(
        <GridToolbarContainer>
            <GridToolbarExport 
                csvOptions={{
                    allColumns:true
                }}
            />
        </GridToolbarContainer>
    )
}

function Actions(params){
    const {auth} = useUserContext();
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [downloadButtonWord, setDownloadButtonWord] = useState('Download');
    const [updateButtonWord, setUpdateButtonWord] = useState('Update');

    //for downloading
    const download = () => {
        console.log(params.params)
        setDownloadButtonWord("Downloading...")
        setLoadingDownload(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/generate_invoice/${params.params.row.id}`, 
        {
            headers: {
                'Authorization': 'Token ' + auth.logginToken
            },
            responseType: 'blob'
        }).then((res) => {
            console.log(res)

            const new_blob = new Blob( [ res.data ], { type: "application/octetstream" } );
            const url = URL.createObjectURL( new_blob );
            const link = document.createElement('a');
            
            link.href = url;
            link.setAttribute('download', `INV-${params.params.row.serial_number}.html`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setDownloadButtonWord('Download')
            setLoadingDownload(false)

        }).catch((err) => {
            console.log(err)
            setDownloadButtonWord('Download')
            setLoadingDownload(false)
        })
    }

    //for updating
    const update = () => {
        // console.log(props.params)
        // props.setShowMessage(false)
        console.log(params)
        setUpdateButtonWord("Updating...")
        setLoadingUpdate(true)

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/quotation/${params.params.row.id}/`, 
        {
            'status': params.params.row.status,
            'delivery': params.params.row.delivery,
        },
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res) => {
            console.log(res)
            setUpdateButtonWord("Update")
            setLoadingUpdate(false)
            // props.setSuccess(true)
            // props.setShowMessage(true)
        }).catch((err) => {
            console.log(err)
            setUpdateButtonWord('Update')
            setLoadingUpdate(false)
            // props.setSuccess(false)
            // props.setShowMessage(true)
        })

    }

    return(
        <Stack direction="row" justifyContent='center'>
            <LoadingButton
                loading={loadingUpdate}
                onClick={update}
                // variant="outlined"
                loadingPosition="start"
                startIcon={<CloudUploadIcon/>}
                sx={{borderRadius:5}}
                >
                {updateButtonWord}
            </LoadingButton>
            <LoadingButton
                loading={loadingDownload}
                onClick={download}
                // variant="outlined"
                loadingPosition="start"
                startIcon={<RemoveRedEyeOutlinedIcon/>}
                sx={{borderRadius:5}}
                >
                {downloadButtonWord}
            </LoadingButton>
        </Stack>
    )
}

function DownloadButton(params){

    const {auth} = useUserContext();
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [downloadButtonWord, setDownloadButtonWord] = useState('Download');

    const download = () => {
        console.log(params.params)
        setDownloadButtonWord("Downloading...")
        setLoadingDownload(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/generate_invoice/${params.params.row.id}`, 
        {
            headers: {
                'Authorization': 'Token ' + auth.logginToken
            },
            responseType: 'blob'
        }).then((res) => {
            console.log(res)

            const new_blob = new Blob( [ res.data ], { type: "application/octetstream" } );
            const url = URL.createObjectURL( new_blob );
            const link = document.createElement('a');
            
            link.href = url;
            link.setAttribute('download', `INV-${params.params.row.serial_number}.html`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setDownloadButtonWord('Download')
            setLoadingDownload(false)

        }).catch((err) => {
            console.log(err)
            setDownloadButtonWord('Download')
            setLoadingDownload(false)
        })
    }

    return(
        <LoadingButton
        loading={loadingDownload}
        onClick={download}
        variant="outlined"
        loadingPosition="start"
        startIcon={<ArrowDownwardOutlinedIcon/>}
        sx={{borderRadius:5}}
        >
        {downloadButtonWord}
        </LoadingButton>
    )
}

function UpdateButton(props){

    const {auth} = useUserContext();
    const [loading, setLoading] = useState(false);
    const [updateButtonWord, setUpdateButtonWord] = useState('Update');

    const update = () => {
        console.log(props.params)
        props.setShowMessage(false)
        setUpdateButtonWord("Updating...")
        setLoading(true)

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/quotation/${props.params.row.id}/`, 
        {
            'status': props.params.row.status,
            'delivery': props.params.row.delivery,
            'serial_number': props.params.row.serial_number
        },
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res) => {
            console.log(res)
            setUpdateButtonWord("Update")
            setLoading(false)
            props.setSuccess(true)
            props.setShowMessage(true)
        }).catch((err) => {
            console.log(err)
            setUpdateButtonWord('Update')
            setLoading(false)
            props.setSuccess(false)
            props.setShowMessage(true)
        })

    }

    return(
        <LoadingButton
        loading={loading}
        onClick={update}
        variant="outlined"
        loadingPosition="start"
        startIcon={<SaveAltOutlinedIcon/>}
        sx={{borderRadius:5}}
        >
        {updateButtonWord}
        </LoadingButton>
    )
}

//when there is no order (row) data
function NoRowsOverlay() {
    return (
    <Stack height="100%" alignItems="center" justifyContent="center" sx={{color:'#778CA2'}}>
        No orders yet!
    </Stack>
    );
}

//to change the format of the invoice
function getFullInvoice(params){
    if(params.row.status === 'PP' || params.row.status === 'Payment Pending'){
        return(`QUOT-${params.row.serial_number}`)
    }else{
        return(`INV-${params.row.serial_number}`)
    }
}

//to add "$" infront of the cost value
function getCostValue(params){

    if(params.row.cost === null){
        return("Pending")
    }else{
        return(`$${(params.row.cost).toFixed(2)}`)
    }
    
}

//to return the date as a DateTime data type
function getDate(params){

    return(new Date(params.row.order_date))
}

//to format the date as DD/MM/YYYY
function formatDate(params){
    // console.log(params.value)
    let d = moment(params.value)

    return(d.format("DD/MM/YYYY"))
}

//for the search toolbar 
function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          display:'flex',
          justifyContent: 'flex-start',
          p:2
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
}

//this function helps with the status colours
function statusStyle(values){

    let status_style = { paddingLeft:20, 
                            paddingRight:20, 
                            paddingTop:5, 
                            paddingBottom:5, 
                            color:'white',
                            borderRadius:4
                        }

    if(values === 'Completed'){
        return ({...status_style, backgroundColor:'#182869'})
    }else if(values === 'PP' || values === 'Payment Pending' ){
        return({...status_style, backgroundColor: '#F293AC'})
    }else if(values === 'Payment Received'){
        return({...status_style, backgroundColor: '#FFE192'})
    }else if(values === 'Ready for Collection' || values === 'On Delivery'){
        return({...status_style, backgroundColor: '#28D7A9'})
    }else if(values === 'Cancelled'){
        return({...status_style, backgroundColor: '#DC3233'})
    }else if(values === 'Drafting'){
        return({...status_style, backgroundColor: '#87C7EE'})
    }else{
        return({color: "black"})
    }
}

//to add the drop arrow icon beside the dropdown button
function Dropdown(props){
    const { value } = props;

    if (!value) {
        return null;
    }
    
    const valueStr = value.toString();

    return(
        <Box sx={{ display: 'flex', flexDirection:'row'}} style={{width:'100%'}}>
            <Box sx={{flexGrow:1, justifyContent: 'flex-start', textAlign:'left'}}>
                <span style={statusStyle(valueStr)}>{valueStr}</span>
            </Box>

            <Box>
                <ArrowDropDownOutlinedIcon sx={{ color: '#2196f3'}} />
            </Box>
            
        </Box>
    )
}

//for data checking
function FilterData(data, fromDate, toDate){

    //if there is no data, then return empty list
    if(!data){
        return []
    //if there is no date filter, then return the orginial data
    }else if(fromDate === null && toDate === null){
        return data
        
    //if there toDate value is null, then return data that has bigger dates than the toDate
    }else if(toDate === null){

        let filtered_output_data = []

        for(let i=0; i<data.length; i++){
            //this is to convert the date into ISO time format. Followed by spliting it to get "yyyy-mm-dd" format.
            //after getting the above format, using the getTime() to convert to milliseconds
            //using this milliseconds, we will convert which date is smaller/bigger
            let fromDate_converted = new Date(fromDate.toISOString().split('T')[0]).getTime();
            let order_date = new Date(data[i].order_date.split('T')[0].toString()).getTime();

            if(order_date >= fromDate_converted){
                filtered_output_data.push(data[i])
            }
        }
        return filtered_output_data

    //if fromDate is null, there return data that has smaller dates than the toDate
    }else if(fromDate === null){

        let filtered_output_data = []

        for(let i=0; i<data.length; i++){
            //this is to convert the date into ISO time format. Followed by spliting it to get "yyyy-mm-dd" format.
            //after getting the above format, using the getTime() to convert to milliseconds
            //using this milliseconds, we will convert which date is smaller/bigger
            let order_date = new Date(data[i].order_date.split('T')[0].toString()).getTime();
            let toDate_converted = new Date(toDate.toISOString().split('T')[0]).getTime();

            if(order_date <= toDate_converted){
                filtered_output_data.push(data[i])
            }
        }
        return filtered_output_data

    //if both fromDate and toDate has values, then return data that has dates between the fromDate and toDate
    }else if(fromDate != null && toDate != null){

        let filtered_output_data = []

        for(let i=0; i<data.length; i++){
            // console.log("-----------------------------------------------------")
            // console.log(new Date(data[i].order_date.split('T')[0]))
            // console.log(new Date(fromDate))
            // console.log(toDate)
            // console.log(new Date(data[i].order_date.split('T')[0]).getTime() >= new Date(fromDate.toISOString().split('T')[0]).getTime())

            //this is to convert the date into ISO time format. Followed by spliting it to get "yyyy-mm-dd" format.
            //after getting the above format, using the getTime() to convert to milliseconds
            //using this milliseconds, we will convert which date is smaller/bigger
            let fromDate_converted = new Date(fromDate.toISOString().split('T')[0]).getTime();
            let toDate_converted = new Date(toDate.toISOString().split('T')[0]).getTime();
            let order_date = new Date(data[i].order_date.split('T')[0]).getTime();
    
            if(order_date >= fromDate_converted && order_date <= toDate_converted){
                filtered_output_data.push(data[i])
            }
        }
        return filtered_output_data

    }
}

 
export default function DataGridAdmin(props, theme) {
    console.log(props.rowData)
    theme=useTheme(theme)
    //if u change the number of rows per page, pls changed it in the CustomPagination() too
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rows = FilterData(props.rowData, props.fromDate, props.toDate);
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 250,
            flex:1,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },{
            field: 'quotation_number', 
            headerName: 'Quotation No.', 
            minWidth: 150,
            flex:0.6,
            headerAlign: 'center',
            align: 'center',
            paddingLeft:2,
            valueGetter: getFullInvoice,
            cellClassName: (params) =>{
                if(params.value.split('-')[0] === "INV"){
                    return("InvoiceINV")
                }else if(params.value.split('-')[0] === "QUOT"){
                    return("InvoiceQUOT")
                }else if(params.value.split('-')[0] === "PO"){
                    return("InvoiceQO")
                }
            }
        },
        {
            field: 'date_of_order',
            headerName: 'Order Date',
            type: 'dateTime',
            minWidth: 130,
            flex:0.7,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            valueGetter: getDate,
            valueFormatter: formatDate,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 180,
            flex:0.5,
            headerAlign: 'center',
            align: 'center',
            editable: true,
            type: "singleSelect",
            value: 'status',
            label:'status',
            valueOptions:['Drafting', 'Payment Pending', 'Payment Received', 'Ready for Collection', 'On Delivery', 'Completed', 'Canceled'],
            renderCell:Dropdown
        },
        {
            field: 'email',
            headerName: 'Owner',
            minWidth: 250,
            flex:0.5,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            field: 'owner',
            headerName: 'Ownder ID',
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            field: 'cost',
            headerName: 'Cost',
            minWidth: 150,
            flex:0.5,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            valueGetter: getCostValue,
        },
        {
            field: 'delivery',
            headerName: 'Collection Method',
            minWidth: 160,
            flex:0.3,
            headerAlign: 'center',
            align: 'center',
            editable: true,
            type: "singleSelect",
            value: 'delivery',
            label:'delivery',
            valueOptions:['Normal Delivery', 'Pickup', 'Expedite Delivery'],
            renderCell:Dropdown
        },
        {
            field: 'payment_request_id',
            headerName: 'Payment Request ID',
            minWidth: 250,
            flex:1,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            field: 'reference_number',
            headerName: 'Reference Number',
            minWidth: 250,
            flex:1,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },        {
            field: 'tenant',
            headerName: 'Tenant',
            minWidth: 250,
            flex:1,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200,
            flex:1,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            //to add the download button to every cell
            renderCell: (params)=><Actions params={params}/>
        }
    ];


    return (
        <div style={{height: 85*rowsPerPage, width: "100%" }}>
            <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            rowHeight={65}
            rowLength={rowsPerPage}
            sx={
                { 
                    bgcolor:'white',
                    border:0,
                    mr:2,
                    ml:2,
                    pl:2,
                    pr:2,
                    overflowX: 'scroll',
                    "& .MuiDataGrid-columnHeaders": {
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        paddingRight:3
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight":{
                        visibility:'hidden'
                    },
                    "& .MuiDataGrid-cell":{
                        borderBottom:'0px',
                        justifyContent:'center'
                    },
                    "& .InvoiceINV":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#009AF9"
                    },
                    "& .InvoiceQUOT":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#778CA2"
                    },
                    "& .InvoicePO":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#DC3233"
                    },
                }
            }
            disableColumnSelector
            disableDensitySelector
            components={{
                NoRowsOverlay,
                Toolbar: CustomToolBar,
                Footer: CustomPagination,
            }}
            componentsProps={{
                footer: { rows }
            }}
            // filterModel={{
            //     items:props.searchValue
            // }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'date_of_order', sort: 'desc' }],
                },
                columns: {
                    columnVisibilityModel: {
                        id:false,
                        owner:false,
                        payment_request_id: false,
                        reference_number: false,
                        tenant:false
                    },
                  },
            }}
            />
        </div>

        
    );
}
