import {useState} from 'react';
import FlaredSpokes from "./makerjs-Dial";
var makerjs = require('makerjs');

function SquareShape(){

    const [squareSide, setSquareSide] = useState(100);

    //for simple shapes, pls follow the object path like squareModels/squareRecModels, 
    //this is to make sure it works with the download button
    let squareModels = {
        models: new makerjs.models.Square(squareSide)
    }

    return {squareModels, squareSide, setSquareSide};
}

function CircleShape(){

    const [circleRadius, setCircleRadius] = useState(50);

    let circleModels = {
        models: new makerjs.models.Ring(circleRadius)
    }

    return {circleModels, circleRadius, setCircleRadius}
}

function SquareRecShape(){
    
    const [length, setLength] = useState(100);
    const [width, setWidth] = useState(100);
    const [radius, setRadius] = useState(5);

    let squareRecModels = {
      models: new makerjs.models.RoundRectangle(length,width,radius)
      
    };

    return {squareRecModels,
            length, 
            width, 
            radius,
            setLength,
            setWidth,
            setRadius
    }
}

function DialShape(){
    const [outerRadius, setOuterRadius] = useState(100);
    const [innerRadius, setInnerRadius] = useState(93);
    const [count, setCount] = useState(15);
    const [spokeWidth, setSpokeWidth] = useState(1);
    const [flareWidth, setFlareWidth] = useState(5);

    let dialModels = {
        models: new FlaredSpokes(
            outerRadius,
            innerRadius,
            count,
            spokeWidth,
            flareWidth,
            0,
            0,
            0
        )
    };

    return {
        dialModels,
        outerRadius,
        innerRadius,
        count,
        spokeWidth,
        flareWidth,
        setOuterRadius,
        setInnerRadius,
        setCount,
        setSpokeWidth,
        setFlareWidth
    }
}

function StarShape(){

    const [numOfSides, setNumOfSides] = useState(4);
    const [starOuterRadius, setStarOuterRadius] = useState(50);
    const [starInnerRadius, setStarInnerRadius] = useState(15);
    
    const starModels = {
        models: new makerjs.models.Star(numOfSides, starOuterRadius, starInnerRadius)
    }

    return{
        starModels,
        numOfSides,
        starOuterRadius,
        starInnerRadius,
        setNumOfSides,
        setStarOuterRadius,
        setStarInnerRadius
    }

}

function OvalShape(){
    const [radiusX, setRadiusX] = useState(50);
    const [radiusY, setRadiusY] = useState(50);

    const ovalModels = {
        models: new makerjs.models.Ellipse(radiusX, radiusY)
    }

    return{
        ovalModels,
        radiusX,
        radiusY,
        setRadiusX,
        setRadiusY
    }
}

function PolygonShape(){
    const [polyNumSides, setPolyNumSides] = useState(6);
    const [polyRadius, setPolyRadius] = useState(50);
    const [offsetAngle, setOffsetAngle] = useState(0);
    const flat=false

    const polygonModels = {
        models: new makerjs.models.Polygon(polyNumSides, polyRadius, offsetAngle, flat)
    };

    return{
        polygonModels,
        polyNumSides,
        polyRadius,
        offsetAngle,
        setPolyNumSides,
        setPolyRadius,
        setOffsetAngle
    }
}

function TableShape(){
    const [tableWidth, setTableWidth] = useState(50);
    const [tableHeight, setTableHeight] = useState(100);
    const [tableRadius, setTableRadius] = useState(5);
    const tableStyle = 0;
    const bottomless = false;

    const tableModels = {
        models: new makerjs.models.Dogbone(tableWidth, tableHeight, tableRadius, tableStyle, bottomless)
    }

    return{
        tableModels,
        tableHeight,
        tableWidth,
        tableRadius,
        tableStyle,
        setTableHeight,
        setTableWidth,
        setTableRadius
    }
}


export {
    SquareShape,
    CircleShape,
    SquareRecShape,
    DialShape,
    StarShape,
    OvalShape,
    PolygonShape,
    TableShape
}