import { useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useRef,useContext } from 'react';
import { useTheme } from '@mui/material';
import { useUserContext } from '../context/UserContext';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ManualCard from '../components/ManualCard';
import { SettingsInputAntenna } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import { Background } from '../components/CustomProps';
import { Helmet } from 'react-helmet'

function ManualQuotation(theme) {
    theme=useTheme(theme)
    const location = useLocation()
    const costingTable = JSON.parse(localStorage.getItem('costingTable'))
    const [searchParams, setSearchParams] = useSearchParams();
    var fileId=false
    const TITLE = 'CutAnything | Quotation Request'
    if (searchParams.get('fileId')!='undefined')
        { fileId=searchParams.get('fileId')}

    const imageUrl=`${process.env.REACT_APP_HOSTNAME}/`+searchParams.get('imageUrl')

    const fileName=searchParams.get('fileName')  
    const userEmail = localStorage.getItem('user')

    const {auth} = useUserContext();

    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [comments,setComments] = useState('')
    const [itemCosting,setItemCosting] = useState(0)
    const [quantity,setQuantity] = useState(1)
    const [step,setStep] =useState(0)
    console.log(auth)
    const checkValidity = () =>{
        if (itemCosting)
            {return true}
    }
    const handleSubmit = (e) => {
        console.log('CHECK: ', itemCosting,comments)
        if(checkValidity())

    {    setDisabled(true)
        setStep(1)
        
        console.log(auth.logginToken)
        
        axios.post(`${process.env.REACT_APP_HOSTNAME}/quotation/`, 
        {  
          status: 'DR',
          user_comments: comments,
        },
      {
          headers: {
              'Authorization': 'Token ' + auth.logginToken
          }
      }).then((res) => {
        console.log(res.data)
    
        upload_order(res.data.id)})
        .catch((err)=>{console.log('validity check failed: ', itemCosting,comments)})
    }
    else
        {console.log('validity check failed: ', itemCosting,comments)}
      }


    const upload_order = (quotationId) => {
        var orderData = new FormData();

        orderData.append('file', parseInt(fileId)); 
        orderData.append('costing', itemCosting);
        orderData.append('quantity', quantity);
        orderData.append('quotation', quotationId);

        axios.post(`${process.env.REACT_APP_HOSTNAME}/order/`, 
       orderData,
    {
        headers: {
            'Authorization': 'Token ' + auth.logginToken
        }
    }).then((res) => {
      console.log('Order created')
      console.log(res)
      axios.post(`${process.env.REACT_APP_HOSTNAME}/manual-quotation-email/`,
      {
        quotationId: quotationId
      },{
        headers: {
            'Authorization': 'Token ' + auth.logginToken
        }
    }).then((res) => {
      setStep(2)
  
      })
    }).catch((error)=>{
            console.log()
})
        
    }
    

switch (    step) {
        case 0: 
        return (
                // <Box sx={{display:'flex', flexDirection:'column'}}>
                <Background sx={{flexDirection:'column', pt:0}}>
                    <Helmet>
                        <title>{ TITLE }</title>
                    </Helmet>
                    <Paper width={'100%'} sx={{mb:3, pl:12, pr:12, pt:3, pb:3}} elevation={4}>

                        <Box sx={{display:'flex', bgcolor:'white', flexDirection:'row'}}>

                                <Box sx={{flexGrow:1}}>
                                    <Typography variant="manualQuotation" >Quotation Request</Typography>
                                    <Typography sx={{color:theme.palette.neutral.darkgrey1.main, mt:1}}>You will receive a quotation via email in 3 working days</Typography>
                                </Box>

                                <Button disabled={disabled} onClick={(e)=>{handleSubmit(e)}}  variant='outlined' size="large" 
                                    sx={{mt:1, mb:1, color:'white', backgroundColor: '#009AF9', borderColor:'#009AF9', borderRadius:"6px" }}  > 
                                        <Typography variant="uploadBtn">Submit Request</Typography>
                                </Button>
                            
                        </Box>

                    </Paper>

                    <Box sx={{m:12, mt:5}}>
                        {/* <Stack spacing={1} sx={{mt:0, mb:1, color:'#009AF9', backgroundColor: `${theme.palette.neutral.lightgrey2.main}`, borderColor:'#009AF9',width:"100%"}} > */}
                        <ManualCard fileName={fileName} imageUrl={imageUrl} costingTable={costingTable} setItemCosting={(e)=>setItemCosting(e)} setQuantity={(e)=>setQuantity(e)} setComments={(e)=>setComments(e)} /> 
                        {/* </Stack> */}
                    </Box>
                </Background>
                // </Box>
                
    )   
        case 1:
            (                <Stack direction='row'>
            <Stack width={'100%'} sx={{ display: 'flex', 
                bgcolor: `${theme.palette.neutral.lightgrey2.main}`,

    
                mt:1, 
                }}>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>

                <Grid container spacing={1} sx={{m:0, bgcolor:'white'}}
                    direction='row'>

                    <Grid item xs={6} sx={{p:1}} >
                        <Stack sx={{ml:22}}>
                            <Typography variant="manualQuotation"  sx={{textAlign:"left"}}>Quotation Request</Typography>
                            <Typography sx={{textAlign:"left"}} >You will receive a quotation via email in 3 working days</Typography>
                        </Stack>

                    </Grid>

                    <Grid item xs={6} sx={{p:1}} >
                            <Button disabled={disabled} onClick={(e)=>{handleSubmit(e)}}  variant='outlined' size="large" 
                            sx={{mt:1, mb:1, color:'white', backgroundColor: '#009AF9', borderColor:'#009AF9', borderRadius:"6px" }}  > 
                                <Typography variant="uploadBtn">Submit Request</Typography>
                            </Button>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12} sx={{m:22,mt:2}}>
                        <Stack spacing={1} sx={{mt:0, mb:1, color:'#009AF9', backgroundColor: `${theme.palette.neutral.lightgrey2.main}`, borderColor:'#009AF9',width:"100%"}} >
                        <Typography variant="h2" sx={{m:5}} >Submitting Request...</Typography>

                            
                        </Stack>
                </Grid>
            </Stack>
        </Stack>)
        case 2:
            return (<Stack direction='row'>
            <Stack width={'100%'} sx={{ display: 'flex', 
                bgcolor: `${theme.palette.neutral.lightgrey2.main}`,   
                mt:1, 
                alignItems:"center"
                }}>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <CheckCircleIcon sx={{height:"83px",width:"83px",color:`${theme.palette.success.main}`,m:2,mt:4}} />
                <Typography variant="manualQuotation" sx={{m:3}} >Request Submitted!</Typography>

                <Typography variant="finishManual" sx={{m:5,mb:0}} >We have received your request. </Typography>
                <Typography variant="finishManual" sx={{mt:0}} >Quotation will be sent to you within 3 working days to your email:</Typography>
                <Typography variant="uploadBtn" sx={{m:2}} >{userEmail}</Typography>
                <Button onClick={(e)=>navigate("/quotation")}  variant="contained" component="span"sx={{m:3,mb:10, color:"white", bgcolor:'#009AF9'}}  size="large">
                <Typography variant="uploadBtn" sx={{m:1}}> Back to Homepage </Typography> </Button>
            </Stack>
        </Stack>
        )
    ;}

}

export default ManualQuotation;


