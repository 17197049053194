import React from 'react';
import {Background} from '../../../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';

function ReturnPolicy(props, theme) {

    theme=useTheme(theme)

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
              <title>CutAnything | Return Policy</title>
              <meta name='description' content="Find out more about our return policies. Do contact us to allow us to know the reason for returning of products."/>
              <link rel='canonical' href='/policy/returns'/>
            </Helmet>

            <Typography variant="title">Return Policy</Typography>
            
        </Background>
    );
}

export default ReturnPolicy;