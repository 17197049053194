import {useState, useContext} from 'react';
import { useUserContext } from '../../context/UserContext';
import { useNavigate } from "react-router-dom";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbarQuickFilter
    } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import IconButton from '@mui/material/IconButton';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';


//to customise the bottom right footer
function labelDisplayRows({from, to, count}){
    return(<span>Showing {from} to {to} of {count} entries</span>)
}

//this function is for the footer customisation
function CustomPagination(props) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(2);

    const handleChangePage = (event, newPage) => {
        setSelectedPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        // setPage(0);
    };

    return (
        <Box sx={{display:'flex'}} pb={2} >
            {/* bottom left footer  */}
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                sx={{pt:1, flexGrow:3 }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      color: '#778CA2',
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        color: "#009AF9",
                      },
                      "& .MuiSvgIcon-root":{
                        color:'#009AF9'
                      }
                    }}
                  />
                )}
            />

            {/* bottom right footer */}
            <TablePagination
                component="div"
                count={props.rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={(page) => labelDisplayRows(page)}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                rowsPerPageOptions={[]}
                sx={{color:'#778CA2'}}
            />
        </Box>
    );
}

function Actions(params){

    const {auth} = useUserContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [downloadButtonWord, setDownloadButtonWord] = useState()
    const status = params.params.row.status;

    const download = () => {
        console.log(params.params)
        setDownloadButtonWord("Downloading...")
        setLoading(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/generate_invoice/${params.params.row.id}`, 
        {
            headers: {
                'Authorization': 'Token ' + auth.logginToken
            },
            responseType: 'blob'
        }).then((res) => {
            console.log(res)

            const new_blob = new Blob( [ res.data ], { type: "application/octetstream" } );
            const url = URL.createObjectURL( new_blob );
            const link = document.createElement('a');
            
            link.href = url;
            if(params.params.row.status === 'PP' || params.params.row.status === 'Payment Pending'){
                link.setAttribute('download', `CutAnything_Quotation-${params.params.row.serial_number}.html`); //or any other extension
            }else{
                link.setAttribute('download', `CutAnything_Invoice-${params.params.row.serial_number}.html`); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
            setDownloadButtonWord()
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setDownloadButtonWord()
            setLoading(false)
        })
    }

    const payment = () =>{
        console.log(params.params.row.id)
        localStorage.setItem('currentQuotation', params.params.row.id)
        navigate('/delivery')

    }

    return(
        status === 'Payment Pending'
        ? 
        <Stack direction="row" justifyContent='center'>
            <IconButton 
                aria-label="delete" 
                variant="contained"
                onClick={payment}
            >
                <CreditCardOutlinedIcon color='primary'/>
            </IconButton>
            <LoadingButton
                loading={loading}
                onClick={download}
                // variant="outlined"
                loadingPosition="start"
                startIcon={<RemoveRedEyeOutlinedIcon/>}
                sx={{borderRadius:5}}
                >
                {downloadButtonWord}
            </LoadingButton>
        </Stack>
        :
            <LoadingButton
                loading={loading}
                onClick={download}
                // variant="outlined"
                loadingPosition="start"
                startIcon={<RemoveRedEyeOutlinedIcon/>}
                sx={{borderRadius:5}}
                >
                {downloadButtonWord}
            </LoadingButton>
    )
}

//this function helps with the status colours
function statusStyle(values){

    let status_style = { paddingLeft:20, 
                            paddingRight:20, 
                            paddingTop:5, 
                            paddingBottom:5, 
                            color:'white',
                            borderRadius:4
                        }

    if(values.row.status === 'Completed'){
        return ({...status_style, backgroundColor:'#182869'})
    }else if(values.row.status === 'PP' || values.row.status === 'Payment Pending' ){
        return({...status_style, backgroundColor: '#F293AC'})
    }else if(values.row.status === 'Payment Received'){
        return({...status_style, backgroundColor: '#FFE192'})
    }else if(values.row.status === 'Ready for Collection' || values.row.status === 'On Delivery'){
        return({...status_style, backgroundColor: '#28D7A9'})
    }else{
        return({color: "#00B283"})
    }
}

//when there is no order (row) data
function NoRowsOverlay() {
    return (
    <Stack height="100%" alignItems="center" justifyContent="center" sx={{color:'#778CA2'}}>
        No orders yet!
    </Stack>
    );
}

function getFullInvoice(params){
    if(params.row.status === 'PP' || params.row.status === 'Payment Pending' || params.row.status === "Quotation Ready"){
        return(`QUOT-${params.row.serial_number}`)
    }else if(params.row.status === "Pending Quotation"){
        return(`PO-${params.row.serial_number}`)
    }else{
        return(`INV-${params.row.serial_number}`)
    }
    
}

//to add "$" infront of the cost value
function getCostValue(params){

    if(params.row.cost === null){
        return("Pending")
    }else{
        return(`$${(params.row.cost).toFixed(2)}`)
    }
    
}

//to return the date as a DateTime data type
function getDate(params){

    return(new Date(params.row.order_date))
}

//to format the date as DD/MM/YYYY
function formatDate(params){
    let d = moment(params.value)

    return(d.format("DD/MM/YYYY"))
}

//to render a delete button on the datagrid
function Delete(props, theme){

    theme=useTheme(theme)

    const openBackdrop = () =>{
        console.log(props.params)
        props.setDeleteOrderDetails(props.params)
        props.open(true)
    }

    if(props.params.row.status === 'PP' || props.params.row.status === 'Payment Pending'){
        return(
            <Button
            startIcon={<CloseIcon sx={{color:theme.palette.error.main}}/>}
            onClick={openBackdrop}
            variant='outlined'
            sx={
                {
                    color:theme.palette.error.main, 
                    borderRadius:5, 
                    borderColor:theme.palette.error.main, 
                    ml:2,
                    '&:hover': {
                        backgroundColor: theme.palette.error.light,
                        borderColor: theme.palette.error.main,
                    },
                }
            }
            >
                Cancel
            </Button>
        )
    }else{
        return(<></>)
    }

}

//for the search toolbar 
function QuickSearchToolbar(theme) {
    theme=useTheme(theme)
    return (
      <Box
        sx={{
          display:'flex',
          justifyContent: 'flex-start',
          p:2,
          boder:'1px solid',
          borderColor:'black',
          
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
}

//for data checking
function FilterData(data, fromDate, toDate){

    //if there is no data, then return empty list
    if(!data){
        return []
    //if there is no date filter, then return the orginial data
    }else if(fromDate === null && toDate === null){
        return data
        
    //if there toDate value is null, then return data that has bigger dates than the toDate
    }else if(toDate === null){

        let filtered_output_data = []

        for(let i=0; i<data.length; i++){
            //this is to convert the date into ISO time format. Followed by spliting it to get "yyyy-mm-dd" format.
            //after getting the above format, using the getTime() to convert to milliseconds
            //using this milliseconds, we will convert which date is smaller/bigger
            let fromDate_converted = new Date(fromDate.toISOString().split('T')[0]).getTime();
            let order_date = new Date(data[i].order_date.split('T')[0].toString()).getTime();

            if(order_date >= fromDate_converted){
                filtered_output_data.push(data[i])
            }
        }
        return filtered_output_data

    //if fromDate is null, there return data that has smaller dates than the toDate
    }else if(fromDate === null){

        let filtered_output_data = []

        for(let i=0; i<data.length; i++){
            //this is to convert the date into ISO time format. Followed by spliting it to get "yyyy-mm-dd" format.
            //after getting the above format, using the getTime() to convert to milliseconds
            //using this milliseconds, we will convert which date is smaller/bigger
            let order_date = new Date(data[i].order_date.split('T')[0].toString()).getTime();
            let toDate_converted = new Date(toDate.toISOString().split('T')[0]).getTime();

            if(order_date <= toDate_converted){
                filtered_output_data.push(data[i])
            }
        }
        return filtered_output_data

    //if both fromDate and toDate has values, then return data that has dates between the fromDate and toDate
    }else if(fromDate != null && toDate != null){

        let filtered_output_data = []

        for(let i=0; i<data.length; i++){
            // console.log("-----------------------------------------------------")
            // console.log(new Date(data[i].order_date.split('T')[0]))
            // console.log(new Date(fromDate))
            // console.log(toDate)
            // console.log(new Date(data[i].order_date.split('T')[0]).getTime() >= new Date(fromDate.toISOString().split('T')[0]).getTime())

            //this is to convert the date into ISO time format. Followed by spliting it to get "yyyy-mm-dd" format.
            //after getting the above format, using the getTime() to convert to milliseconds
            //using this milliseconds, we will convert which date is smaller/bigger
            let fromDate_converted = new Date(fromDate.toISOString().split('T')[0]).getTime();
            let toDate_converted = new Date(toDate.toISOString().split('T')[0]).getTime();
            let order_date = new Date(data[i].order_date.split('T')[0]).getTime();
    
            if(order_date >= fromDate_converted && order_date <= toDate_converted){
                filtered_output_data.push(data[i])
            }
        }
        return filtered_output_data

    }
}

 
export default function DataGridOrderAllOrders(props, theme) {

    console.log(props.rowData)

    theme=useTheme(theme)
    const {auth} = useUserContext();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [deleteOrderDetails, setDeleteOrderDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    // const rows = !props.rowData?[]:props.rowData;
    const rows = FilterData(props.rowData, props.fromDate, props.toDate)
    const columns = [
    { 
        field: 'invoice', 
        headerName: 'Invoice no.', 
        minWidth: 200,
        flex:1,
        color: "red",
        paddingLeft:2,
        headerAlign: 'center',
        valueGetter: getFullInvoice,
        cellClassName: (params) =>{
            if(params.value.split('-')[0] === "INV"){
                return("InvoiceINV")
            }else if(params.value.split('-')[0] === "QUOT"){
                return("InvoiceQUOT")
            }else if(params.value.split('-')[0] === "PO"){
                return("InvoiceQO")
            }
        }
    },
    {
        field: 'date_of_order',
        headerName: 'Order Date',
        type: 'dateTime',
        minWidth: 200,
        flex:0.6,
        editable: false,
        headerAlign: 'center',
        valueGetter: getDate,
        valueFormatter: formatDate,
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex:1,
        editable: false,
        headerAlign: 'center',
        cellClassName: (params) => {
            if(params.row.status === "Payment Pending" || params.row.status === "PP"){
                return ("PendingPayment")
            }else if(params.row.status === "Pending Quotation"){
                return("PendingQuotation")
            }else if(params.row.status === "Quotation Ready"){
                return("QuotationReady")
            }else if(params.row.status === "Payment Received"){
                return("InProduction")
            }else if(params.row.status === "Ready for Collection" || params.row.status === "On Delivery"){
                return("ReadyForCollection")
            }else if(params.row.status === "Completed"){
                return("OrderCompleted")
            }
        }   
    },
    {
        field: 'cost',
        headerName: 'Cost',
        minWidth: 200,
        flex:1,
        editable: false,
        headerAlign: 'center',
        valueGetter: getCostValue,
    },
    {
        field: 'delivery',
        headerName: 'Collection Method',
        minWidth: 200,
        flex:1,
        editable: false,
        headerAlign: 'center',
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex:0.7,
        editable: false,
        headerAlign: 'center',
        //to add the download button to every cell
        renderCell: (params)=><Actions params={params}/>
    }
    ];

    //to delete the order
    const deleteOrder = () => {

        console.log(deleteOrderDetails)
        setLoading(true)

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/quotation/${deleteOrderDetails.params.row.id}/`, 
        {
            'status': "Cancelled",
            'delivery': deleteOrderDetails.row.delivery,
            'serial_number': deleteOrderDetails.row.serial_number
        },
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res) => {
            console.log(res)
            console.log("deleted")
            setLoading(false)
            setSuccess(true)
            setOpen(false)
            props.setRowData((previousRow) => previousRow.filter((row) => deleteOrderDetails.id !== row.id ))

        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
        
    }

    return (
        
        <div style={{ height: 85*rowsPerPage , width: '100%' }}>
            <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            rowHeight={65}
            rowLength={rowsPerPage}
            sx={
                { 
                    bgcolor:'white',
                    border:0,
                    mr:2,
                    ml:2,
                    pl:2,
                    pr:2,
                    overflowX: 'scroll',
                    "& .MuiDataGrid-columnHeaders": {
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        paddingRight:3
                    },
                    "& .MuiDataGrid-cell":{
                        borderBottom:'0px',
                        justifyContent:'center'
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight":{
                        visibility:'hidden'
                    },
                    "& .InvoiceINV":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#009AF9"
                    },
                    "& .InvoiceQUOT":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#778CA2"
                    },
                    "& .InvoicePO":{
                        backgroundColor: '#F2FAFF',
                        color: 'Black',
                        borderLeft: '4px solid',
                        borderColor: "#DC3233"
                    },
                    "& .PendingPayment":{
                        backgroundColor: '#F293AC',
                        color: 'white',
                    },
                    "& .PendingQuotation":{
                        backgroundColor: '#F8A86E',
                        color: 'white',
                    },
                    "& .QuotationReady":{
                        backgroundColor: '#87C7EE',
                        color: 'white',
                    },
                    "& .InProduction":{
                        backgroundColor: '#FFE192',
                        color: 'white',
                    },
                    "& .ReadyForCollection":{
                        backgroundColor: '#28D7A9',
                        color: 'white',
                    },
                    "& .OrderCompleted":{
                        backgroundColor: '#182869',
                        color: 'white',
                    },
                    
                }
            }
            components={{
                NoRowsOverlay,
                Footer: CustomPagination,
                // Toolbar: QuickSearchToolbar,
            }}
            componentsProps={{
                footer: { rows }
            }}
            filterModel={{
                items:props.searchValue
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'date_of_order', sort: 'desc' }],
                }
            }}
            />
            <Backdrop
            open={open}
            >
                <Paper elevation={3}>
                    {loading
                    ?
                    <Box sx={{display:'flex', flexDirection: 'column', p:10, justifyContent: 'center', alignItems: 'center'}}>
                        <Fab>
                            {success?<DoneIcon/>:<DeleteOutlineOutlinedIcon/>}
                            {loading
                            ?   
                                <Stack direction='row'>
                                    <CircularProgress
                                        size={60}
                                        sx={{
                                            color:theme.palette.error.main,
                                            position: 'absolute',
                                            top: -4,
                                            left: -2,
                                        }}
                                    />
                                </Stack>
                            :
                                <></>}
                        </Fab>
                        <br/>
                        <Typography variant='body1' sx={{color:'black'}}>Deleting in progress...</Typography>
                    </Box>
                    :
                    <Box sx={{display:'flex', flexDirection: 'column', p:10, justifyContent: 'center', alignItems: 'center'}}>
                        <DeleteOutlineOutlinedIcon
                            fontSize='large'
                            sx={
                                {
                                    color:theme.palette.error.main,
                                    border:3,
                                    borderColor:theme.palette.error.main,
                                    bgcolor:"white",
                                    borderRadius:'50%',
                                    p:5,
                                    mb:2
                                }
                            }
                        />
                        <Typography variant='body1' sx={{color:'black'}}>You are about to delete an order!</Typography>
                        <Typography variant='body1' sx={{color:theme.palette.neutral.darkgrey2.main}}>Are you sure you want to delete this order?</Typography>
                        <br/>
                        <Stack direction='row' spacing={2}>
                            <Button onClick={()=>{setOpen(false)}} variant="contained" sx={{bgcolor:theme.palette.neutral.lightgrey2.main, color:theme.palette.neutral.darkgrey1.main}}>Cancel</Button>
                            <Button variant="contained" onClick={deleteOrder} sx={{bgcolor:theme.palette.error.main, color:'white'}}>Confirm</Button>
                        </Stack>
                    </Box>
                    }

                </Paper>

                
            </Backdrop>
        </div>
    );
}
