// apiClient.js
class MyImageUploadAdapter {
    constructor(loader, apiClient) {
      this.loader = loader;
      this.apiClient = apiClient;

    }
    
  
    async upload() {
      const data = new FormData();
      data.append('upload', await this.loader.file);
  
      // Adjust the endpoint URL according to your Django API
      const response = await this.apiClient.post('/uploadImage/', data);
      return {
        default: response.data.url, // Adjust this according to the response structure from your Django API
      };
    }
  
    abort() {
      // Handle the request cancellation (if necessary)
    }
  }
  
  export default function MyImageUploadAdapterFactory(editor) {
    const apiClient = editor.config.get('myApi.client');
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      new MyImageUploadAdapter(loader, apiClient);

  }
  