import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ffcccc',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: "#FF0000",
}));

export const Background = styled(Box)(({}) => ({
    backgroundColor: "#F2F4F6",
    display:'flex',
    paddingTop:20,
    paddingBottom:10,
    width: '100%'
}));

export const DataGridBackground = styled(Box)(({}) => ({
    flexGrow:1, 
    justifyContent:'center', 
    bgcolor:'white', 
    borderRadius:3, 
    boxShadow:3, 
    pb:5, 
    pt:3, 
    minHeight:'600px'
}));

export const ResponsiveGridContainer = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        width:"100%",
        justifyContent:"Center"
        
    },
    [theme.breakpoints.up('md')]: {
        padding:80,
   
    },
    [theme.breakpoints.up('lg')]: {

    },
}));

export const TypographyChanged = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    fontWeight:'bold',
    component:'div',
    [theme.breakpoints.down('md')]: {
      textAlign:'center',
    },
    [theme.breakpoints.up('md')]: {
        textAlign:'center',
    },
    [theme.breakpoints.up('lg')]: {
        textAlign:'left',
    },
}));

export const TypographyChangedFooter = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    variant:'button',
    component:'div',
    fontWeight:'bold',
    color:theme.palette.primary.main, //#3898ec
    paddingLeft:0,
    [theme.breakpoints.down('md')]: {
      textAlign:'left',
      fontSize:14
    },
    [theme.breakpoints.up('md')]: {
        textAlign:'left',
        fontSize:16
    },
    [theme.breakpoints.up('lg')]: {
        textAlign:'left',
    },
}));

export const StackChanged = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        justifyContent:'center'
    },
    [theme.breakpoints.up('md')]: {
        justifyContent:'center'
    },
    [theme.breakpoints.up('lg')]: {
        justifyContent:'left',
    },
}));

export const LinkChanged = styled(Link)(({ theme }) => ({
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    textDecoration: 'none', // remove underline
    color:theme.palette.secondary.main,
    cursor: "pointer",
    [theme.breakpoints.down('md')]: {
     textAlign:'left',
     fontSize:10
    },
    [theme.breakpoints.up('md')]: {
        textAlign:'left',
        fontSize:12
    },
    [theme.breakpoints.up('lg')]: {
        textAlign:'left',
    },
}));


export const Address = styled(Typography)(({ theme }) => ({
    variant:'body2',
    component:'div',
    fontWeight:'bold',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
     textAlign:'center',
    },
    [theme.breakpoints.up('md')]: {
        textAlign:'center',
    },
    [theme.breakpoints.up('lg')]: {
        textAlign:'center',
    },
}));

export const HomeH2 = styled(Typography)(({ theme }) => ({
    fontFamily:"Oxygen",
    color:"#009af9",
     
    height:'80px',
    [theme.breakpoints.down('md')]: {
        fontSize:'28px',
        lineHeight: '30px',
        fontWeight:400, 
    },
    [theme.breakpoints.up('md')]: {
        fontSize:'28px',
        fontWeight:400, 
    },
    [theme.breakpoints.up('lg')]: {
        fontSize:'28px',
        fontWeight:400, 
    },
}));

export const HomeP2 = styled(Typography)(({ theme }) => ({
    fontFamily:"Nunito",

    lineHeight:"25px", 
    textTransform:'none',
    color: '#1B1E24',

    [theme.breakpoints.down('md')]: {
        fontSize:14, 
        fontWeight:400,
    },
    [theme.breakpoints.up('md')]: {
        fontSize:16, 
        fontWeight:400,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize:18, 
        fontWeight:400, 
    },
}));

export const HomeImgBox = styled(Box)(({ theme }) => ({
    fontFamily:"Nunito",

    lineHeight:"25px", 
    textTransform:'none',
    color: '#1B1E24',
    [theme.breakpoints.down('xs')]: {
        fontSize:16, 
        fontWeight:400,
    },
    [theme.breakpoints.up('md')]: {
        fontSize:18, 
        fontWeight:400,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize:20, 
        fontWeight:400, 
    },
}));

export const HomeP3 = styled(Typography)(({ theme }) => ({
    fontFamily:"Open Sans",fontWeight:400, textTransform:'none',color: '#1B1E24',

    [theme.breakpoints.down('xs')]: {
        fontSize:18, 
        fontWeight:400,
        lineHeight:"30px",
    },
    [theme.breakpoints.up('md')]: {
        fontSize:18, 
        fontWeight:400,
        lineHeight:"30px",
    },
    [theme.breakpoints.up('lg')]: {
        fontSize:18, 
        fontWeight:400, 
        lineHeight:"30px",
    },
}));


export const styledTypo = styled(Typography)(({}) => ({
    '&. accountTitle':{
        color: '#778CA2'
    }
}));