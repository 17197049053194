import { useState, useContext} from 'react';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

function ProductListingFilterBar(props, theme) {

    theme=useTheme(theme)
    const selected = props.selectedCategory;

    const FilterButton = styled(Button)((props) => ({
        //the first 2 props is based on the "selected" useState
        //for border, need to use this format "6px solid", if not, it will not appear
        borderBottom:selected === props.name?"2px solid":0,
        borderColor:selected === props.name?theme.palette.primary.main:"white",
    }));

    const filter = (name, item) => {
        //to change the title of products after filtering
        props.setSelectedCategory(name)

        if(name === "All Products"){
            props.setFilteredProductList(props.productList)
        }else{
            const output = props.productList.filter(x=> x.group === item.id)
            props.setFilteredProductList(output)
        }

    }


    return (
        <>
            <Box
                sx={{
                    display:'flex',
                    flexGrow:1,
                    justifyContent: 'center',
                    borderRight:1,
                }}

            >
                <FilterButton
                    name='All Products'
                    // sx={{
                    //     borderBottom: 1
                    // }}
                    onClick={(e)=>{filter(e.target.name)}}
                >
                    All Products
                </FilterButton>

            </Box>

            {props.productGroups.map(function(item, i){
                return(
                    <Box
                        key={i}
                        sx={{
                            display:'flex',
                            flexGrow:1,
                            justifyContent: 'center',
                            //if its the last item in the list, it will not have right border
                            borderRight:i === (props.productGroups.length - 1)? 0:1
                        }}
                    >
                        <FilterButton
                            name={item.name}
                            onClick={(e)=>{filter(e.target.name, item)}}
                        >
                           {item.name}
                        </FilterButton>
                    </Box>
                )
            })}
        </>
    );
}

export default ProductListingFilterBar;