import {useContext} from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from '../context/UserContext';

function checkToken(auth){
    console.log(auth.logginToken)
    if(auth.logginToken === "no token yet" || auth.logginToken === "token removed"){
        console.log("publicpage: true")
        return true
    }else{
        console.log("publicpage: false")
        return false
    }

}

function PublicPageCheck() {

    const {auth} = useUserContext();

    const check = checkToken(auth);


    return  <Outlet />
}

export default PublicPageCheck;