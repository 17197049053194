import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Background, ResponsiveGridContainer} from '../components/CustomProps';
import { useTheme } from '@mui/material';
import LogoPhrase from '../components/LogoPhrase';
import { Helmet } from 'react-helmet-async';

function ResetPassword(theme) {

    theme=useTheme(theme)
    const inputPassword = useRef(null);
    const inputCfmPassword = useRef(null);
    const [userId, setUserId] = useState();
    const [passwordToken, setPasswordToken] = useState();
    const [msg, setMsg] = useState('Reset Password');
    const [disabled, setDisabled] = useState(false);
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        //make sure to change the link !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // let weblink = "https://www.cutanything.co/reset-password?uid=MjU&token=e5764db990b4ab4f45496fea9d8f8376d15dc849"
        // localhost:3000/reset-password?uid=MjU&token=e5764db990b4ab4f45496fea9d8f8376d15dc849
        let weblink = window.location.href;
        if(weblink.split("?").length > 1){
            let anchor = weblink.toString().split("?")[1];
            let parsed = JSON.parse(`{"${decodeURI(anchor).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"')}"}`)
            console.log(parsed)
            setUserId(parsed.uid)
            setPasswordToken('Token ' + parsed.token)
        }
    }, [])

    const reset = () => {
        setDisabled(true)
        setMsg('Reseting...')
        axios.post(`${process.env.REACT_APP_HOSTNAME}/reset_password/`, 
        {
            uidb64: userId,
            password1: inputPassword.current.value,
            password2: inputCfmPassword.current.value,
            domain_url: window.location.host
        },
        {
            headers:{
                'Authorization': passwordToken,
                "Content-Type": "multipart/form-data" 
            }
        }).then((res) => {
            console.log(res)
            setChanged(true)

        }).catch((err) => {
            console.log(err)
            setDisabled(false)
            setMsg('Reset Password')
        })
    }


    return (
        <Background sx={{pb:10, pt:10}}>

            <Helmet>
                <title>CutAnything | Password Reset</title>                    
                <meta name='description' content="Enter your new password here."/>
                <link rel='canonical' href='/reset-password'/>
            </Helmet>

            <ResponsiveGridContainer container spacing={{
                        xs: 0,
                        sm: 0,
                        md: 2
                    }} sx={{alignItems:'center',justifyContent:'space-evenly'}}>

                <LogoPhrase/>

                <Grid item xs={12} md={12} lg={4}>
                    <Box 
                        padding={{xs: 4,sm: 8}} 
                        sx={{bgcolor: 'white',  borderRadius: 5}}
                    >
                        {changed? <Typography variant="body1" component="div"  align='center' sx={{fontWeight: 'bold'}}>Your password has been reset</Typography>
                        :
                        <>
                            <Stack spacing={2}>
                                <TextField  id="password" label="New Password" type="password" inputRef={inputPassword} required/>
                                <TextField  id="cfm_password" label="Confirm Password" type="password" inputRef={inputCfmPassword} required/>
                                <Button style={{color:'white', backgroundColor: disabled?theme.palette.neutral.darkgrey1.main:theme.palette.primary.main}} onClick={reset} disabled={disabled}>{msg}</Button>
                            </Stack>
                        </>
                        }
                    </Box>
                </Grid>
                
            </ResponsiveGridContainer>
        </Background>
    );
}

export default ResetPassword;