import React from 'react';

function Unauthorised(props) {
    return (
        <div>
            this is unauthorised page
        </div>
    );
}

export default Unauthorised;