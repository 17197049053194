import React from 'react';
import {Background} from '../../../components/CustomProps';
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';

function Others(props, theme) {

    theme=useTheme(theme)

    return (
        <Background sx={{justifyContent: 'center', alignItems: 'center', pt:5, pb:5}}>

            <Helmet>
              <title>CutAnything | Others</title>
              <meta name='description' content="In this page, you can also consult us on other materials that you have. We will always have ways for process your material for you."/>
              <link rel='canonical' href='/guides/others'/>
            </Helmet>

            <Typography variant="title">Others</Typography>
            
        </Background>
    );
}

export default Others;