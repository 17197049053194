import React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {TypographyChangedFooter, LinkChanged, Address} from '../components/CustomProps';

function Footer() {
    const navigate = useNavigate();
   
    return (
        <div className='footer'>
            <Box sx={{ bgcolor: 'white',display:'block'}} mt={2} pt={5}  >
                <Grid container spacing={{xs:0,md:0}} justifyContent={{xs:"center"}} padding={2} columns={12} pb={3} >
                    <Grid item  ml={5} mr={5} pb={3}>
                            <Stack >
                                <Box sx={{display:"flex",justifyContent:'center'}}><Box
                                    component="img"
                                    sx={{ display: {display:'flex', width:"100%", height:'100%', maxWidth:"330px",justifyContent:'center' } }}
                                    alt="cutanything_word log"
                                    src="https://global-uploads.webflow.com/6100f59c138a680259458e8b/615bd984b674160508289339_Artboard%202%20copy%2021.png"
                                /></Box>
                                
                                <Stack direction='row' spacing={5} m={3} sx={{justifyContent:'center'}}>
                                    <a href='https://www.instagram.com/cutanything.co/'><InstagramIcon /></a>
                                    <a href='https://www.facebook.com/profile.php?id=100087426408420'><FacebookIcon /></a>
                                    <a href='https://www.linkedin.com/company/cutanything/'><LinkedInIcon  /></a>
                                </Stack>
                                <Address>25 Kaki Bukit Rd 4,</Address>
                                <Address> #06-61</Address>
                                <Address>Singapore 417800</Address>
                                
                            </Stack>
                    </Grid>

                    {/* <Grid item container xs={12} md={6} spacing={2}>
                        <Grid item xs={4} md='auto' >
                            <TypographyChangedFooter>Menu</TypographyChangedFooter>
                            <Stack spacing={2} mt={2} mb={2} textAlign='left'>
                                <LinkChanged onClick={() => {navigate("/about")}}>About</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/quotation")}}>Instant Quote</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/")}}>Other Services</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/contact-us")}}>Contact</LinkChanged>
                            </Stack>
                        </Grid>

                        <Grid item xs={4} md='auto' >
                            <TypographyChangedFooter>Capabilities</TypographyChangedFooter>
                            <Stack spacing={2} mt={2} mb={2} textAlign='left'>
                                <LinkChanged onClick={() => {navigate("/capabilities/fiberlaser")}}>Fiber Laser</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/capabilities/sixaxis")}}>6 Axis Gantry Router</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/capabilities/co2laser")}}>CO2 Laser</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/capabilities/waterjet")}}>Water Jet</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/capabilities/metaltubing")}}>Metal Tubing Cutting</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/capabilities/spindlerouting")}}>Spindle Routing</LinkChanged>
                            </Stack>
                        </Grid>

                        <Grid item xs={4} md='auto' >
                            <TypographyChangedFooter>Material Guides</TypographyChangedFooter>
                            <Stack spacing={2} mt={2} mb={2} >
                                <LinkChanged onClick={() => {navigate("/guides/metal")}}>Metal</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/guides/plastic")}}>Plastic</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/guides/wood")}}>Wood</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/guides/others")}}>Others</LinkChanged>
                                
                            </Stack>
                        </Grid>

                        <Grid item xs={4} md='auto' >
                        <TypographyChangedFooter>Knowledge Base</TypographyChangedFooter>
                            <Stack spacing={2} mt={2} mb={2} >
                                <LinkChanged onClick={() => {navigate("/guides/mfg")}}>Design for Manufacturability Guide</LinkChanged>
                            </Stack>
                        </Grid> */}

                        {/* <Grid item md='auto' >
                        <TypographyChangedFooter>Policy Information</TypographyChangedFooter>
                            <Stack spacing={2} mt={2} mb={2} >
                                <LinkChanged onClick={() => {navigate("/policy/returns")}}>Returns & Exchanges</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/policy/delivery")}}>Delivery Policies</LinkChanged>
                                <LinkChanged onClick={() => {navigate("/policy/termsofuse")}}>Terms of use</LinkChanged>
                            </Stack>
                        </Grid> */}
                        {/* <Grid item xs={5} md={5} />
                    </Grid> */}
                </Grid>
            </Box>
        </div>
    );
}

export default Footer;