import {useState, useEffect} from 'react';
import axios from 'axios';
import { useUserContext } from '../context/UserContext';
import {Background} from '../components/CustomProps';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import AdminControlSideBar from '../components/AdminControlSideBar';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
//to import all the datagrids
import DataGridAdmin from '../components/DataGrids/DataGridAdmin';
import DataGridUser from '../components/DataGrids/DataGridUser';
import DataGridCost from '../components/DataGrids/DataGridCost';
import DataGridCategory from '../components/DataGrids/DataGridCategory';
import DataGridDiscount from '../components/DataGrids/DataGridDiscount';
//to import all the backdrops
import AddMaterialBackprop from '../components/Backdrops/AddMaterialBackprop';
import UpdateMaterialBackdrop from '../components/Backdrops/UpdateMaterialBackdrop';
import DeleteMaterialBackdrop from '../components/Backdrops/DeleteMaterialBackdrop';
import DeleteMultipleMaterialBackdrop from '../components/Backdrops/DeleteMultipleMaterialBackdrop';
import AddCategoryBackdrop from '../components/Backdrops/AddCategoryBackdrop';
import UpdateCategoryBackdrop from '../components/Backdrops/UpdateCategoryBackdrop';
import DeleteCategoryBackdrop from '../components/Backdrops/DeleteCategoryBackdrop';
import AddDiscountBackdrop from '../components/Backdrops/AddDiscountBackdrop';
import UpdateDiscountBackdrop from '../components/Backdrops/UpdateDiscountBackdrop';
import DeleteDiscountBackdrop from '../components/Backdrops/DeleteDiscountBackdrop';
import DeleteMultipleCategoryBackdrop from '../components/Backdrops/DeleteMultipleCategoryBackdrop';
import DeleteMultipleDiscountBackdrop from '../components/Backdrops/DeleteMultipleDiscountBackdrop';
//to import for product management
import AddNewProduct from '../components/AdminProduct/AddNewProduct';
import AddNewCategories from '../components/AdminProduct/AddNewCategories';
import CurrentProducts from '../components/AdminProduct/CurrentProducts';

function AdminControl(props, theme) {

    theme=useTheme(theme)
    const {auth} = useUserContext();

    const [success, setSuccess] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [displayItem, setDisplayItem] = useState('');
    const [title, setTitle] = useState('All Quotation');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    //for order data
    const [orderData, setOrderData] = useState({});
    const [allOrderData, setAllOrderData] = useState();

    //for user data
    const [dataGridUser, setGridUserData] = useState([]);

    //for cost table
    const [dataCostTable, setDataCostTable] = useState([]);

    //for category table
    const [categoryTable, setCategoryTable] = useState([]);
    const [categoryColor, setCategoryColor] = useState({});

    //for discount table
    const [discountTable, setDiscountTable] = useState([]);

    //for loading the API
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [costTableLoading, setCostTableLoading] = useState(false);
    const [categoryTableLoading, setCategoryTableLoading] = useState(false);
    const [discountLoading, setDiscountLoading] = useState(false);

    //for the backdrops
    //backdrop for cost table
    const [addBackdrop, setAddBackdrop] = useState(false);
    const [updateBackdrop, setUpdateBackdrop] = useState(false);
    const [deleteBackdrop, setDeleteBackdrop] = useState(false);
    const [deleteMultipleBackdrop, setDeleteMultipleBackdrop] = useState(false);
    //backdrop for category
    const [addCategoryBackdrop, setAddCategoryBackdrop] = useState(null);
    const [updateCategoryBackdrop, setUpdateCategoryBackdrop] = useState(false);
    const [deleteCategoryBackdrop, setDeleteCategoryBackdrop] = useState(false);
    const [deleteMultipleCategoryBackdrop, setDeleteMultipleCategoryBackdrop] = useState(false);
    //backdrop for discount
    const [addDiscountBackdrop, setAddDiscountBackdrop] = useState(false);
    const [updateDiscountBackdrop, setUpdateDiscountBackdrop] = useState(false);
    const [deleteDiscountBackdrop, setDeleteDiscountBackdrop] = useState(false);
    const [deleteMultipleDiscountBackdrop, setDeleteMultipleDiscountBackdrop] = useState(false);

    //this is the information that the user wants to update or delete
    const [updateMaterialInfo, setUpdateMaterialInfo] = useState(null);
    const [deleteMaterialInfo, setDeleteMaterialInfo] = useState(null);
    const [updateCategoryInfo, setUpdateCategoryInfo] = useState(null);
    const [deleteCategoryInfo, setDeleteCategoryInfo] = useState(null);
    const [updateDiscountInfo, setUpdateDiscountInfo] = useState(null);
    const [deleteDiscountInfo, setDeleteDiscountInfo] = useState(null);
    
    //for the delete items from cost table
    const [numOfItemSelected, setNumOfItemSelected] = useState(0);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    //for the delete items from category table
    const [numOfCategorySelected, setNumOfCategorySelected] = useState(0);
    const [rowCategorySelectionModel, setRowCategorySelectionModel] = useState([]);

    //for the detele items from discount table
    const [numOfDiscountSelected, setNumOfDiscountSelected] = useState(0);
    const [rowDiscountSelectedModel, setRowDiscountSelectedModel] = useState([]);
    
    //for product management
    const [productList, setProductList] = useState([]);
    const [productGroups, setProductGroups] = useState([]);

    //for the search bars
    // const [email, setEmail] = useState('');
    // const [invoice, setInvoice] = useState('');
    // const [material, setMaterial] = useState('');
    // const [searchValue, setSearchValue] = useState([
    //                                         {
    //                                         columnField: "quotation_number",
    //                                         operatorValue: "contains",
    //                                         value: null
    //                                         }
    // ]);

    //to handle the backdrop
    const handleBackdrop = (value) => {
        setAddBackdrop(value)
    }

    //to retrieve the order data
    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/quotation/admin_list/`,
        {
            headers:{
                'Authorization': "Token " + auth.logginToken
            }
        }).then((res) => {
            // console.log(res.data)

            let orderData = {
                                'Completed': [],
                                'Payment Pending': [], 
                                'Payment Received': [],
                                'Ready for Collection': [],
                                'On Delivery': [],
                                'Cancelled': []
                            }

            for(let i of res.data){
                if(i.status === 'Payment Pending' || i.status === 'PP'){
                    orderData['Payment Pending'].push(i)
                }else if(i.status === 'Completed'){
                    orderData['Completed'].push(i)
                }else if(i.status === 'Payment Received'){
                    orderData['Payment Received'].push(i)
                }else if(i.status === 'Ready for Collection'){
                    orderData['Ready for Collection'].push(i)
                }else if(i.status === 'On Delivery'){
                    orderData['On Delivery'].push(i)
                }else if(i.status === 'Cancelled' || i.status === 'Canceled'){
                    orderData['Cancelled'].push(i)
                }
            }
            
            setAllOrderData(res.data)
            setOrderData(orderData)
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)

        })
    }, [auth.logginToken])

    //to retrieve the customer data
    useEffect(() => {
        setUserLoading(true)

        axios.get(`${process.env.REACT_APP_HOSTNAME}/users/`,
        {
            headers:{
                'Authorization': "Token " + auth.logginToken
            }
        }).then((res)=>{
            // console.log(res)
            console.log(res.data)


            const userData = res.data.map(item => {
                return{
                    id: item.email,
                    ...item
                }
            })
            //console.log(userData)
            setGridUserData(userData)
            setUserLoading(false)
        }).catch((err)=>{
            console.log(err)
            setUserLoading(false)
        })
    }, [auth.logginToken])

    //to retrieve the costing table
    useEffect(() => {
        setCostTableLoading(true);
        axios.get(`${process.env.REACT_APP_HOSTNAME}/get-cost-table/`,
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res) => {
            console.log(res.data.data)
            
            setDataCostTable(res.data.data)
            setUpdateMaterialInfo(res.data.data[0])
            setCostTableLoading(false)

        }).catch((err) => {
            console.log(err)
            setCostTableLoading(false)
        })
    }, [auth.logginToken])

    //to retrieve the Category table
    useEffect(() => {
        setCategoryTable(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/get-category-table/`,
        {
            headers: {
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res)=>{

            console.log(res)

            const output = []
            const category_color = {}

            for(let i in res.data){
                output.push(res.data[i])
                category_color[res.data[i].category_name] = res.data[i].color
            }
            setCategoryColor(category_color)
            setCategoryTable(output)
            setCategoryTableLoading(false)
        }).catch((err)=>{
            console.log(err)
            setCategoryTableLoading(false)
        })
    }, [auth.logginToken])

    //to retrieve the discount table
    useEffect(()=>{
        setDiscountLoading(true)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/discount_settings/`,
        {
            headers: {
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res) => {
            // console.log(res)
            console.log(res.data.data)
            const data = res.data.data
            const output = []

            for(const i in data){
                const obj = data[i]
                // console.log(obj)
                output.push(obj)
            }

            // console.log(output)
            setDiscountTable(output)
            setDiscountLoading(false)
      
        }).catch((err)=>{
            console.log(err)
            setDiscountLoading(false)
        })
    }, [auth.logginToken])

    //to retrieve the products for product management
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_HOSTNAME}/products/`)
        .then((res)=>{

            console.log(res)
            setProductList(res.data)
            setLoading(false)

        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
    }, [])

    //to retrieve the product groups for product management 
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_HOSTNAME}/productgroups/`)
        .then((res)=>{
            
            console.log(res.data)
            setProductGroups(res.data)
            setLoading(false)

        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
    }, [])

    //when user used the invoice filtering textfield
    //https://stackoverflow.com/questions/73200492/how-to-filter-datagrid-columns-on-click-of-an-external-button-in-mui
    // const searchData = (fieldName, event) =>{
    //     setSearchValue([
    //             {
    //               columnField: fieldName,
    //               operatorValue: "contains",
    //               value: event.target.value
    //             }
              
    //         ])
    //     // console.log(searchValue)
    // }

    //to reset the date filter
    const resetDateFilter = () =>{
        setFromDate(null)
        setToDate(null)
    }

    const Delete = (backdropTitle) =>{
        // console.log(rowSelectionModel)
        if(backdropTitle === "cost"){
            setDeleteMultipleBackdrop(true)
        }else if(backdropTitle === "category"){
            setDeleteMultipleCategoryBackdrop(true)
        }else if(backdropTitle === "discount"){
            setDeleteMultipleDiscountBackdrop(true)
        }

    }

    //to display the datagrid
    useEffect(() => {

        //to reset the multiple material delete button
        setNumOfItemSelected(0)
        setNumOfCategorySelected(0)
        setNumOfDiscountSelected(0)
        setRowSelectionModel([])
        setRowCategorySelectionModel([])
        setRowDiscountSelectedModel([])

        if(title === 'All Quotation'){
            setDisplayItem(
                <Box>
                    {/* https://stackoverflow.com/questions/29149169/how-to-loop-and-render-elements-in-react-js-without-an-array-of-objects-to-map
                    {orderData && Object.keys(orderData).map(function(key, index){
                        return(
                            <Box key={index} sx={{borderBottom:10, borderColor:'#F2F4F6'}}>
                                <Typography variant='h5' sx={{color:theme.palette.neutral.darkgrey1.main, mb:5, mt:3, textAlign:'center'}}>{key}</Typography>
                                <DataGridAdmin rowData={orderData[key]} setSuccess={setSuccess} setShowMessage={setShowMessage}/>
                            </Box>
                        )
                    }.bind(this))} */}
                    <Box sx={{borderBottom:10, borderColor:'#F2F4F6'}}>
                        <DataGridAdmin 
                            rowData={allOrderData} 
                            fromDate={fromDate} 
                            toDate={toDate} 
                            setSuccess={setSuccess} 
                            setShowMessage={setShowMessage}
                        />
                    </Box>
                </Box>
            )
        }else if(title === 'Users'){
            setDisplayItem(
                <>
                    <DataGridUser rowData={dataGridUser} />
                </>
            )
        }else if(title === 'Cost Table'){
            setDisplayItem(
                <>
                    <DataGridCost
                        //this is to give all the data of the cost table to the backdrop so that it can be updated after an update/delete function
                        rowData={dataCostTable}
                        setDataCostTable={setDataCostTable}
                        //this is when user open/close the backdrop for update/delete
                        setUpdateBackdrop={setUpdateBackdrop}
                        setDeleteBackdrop={setDeleteBackdrop}
                        //this is to give the information of the selected items to the backdrop
                        setUpdateMaterialInfo={setUpdateMaterialInfo}
                        setDeleteMaterialInfo={setDeleteMaterialInfo}
                        //this is to count the number of selected checkedbox. When number is more than 0, it will show the delete button
                        setNumOfItemSelected={setNumOfItemSelected}
                        setRowSelectionModel={setRowSelectionModel}
                        //this is for the category colors
                        categoryColor={categoryColor}
                    />
                </>
            )
        }else if(title === 'Category Table'){
            setDisplayItem(
                <>
                    <DataGridCategory
                        rowData={categoryTable}
                        //this is to open the update category backdrop
                        setUpdateCategoryBackdrop={setUpdateCategoryBackdrop}
                        setDeleteCategoryBackdrop={setDeleteCategoryBackdrop}
                        //this is to pass the information that user has selected that they want to update/delete
                        setUpdateCategoryInfo={setUpdateCategoryInfo}
                        setDeleteCategoryInfo={setDeleteCategoryInfo}
                        //this is to count the number of selected checkedbox. When number is more than 0, it will show the delete button
                        setNumOfCategorySelected={setNumOfCategorySelected}
                        setRowCategorySelectionModel={setRowCategorySelectionModel}
                    />
                </>
            )
        }else if(title === 'Discount Table'){
            setDisplayItem(
                <>
                    <DataGridDiscount
                        rowData={discountTable}
                        //this is to pass the nformation that user has selected that they want to update/delete
                        setUpdateDiscountBackdrop={setUpdateDiscountBackdrop}
                        setUpdateDiscountInfo={setUpdateDiscountInfo}
                        //this is to pass the information that user has selected that they want to update/delete
                        setDeleteDiscountBackdrop={setDeleteDiscountBackdrop}
                        setDeleteDiscountInfo={setDeleteDiscountInfo}
                        //this is to count the number of selected checkedbox. When number is more than 0, it will show the delete button
                        setNumOfDiscountSelected={setNumOfDiscountSelected}
                        setRowDiscountSelectedModel={setRowDiscountSelectedModel}
                    />
                </>
            )
        }else if(title === 'Current Products'){
            setDisplayItem(
                <>
                    <CurrentProducts 
                        productList={productList}
                        productGroups={productGroups}
                        setProductList={setProductList}
                        setProductGroups={setProductGroups}
                    />
                </>
            )
        }else if(title === 'Add Categories'){
            setDisplayItem(
                <>
                    <AddNewCategories />
                </>
            )
        }else if(title === 'Add Products'){
            setDisplayItem(
                <AddNewProduct 
                    productGroups={productGroups}
                />
            )
        }else{
            // this is for the other datagrid such as "Completed Orders, Pending Quotation, Payment Receieved, Ready for Collection, On Delivery, Canceled"
            setDisplayItem(
                <>
                    <DataGridAdmin 
                        rowData={orderData[title]} 
                        fromDate={fromDate} 
                        toDate={toDate} 
                        setSuccess={setSuccess} 
                        setShowMessage={setShowMessage}
                    />
                </>
            )
        }

    }, [title, orderData, allOrderData, dataGridUser, dataCostTable, categoryTable, discountTable, fromDate, toDate, categoryColor, productGroups, productList])

    return (
        <Background sx={{pb:3, flexDirection:'column', backgroundColor:'white'}}>

            <Helmet>
              <title>CutAnything | Admin Control</title>
            </Helmet>

            <Typography variant="h4" sx={{ml:10, mb:3}}>
                Admin Control
            </Typography>

            {/* to display the side bar */}
            <Box sx={{display:'flex', flexDirection:'row', flexGrow:1}}>
                <Box sx={
                        {
                            bgcolor:'white', 
                            borderRadius:1, 
                            // boxShadow:3, 
                            minHeight:'450px',
                            ml:1,
                            mr:0,
                            border: '1px solid',
                            borderColor:'#D9D9D9'
                        }
                    }>

                        <AdminControlSideBar setTitle={setTitle}/>
                    
                </Box>
                

                <Box sx={{flexGrow:1, ml:0, mr:2}}>
                    <Box sx={{display:'flex', border:'1px solid', borderColor:'#D9D9D9', mb:2}}> 
                        {/* 
                        the search bar will change according the title
                        - it will check if title === 'Users',
                        - if not, it will check if title === 'Cost Table',
                        - if not, it will show search bar for the orders 
                        */}
                        {title === 'Users'
                        ?
                            //this is for the Users search bar    
                            // <>
                            //     <Box sx={{flexGrow:1}}> 
                            //         <TextField
                            //             id="searchUserEmail"
                            //             variant='outlined'
                            //             label='Search by Email'
                            //             value={email}
                            //             onChange={(e)=>{searchData('email', e); setEmail(e.target.value)}}
                            //             sx={{ m: 2, width: '400px', backgroundColor:'white', borderRadius:1}}
                            //             InputProps={{
                            //                 endAdornment: <InputAdornment position="start"><SearchOutlinedIcon/></InputAdornment>,
                            //             }}
                            //         />
                            //     </Box>
                            // </>
                            <Box sx={{minHeight:'85px'}}></Box>               
                        :
                            //this is for the cost table search bar
                            title === 'Cost Table'
                            ?
                                <div style={{width:'100%'}}>
                                    <Box sx={{display:'flex', flexDirection:'row-reverse'}}>
                                        {/* <Box sx={{flexGrow:1}}>
                                            <TextField
                                                id="searchMaterial"
                                                variant='outlined'
                                                label='Search by Material'
                                                value={material}
                                                onChange={(e)=>{searchData('material', e); setMaterial(e.target.value)}}
                                                sx={{ m: 2, width: '400px', backgroundColor:'white', borderRadius:1}}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start"><SearchOutlinedIcon/></InputAdornment>,
                                                }}
                                            />
                                        </Box> */}


                                        <Box sx={{m:3}}>
                                                <Button variant='contained' onClick={()=>{handleBackdrop(true)}}>Add New Material</Button>
                                        </Box>
                                        
                                        
                                        {numOfItemSelected === 0?
                                            <></>
                                        :
                                            <Box sx={{m:3}}>
                                                <LoadingButton 
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor:'red', 
                                                        '&:hover': {
                                                            backgroundColor: 'red',
                                                        },
                                                    }} 
                                                    onClick={()=>{Delete("cost")}}
                                                >
                                                    Delete Material(s)
                                                </LoadingButton>
                                            </Box>
                                        }

                                    </Box>
                                </div>

                            :
                                //this is for the add Category table button
                                title === 'Category Table'
                                ?   
                                    <Box sx={{display:'flex', flexDirection:'row-reverse', width:'100%'}}>
                                        <Box sx={{m:3}}>
                                            <Button variant='contained' onClick={()=>{setAddCategoryBackdrop(true)}}>Add Material Category</Button>
                                        </Box>

                                        {numOfCategorySelected === 0?
                                            <></>
                                        :
                                            <Box sx={{m:3}}>
                                                <LoadingButton 
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor:'red', 
                                                        '&:hover': {
                                                            backgroundColor: 'red',
                                                        },
                                                    }} 
                                                    onClick={()=>{Delete("category")}}
                                                >
                                                    Delete Material(s)
                                                </LoadingButton>
                                            </Box>
                                        }
                                    </Box>
                                :
                                    title === 'Discount Table'?
                                        <>
                                            <Box sx={{display:'flex', flexDirection:'row-reverse', width:'100%'}}>
                                                <Box sx={{m:3}}>
                                                    <Button variant='contained' onClick={()=>{setAddDiscountBackdrop(true)}}>Add New Discount Type</Button>
                                                </Box>

                                                {numOfDiscountSelected === 0?
                                                    <></>
                                                :
                                                    <Box sx={{m:3}}>
                                                        <LoadingButton 
                                                            variant='contained'
                                                            sx={{
                                                                backgroundColor:'red', 
                                                                '&:hover': {
                                                                    backgroundColor: 'red',
                                                                },
                                                            }} 
                                                            onClick={()=>{Delete("discount")}}
                                                        >
                                                            Delete Material(s)
                                                        </LoadingButton>
                                                    </Box>
                                                }
                                                    </Box>
                                        </>
                                    :

                                    // this is for the order data search bar
                                    <>
                                        {/* <Box>
                                            <TextField
                                                id="searchInvoice"
                                                variant='outlined'
                                                label='Search by Invoice No.'
                                                value={invoice}
                                                onChange={(e)=> {searchData('quotation_number', e); setInvoice(e.target.value)}}
                                                sx={{ m: 2, width: '400px', backgroundColor:'white', borderRadius:1}}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start"><SearchOutlinedIcon/></InputAdornment>,
                                                }}
                                            />
                                        </Box> */}

                                        {/* for the from date picker */}
                                        <Box sx={{m:2, maxWidth:300}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="From"
                                                    value={fromDate}
                                                    onChange={(newValue) => {
                                                        newValue === null? setFromDate(null):setFromDate(newValue.$d)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>                   
                                        
                                        {/* for the To date picker */}
                                        <Box sx={{m:2, maxWidth:300}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="To"
                                                    value={toDate}
                                                    onChange={(newValue) => {
                                                        newValue === null? setToDate(null):setToDate(newValue.$d)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>

                                        {/* for the reset date filter button */}
                                        <Box sx={{m:2}}>
                                            <Button 
                                                variant="contained"
                                                sx={{
                                                    p:2,
                                                    display: fromDate != null || toDate != null?'':'none'
                                                }}
                                                onClick={resetDateFilter}
                                                >
                                                    Reset Date Filter
                                            </Button>
                                        </Box>
                                    </>
                        }
                    </Box>
                    
                    {/* to display the data grids */}
                    {/* also for loading page while the API retrieve data from backend */}
                    {loading?
                        <Paper 
                            sx={{
                                minHeight:'600px', 
                                border: '1px solid',
                                borderColor:'#D9D9D9'
                            }} 
                            elevation={0}
                        >
                            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', minHeight:'600px'}}>
                                <Stack spacing={1} alignItems='center'>
                                    <CircularProgress/>
                                    <Typography>Loading Data</Typography>
                                </Stack>
                            </Box>
                        </Paper>
                    :
                        <Box 
                        sx={
                                {    
                                    justifyContent:'center', 
                                    bgcolor:'white', 
                                    pb:5, 
                                    pt:3,
                                    border: '1px solid',
                                    borderColor:'#D9D9D9'
                                }
                            }
                        >
                            {displayItem}
                        </Box>
                    }

                    
                    {/* for the backdrop to make new material */}
                    {AddMaterialBackprop?
                        <AddMaterialBackprop 
                            //this is to open/close the backdrop when user click on the button/cross-button
                            addBackdrop={addBackdrop}
                            setAddBackdrop={setAddBackdrop}
                        />
                    :
                        <></>
                    }

                    
                    {updateBackdrop?
                        <UpdateMaterialBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            updateBackdrop = {updateBackdrop}
                            setUpdateBackdrop = {setUpdateBackdrop}
                            updateMaterialInfo = {updateMaterialInfo}
                            //this is to update the cost table after an update from the UpdateMaterialBackdrop.js
                            dataCostTable = {dataCostTable}
                            setDataCostTable = {setDataCostTable}
                        />
                    :
                        <></>
                    }

                    {deleteBackdrop?
                        <DeleteMaterialBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            deleteBackdrop={deleteBackdrop}
                            setDeleteBackdrop={setDeleteBackdrop}
                            deleteMaterialInfo={deleteMaterialInfo}
                            //this is to update the cost table after a delete from the UpdateMaterialBackdrop.js
                            dataCostTable = {dataCostTable}
                            setDataCostTable = {setDataCostTable}
                        />
                    :
                        <></>
                    }


                    {deleteMultipleBackdrop?
                        <DeleteMultipleMaterialBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            deleteMultipleBackdrop={deleteMultipleBackdrop}
                            setDeleteMultipleBackdrop={setDeleteMultipleBackdrop}
                            //this is to update the cost table after a delete from the UpdateMaterialBackdrop.js
                            dataCostTable = {dataCostTable}
                            setDataCostTable = {setDataCostTable}
                            //this is to send the list of IDs to the backdrop component
                            rowSelectionModel={rowSelectionModel}
                        />
                    :
                        <></>
                    }

                    {addCategoryBackdrop?
                        <AddCategoryBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            addCategoryBackdrop={addCategoryBackdrop}
                            setAddCategoryBackdrop={setAddCategoryBackdrop}
                            //this is to update the category table after adding a new category
                            categoryTable={categoryTable}
                            setCategoryTable={setCategoryTable}
                        />
                    :
                        <></>
                    }

                    {updateCategoryBackdrop?
                        <UpdateCategoryBackdrop
                            updateCategoryBackdrop={updateCategoryBackdrop}
                            setUpdateCategoryBackdrop={setUpdateCategoryBackdrop}
                            updateCategoryInfo={updateCategoryInfo}
                            //this is to update the category table after updating a category details
                            categoryTable={categoryTable}
                            setCategoryTable={setCategoryTable}
                            //to update the color for the costing table
                            categoryColor={categoryColor}
                            setCategoryColor={setCategoryColor}
                        />
                    :
                        <></>
                    }

                    {deleteCategoryBackdrop?
                        <DeleteCategoryBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            deleteCategoryBackdrop={deleteCategoryBackdrop}
                            setDeleteCategoryBackdrop={setDeleteCategoryBackdrop}
                            //this is to sent the selected info that the user want to delete to the backdrop 
                            deleteCategoryInfo={deleteCategoryInfo}
                            //this is to update the data after the info is deleted
                            categoryTable={categoryTable}
                            setCategoryTable={setCategoryTable}
                        />
                    :
                        <></>
                    }

                    {deleteMultipleCategoryBackdrop?
                        <DeleteMultipleCategoryBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            deleteMultipleCategoryBackdrop={deleteMultipleCategoryBackdrop}
                            setDeleteMultipleCategoryBackdrop={setDeleteMultipleCategoryBackdrop}
                            //this is to sent the selected info that the user want to delete to the backdrop
                            categoryTable={categoryTable}
                            setCategoryTable={setCategoryTable}
                            //this is to send the list of IDs to the backdrop component
                            rowCategorySelectionModel={rowCategorySelectionModel}
                        />  
                    :
                        <></>
                    }

                    {addDiscountBackdrop?
                        <AddDiscountBackdrop
                            //this is to open/close the backdrop
                            addDiscountBackdrop={addDiscountBackdrop}
                            setAddDiscountBackdrop={setAddDiscountBackdrop}
                            //this is to update the category table after updating a category details
                            discountTable={discountTable}
                            setDiscountTable={setDiscountTable}
                        />
                    :
                        <></>
                    }

                    {updateDiscountBackdrop?
                        <UpdateDiscountBackdrop
                            updateDiscountBackdrop={updateDiscountBackdrop}
                            setUpdateDiscountBackdrop={setUpdateDiscountBackdrop}
                            updateDiscountInfo={updateDiscountInfo}
                            //this is to update the category table after updating a category details
                            discountTable={discountTable}
                            setDiscountTable={setDiscountTable}
                        />
                    :
                        <></>
                    }

                    {deleteDiscountBackdrop?
                        <DeleteDiscountBackdrop
                            deleteDiscountBackdrop={deleteDiscountBackdrop}
                            setDeleteDiscountBackdrop={setDeleteDiscountBackdrop}
                            deleteDiscountInfo={deleteDiscountInfo}
                            //this is to delete the category table after updating a category details
                            discountTable={discountTable}
                            setDiscountTable={setDiscountTable}
                        />
                    :
                        <></>
                    }

                    {deleteMultipleDiscountBackdrop?
                        <DeleteMultipleDiscountBackdrop
                            //this is to open/close the backdrop when user click on the button/cross-button
                            deleteMultipleDiscountBackdrop={deleteMultipleDiscountBackdrop}
                            setDeleteMultipleDiscountBackdrop={setDeleteMultipleDiscountBackdrop}
                            //this is to sent the selected info that the user want to delete to the backdrop
                            discountTable={discountTable}
                            setDiscountTable={setDiscountTable}
                            //this is to send the list of IDs to the backdrop component
                            rowDiscountSelectedModel={rowDiscountSelectedModel}
                        />
                    :
                        <></>
                    }



                </Box>
                    
            </Box>

        </Background>
    );
}

export default AdminControl;