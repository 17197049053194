import {useEffect, useState, useContext} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from '../context/UserContext';
import axios from "axios";
import { useTheme } from '@mui/material';
import {Background, ResponsiveGridContainer} from '../components/CustomProps';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Verifying from '../components/Verifying';
import { Helmet } from 'react-helmet-async';
import LogoPhrase from '../components/LogoPhrase';

function AccountVerification(theme) {
    const navigate=useNavigate()
    theme=useTheme(theme)
    const location = useLocation();
    const {setAuth} = useUserContext();
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonMsg, setButtonMsg] = useState('Send Verification Email');

    useEffect(() => {
        //remember to change this to the link that user recieved in their email!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // let weblink = "https://www.cutanything.co/account-verification?uid=Mzk&token=b6n5c7-b7d1bb76a65d09c9afbcebf856bc910a"
        // localhost:3000/account-verification?uid=Mzk&token=b6n5c7-b7d1bb76a65d09c9afbcebf856bc910a
        let weblink = window.location.href;

        if(weblink.split("?").length > 1){
            setLoading(true)
            let anchor = weblink.toString().split("?")[1];
            let parsed = JSON.parse(`{"${decodeURI(anchor).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"')}"}`)
            console.log(parsed)

            axios.get(`${process.env.REACT_APP_HOSTNAME}/activate/${parsed.uid}/${parsed.token}`)
            .then((res) => {
                console.log(res)
                setVerified(true)

                var logginToken = res.data.token
                var expiry = res.data.expires

                localStorage.setItem('login_state', res.data.status)
                localStorage.setItem('user',  res.data.user)
                localStorage.setItem('user_id', res.data.user_id)
                localStorage.setItem('token', logginToken)
                localStorage.setItem('expiry', expiry)

                
                axios.get(`${process.env.REACT_APP_HOSTNAME}/token_check/`, 
                {
                    headers: {
                        'Authorization': 'Token ' + res.data.token
                    }
                }).then((res) => {
                    console.log("token_check")
                    console.log(res.data)

                    localStorage.setItem('verified', res.data.is_verified)
                    setLoading(false)
                    setVerified(true)
                    setAuth({logginToken: logginToken, 
                        verified: res.data.is_verified, 
                        userRole: res.data.is_admin?1:2,
                        expiry: expiry})

                    navigate('/quotation')


                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                })



            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
        }
    }, [])

    const resendEmail = () => {
        console.log(localStorage.getItem('token'))
        setButtonLoading(true)
        setButtonMsg("Sending new verification email...")
        axios.get(`${process.env.REACT_APP_HOSTNAME}/activate-link/`,
        {
            headers:{
                "Authorization": 'Token ' + localStorage.getItem('token')
            }
        }).then((res) => {
            console.log(res)
            setButtonLoading(false)
            setButtonMsg('Send Verification Email')
        }).catch((err) =>{
            console.log(err)
            setButtonLoading(false)
            setButtonMsg('Send Verification Email')
        })
    }

    return (
        <Background>

                <Helmet>
                    <title>CutAnything | Account Verification</title>
                    <meta name='description' content="Verify your account and you can start cutting your materials with us!!!"/>
                    <link rel='canonical' href='/account-verification'/>
                </Helmet>

            <ResponsiveGridContainer container spacing={{
                    xs: 0,
                    sm: 0,
                    md: 2
                }} sx={{alignItems:'center',justifyContent:'space-evenly'}}>

                <LogoPhrase/>

                <Grid item xs='auto' md='auto'>
                    <Box padding={{xs: 2,sm: 8}} sx={{bgcolor: 'white',  borderRadius: 5}}>
                    {loading 
                    ? <Verifying name='Verifying Account' /> 
                    : verified
                        ? 
                        <Verifying name='Account Verified. Logging you into your new account.' /> 
                        :
                        <Grid item justifyContent='center' flex  textAlign='center'>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                A verification email has been sent to {location.state}
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom component="div">
                                If you don't see it, click on the button below!
                            </Typography>
                            
                            <Button className="caBtn navBtn"
                            size='large'
                            sx={{color:'white', backgroundColor: buttonLoading?theme.palette.neutral.darkgrey2.main:theme.palette.primary.main, m:5, cornerRadius:5}}
                            onClick={resendEmail}
                            disabled={buttonLoading}
                            >
                                {buttonMsg}
                            </Button>
                        </Grid>
                    }

                    </Box>
                </Grid>

                
            </ResponsiveGridContainer>
        </Background>
    );
}

export default AccountVerification;