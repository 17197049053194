import { useState, Fragment} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function ProductListingFilterMobile(props) {

    const [state, setState] = useState({
        bottom: false,
      });
    
    const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }

    setState({ ...state, [anchor]: open });
    };

    const filter = (e, item) => {
        //to change the title of products after filtering
        props.setSelectedCategory(e.target.innerText)

        if(e.target.innerText === "All Products"){
            props.setFilteredProductList(props.productList)
        }else{
            const output = props.productList.filter(x=> x.group === item.id)
            props.setFilteredProductList(output)
        }
    }

    const search = (e) => {
        if(e.target.value.length === 0){
            props.setSelectedCategory("All Products")
            props.setFilteredProductList(props.productList)
        }else{
            props.setSelectedCategory("Searched Products")
            // console.log(e.target.value)
            //to filter product items based on the name of the searched item using .includes()
            const output = props.productList.filter(x=>x.name.toLowerCase().includes(e.target.value.toLowerCase()))
            props.setFilteredProductList(output)
        }
    }

    const list = (anchor) => (
    <Box
        sx={{ width: anchor === 'bottom' ? 'auto' : 250, ml:3, pb:2 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    >
        <List>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'row',
                    pt:3,
                    pb:1
                }}
            >
                <FilterAltIcon />
                <Typography>Filter by Category</Typography>
            </Box>
            <Divider/>
            <ListItem disablePadding>
                <ListItemButton onClick={(e)=>{filter(e)}}>
                <ListItemText primary={"All Products"} /> 
                </ListItemButton>
            </ListItem>
            {props.productGroups.map((item, i) => (
                <ListItem key={i} disablePadding>
                    <ListItemButton onClick={(e)=>{filter(e, item)}}>
                    <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>
    );

    return (
        <Box
            sx={{
                display:'flex',
                flexDirection:'row',
            }}
        >
            <Box>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                    <InputLabel htmlFor="productSearch">Search Product</InputLabel>
                    <Input
                        id="productSearch"
                        endAdornment={
                        <InputAdornment position="end">
                            <SearchOutlinedIcon/>
                        </InputAdornment>
                        }
                        onChange={(e)=>{search(e)}}
                    />
                </FormControl>
            </Box>

            <Box
                sx={{
                    m:3
                }}
            >
                <Fragment key={'bottom'}>
                    <Button variant='contained' onClick={toggleDrawer("bottom", true)}>Filter</Button>
                    <Drawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    >
                    {list('bottom')}
                    </Drawer>
                </Fragment>
            </Box>
      </Box>
    );
}

export default ProductListingFilterMobile;