import React, { useEffect } from 'react';
import { getDeploymentConfig } from './utils/domain';
import MainSite from './MainSite';
import ClientSite from './ClientSite';
import { UserProvider, useUserContext } from './context/UserContext'

const AppContent = () => {
  const config = getDeploymentConfig();


  return (
    <>
      {config.siteType === 'main-site' && <MainSite />}
      {config.siteType === 'client-site' && <ClientSite />}
    </>
  );
};

const App = () => {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
};

export default App;