import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, 
    Button, 
    Grid, 
    Paper, 
    Typography,
    TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    CardActionArea
} from '@mui/material';
import useWindowDimensions from '../../components/GetWindowSize';
import CircularProgress from '@mui/material/CircularProgress';
import ProductListingFilterBar from '../ProductListingFilterBar';

function CurrentProducts(props) {

    const dimension = useWindowDimensions();
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("All Products");
    const [productList, setProductList] = useState(props.productList);
    const [filteredProductList, setFilteredProductList] = useState(props.productList);
    const [productGroups, setProductGroups] = useState(props.productGroups);


    return (
        <Box
            sx={{
                p:10,
                pt:5

            }}
        >
            <Typography variant='h3' sx={{mb:3}}>Current Products</Typography>

            {/* this is for the Filter bar */}
            <Box 
                sx={{
                    display:'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-evenly',
                    backgroundColor:"white",
                    height:dimension.width < 600? '100px' : '50px',
                    mb:5,
                    pb:2
                }}
                >
                    <>
                        <ProductListingFilterBar
                            productList={productList}
                            setFilteredProductList={setFilteredProductList}
                            productGroups={productGroups}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                        />
                    </>
                    
            </Box>

            <Box
                sx={{
                    display:"flex",
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}
            > 

            {/* this is for the product list */}
            <Typography 
                variant='h4'
                sx={{
                    ml:dimension.width < 600? 0:10,
                    mb:5,
                    textAlign:'center'
                }}
            >
                {selectedCategory}
            </Typography>

            <Grid 
                container
                justifyContent= "center"
                alignItems="center"
            >   
                {/* when there is no items on the filteredList */}
                {filteredProductList.map(function(item, i){
                    return(
                        <Grid
                            item
                            key={i}
                            xs={8}
                            sm={8}
                            md={5.5}
                            lg={4}
                            xl={4}
                            sx={{
                                // border:1,
                                mb:3
                            }}
                        >
                            <Card 
                                raised 
                                sx={{ 
                                    maxWidth: {
                                        xs:350,
                                        sm:400,
                                        md:400,
                                        lg:350,
                                        xl:500
                                    } 
                                }}
                            >
                                <CardActionArea 
                                    //onClick={()=> {moveOnToNextPage(item.name, i)}}
                                >
                                    <CardMedia
                                        component="img"
                                        image={item.image}
                                        alt={item.name}
                                        sx={{
                                            maxHeight:250,
                                        }}
                                    />

                                    <CardContent
                                        sx={{
                                            maxHeight:100,
                                            backgroundColor:'#F2F4F6',
                                            overflow:'hidden',
                                        }}
                                    >

                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.name}
                                        </Typography>

                                        <Typography 
                                            variant="body2" 
                                            color="text.secondary"
                                            noWrap
                                        >
                                            {item.description}
                                        </Typography>

                                    </CardContent>
                                </CardActionArea>
                                <CardActions 
                                    sx={{
                                        justifyContent: 'flex-end',
                                        backgroundColor:'#F2F4F6'
                                    }}
                                >
                                    <Button 
                                        size="small" 
                                        //onClick={()=>{moveOnToNextPage(item.name, i)}}
                                    >
                                        Learn More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>



                
            </Box>

        </Box>
    );
}

export default CurrentProducts;