
import { useNavigate } from "react-router-dom";

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import DragAndDrop from "../../components/DragAndDrop";

function Upload(props,theme) {

    
    theme=useTheme(theme)
    const navigate = useNavigate();


    return (
        <Box sx={{ display: 'flex', 
                    bgcolor: `${theme.palette.neutral.lightgrey2.main}`,
                    width: "100%",
                    justifyContent:'center',
                    flexDirection:'column',
                    }}>

            <Grid 
                container 
                spacing={2}
                direction='row'
                justifyContent='center'
            >

                <Grid item>
                    <DragAndDrop variant='full' {...props}/>
                </Grid>

                <Grid item>
                    <Box sx={{bgcolor:'white',
                                border: 1,
                                borderColor: '#D9E6EE',
                                borderRadius: 1,
                                height:168,
                                width:380,
                                textAlign:'center',
                            }}>

                         <Button 
                            disabled={props.uploadDisabled} 
                            onClick={()=>{props.setShow(true); console.log('show is ')}} 
                            variant='outlined' 
                            sx={{mt:4, mb:1, color:'#009AF9', backgroundColor: 'white', borderColor:'#009AF9'}} 
                            size="large" 
                            startIcon={<AddCircleOutlineOutlinedIcon sx={{color:'#009AF9'}} />}
                        > 
                            <Typography variant="uploadBtn">Create Shape</Typography>
                        </Button>
                        <Typography variant="caption1" display="block" gutterBottom> or make simple cuts in circle or rectangle </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid 
                container 
                direction='row'
                justifyContent='center'
                sx={{mt:5}}
            >
                <Grid item sx={{ textAlign: 'left'}}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}} gutterBottom> 
                        Please make sure that each file
                    </Typography>
                    <ul>
                        <li><Typography>contains lines that form a closed contour</Typography></li>
                        <li><Typography>does not contain no title block, dimension or text in the file</Typography></li>
                        <li><Typography> only has one model part</Typography></li>
                        <li><Typography>has a scale of model ratio 1:1 in mm</Typography></li>
                    </ul>
                    <Button sx={{ color:'#009AF9', backgroundColor: `${theme.palette.neutral.lightgrey2.main}`}} size="large" startIcon={<FileDownloadOutlinedIcon />}> 
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Download sample file</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Upload;