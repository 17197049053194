import {useState, useEffect} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from '../GetWindowSize';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function UpdateDiscountBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Update');
    const [uploadResult, setUploadResult] = useState('none');
    const {height, width} = useWindowDimensions();

    const [id, setId] = useState('');
    const [size, setSize] = useState('');
    const [discountBreakpoints, setDiscountBreakpoints] = useState('');
    const [hardStop, setHardStop] = useState('');
    const [softStop, setSoftStop] = useState('');
    const [maxDiscount, setMaxDiscount] = useState('');
    const [discountQuantity, setDiscountQuantity] = useState('');
    const [multiplier, setMultiplier] = useState('');

    useEffect(()=>{
        console.log(props.updateDiscountInfo)
        if(props.updateDiscountInfo != null){
            const discountInfo = props.updateDiscountInfo
            setId(discountInfo.id)
            setSize(discountInfo.sizing)
            setDiscountBreakpoints(discountInfo.discount_breakpoints)
            setHardStop(discountInfo.hard_stop)
            setSoftStop(discountInfo.soft_stop)
            setMaxDiscount(discountInfo.max_discount)
            setDiscountQuantity(discountInfo.max_discount_quantity)
            setMultiplier(discountInfo.multiplier)
        }
    }, [props.updateDiscountInfo])

    const update = (props) =>{
        setLoading(true)
        setSubmitText('Updating...')
        setUploadResult('none')

        console.log(typeof discountBreakpoints)
        var b = [];
        if(typeof discountBreakpoints === 'string'){
            b = discountBreakpoints.split(',').map(Number)
        }else{
            b = discountBreakpoints
        }
        
        console.log(b)

        const updatedDiscount = {
            id:id,
            sizing: size,
            discount_breakpoints: b,
            hard_stop: hardStop,
            soft_stop: softStop,
            max_discount: maxDiscount,
            max_discount_quantity: discountQuantity,
            multiplier: multiplier,
            valid:1
        }

        console.log(updatedDiscount)

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/discount_settings/`, updatedDiscount,
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res)=>{
            console.log(res)

            const result  = props.discountTable.map(obj => [updatedDiscount].find( o => o.id === obj.id) || obj)
            props.setDiscountTable(result)

            setLoading(false)
            setSubmitText('Update')
            setUploadResult('success')

        }).catch((err)=>{
            console.log(err)
            setLoading(false)
            setSubmitText('Update')
            setUploadResult('fail')
        })
    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.updateDiscountBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Update Discount Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setUpdateDiscountBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />
                
                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Size</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={size}
                            onChange={(e)=>{setSize(e.target.value)}}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Hard Stop</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="hardStop"
                                id="hardStop"
                                name="hardStop"
                                value={hardStop}
                                onChange={(e)=>{setHardStop(e.target.value)}}
                            >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Soft Stop</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={softStop}
                            onChange={(e)=>{setSoftStop(e.target.value)}}
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Discount</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={maxDiscount}
                            onChange={(e)=>{setMaxDiscount(e.target.value)}}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Discount Quantity</Typography>
                        <TextField 
                            id="interiorLineMarkup" 
                            variant="outlined"
                            value={discountQuantity}
                            onChange={(e)=>{setDiscountQuantity(e.target.value)}} 
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Discount Breakpoints</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={discountBreakpoints}
                            onChange={(e)=>{setDiscountBreakpoints(e.target.value)}}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Multiplier</Typography>
                        <TextField 
                            id="interiorLineMarkup" 
                            variant="outlined"
                            value={multiplier}
                            onChange={(e)=>{setMultiplier(e.target.value)}} 
                            fullWidth
                        />
                    </Box>
                </Box>

                {/* fifth row of inputs */}
                <Box sx={{display:'flex', flexDirection:'row-reverse', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maxWidth}}>
                        <Button 
                                variant='contained'
                                onClick={()=>{update(props)}}
                                disabled={loading}
                                sx={{
                                    minWidth:'300px', 
                                    minHeight:'50px',
                                    backgroundColor:loading?'#98A9BC':'#009AF9',
                                    mb:2
                                }} 
                            >
                            {submitText}
                        </Button>

                        {uploadResult === 'none'
                        ?
                            <></>
                        :
                        uploadResult === 'success'
                            ?
                                <Typography sx={{color:'green'}}>
                                    The new category has been successfully added.
                                </Typography>
                            :
                                <Typography sx={{color:'red'}}>
                                    Please check the above information again
                                </Typography>
                        }

                    </Box>
                </Box>

            </Paper>
        </Backdrop>
    );
}

export default UpdateDiscountBackdrop;