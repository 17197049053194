import React from "react";
import NumberedSlider from '../../../components/NumberedSlider';

function Table(props) {
  const { setTableWidth, setTableHeight, setTableRadius, tableWidth, tableHeight, tableRadius } = props;

  return (
    <div className="container">
      <div>
        <h3>Width (mm)</h3>
        <NumberedSlider
          setValue={setTableWidth}
          value={tableWidth}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />
        <h3>Height (mm)</h3>
        <NumberedSlider
          setValue={setTableHeight}
          value={tableHeight}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />
        <h3>Radius (mm)</h3>
        <NumberedSlider
          setValue={setTableRadius}
          value={tableRadius}
          valueLabelDisplay="auto"
          min={0}
          max={50}
        />
      </div>
    </div>
  );
}

export default Table;
