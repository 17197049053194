import {useEffect, useState, useContext} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import { Background } from "../../components/CustomProps";
import { Helmet } from "react-helmet";

import {useTheme } from '@mui/material';
import ShapeEditor from "./ShapeEditor"


var makerjs = require('makerjs');

function EditorPage(props, theme) {
    theme=useTheme(theme)
    const {auth} = useUserContext();

    return (
        <Background sx={{borderRadius:'8px',justifyContent:'center',}}>
            <Helmet>
                <title>CutAnything | Editor</title>
            </Helmet>

            <ShapeEditor sx={{display: 'flex', 
                    bgcolor: `#F2F4F6`,
                    width: "80%",
                    justifyContent:'center',
                    m:5}} />

    </Background>
      )
}

export default EditorPage;