import React from 'react';
import Box from '@mui/material/Box';
import { render } from 'react-dom';

function SelectComponent(props){
  const Option = (props) => (
      <option 
        value={props.value} 
      >{props.text}</option>
    )

  const style2 = () => {
    if( props.selected==''){
      return {width:"100%", height:"48px",border: "1px solid #ECEBED",borderRadius: "4px",
      color:"#98A9BC",fontFamily:"Open Sans",fontSize:16,fontWeight:400,lineHeight:"22px",
      textTransform:'none', padding:"12px",fontColor:"#98A9BC", background:"#fff"}
    }
    else{
      return {width:"100%", height:"48px",border: "1px solid #ECEBED",borderRadius: "4px",
      color:"#1B1E24", fontWeight:600,fontFamily:"Open Sans",fontSize:16,lineHeight:"22px",
      textTransform:'none', padding:"12px",fontColor:"#98A9BC", background:"#fff"}
    }
  }
    
  if(props.items.length!=0)
  {return <select style={style2()} name={props.name}
    onChange={props.handleSelect} value={props.selected}
  > 
  <option value='' >Select {props.nextSelect}</option>
    {props.items.map((item, i) => <Option
      key={i}
      name={item.name}
      value={item.value}
      text={item.text}
      handleSelect={props.handleSelect}
      selected={item.value==props.selected}
      
      />
    )}
  </select>}
  else
  {
    return <select disabled style={style2()} name={props.name}
    onChange={props.handleSelect} value={props.selected} className="enterDetails"
  > 
  <option >Select {props.nextSelect}</option>
  </select>
  }
};



export default SelectComponent

