import {useContext, useEffect, useState} from 'react';
import { Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";
import { useUserContext } from '../context/UserContext';
import axios from "axios";
import Loader from './Loader';

function checkToken(auth){

    if(auth.logginToken !== "no token yet" && auth.logginToken !== "token removed"){
        console.log("persisntlogin: true")
        return true 
    }else{
        console.log("persisntlogin: false")
        return false
    }

}

function PersistLogin() {

    const location = useLocation();
    const navigate = useNavigate();
    const {auth, setAuth} = useUserContext();
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const role = async () => {
            try{
                const res = await axios.get(`${process.env.REACT_APP_HOSTNAME}/token_check/`,
                    {
                        headers: {
                            'Authorization': 'Token ' + auth.logginToken
                        }
                    })
                console.log(res)
                setAuth({...auth, userRole: res.data.is_admin?1:2})
                setIsLoading(false)
            }
            catch(err) {
                console.log(err)
                localStorage.clear();
                sessionStorage.clear()
                setAuth({...auth, logginToken:'token removed'})
                navigate('/')
                setIsLoading(false)
            }
        }
        //before running the useEffect, check if there is a user role stored in the auth state
        if(!auth.userRole){
            role()
        }else{
            setIsLoading(false)
        }

    }, [])


    return isLoading 
            ? <Loader/>
            : checkToken(auth)
                ? <Outlet/>
                : <Navigate to='/login' state={{ from: location }} replace/>
    
}

export default PersistLogin;