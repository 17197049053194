import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import LoginPopOut from './LoginPopOut';
import { useTheme } from '@mui/material';


const ClientNavbar = (props, theme) => {
  theme=useTheme(theme)
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static" sx={{bgcolor:'white', marginBottom:2, mt:-1}} elevation={0} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
      
      
        <Container maxWidth='xl'>
          <Box sx={{  display: { xs: 'none',  md: 'none', lg: 'flex'},  justifyContent: 'flex-end', alignItems:'center', mr: 1}} >
            <Button 
                className="caBtn navBtn"
                onClick={() => props.setBackdropLogin(true)}
                sx={{
                    borderColor: theme.palette.error.main,
                    '&:hover': {
                        backgroundColor: 'white',
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    }
                }}
            >Login</Button>
          </Box>
        </Container>


          {/* this is the for side bar */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg:'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block', lg:'none'  }
              }}
            >
            <MenuItem  onClick={() => {handleCloseNavMenu();props.setBackdropLogin(true)}}>
                Login
            </MenuItem>

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ClientNavbar;
