import {useState} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import useWindowDimensions from '../GetWindowSize';

function DeleteMaterialBackdrop(props) {

    const {auth} = useUserContext();
    const maximumWidth = '400px';
    const materialInfo = props.deleteMaterialInfo;
    const [loading, setLoading] = useState(false);
    const {height, width} = useWindowDimensions();

    const Delete = () =>{
        // console.log('deleted')
        // console.log(props.dataCostTable)
        //console.log(materialInfo)
        setLoading(true)

        axios.delete(`${process.env.REACT_APP_HOSTNAME}/cost`, {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            },
            data:{id_list:[materialInfo.id]}
        }).then((res)=>{
            console.log(res)
            const output = props.dataCostTable.filter(function(obj){
                return obj.id !== materialInfo.id
            })
            setLoading(false)
            props.setDataCostTable(output)
            props.setDeleteBackdrop(false)
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.deleteBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Delete Material Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setDeleteBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />

                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom
                    sx={{ml:7, color:'red'}}
                >
                    You are about to delete the following material:
                </Typography>

                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Category</Typography>
                        <TextField 
                            id="category" 
                            variant="outlined" 
                            value={materialInfo.category} 
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Material</Typography>
                        <TextField 
                            id="material" 
                            variant="outlined" 
                            value={materialInfo.material} 
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Processing Method</Typography>
                        <TextField 
                            id="processingMethod" 
                            variant="outlined" 
                            value={materialInfo.processing_method} 
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Surfacing</Typography>
                        <TextField 
                            id="surfacing" 
                            variant="outlined" 
                            value={materialInfo.surfacing} 
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Max Length (mm)</Typography>
                        <TextField 
                            id="maxLength" 
                            variant="outlined" 
                            value={materialInfo.max_length} 
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Max Width (mm)</Typography>
                        <TextField 
                            id="maxWidth" 
                            variant="outlined" 
                            value={materialInfo.max_width} 
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Thickness</Typography>
                        <TextField 
                            id="thickness" 
                            variant="outlined" 
                            value={materialInfo.thickness} 
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* fifth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Router Speed</Typography>
                        <TextField 
                            id="routerSpeed" 
                            variant="outlined" 
                            value={materialInfo.router_speed} 
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Run Cost Per Hour</Typography>
                        <TextField 
                            id="runCostHour" 
                            variant="outlined" 
                            value={materialInfo.run_cost_hour} 
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* sixth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Unit Cost Area</Typography>
                        <TextField 
                            id="unitCostArea" 
                            variant="outlined" 
                            value={materialInfo.unit_cost_area} 
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Unit Cost Length</Typography>
                        <TextField 
                            id="unitCostLength" 
                            variant="outlined" 
                            value={materialInfo.unit_cost_length} 
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.7, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Base Unit Cost Area</Typography>
                        <TextField 
                            id="baseUnitCostAread"  
                            variant="outlined" 
                            value={materialInfo.base_unit_cost_area} 
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maximumWidth}}>
                        <LoadingButton
                            loading = {loading}
                            loadingIndicator = 'Deleting...'
                            variant='contained' 
                            sx={{
                                minWidth:'300px', 
                                minHeight:'50px', 
                                backgroundColor:'red', 
                                '&:hover': {
                                    backgroundColor: 'red',
                                },
                            }} 
                            onClick={Delete}
                        >
                            Delete
                        </LoadingButton>
                    </Box>
                </Box>
            
            </Paper>
        </Backdrop>
    );
}

export default DeleteMaterialBackdrop;