import {useState} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import useWindowDimensions from '../GetWindowSize';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function DeleteDiscountBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const discountInfo = props.deleteDiscountInfo;
    const [loading, setLoading] = useState(false);
    const {height, width} = useWindowDimensions();

    const Delete = (props) =>{
        setLoading(true)
        console.log('delete')
        // const updatedDiscount = {
        //     id:discountInfo.id,
        //     sizing: discountInfo.sizing,
        //     discount_breakpoints: discountInfo.discount_breakpoints,
        //     hard_stop: discountInfo.hard_stop,
        //     soft_stop: discountInfo.soft_stop,
        //     max_discount: discountInfo.max_discount,
        //     max_discount_quantity: discountInfo.max_discount_quantity,
        //     multiplier: discountInfo.multiplier,
        //     valid:0
        // }
        // console.log(updatedDiscount)
        axios.delete(`${process.env.REACT_APP_HOSTNAME}/discount_settings/`,
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            },
            data:{id_list:[discountInfo.id]}
        }).then((res)=>{
            console.log(res)
            const output = props.discountTable.filter(function(obj){
                return obj.id !== discountInfo.id
            })
            props.setDiscountTable(output)
            props.setDeleteDiscountBackdrop(false)
            setLoading(false)
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
        })

    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.deleteDiscountBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Delete Material Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setDeleteDiscountBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />

                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Size</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={discountInfo.sizing}
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Hard Stop</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="hardStop"
                                id="hardStop"
                                name="hardStop"
                                value={discountInfo.hard_stop}
                                disabled
                            >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Soft Stop</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={discountInfo.soft_stop}
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Discount</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={discountInfo.max_discount}
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Discount Quantity</Typography>
                        <TextField 
                            id="interiorLineMarkup" 
                            variant="outlined"
                            value={discountInfo.max_discount_quantity}
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Discount Breakpoints</Typography>
                        <TextField 
                            id="areaMarkup" 
                            variant="outlined"
                            value={discountInfo.discount_breakpoints}
                            fullWidth
                            disabled
                        />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Multiplier</Typography>
                        <TextField 
                            id="interiorLineMarkup" 
                            variant="outlined"
                            value={discountInfo.multiplier}
                            fullWidth
                            disabled
                        />
                    </Box>
                </Box>

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', flexDirection:'row-reverse', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maxWidth}}>
                        <LoadingButton
                            loading = {loading}
                            loadingIndicator = 'Deleting...'
                            variant='contained' 
                            sx={{
                                minWidth:'300px', 
                                minHeight:'50px', 
                                backgroundColor:'red', 
                                '&:hover': {
                                    backgroundColor: 'red',
                                },
                            }} 
                            onClick={()=>{Delete(props)}}
                        >
                            Delete
                        </LoadingButton>
                    </Box>
                </Box>
            
            </Paper>
        </Backdrop>
    );
}

export default DeleteDiscountBackdrop;