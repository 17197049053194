import React, { useRef, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from 'buffer';
import { useUserContext } from '../context/UserContext';

//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import {Item,ResponsiveGridContainer,TypographyChanged,StackChanged,Background} from '../components/CustomProps'
import { Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LogoPhrase from '../components/LogoPhrase';


function Login(theme) {
    theme=useTheme(theme)


    const navigate = useNavigate();
    const inputEmail = useRef(null);
    const inputPassword = useRef(null);
    const [msg, setMsg] = useState('Login')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [disabled, setDisabled] = useState(false)
    const {setAuth} = useUserContext();

    const login = () => {
        setDisabled(true)
        setMsg('Signing in...')
        setError(false)

        const token = Buffer.from(`${inputEmail.current.value.toLowerCase()}:${inputPassword.current.value}`, 'utf8').toString('base64')
        console.log("Basic "+ token)

        axios.post(`${process.env.REACT_APP_HOSTNAME}/api-token-auth/`, {},
        {
            headers: {
            'Authorization': 'Basic ' + token
            }
        })
        .then((res) => {
            console.log("login in:")
            console.log(res.data)
            // console.log(res.data.token)

            var logginToken = res.data.token
            var expiry = res.data.expires

            localStorage.setItem('login_state', res.data.status)
            localStorage.setItem('user',  res.data.user)
            localStorage.setItem('user_id', res.data.user_id)
            localStorage.setItem('token', logginToken)
            localStorage.setItem('expiry', expiry)



            axios.get(`${process.env.REACT_APP_HOSTNAME}/token_check/`, 
            {
                headers: {
                    'Authorization': 'Token ' + res.data.token
                }
            }).then((res) => {
                console.log("token_check")
                console.log(res.data)
                // console.log(res.data.is_verified)

                // console.log("setting of auth")
                localStorage.setItem('verified', res.data.is_verified)
                setAuth({logginToken: logginToken, 
                            verified: res.data.is_verified, 
                            userRole: res.data.is_admin?1:2,
                            expiry: expiry})
                navigate('/quotation')
            }).catch((err) => {
                setDisabled(false)
                navigate('/login')
            })
        })
        .catch((err) => {
            setErrorMsg(err.response.data.detail)
            setError(true)
            setMsg('Login')
            setDisabled(false)
        })
    };

    const handleKeyDown = event => {
        console.log("entered key pressed")
        if (event.key === 'Enter') {
            login()
        }
      };
    

    return (
        <div>
            <Background sx={{pb:10}}>

                <Helmet>
                    <title>CutAnything | Login</title>
                    <meta name='description' content="Login to your account to start cutting with us"/>
                    <link rel='canonical' href='/login'/>
                </Helmet>

                <ResponsiveGridContainer container spacing={{
                        xs: 0,
                        sm: 0,
                        md: 2
                    }} sx={{alignItems:'center',justifyContent:'space-evenly'}}>
                    
                    <LogoPhrase/>

                    <Grid item xs='auto' md='auto'>
                        <Box  
                            padding={{xs: 4,sm: 8}} 
                            paddingTop={{xs:4}}
                            paddingBottom={{xs:4}}
                            margin={{xs:1, md:0}}
                            sx={{bgcolor: 'white',  borderRadius: 5}}
                        >
                            <Stack spacing={2}>
                                <TextField id="email" label="Email Address" inputRef={inputEmail} onKeyDown={handleKeyDown} required/>
                                <TextField  id="password" label="Password" type="password" inputRef={inputPassword} onKeyDown={handleKeyDown} required/>
                                <Button style={{color:'white', backgroundColor: disabled?'#98A9BC':'#009AF9'}} onClick={login}  disabled={disabled}>{msg}</Button>
                                {error? (               
                                     <Item elevation={0}>{errorMsg}</Item>
                                ):(
                                    <></>
                                )}
                                <Link component="button" onClick={() => {navigate("/forget-password")}}>Forget Password?</Link>
                            </Stack>
                        </Box>

                        <Box sx={{display:'flex', flexDirection:'row', bgcolor: 'white',  borderRadius: 5, mt:2, justifyContent:'center'}} padding={{xs:2, md:6}}>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'right', mr: 4, ml:2}}>
                                <Typography variant='subtitle1' sx={{textAlign:'left'}}>Don't have an account?</Typography>
                                <Typography variant='bold' sx={{textAlign:'left', fontWeight: 'bold'}}>Create free account here</Typography>
                            </Box>

                            <Box sx={{display:'flex',  alignItems:'flex-end', justifyContent:'left'}}>
                                <Button sx={{color:'white', backgroundColor:'#009AF9', pl:3, pr:3}} onClick={() => {navigate("/sign-up")}}>Sign Up</Button>
                            </Box>
                        </Box>


                    </Grid>
    
                </ResponsiveGridContainer>
            </Background>
        </div>
    );
}

export default Login;




{/* <Link onClick={() => {navigate("/sign-up")}}>Don't have an account?</Link>
<Link onClick={() => {navigate("/forget-password")}}>Forget your password?</Link> */}
