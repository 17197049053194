import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Typography } from '@mui/material';
import { useLocation } from "react-router-dom";

function HashLink(props) {
    
    //using the react-router-hash-link library
    //https://stackoverflow.com/questions/70839167/how-can-i-change-the-color-of-the-only-active-navhashlink-activeclassname-is-s --> to change color after clicking

    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;

    return (
        <Link 
            smooth 
            to={`#${props.section}`} 
            style={
                isActive(`#${props.section}`)
                  ? {
                      color: "black",
                      textDecoration: 'none'
                    }
                  : {
                    color: "black",
                    textDecoration: 'none'
                  }
              }
        >
            <Typography variant='button'>
                {props.name}
            </Typography> 
        </Link>
    );
}

export default HashLink;