import React, {useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import { useUserContext } from '../context/UserContext';

function userAuth(auth){

    if(auth.logginToken !== "no token yet" || auth.logginToken !== "token removed"){
        // console.log('userAuth:', false)
        return true
    }else{
        // console.log('userAuth:', true)
        return false
    }
}


function ProtectedRoutes({allowedRoles}) {

    const {auth} = useUserContext();
    // console.log(auth)

    //first, it will check if userRole is null or not? if not, it will go to login page
    //then it will check if account is verified and userRoleis included in the allowedRoles list. 
    //if all passes, it will allow user to access pages based on their role. if not, it will go to quotation page
    //condition? true:false
    return userAuth(auth)?
                auth.verified && allowedRoles.includes(parseInt(auth.userRole))
                ? <Outlet/>
                : <Navigate to='/quotation'/>
            : <Navigate to='/login'/>

}

export default ProtectedRoutes;