//components from material ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {TypographyChanged, StackChanged} from '../components/CustomProps';
import { useTheme } from '@mui/material';

function LogoPhrase(props, theme) {

    theme=useTheme(theme)

    return (
        <Grid item xs='auto' mt={{xs:5,sm:10}}>
            <Stack flexDirection='row' justifyContent='center' alignItems='center' mb={2}>
                <Box 
                    component="img"
                    src='https://global-uploads.webflow.com/6100f59c138a680259458e8b/61260343355e4581f217437e_Artboard%20132x32.png' 
                    width="auto"
                    height={{
                        xs: 100,
                        sm: 256,
                        md: 256,
                        lg: 350
                    }}
                    alt="cutanything logo"
                />
    
                <Stack spacing={-3}>
                    <TypographyChanged variant="h4" fontSize={{xs: 22,sm: 34,md: 34,lg: 40,}}>Ready to create?</TypographyChanged>
                    <TypographyChanged variant="h4" fontSize={{xs: 22,sm: 34,md: 34,lg: 40,}}>Log in with your account</TypographyChanged>
                    <TypographyChanged variant="h2" fontSize={{xs: 30,sm: 48,md: 60,lg: 72,}} sx={{color:theme.palette.primary.main}}>CutAnything</TypographyChanged>
                    <TypographyChanged variant="h2" fontSize={{xs: 30,sm: 48,md: 60,lg: 72,}}>Account</TypographyChanged>
                    <StackChanged direction='row' spacing={{xs:-1.5,sm:-1}}>
                        <TypographyChanged variant="h5" fontSize={{xs: 16,sm: 24,md: 24,lg: 28,}}>Instant </TypographyChanged>
                        <TypographyChanged variant="h5" fontSize={{xs: 16,sm: 24,md: 24,lg: 28,}}>Quotes</TypographyChanged>
                        <TypographyChanged variant="h5" fontSize={{xs: 16,sm: 24,md: 24,lg: 28,}} sx={{color:theme.palette.primary.main}}>Free</TypographyChanged>
                        <TypographyChanged variant="h5" fontSize={{xs: 16,sm: 24,md: 24,lg: 28,}}>Always</TypographyChanged>
                    </StackChanged>
                </Stack>
            </Stack>
        </Grid> 
    );
}

export default LogoPhrase;