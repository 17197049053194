import {useState, useRef} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from '../GetWindowSize';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function AddDiscountBackdrop(props) {

    const {auth} = useUserContext();
    const maxWidth = '400px';
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Submit');
    const [uploadResult, setUploadResult] = useState('none');
    const {height, width} = useWindowDimensions();

    //for the useRef of the fields
    const inputSize = useRef(null);
    const inputDiscountBreakpoints = useRef(null);
    const inputMaxDiscount = useRef(null);
    const inputDiscountQuantity = useRef(null);
    const inputMultiplier = useRef(null);
    const inputSoftStop = useRef(null);
    const [hardStop, setHardStop] = useState('');

    const submit = (props) => {
        setLoading(true)
        setSubmitText('Submitting...')
        setUploadResult('none')

        const b = inputDiscountBreakpoints.current.value.split(',').map(Number);

        axios.post(`${process.env.REACT_APP_HOSTNAME}/discount_settings/`,
        {
            sizing: inputSize.current.value,
            discount_breakpoints: b,
            hard_stop: hardStop,
            max_discount: inputMaxDiscount.current.value,
            max_discount_quantity: inputDiscountQuantity.current.value,
            soft_stop: inputSoftStop.current.value,
            valid: 1
        },
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }).then((res)=>{
            console.log(res)

            setLoading(false)
            setSubmitText('Submit')
            setUploadResult('success')
        }).catch((err)=>{
            console.log(err)
            setLoading(false)
            setSubmitText('Submit')
            setUploadResult('fail')
        })
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.addDiscountBackdrop}
        >

            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth: width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Add New Discount Type</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setAddDiscountBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />
                
                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Size</Typography>
                        <TextField id="areaMarkup" variant="outlined" inputRef={inputSize} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Hard Stop</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="hardStop"
                                id="hardStop"
                                name="hardStop"
                                value={hardStop}
                                onChange={(e)=>{setHardStop(e.target.value)}}
                            >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Soft Stop</Typography>
                        <TextField id="interiorLineMarkup" variant="outlined" inputRef={inputSoftStop} fullWidth/>
                    </Box>

                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Discount</Typography>
                        <TextField id="lineMarkup" variant="outlined" inputRef={inputMaxDiscount} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Max Discount Quantity</Typography>
                        <TextField id="minCost" variant="outlined" inputRef={inputDiscountQuantity} fullWidth/>
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Discount Breakpoints</Typography>
                        <TextField id="breakpoints" variant="outlined" placeholder='1, 5, 10, 25, 50, 100' inputRef={inputDiscountBreakpoints} fullWidth/>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maxWidth}}>
                        <Typography variant='body2' gutterBottom>Multiplier</Typography>
                        <TextField id="multiplier" variant="outlined" inputRef={inputMultiplier} fullWidth/>
                    </Box>
                </Box>

                {/* fifth row of inputs */}
                <Box sx={{display:'flex', flexDirection:'row-reverse', ml:5, mb:4, mr:5}}>
                    <Box>
                        <Button 
                            variant='contained'
                            onClick={()=>{submit(props)}}
                            disabled={loading}
                            sx={{
                                minWidth:'100px', 
                                minHeight:'50px',
                                backgroundColor:loading?'#98A9BC':'#009AF9',
                                mb:2,
                                mt:4,
                                ml:4
                            }} 
                            >
                            {submitText}
                        </Button>
                        {uploadResult === 'none'
                                ?
                                    <></>
                                :
                                uploadResult === 'success'
                                    ?
                                        <Typography sx={{color:'green'}}>
                                            The new category has been successfully added.
                                        </Typography>
                                    :
                                        <Typography sx={{color:'red'}}>
                                            Please check the above information again
                                        </Typography>
                            }
                    </Box>                    

                </Box>

            </Paper>
            
        </Backdrop>
    );
}

export default AddDiscountBackdrop;