import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import { Box, 
    Button, 
    Grid, 
    Paper, 
    Typography,
    TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 40,
    marginBottom: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'black',
    borderStyle: 'dashed',
    backgroundColor: 'white',
    transition: 'border .24s ease-in-out',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};
  
const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    height: '100%',
    padding: 4,
    boxSizing: 'border-box'
};
  
const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};
  
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

function AddNewProduct(props, theme) {

    theme=useTheme(theme)
    const [productGroup, setProductGroup] = useState(props.productGroups);
    const [loading, setLoading] = useState(true);
    const [selectedProductGroup, setSelectedProductGroup] = useState(1);
    const [files, setFiles] = useState([]);

    //useRef
    const inputProductName = useRef(null);
    const inputProductDescription = useRef(null);


    const handleSubmission = (acceptedFiles) => {
        var i = 0;
        console.log(acceptedFiles)
    }

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        handleSubmission(acceptedFiles)
    }, [])

    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
    } = useDropzone({
        onDrop,
        noClick:true, 
        accept:{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg, .jpg']
        }});

    const style = useMemo(() => ({
        ...baseStyle,
    }));

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img
              src={file.preview}
              style={img}
              alt={file.name}
              // Revoke data uri after image is loaded
              onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
          </div>
        </div>
    ));
    
    useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);


    const productGroupChange = (value) => {
        setSelectedProductGroup(value)
    }

    const submit = () => {
        console.log("submit")
        console.log(inputProductName.current.value)
        console.log(inputProductDescription.current.value)
        console.log(selectedProductGroup)
    }

    return (
        <Box
            sx={{
                p:10,
                pt:5

            }}
        >
            <Typography variant='h3' sx={{mb:3}}>Add New Product</Typography>

            <Box
                sx={{
                    display:"flex",
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}
            > 

                <Box
                    sx={{
                        flexGrow:1
                    }}
                >  
                    {/* product name */}
                    <TextField 
                        required
                        fullWidth
                        label="Product Name"
                        inputRef={inputProductName}
                        sx={{
                            mb:3
                        }}
                    />

                    {/* product description */}
                    <TextField 
                        required
                        fullWidth
                        multiline
                        label="Product Description"
                        rows={3}
                        inputRef={inputProductDescription}
                        sx={{
                            mb:3
                        }}
                    />

                    {/* product groups */}
                    <FormControl 
                        fullWidth
                        sx={{
                            mb:3
                        }}
                    >
                        <InputLabel id="productGroup">Product Group</InputLabel>
                        <Select
                            labelId="productGroup"
                            id="productGroup"
                            value={selectedProductGroup}
                            label="Product Group"
                            onChange={(e)=>{productGroupChange(e.target.value)}}
                        >
                            {productGroup.map(function(value, i){
                                return(
                                    <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                    
                    {/* drag and drop for images */}
                    {/* https://react-dropzone.js.org/#section-previews */}
                    <div {...getRootProps({style})}>
                        <label htmlFor="contained-button-file">
                            <input 
                                disabled={props.uploadDisabled} 
                                onChange={(e)=>handleSubmission(e.target.files)}
                                accept=".png , .jpg"
                                multiple type="file"  
                                {...getInputProps()} 
                                id="contained-button-file"
                            />
                            
                            <Button 
                            disabled={props.uploadDisabled} 
                            sx={
                                {
                                mt:4, 
                                mb:1, 
                                color:"white", 
                                bgcolor:'#009AF9',
                                ':hover': {
                                    bgcolor: theme.palette.action.main,
                                    color: 'white',
                                },
                                }
                            } 
                            variant="outlined" 
                            component="span"  
                            startIcon={<FileUploadOutlinedIcon />}  
                            size="large"
                            >
                                <Typography variant="uploadBtn">Upload file(s)</Typography>
                            </Button>
                        </label>        

                        <Typography variant="caption1" display="block"  gutterBottom> or drag and drop here </Typography>
                        <Typography variant="caption2" display="block"  gutterBottom> .dxf files are supported </Typography>

                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                    </div>

                    <Button
                        variant='contained'
                        fullWidth
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Box>
                
            </Box>

        </Box>
    );
}

export default AddNewProduct;