import React from 'react';
import { Button } from '@mui/material';
import Typography from "@mui/material/Typography";
var makerjs = require('makerjs');

function DownloadDxfButton(props) {


    const downloadFile = () =>{

        var preModel = props.activeModel

        // if(props.itemName === 'Square'){
        //     preModel = props.squareModel
        // }else if(props.itemName === "RoundRect"){
        //     preModel = props.squareRecModel
        // }else if(props.itemName === 'Dial'){
        //     preModel = props.dialModel
        // }else if(props.itemName === 'Circle'){
        //     preModel = props.circleModel
        // }

        const pathObj = {}
        var iter = 1;

        console.log(preModel)
        const checkPath = (preModel) =>{
            //it will go through every key in the object
            for(var key in preModel){
                //it will then check if there is a "paths" key in the object
                //if there is no "paths" key in the object, then it will call the checkPath function again with the current object as parameter
                //https://stackoverflow.com/questions/13523951/how-to-check-the-depth-of-an-object
                if(!preModel.hasOwnProperty('paths')){
                    // console.log(preModel[key])
                    checkPath(preModel[key])
                //if there is ONLY a "paths" key in the object and no other key,
                //for the "paths" key value, it will be pushed to the pathObj
                }else if(preModel.hasOwnProperty('paths') && Object.keys(preModel).length === 1){
                    //it will then replace the key value with the iter value and assign the value to the key value
                    //it lastly increse the iter value for every key-value pair it pushed
                    for(let key2 in preModel.paths){
                        pathObj[iter] = preModel.paths[key2]
                        iter += 1
                    }
                //if the preModel has a "paths" key but there are also other keys in it
                //for the "paths" key value, it will be pushed to the pathObj
                //for the key value that are not "paths", then it will call the checkPath function again
                }else if(preModel.hasOwnProperty('paths') && Object.keys(preModel).length > 1){
                    if(key === 'paths'){
                        for(let key2 in preModel.paths){
                            pathObj[iter] = preModel.paths[key2]
                            iter += 1
                        }
                    }else{
                        checkPath(preModel[key])
                    }
                }
            }
        }

        checkPath(preModel)

        //console.log(pathObj)
        const model = {paths:pathObj}
        // console.log(makerjs.exporter.toDXF(model))
        //https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
        const file = new File([makerjs.exporter.toDXF(model)], 'model.dxf')
      
        // Create a link and set the URL using `createObjectURL`
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = URL.createObjectURL(file);
        link.download = file.name;
      
        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();
      
        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
          URL.revokeObjectURL(link.href);
          link.parentNode.removeChild(link);
        }, 0);
    }

    return (
        <Button
            variant='contained'
            size="large"
            sx={{
                mt:4, 
                mb:1, 
                color:'white', 
                backgroundColor: '#009AF9',
                '&:hover': {
                    backgroundColor: 'white',
                    color:'#009AF9'
                },
            }} 
            onClick={()=>{downloadFile()}}
        >
            <Typography variant="uploadBtn">Download Dxf file</Typography>
            
        </Button>
    );
}

export default DownloadDxfButton;