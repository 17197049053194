import {useEffect, useState} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import { Background } from "../../components/CustomProps";
import { Helmet } from "react-helmet";

import { FormControl,InputLabel,MenuItem,TextField,Button,Stack,Grid,Box,useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";

import Dial from "./shape-library/Dial";
import RoundRect from "./shape-library/RoundRect";
import Square from "./shape-library/Square";
import Circle from './shape-library/Circle';
import Star from './shape-library/Star';
import Oval from './shape-library/Oval';
import Polygon from './shape-library/Polygon';
import Table from './shape-library/Table';

import Blueprint from "react-blueprint-svg";
import DownloadDxfButton from "./DownloadDxfButton";
import FlaredSpokes from "./makerjs-Dial";
import { EditOffRounded } from '@mui/icons-material';
import {SquareShape, 
        CircleShape, 
        SquareRecShape,
        DialShape,
        StarShape,
        OvalShape,
        PolygonShape,
        TableShape} from './Shapes';


var makerjs = require('makerjs');

function EditorSide(props, theme) {
    theme=useTheme(theme)
    const {auth} = useUserContext();

    const [itemType, setItemType] = useState('Square');

    const [itemName, setItemName] = useState('model.dxf');

    const [displayItem, setDisplayItem] = useState('');
    const shapeList = ['Square', 'RoundRectangle', 'Circle', 'Dial', 'Star', 'Oval', 'Polygon', 'Table'];

    //for the shape
    const {squareModels, squareSide, setSquareSide} = SquareShape();
    const {circleModels, circleRadius, setCircleRadius} = CircleShape();
    const {squareRecModels, length, width,radius, setLength, setWidth, setRadius} = SquareRecShape();
    const {ovalModels, radiusX, radiusY, setRadiusX, setRadiusY} = OvalShape();
    const {polygonModels, polyNumSides, polyRadius, offsetAngle, setPolyNumSides, setPolyRadius, setOffsetAngle} = PolygonShape();
    const {dialModels,
            outerRadius,
            innerRadius,
            count,
            spokeWidth,
            flareWidth,
            setOuterRadius,
            setInnerRadius,
            setCount,
            setSpokeWidth,
            setFlareWidth} = DialShape();
    const {starModels,
            numOfSides,
            starOuterRadius,
            starInnerRadius,
            setNumOfSides,
            setStarOuterRadius,
            setStarInnerRadius} = StarShape();
    const {tableModels,
            tableHeight,
            tableWidth,
            tableRadius,
            setTableHeight,
            setTableWidth,
            setTableRadius} = TableShape();

    const [activeModel,setActiveModel] = useState(squareModels)

    const handleChange = (event) => {
        setItemType(event.target.value);
        setItemName(event.target.value+'.dxf')
        };  

    useEffect(()=>{
        if(itemType === 'Square'){
            setDisplayItem(                        
                <> 
                    {/* every model component should send its model and react.states that it need */}
                    <Square
                        squareSide={squareSide}
                        setSquareSide={setSquareSide}
                    />
                </>
            )
            setActiveModel(squareModels)
        }else if(itemType === 'RoundRectangle'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <RoundRect
                        length={length}
                        width={width}
                        radius={radius}
                        setLength={setLength}
                        setWidth={setWidth}
                        setRadius={setRadius}
                    />
                </>
            )
            setActiveModel(squareRecModels)
        }else if(itemType === 'Dial'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <Dial
                        outerRadius={outerRadius}
                        innerRadius={innerRadius}
                        count={count}
                        spokeWidth={spokeWidth}
                        flareWidth={flareWidth}
                        setOuterRadius={setOuterRadius}
                        setInnerRadius={setInnerRadius}
                        setCount={setCount}
                        setSpokeWidth={setSpokeWidth}
                        setFlareWidth={setFlareWidth}
                    />
                </>
            )
            setActiveModel(dialModels)
        }else if(itemType === 'Circle'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <Circle
                        circleRadius={circleRadius}
                        setCircleRadius={setCircleRadius}
                    />
                </>
            )
            setActiveModel(circleModels)
        }else if(itemType === 'Star'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <Star
                        starModels={starModels}
                        numOfSides={numOfSides}
                        starOuterRadius={starOuterRadius}
                        starInnerRadius={starInnerRadius}
                        setNumOfSides={setNumOfSides}
                        setStarOuterRadius={setStarOuterRadius}
                        setStarInnerRadius={setStarInnerRadius}
                    />
                </>
            )
            setActiveModel(starModels)
        }else if(itemType === 'Oval'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <Oval
                        ovalModels={ovalModels}
                        radiusX={radiusX}
                        radiusY={radiusY}
                        setRadiusX={setRadiusX}
                        setRadiusY={setRadiusY}
                    />
                </>
            )
            setActiveModel(ovalModels)
        }else if(itemType === 'Polygon'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <Polygon
                        polygonModels={polygonModels}
                        polyNumSides={polyNumSides}
                        polyRadius={polyRadius}
                        offsetAngle={offsetAngle}
                        setPolyNumSides={setPolyNumSides}
                        setPolyRadius={setPolyRadius}
                        setOffsetAngle={setOffsetAngle}
                    />
                </>
            )
            setActiveModel(polygonModels)
        }else if(itemType === 'Table'){
            setDisplayItem(
                <>
                    {/* every model component should send its model and react.states that it need */}
                    <Table
                        tableHeight={tableHeight}
                        tableWidth={tableWidth}
                        tableRadius={tableRadius}
                        setTableHeight={setTableHeight}
                        setTableWidth={setTableWidth}
                        setTableRadius={setTableRadius}
                    />
                </>
            )
            setActiveModel(tableModels)
        }
    }, [itemType, squareSide, length, width, radius, outerRadius, innerRadius, count, spokeWidth, 
        flareWidth, circleRadius, numOfSides, starOuterRadius, starInnerRadius, radiusX, radiusY, 
        polyNumSides, polyRadius, offsetAngle, tableHeight, tableWidth, tableRadius])

    //this is to create shape into a dxf format and send it to the backend
    const Create = () =>{
        console.log('create')

        var preModel = activeModel

        const pathObj = {}
        var iter = 1;

        console.log(preModel)
        const checkPath = (preModel) =>{
            //it will go through every key in the object
            for(var key in preModel){
                //it will then check if there is a "paths" key in the object
                //if there is no "paths" key in the object, then it will call the checkPath function again with the current object as parameter
                //https://stackoverflow.com/questions/13523951/how-to-check-the-depth-of-an-object
                if(!preModel.hasOwnProperty('paths')){
                    // console.log(preModel[key])
                    checkPath(preModel[key])
                //if there is ONLY a "paths" key in the object and no other key,
                //for the "paths" key value, it will be pushed to the pathObj
                }else if(preModel.hasOwnProperty('paths') && Object.keys(preModel).length === 1){
                    //it will then replace the key value with the iter value and assign the value to the key value
                    //it lastly increse the iter value for every key-value pair it pushed
                    for(let key2 in preModel.paths){
                        pathObj[iter] = preModel.paths[key2]
                        iter += 1
                    }
                //if the preModel has a "paths" key but there are also other keys in it
                //for the "paths" key value, it will be pushed to the pathObj
                //for the key value that are not "paths", then it will call the checkPath function again
                }else if(preModel.hasOwnProperty('paths') && Object.keys(preModel).length > 1){
                    if(key === 'paths'){
                        for(let key2 in preModel.paths){
                            pathObj[iter] = preModel.paths[key2]
                            iter += 1
                        }
                    }else{
                        //check if in the preModel is still an object,
                        //if it is, it will run it against the checkPath function again
                        if(!Object.keys(preModel).length === 0){
                            checkPath(preModel[key])
                        }
                    }
                }
            }
        }

        checkPath(preModel)

        //console.log(pathObj)
        const model = {paths:pathObj}
        // console.log(makerjs.exporter.toDXF(model))
        //https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
        setItemName(itemType+'.dxf')
        const file = new File([makerjs.exporter.toDXF(model)], itemName)

        var formData = new FormData();
        formData.append('file', file);
        formData.append('file_name',itemType+'.dxf');
        formData.append('imgRes', 50)
        formData.append('is_simple', 'True')

        props.updateItemState(props.itemNum,'uploading')
        props.addItemName(itemType+'.dxf','itemNames')


        axios.post(`${process.env.REACT_APP_HOSTNAME}/asyncFileUpload/`, formData,
            {
                headers:{
                    'Authorization': 'Token ' + auth.logginToken
                },
                timeout: 35000
            }
        ).then((res)=>{
            console.log(res)
            console.log("filesubmitted:")
            console.log(res.data)
            console.log(props.itemNum)
            props.updateItemId(props.itemNum,res.data.id)
            props.updateItemState(props.itemNum,'loading')
            props.checkComplete(res.data.id,props.itemNum,0)

        }).catch((err)=>{
            console.log(err.response.data)
            props.addFile(err.response.data)
            props.updateItemState(props.itemNum,'error')
        })

        props.setShow(false)    
        props.setStep(2)
        props.setUploadDisabled(false)
    }

    return (
        <Box sx={props.sx} >
            <Grid 
                container 
                direction='row'
                spacing = {2}
                justifyContent='flex-end'
            >
                <Grid 
                    item 
                    xs={12}
                    md={12}
                    lg={8}
                    sx={{maxHeight:'500px'}}
                >
                    <Blueprint model={activeModel['models']} title={itemType} options={{fitOnScreen:true}}/>
                </Grid>

                <Grid 
                    item 
                    xs={12}
                    md={12}
                    lg={4}
                >
                    <Stack >
                        <Grid item sx={{justifyContent: 'left', mt:5 }}>
                            <TextField 
                                select
                                label="Select"
                                value={itemType}
                                onChange={handleChange}
                                sx={{width:'100%'}}
                            >   
                                {/* for every new shape, add it to the shapeList */}
                                {shapeList.map(function(item, i){ 
                                    return <MenuItem key={i} value={`${item}`}>{item}</MenuItem>
                                })}
                            </TextField>
                        </Grid>

                        <Grid item>{displayItem}</Grid>

                        <Grid item sx={{display:'flex', flexDirection:'row-reverse', pt:5, pb:5}}>
                            <Stack spacing={2}>
                                <Button 
                                    variant="contained"
                                    onClick={Create}
                                    sx={{
                                        mt:4, 
                                        mb:1, 
                                        color:'white', 
                                        backgroundColor: '#009AF9',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            color:'#009AF9'
                                        },
                                    }} 
                                >
                                    Create
                                </Button>
                                {/* make sure that the model is passed from this component to the child component (which is the model file js) */}
                                {/* <DownloadDxfButton
                                    itemName={itemName}
                                    activeModel={activeModel}
                                /> */}
                            </Stack>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Box>

      )
}

export default EditorSide;