import React from "react";
import Slider from '@mui/material/Slider';
import NumberedSlider from "../../../components/NumberedSlider";

function Circle(props) {


    return (
        <div className="container">

            <div>
                <h3>Circle Radius (mm)</h3>
                <NumberedSlider
                    setValue={props.setCircleRadius}
                    value={props.circleRadius}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1500}
                    />
            </div>
      </div>
    );
}

export default Circle;