import {useState, useEffect} from 'react';
import { useUserContext } from '../../context/UserContext';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import useWindowDimensions from '../GetWindowSize';

function UpdateMaterialBackdrop(props) {

    const {auth} = useUserContext();
    const maximumWidth = '400px';
    const [id, setId] = useState('');
    const [category, setCategory] = useState('');
    const [material, setMaterial] = useState('');
    const [processingMethod, setProcessingMethod] = useState('');
    const [surfacing, setSurfacing] = useState('');
    const [maxLength, setMaxLength] = useState('');
    const [maxWidth, setMaxWidth] = useState('');
    const [thickness, setThickness] = useState('');
    const [routerSpeed, setRouterSpeed] = useState('');
    const [runCostHour, setRunCostHour] = useState('');
    const [unitCostArea, setUnitCostArea] = useState('');
    const [unitCostLength, setUnitCostLenght] = useState('');
    const [baseUnitCostArea, setBaseUnitCostArea] = useState('');
    const [result, setResult] = useState('none');
    const [loading, setLoading] = useState(false);
    const {height, width} = useWindowDimensions();

    //to auto populate the fields according to the selected items
    useEffect(() => {
        console.log(props.updateMaterialInfo)
        if(props.updateMaterialInfo != null){
            const materialInfo = props.updateMaterialInfo;
            // console.log(materialInfo)
            setId(materialInfo.id)
            setCategory(materialInfo.category)
            setMaterial(materialInfo.material)
            setProcessingMethod(materialInfo.processing_method)
            setSurfacing(materialInfo.surfacing)
            setMaxLength(materialInfo.max_length)
            setMaxWidth(materialInfo.max_width)
            setThickness(materialInfo.thickness)
            setRouterSpeed(materialInfo.router_speed)
            setRunCostHour(materialInfo.run_cost_hour)
            setUnitCostArea(materialInfo.unit_cost_area)
            setUnitCostLenght(materialInfo.unit_cost_length)
            setBaseUnitCostArea(materialInfo.base_unit_cost_area)
        }

    }, [props.updateMaterialInfo])

    const update = (props) => {
        // console.log(category)
        // console.log(material)
        // console.log(processingMethod)
        // console.log(surfacing)
        // console.log(maxLength)
        // console.log(maxWidth)
        // console.log(thickness)
        // console.log(routerSpeed)
        // console.log(runCostHour)
        // console.log(unitCostArea)
        // console.log(unitCostLength)
        // console.log(baseUnitCostArea)
        setResult('none')
        setLoading(true)
        const changedMaterialInfo = [{
            id: id,
            category: category,
            material: material,
            processing_method: processingMethod,
            surfacing: surfacing,
            max_length: maxLength,
            max_width: maxWidth,
            thickness: thickness,
            router_speed: routerSpeed,
            run_cost_hour: runCostHour,
            unit_cost_area: unitCostArea,
            unit_cost_length: unitCostLength,
            base_unit_cost_area: baseUnitCostArea
        }]

        axios.patch(`${process.env.REACT_APP_HOSTNAME}/cost/`, changedMaterialInfo[0],
        {
            headers:{
                'Authorization': 'Token ' + auth.logginToken
            }
        }
        ).then((res)=>{
            // console.log(res)
            //https://stackoverflow.com/questions/37585309/replacing-objects-in-array
            const result  = props.dataCostTable.map(obj => changedMaterialInfo.find( o => o.id === obj.id) || obj)
            props.setDataCostTable(result)
            setResult('success')
            setLoading(false)

        }).catch((err)=>{
            console.log(err)
            setResult('failed')
            setLoading(false)
        })
    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.updateBackdrop}
        >
            <Paper sx={{
                        backgroundColor:'white',
                        minHeight:'500px',
                        minWidth:width*0.8,
                        borderRadius:'10px'
                    }}>
                
                <div style={{width:'100%'}}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{flexGrow:1, m:4}}>
                            <Typography variant='h6'> Update Material Information</Typography>
                        </Box>


                        <Box sx={{flexGrow:0.1, mt:4}}>
                            <IconButton onClick={()=>{props.setUpdateBackdrop(false)}}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </div>

                <Divider sx={{backgroundColor:'#555555', mb:3}} />
                
                {/* first row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Choose Category</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="category"
                                id="category"
                                value={category}
                                onChange={(e)=>{setCategory(e.target.value)}}
                            >
                            <MenuItem value={'Metal'}>Metal</MenuItem>
                            <MenuItem value={'Wood'}>Wood</MenuItem>
                            <MenuItem value={'Plastic'}>Plastic</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Material</Typography>
                        <TextField 
                            id="material" 
                            variant="outlined" 
                            value={material} 
                            onChange={(e)=>{setMaterial(e.target.value)}} 
                            fullWidth
                            />
                    </Box>
                </Box>

                {/* second row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Processing Method</Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="processingMethod"
                                id="processingMethod"
                                value={processingMethod}
                                onChange={(e)=>{setProcessingMethod(e.target.value)}}
                            >
                            <MenuItem value={'CNC Router'}>CNC Router</MenuItem>
                            <MenuItem value={'Fiber Laser'}>Fiber Laser</MenuItem>
                            <MenuItem value={'CO2 Laser'}>CO2 Laser</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Surfacing</Typography>
                        <TextField 
                            id="surfacing" 
                            variant="outlined" 
                            value={surfacing} 
                            onChange={(e)=>{setSurfacing(e.target.value)}}
                            fullWidth
                            />
                    </Box>
                </Box>

                {/* third row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Max Length (mm)</Typography>
                        <TextField 
                            id="maxLength" 
                            variant="outlined" 
                            value={maxLength} 
                            onChange={(e)=>{setMaxLength(e.target.value)}}
                            fullWidth
                            />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Max Width (mm)</Typography>
                        <TextField 
                            id="maxWidth" 
                            variant="outlined" 
                            value={maxWidth} 
                            onChange={(e)=>{setMaxWidth(e.target.value)}} 
                            fullWidth
                            />
                    </Box>
                </Box>

                {/* fourth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Thickness</Typography>
                        <TextField 
                            id="thickness" 
                            variant="outlined" 
                            value={thickness} 
                            onChange={(e)=>{setThickness(e.target.value)}}
                            fullWidth
                            />
                    </Box>
                </Box>

                {/* fifth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Router Speed</Typography>
                        <TextField 
                            id="routerSpeed" 
                            variant="outlined" 
                            value={routerSpeed} 
                            onChange={(e)=>{setRouterSpeed(e.target.value)}}
                            fullWidth
                            />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Run Cost Per Hour</Typography>
                        <TextField 
                            id="runCostHour" 
                            variant="outlined" 
                            value={runCostHour} 
                            onChange={(e)=>{setRunCostHour(e.target.value)}}
                            fullWidth
                            />
                    </Box>
                </Box>

                {/* sixth row of inputs */}
                <Box sx={{display:'flex', ml:5}}>
                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Unit Cost Area</Typography>
                        <TextField 
                            id="unitCostArea" 
                            variant="outlined" 
                            value={unitCostArea} 
                            onChange={(e)=>{setUnitCostArea(e.target.value)}}
                            fullWidth
                            />
                    </Box>

                    <Box sx={{flexGrow:0.5, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Unit Cost Length</Typography>
                        <TextField 
                            id="unitCostLength" 
                            variant="outlined" 
                            value={unitCostLength} 
                            onChange={(e)=>{setUnitCostLenght(e.target.value)}}
                            fullWidth
                            />
                    </Box>
                </Box>

                {/* seventh row of inputs */}
                <Box sx={{display:'flex', ml:5, mb:5}}>
                    <Box sx={{flexGrow:0.7, m:2, maxWidth:maximumWidth}}>
                        <Typography variant='body2' gutterBottom>Base Unit Cost Area</Typography>
                        <TextField 
                            id="baseUnitCostAread"  
                            variant="outlined" 
                            value={baseUnitCostArea} 
                            onChange={(e)=>{setBaseUnitCostArea(e.target.value)}} 
                            fullWidth
                            />
                    </Box>

                    <Box sx={{flexGrow:0.5, mt:6, pl:17, maxWidth:maximumWidth}}>
                        <LoadingButton
                            loading = {loading}
                            loadingIndicator = 'Updating...'
                            variant='contained' 
                            sx={{minWidth:'300px', minHeight:'50px'}} 
                            onClick={()=>{update(props)}}
                        >
                            Update
                        </LoadingButton>

                        {result === 'none'?
                            <></>
                        :
                            result === 'success'?
                                <Typography sx={{color:'green'}}>Data has been successfully updated</Typography>
                            :
                                <Typography sx={{color:'red'}}>Update failed, please check your input and try again</Typography>
                        }
                    </Box>
                </Box>

            </Paper>
        </Backdrop>
    );
}

export default UpdateMaterialBackdrop;