import {useEffect, useState, useContext, useRef} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserContext } from '../../context/UserContext';
import { useTheme } from '@mui/material';

//import materials
import {Background} from '../../components/CustomProps';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import PhoneInput, { formatPhoneNumberInt } from 'react-phone-number-input';
import MuiField from '../../components/MuiField';

function ProductCheckout(props, theme) {

    theme=useTheme(theme)
    const navigate = useNavigate();
    const {auth} = useUserContext();
    const [quotationID, setQuotationID] = useState(localStorage.getItem('currentQuotationAnonymous')?localStorage.getItem('currentQuotationAnonymous'):props.quotationId)
    // const [quotationID, setQuotationID] = useState(132)

    const [loading, setLoading] = useState(true);
    const [method, setMethod] = useState('Pickup');
    const [activePay, setActivePay] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [detailsDisabled, setDetailsDisabled] = useState(true);
    const [subtotal, setSubtotal] = useState(props.totalCost);
    const [checkoutItems, setCheckoutItems] = useState();
    const [checkoutList, setCheckoutList] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [deliveryFee, setDeliveryFee] = useState('Free');
    const [error, setError] = useState(false);
    const [total, setTotal] = useState(props.totalCost);
    const [paymentButtonMsg, setPaymentButtonMsg] = useState('Pay');

    //to check if it is delivery or self-pickup
    const [deliveryDisabled, setDeliveryDisabled]=useState(true);

    //these variables is to store the address after rendering
    const [userDetails, setUserDetails] = useState('');
    const [deliveryName, setDeliveryName] = useState('');
    const [billingName, setBillingName] = useState('');
    const [email, setEmail] = useState('');
    const [deliveryPostalCode, setDeliveryPostalCode] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [deliveryUnitNumber, setDeliveryUnitNumber] = useState('');
    const [billingContact, setBillingContact] = useState();
    const [billingAddress, setBillingAddress] = useState('');
    const [billingUnitNumber, setBillingUnitNumber] = useState('');
    const [billingPostalCode, setBillingPostalCode] = useState('');


    //useRef
    const inputEmail = useRef(null);
    
    const inputBillingName = useRef(null);
    const inputBillingAddress = useRef(null);
    const inputBillingUnitNumber = useRef(null);
    const inputBillingPostalCode = useRef(null);

    const inputDeliveryAddress = useRef(null);
    const inputDeliveryUnitNumber = useRef(null);
    const inputDeliveryPostalCode = useRef(null);

    //change of delivery method
    const methodChange = (event) => {
        setMethod(event.target.value);
        if(event.target.value === 'Normal Delivery'){
            console.log(event.target.value)
            setDeliveryFee(50)
            setTotal(parseFloat(subtotal) + 50)
            setDeliveryDisabled(false)

        }else{
            console.log(event.target.value)
            setDeliveryFee('Free')
            setTotal(parseFloat(subtotal))
            setDeliveryDisabled(true)
        }
    };

    //to check if the postal code and address is filled
    useEffect(() => {
        const billingFieldsAreValid = billingName && billingContact && billingAddress && billingUnitNumber && billingPostalCode ;
        const deliveryFieldsAreValid = deliveryAddress && deliveryUnitNumber && deliveryPostalCode;
        
        if(deliveryDisabled) {
            if (billingFieldsAreValid ) {
                setActivePay(true);
                setDisabled(false);
            }else {
                setActivePay(false);
                setDisabled(true);
            }
        }else {
            if (billingFieldsAreValid && deliveryFieldsAreValid ) {
                setActivePay(true);
                setDisabled(false);
            }else {
                setActivePay(false);
                setDisabled(true);
            }
        }
    }, [deliveryDisabled, billingName, billingContact, billingAddress, billingPostalCode, billingUnitNumber, deliveryAddress, deliveryPostalCode, deliveryUnitNumber]);
        

    //to get the checkout items from backend
    useEffect(() => {
        setSubtotal(0)
        axios.get(`${process.env.REACT_APP_HOSTNAME}/quotation/${quotationID}/get_anonymous_quotation`, {}
        
        ).then((res) => {
            console.log(res.data)
            const quotation = res.data

            if(quotation.cost.delivery_cost > 0){
                setDeliveryFee(quotation.cost.delivery_cost)
                setSubtotal(quotation.cost.cutting_cost)
                setTotal(quotation.cost.total_cost)
                setMethod('Normal Delivery')
                setDeliveryDisabled(false)
            }

            if(quotation.status === 'PR'){
                console.log("payment receieved")
                navigate('/my-orders')
            }else{

                if(quotation.cost.delivery_cost > 0){
                    setDeliveryFee(quotation.cost.delivery_cost)
                    setSubtotal(quotation.cost.cutting_cost)
                    setTotal(quotation.cost.total_cost)
                    setMethod('Normal Delivery')
                    setDeliveryDisabled(false)
                }else{
                    //making it 2dp
                    setSubtotal((Math.round(quotation.cost.cutting_cost  * 100) / 100).toFixed(2))
                    setTotal((Math.round(quotation.cost.cutting_cost  * 100) / 100).toFixed(2))
                    // setTotal(0.05)
                }

                setCheckoutList(quotation.orders)
                setLoading(false)
            }
            //error for quotation
        }).catch((err) => {
            console.log(err)
            setLoadingItems(false)
            setError(true)
        })
    },[quotationID])

    //to make payment
    const payment = () => {
        console.log('payment')

        console.log(inputBillingName.current.value)
        console.log(inputEmail.current.value)
        console.log(billingContact)
        console.log(inputBillingAddress.current.value)
        console.log(inputBillingUnitNumber.current.value)
        console.log(inputBillingPostalCode.current.value)
        if (!deliveryDisabled){
            console.log(inputDeliveryAddress.current.value)
            console.log(inputDeliveryUnitNumber.current.value)
            console.log(inputDeliveryPostalCode.current.value)
        }

        setActivePay(false)
        setPaymentButtonMsg('Processing...')
        setDisabled(true)
        
        //this is customer is doing self-pickup
        if (deliveryDisabled){
            var data={
                email: inputEmail.current.value,
                reference_number: quotationID,
                delivery: method,
                //remember to change this!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                redirect_url:  `${window.location.host}/payment-complete`,
                billing_address_line_1: inputBillingAddress.current.value,
                billing_address_line_2: inputBillingUnitNumber.current.value,
                billing_postal_code: inputBillingPostalCode.current.value,
                billing_name: inputBillingName.current.value,
                billing_number: billingContact,
                delivery_address_line_1: '',
                delivery_address_line_2: '',
                delivery_postal_code: '',
            }}
        //this is if customer is doing delivery
        else {
            var data={
                email: inputEmail.current.value,
                delivery: method,
                //remember to change this!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                billing_address_line_1: inputBillingAddress.current.value,
                billing_address_line_2: inputBillingUnitNumber.current.value,
                billing_postal_code: inputBillingPostalCode.current.value,
                billing_name: inputBillingName.current.value,
                billing_number: billingContact,
                delivery_address_line_1: inputDeliveryAddress.current.value,
                delivery_address_line_2: inputDeliveryUnitNumber.current.value,
                delivery_postal_code: inputDeliveryPostalCode.current.value,
            }
        }

        axios.post(`${process.env.REACT_APP_HOSTNAME}/quotation/${quotationID}/payment_request/`,
        data).then((res)=>{
            console.log(res)
            if(res.status === 201){
                console.log(res.data.quotation_status)
                localStorage.setItem('currentQuotation', '')
                window.location.replace(res.data.url)
            }else{
                console.log(res)
            }
        }).catch((err)=>{
            console.log(err)
            setActivePay(true)
            setPaymentButtonMsg('Pay')
            setDisabled(false)
        })
    }

    //to set up the frontend check out items
    useEffect(() => {
        if(checkoutList.length === 0){
            setCheckoutItems(
                <Box sx={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', spacing:2, pl:4, mb:3}}>
                    <Typography variant="body2" component="div"  align='left' sx={{color: theme.palette.neutral.darkgrey1.main}}>No checkout items</Typography>
                </Box>
            )
        }else{
            setDetailsDisabled(false)
            setCheckoutItems(
            <>
                <Box sx={{ flexDirection: 'column', pl:4}}>
                {checkoutList.map(function(details){
                    return <Grid key={details.id}  container direction='row' alignItems='center' sx={{mb:2}}>
                    <Grid item xs={1} md={1} lg={1}>
                        <Typography variant="body2">{details.quantity}x</Typography>
                    </Grid>
        
                    <Grid item xs={8} md={8} lg={8}>
                        <Typography variant="body2">{details.file_name}</Typography>
                    </Grid>
        
                    <Grid item xs={3} md={3} lg={3} pl={2}>
                        <Typography variant="body2">${details.cost}</Typography>
                    </Grid>
                </Grid>;
                })}
                </Box>

                <br/>
    
                <Box sx={{display:'flex', justifyContent:'center', pl:4}}>
                    <Grid container direction='row' alignItems='center' sx={{mb:2}}>
                        <Grid item xs={9} md={9} lg={9}>
                            <Typography sx={{fontWeight: 'bold'}} variant="body2">Subtotal</Typography>
                        </Grid>
    
                        <Grid item xs={3} md={3} lg={3} pl={2}>
                            <Typography sx={{fontWeight: 'bold'}} variant="body2">${parseFloat(subtotal).toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{display:'flex', justifyContent:'center', pl:4}} >
                    <Grid container direction='row' alignItems='center' sx={{mb:2}}>
                        <Grid item xs={9} md={9} lg={9}>
                            <Typography variant="body2">Delivery</Typography>
                        </Grid>

                        <Grid item xs={3} md={3} lg={3} pl={2}>
                            <Typography variant="body2">{deliveryFee === 'Free'? 'Free':'$'+deliveryFee}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{display:'flex', justifyContent:'center', pl:4}}>
                    <Grid container direction='row' alignItems='center' sx={{mb:2}}>
                        <Grid item xs={9} md={9} lg={9}>
                            <Typography sx={{fontWeight: 'bold'}} variant="body2">Total:</Typography>
                        </Grid>

                        <Grid item xs={3} md={3} lg={3} pl={2}>
                            <Typography variant="body2" sx={{fontWeight: 'bold'}}>{'$' + parseFloat(total).toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{display:'flex', justifyContent:'center'}}>
                    <Button 
                        sx={{color:'white', bgcolor: activePay?'#009AF9':"#CFD8DD", flexGrow:1, m:4 }}
                        disabled={disabled}
                        onClick={payment}
                    >
                        {paymentButtonMsg}
                    </Button>
                </Box>
            </>
            )
        }
        
    }, [checkoutList, deliveryFee, activePay, total, subtotal, billingContact])


    return (
        <Background >

        {/* this is for the loading icon when API is retrieveing data from backend */}
        <Backdrop 
            open={loading}
            //this so that the backdrop does not overlap with other items in the page
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
        >
            <Paper sx={{
                    minHeight:'400px', 
                    minWidth:'400px',
                    display: "flex",
                    justifyContent: "center"
                }}>
                <Stack spacing={2} sx={{alignItems:'center', justifyContent:'center'}}>
                    <CircularProgress/>
                    <Typography variant="button" component="div"  align='center' sx={{fontWeight: 'bold', fontSize:20, mb:25}}>Loading Items</Typography>
                </Stack>
            </Paper>
        </Backdrop>


        {/* this is to split the body into 2 columns */}
        <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" columnSpacing={2} rowSpacing={2} textAlign='left' mr={3} ml={5}>

            {/* this is for the left column */}
            <Grid item xs={11.5} md={12} lg={8}>

                {/* this is to divide the left hand side into multiple rows */}
                <Stack direction='column' alignItems='stretch' justifyContent='flex-start'>
                    
                    {/* this is for the collection method */}
                    <Paper elevation={0} sx={{ borderRadius: 1, p:4, mb:3 }} variant="outlined" >

                        <FormControl>
                            <FormLabel id="collection_method" sx={{fontWeight: 'bold', color:'black', mb:1}} >Collection Method</FormLabel>
                            <RadioGroup
                                aria-labelledby="collection_method"
                                name="collection_method_group"
                                value={method}
                                onChange={methodChange}
                            >   
                                <Stack direction='column'>
                                    <FormControlLabel value="Pickup" control={<Radio disabled={detailsDisabled}/>} label="Self pickup"  />
                                    <Typography sx={{pl:4,color:'#778CA2', fontWeight: 'bold'}}>1 to 3 processing days</Typography>
                                    <br/>
                                    <Typography sx={{pl:4,color:'#778CA2'}}>Location:</Typography>
                                    <Typography sx={{pl:4,color:'#778CA2'}}>2 Toa Payoh Industrial Park, #01-1405</Typography>
                                    <Typography sx={{pl:4,color:'#778CA2'}}>Singapore 319054</Typography>
                                    <br/>
                                </Stack>

                                <Stack direction='column'>
                                    <FormControlLabel value="Normal Delivery" control={<Radio disabled={detailsDisabled}/>} label="Delivery" sx={{pr:2.5}}/>
                                    <Typography sx={{pl:4,color:'#778CA2', fontWeight: 'bold'}}>3 to 7 processing days</Typography>
                                    <br/>
                                    <Typography sx={{pl:4,color:'#778CA2'}}>Via a 3rd party courier service</Typography>
                                </Stack>
                                
                            </RadioGroup>
                        </FormControl>

                    </Paper>

                    {/* this is for the user information */}
                    <Paper elevation={0} sx={{ borderRadius: 1, p:4, mb:3 }} variant="outlined"> 

                        <Typography sx={{fontWeight: 'bold', mb:2}}>User Information</Typography>

                        <Grid container direction='row' justifyContent='flex-start' alignItems='center' columnSpacing={3} rowSpacing={2} sx={{mb:2}}>
                            <Grid item>
                                <TextField 
                                    id='name' 
                                    label='Name' 
                                    variant='outlined' 
                                    value={billingName} 
                                    onChange={(e)=>{setBillingName(e.target.value)}} 
                                    inputRef={inputBillingName}
                                    sx={{width:300}} 
                                    disabled={detailsDisabled}
                                />
                            </Grid>

                            <Grid item>
                                {/* https://catamphetamine.gitlab.io/react-phone-number-input/ */}
                                <PhoneInput placeholder="Enter phone number"
                                    value={billingContact}
                                    onChange={setBillingContact}
                                    defaultCountry='SG'
                                    inputComponent={MuiField}
                                    disabled={detailsDisabled}
                                    style={{width:'300px'}}
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction='row' justifyContent='flex-start' alignItems='center' columnSpacing={3} rowSpacing={2} sx={{mb:2}}>
                            <Grid item>
                                <TextField 
                                    id="email" 
                                    label="Email" 
                                    variant="outlined"  
                                    value={email} 
                                    onChange={(e)=>{setEmail(e.target.value)}} 
                                    inputRef={inputEmail}
                                    sx={{width:300}} 
                                    disabled={detailsDisabled}
                                />
                            </Grid>

                        </Grid>


                    </Paper>

                    {/* this is for the billing address */}
                    <Paper elevation={0} sx={{ borderRadius: 1, p:4, mb:3 }} variant="outlined"> 

                        <Typography sx={{fontWeight: 'bold', mb:2}}>Billing Address</Typography>

                        <Grid container direction='row' justifyContent='flex-start' alignItems='center' columnSpacing={3} rowSpacing={2} sx={{mb:2}}>
                            <Grid item>
                                <TextField 
                                    id="address" 
                                    label="Address" 
                                    variant="outlined"  
                                    value={billingAddress} 
                                    onChange={(e)=>{setBillingAddress(e.target.value)}} 
                                    inputRef={inputBillingAddress}
                                    sx={{width:300}} 
                                    disabled={detailsDisabled}
                                />
                            </Grid>

                            <Grid item>
                                <TextField 
                                    id="unit" 
                                    label="Unit no. (Optional)" 
                                    variant="outlined" 
                                    value={billingUnitNumber}  
                                    onChange={(e)=>{setBillingUnitNumber(e.target.value)}} 
                                    inputRef={inputBillingUnitNumber}
                                    sx={{width:300}} 
                                    disabled={detailsDisabled}
                                />
                            </Grid>
                        </Grid>

                        <TextField 
                            type='number' 
                            id="postal_code" 
                            label="Postal Code" 
                            variant="outlined" 
                            sx={{mb:2, width:300}}
                            //this is to limit the input to 6
                            onInput = {(e) =>{e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)}}
                            value={billingPostalCode}
                            onChange={(e)=>{setBillingPostalCode(e.target.value)}}
                            inputRef={inputBillingPostalCode}
                            disabled={detailsDisabled}
                        />
                    </Paper>

                    {deliveryDisabled ? null : 
                    // {/* this is for the delivery address
                    <Paper elevation={0} sx={{ borderRadius: 1, p:4, mb:3 }} variant="outlined"> 

                        <Typography sx={{fontWeight: 'bold', mb:2}}>Delivery Address</Typography>

                        <Grid container direction='row' justifyContent='flex-start' alignItems='center' columnSpacing={3} rowSpacing={2} sx={{mb:2}}>
                            <Grid item>
                                <TextField 
                                    id="address" 
                                    label="Address" 
                                    variant="outlined" 
                                    value={deliveryAddress} 
                                    onChange={(e)=>{setDeliveryAddress(e.target.value)}} 
                                    inputRef={inputDeliveryAddress}
                                    sx={{width:300}} 
                                    disabled={detailsDisabled}
                                />
                            </Grid>

                            <Grid item>
                                <TextField 
                                    id="unit" 
                                    label="Unit no. (Optional)" 
                                    variant="outlined" 
                                    value={deliveryUnitNumber}  
                                    onChange={(e)=>{setDeliveryUnitNumber(e.target.value)}} 
                                    inputRef={inputDeliveryUnitNumber}
                                    sx={{width:300}} 
                                    disabled={detailsDisabled}
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction='row' justifyContent='flex-start' alignItems='center' columnSpacing={3} rowSpacing={2} sx={{mb:2}}>
                            <Grid item>
                                <TextField 
                                    type='number' 
                                    id="postal_code" 
                                    label="Postal Code" 
                                    variant="outlined" 
                                    sx={{mb:2, width:300}}
                                    //this is to limit the input to 6
                                    onInput = {(e) =>{e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)}}
                                    value={deliveryPostalCode}
                                    onChange={(e)=>{setDeliveryPostalCode(e.target.value)}}
                                    inputRef={inputDeliveryPostalCode}
                                    disabled={detailsDisabled}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    }

                </Stack>
            </Grid>

            {/* this is for the right column */}
            <Grid item xs={11.5} md={12} lg={4} >

                <Paper elevation={0} sx={{ borderRadius: 1, height:'100%'}} variant="outlined" >

                    <Typography variant='h5' sx={{fontWeight: 'bold', p:4}}>Order Summary</Typography>
                    

                    {loadingItems
                    ?
                    // if loadingItem is true, it will not display anything
                    <Box sx={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', spacing:2, pl:4, mb:3}}>
                        <CircularProgress/>
                        <Typography variant="body2" component="div"  align='left' sx={{color: theme.palette.neutral.darkgrey1.main}}>Retrieving your orders</Typography>
                    </Box>
                    :
                    error
                        ?
                            //if there is an error, it will display the error message
                            <Box sx={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', spacing:2, pl:4, mb:3}}>
                                <Typography variant="body2" component="div"  align='left' sx={{color: theme.palette.neutral.darkgrey1.main}}>Something went wrong!</Typography>
                                <Typography variant="body2" component="div"  align='left' sx={{color: theme.palette.neutral.darkgrey1.main}}>Please contact the admin.</Typography>
                            </Box>
                        :
                            //if there is no error, it will then display all the checkout items
                            checkoutItems
                    }

                </Paper>
            </Grid>
        </Grid>
    </Background>   
    );
}

export default ProductCheckout;