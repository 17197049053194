import React from "react";
import NumberedSlider from "../../../components/NumberedSlider";

function Dial(props) {

   return (
    <div className="container">

      <div>
        <h3>Outer Size (mm)</h3>
        <NumberedSlider
                    setValue={props.setOuterRadius}
                    value={props.outerRadius}
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    />
      </div>

      <div>
        <h3>Inner Size (mm)</h3>
        <NumberedSlider
                    setValue={props.setInnerRadius}
                    value={props.innerRadius}
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    />
      </div>

      <div>
        <h3>Number of dials</h3>
        <NumberedSlider
                    setValue={props.setCount}
                    value={props.count}
                    valueLabelDisplay="auto"
                    min={0}
                    max={35}
                    />

      </div>
      <div>
        <h3>Spoke width (mm)</h3>
        <NumberedSlider
                    setValue={props.setSpokeWidth}
                    value={props.spokeWidth}
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    />
      </div>

      <div>
        <h3>Flare width (mm)</h3>
        <NumberedSlider
                    setValue={props.setFlareWidth}
                    value={props.FlareWidth}
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    />
      </div>

    </div>
  );
}

export default Dial;
