import React from "react";
import NumberedSlider from "../../../components/NumberedSlider";

function Square(props) {
  const { setSquareSide, squareSide } = props;

  return (
    <div className="container">
      <div>
        <h3>Side Length (mm)</h3>
        <NumberedSlider
          setValue={setSquareSide}
          value={squareSide}
          valueLabelDisplay="auto"
          min={0}
          max={1500}
        />
      </div>
    </div>
  );
}

export default Square;




